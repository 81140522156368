import { connect } from 'react-redux';
import Overlay from './overlay.ui';
import { fetchDynamicData, receiveDynamicData } from '../../state/dynamic/dynamic.action';
import options from '../../state/options/options.actions';

const mapStateToProps = (state: Object) => ({
    fps: state.options.activeVideo.video.fps,
    customPlayer: state.options.tpPlayer,
    overlay: state.options.activeOverlay,
    isOverlayClosed: state.options.isOverlayClosed,
    id: state.options.id,
    playerWidth: state.video.width,
    playerHeight: state.video.height,
    apis: state.dynamic.apis,
    overlaysDynamicData: state.dynamic.overlays,
    dynamicData: state.dynamic,
    isImage: state.options.isImage,
    volume: state.video.volume,
});

const mapDispatchToProps = {
    fetchDynamicData,
    receiveDynamicData,
    setOverlayContent: options.setOverlayContent,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Overlay);
