import { connect } from 'react-redux';

import videoActions from '../../state/video/video.actions';
import options from '../../state/options/options.actions';
import api from '../../state/api/api.actions';
import Checks from '../../hoc/checks';
import LoadOptions from '.';
import playback from '../../state/playback/playback.actions';

const mapStateToProps = (state: Object) => ({
    ...state.options,
    frame: state.video.currentFrame,
    currentTime: state.video.currentTime,
    prevFrame: state.video.prevFrame,
    volume: state.video.volume,
    subtitle: state.video.subtitle,
    player: state.video.playerReady,
    playing: state.video.playing,
    width: state.video.width,
    height: state.video.height,
    hasEnded: state.video.hasEnded,
    hasPlayed: state.video.hasPlayed,
    animationPlaying: state.video.animationPlaying,
    animationEnded: state.video.animationEnded,
    renditionsArray: state.video.renditionsArray,
    playerParams: state.options.playerParams,
    playback: state.playback,
});

const mapDispatchToProps = (dispatch: any): Object => ({
    getOptions(id: number, version: number) {
        dispatch(options.get(id, version));
    },

    // hotspotIsClicked(nameLink, spriteId) {
    //   dispatch(options.hotspotIsClicked(nameLink, spriteId))
    // },

    // hotspotClickout(nameLink: number, spriteId: number, href: string) {
    //   dispatch(options.hotspotClickout(nameLink, spriteId, href))
    // },

    // overlayEvent(overlayId, action, value) {
    //   dispatch(options.overlayEvent(overlayId, action, value))
    // },

    play() {
        dispatch(videoActions.play());
        dispatch(playback.reduxPlay()); // FIXME: this is a walk around to update playback state, not necessarily cause playback changes
    },

    pause() {
        dispatch(videoActions.pause());
        dispatch(playback.reduxPause()); // FIXME: this is a walk around to update playback state, not necessarily cause playback changes
    },

    currentFrame(frame: number, time: number) {
        dispatch(videoActions.setCurrentFrame(frame, time));
    },

    mute(mute) {
        dispatch(videoActions.mute(mute));
    },

    setVolume(volume) {
        dispatch(videoActions.setVolume(volume));
    },

    selectSubtitle(subtitle) {
        dispatch(videoActions.selectSubtitle(subtitle));
    },

    playerReady() {
        dispatch(videoActions.playerReady());
    },

    toggleOverlay(overlay) {
        dispatch(options.toggleOverlay(overlay));
    },

    toggleEmbedOverlay(open) {
        dispatch(options.toggleEmbedOverlay(open));
    },

    switchVideo(video, videoType) {
        dispatch(options.switchVideo(video, videoType));
    },

    endEndFrame() {
        dispatch(options.endEndFrame());
    },

    setDimension(dimension: Object) {
        dispatch(videoActions.setDimension(dimension));
    },

    setVideoQuality(quality: number) {
        dispatch(options.setVideoQuality(quality.toString()));
    },

    seek(frame: number, time: number) {
        dispatch(videoActions.seek(frame, time));
    },

    closeOverlayBool() {
        dispatch(options.closeOverlayBool());
    },

    setForceSpriteIdShown() {
        dispatch(options.setForceSpriteIdShown());
    },

    fullScreen(isFullScreen: boolean) {
        dispatch(options.fullScreen(isFullScreen));
    },

    // setMousePosition(position: Object) {
    //     dispatch(videoActions.setMousePosition(position));
    // },

    toggleControlsVisibility(showControls) {
        dispatch(options.toggleControlsVisibility(showControls));
    },

    // setVideoRenditions(renditions: number[]) {
    //     dispatch(options.setVideoRenditions(renditions));
    // },

    apiRejected() {
        dispatch(api.rejected('SOURCE_LOADING_REJECTED'));
    },

    reduxPlay: () => dispatch(playback.reduxPlay()),

    reduxPause: () => dispatch(playback.reduxPause()),

    reduxSeekTo: (to: number) => dispatch(playback.reduxSeekTo(to)),

    reduxSeeked: () => dispatch(playback.reduxSeeked()),
});

const connectApp = connect(
    mapStateToProps,
    mapDispatchToProps
)(Checks()(LoadOptions));

export default connectApp;
