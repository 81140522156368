import { createBrowserHistory } from 'history';
import { createStore } from 'redux';
import reducers from './state';
import middleware from './middleware';

export const history = createBrowserHistory();

const store = createStore(reducers(history), middleware);

export { store };
