import React from 'react';
import colors from '../constants/colors';

const PasswordLocked = ({ warning }: { warning: boolean }) => {
  const lockColor = warning ? colors.punch : colors.white;

  return (
    <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="66" height="68" viewBox="0 0 66 68">
      <g fill="none" fillRule="evenodd">
        <circle cx="33" cy="43" r="22" stroke={lockColor} strokeWidth="3" />
        <path stroke={lockColor} strokeWidth="3" d="M21 32.5v-18c0-6.627 5.373-12 12-12s12 5.373 12 12v18" />
        <circle cx="33" cy="43" r="6" fill={lockColor} />
      </g>
    </svg>
  );
};

export default PasswordLocked;
