import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const BannerWrapper = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1% 3%;
    transition: all 300ms ease-in-out;
    background-color: ${colors.primary};
`;

export const LogoWrapper = styled.div`
    cursor: pointer;
    margin-right: 1rem;
    width: 20%;
    max-width: 150px;
`;

export const TextWrapper = styled.div`
    margin-left: auto;
    margin-right: 2rem;
    flex: 1;
    display: flex;

    p {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.1;
        color: ${colors.heavyMetal};
        margin-left: auto;
        @media (min-width: 768px) {
            font-size: 1.5rem;
        }
    }
`;

export const Button = styled.button`
    background: ${colors.heavyMetal};
    color: ${colors.white};
    padding: 1rem 1.5rem;
    font-family: Oswald;
    @media (min-width: 768px) {
        min-width: 170px;
        padding: 1.5rem 2rem;
        font-size: 1.5rem;
    }
`;
