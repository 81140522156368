import React from 'react';
import { StyledBannerWrapper, LogoWrapper, ContentWrapper, Spacer } from './messageBanner.styles';
import WirewaxNameLogo from '../../../../assets/wirewax-name-logo';
import { WHITE_LABEL_TYPES } from '../../../../constants/whiteLabel';

const MessageBanner = ({ show, children, whitelabel }) => {
    const isLogoShown =
        typeof whitelabel === 'boolean'
            ? !whitelabel
            : whitelabel !== WHITE_LABEL_TYPES.NO_BRANDING;

    return (
        <StyledBannerWrapper show={show} color="white">
            <ContentWrapper>
                {isLogoShown && (
                    <LogoWrapper
                        onClick={() => window.open('https://www.wirewax.com/?ref=embedder')}
                    >
                        <WirewaxNameLogo />
                    </LogoWrapper>
                )}
                <Spacer width={10} />
                {children}
            </ContentWrapper>
        </StyledBannerWrapper>
    );
};

export default MessageBanner;
