import React from 'react';

const LogoBlack = () => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 177.8 82.2"
        >
            <title>Wirewax logo</title>
            <g>
                <g>
                    <path
                        fill="#1D1D1B"
                        d="M42.8,50.5c0-9.7,0-19.8-0.1-29.6l0-0.4l-0.5,0.1c-2.3,0.4-4.7,0.7-6.9,1l-0.3,0l0,0.3
			c0.1,9.9,0.2,19.9,0.2,29.7c0,9,0.1,18.2,0.2,27.4l0,0.4l0.5-0.1c2.2-0.3,4.4-0.7,6.7-1.1l0.3-0.1l0-0.3
			C42.8,68.7,42.8,59.5,42.8,50.5z"
                    />
                    <path
                        fill="#1D1D1B"
                        d="M63.8,18.7c-1.5-1.1-3.6-1.5-5.9-1c-4,0.8-8,1.5-11.7,2.1l-0.3,0.1l0,0.3c0.1,9.9,0.1,19.9,0,29.6
			c0,9-0.1,18.2,0,27.3l0,0.5l0.5-0.1c2.2-0.4,4.4-0.8,6.7-1.3l0.3-0.1l0-0.3c0-9,0-17.9,0.1-26.3c1-0.2,1.5-0.3,2.6-0.5
			c0.9-0.2,1.4-0.2,1.6,0c0.3,0.2,0.3,1,0.3,1.9c-0.1,7.2-0.1,15.6-0.1,23.9l0,0.5l0.5-0.1c2.2-0.5,4.5-1,6.9-1.5l0.3-0.1l0-0.3
			c0-8.3,0.1-16.8,0.1-23.9c0.2-2.5-0.6-4.2-2.6-5.1c2.4-1.7,2.7-4.4,2.7-6.6c0-5.1,0.1-9.7,0.1-13.9C66,21.6,65.2,19.8,63.8,18.7z
			 M58.1,40c0,1.8-0.1,1.9-1.6,2.2c-1.2,0.2-1.9,0.4-3,0.6c0-6.9,0.1-12.3,0.1-17.4c0.4-0.1,0.8-0.2,1.2-0.2c0.6-0.1,1.2-0.2,2-0.4
			c0.4-0.1,0.8,0,1,0.2c0.3,0.2,0.4,0.5,0.4,1C58.2,30.5,58.2,35,58.1,40z"
                    />
                    <path
                        fill="#1D1D1B"
                        d="M69.3,15.4L69,15.5l0,0.3c0,9.8-0.1,19.8-0.1,29.4c-0.1,8.9-0.1,18.1-0.1,27.2v0.5l0.5-0.1
			c5.6-1.3,11.1-2.6,16.7-3.9l0.3-0.1l0-6.7l-0.5,0.1c-3.1,0.7-6.1,1.5-9.2,2.2c0-6.2,0-12.4,0.1-19.7c2.6-0.6,5.3-1.2,7.9-1.8
			l0.3-0.1l0-6.7l-0.5,0.1c-2.6,0.6-5.1,1.2-7.7,1.7c0-6.9,0.1-12.2,0.1-17.3c3.1-0.7,6.1-1.3,9.2-2l0.3-0.1l0-6.7l-0.5,0.1
			C80.2,13.1,74.8,14.2,69.3,15.4z"
                    />
                    <path
                        fill="#1D1D1B"
                        d="M114.1,62.4l0-0.3c1-8.2,2-16.6,3-24.7c1.2-10.6,2.5-21.5,3.9-32.2l0.1-0.5l-0.5,0.1
			c-2.3,0.4-4.7,0.8-7.1,1.3l-0.3,0.1l0,0.3c-0.9,7.4-1.7,14.8-2.4,21.8c-1.1-8.1-1.9-14.6-2.7-20.7L108,7.2l-0.4,0.1
			c-2.2,0.4-4.3,0.9-6.5,1.3l-0.3,0.1l0,0.3c-0.8,7.3-1.6,14.5-2.4,21.5c-1-7.2-1.8-13.9-2.6-20.3l-0.1-0.4l-0.4,0.1
			c-2.4,0.5-4.8,1-7.2,1.5l-0.4,0.1l0,0.3c1.4,11,2.8,22.2,4.2,33c0.9,7.2,1.9,14.3,2.8,21.5l0.1,0.4l0.4-0.1c2.1-0.5,4.2-1,6.3-1.5
			l0.3-0.1l0-0.3c0.9-6.8,1.9-14.3,3-23.2c0.8,7.6,1.6,14.7,2.3,21.8l0,0.4l0.4-0.1c2.1-0.5,4.2-0.9,6.2-1.3L114.1,62.4z"
                    />
                    <path
                        fill="#1D1D1B"
                        d="M134.4,59l0.4-0.1c2.4-0.3,4.8-0.6,7.2-0.9l0.4,0l0-0.4c-1.1-8.4-2.3-16.9-3.4-25.2
			c-1.3-9.7-2.7-19.7-3.9-29.5l0-0.4l-0.4,0.1c-2.8,0.4-5.7,0.8-9.1,1.4l-0.3,0l0,0.3c-1.3,10.3-2.6,20.8-3.8,30.9
			c-1,8.5-2.1,17.2-3.1,25.8l-0.1,0.5l0.5-0.1c2.2-0.4,4.4-0.8,6.6-1.2l0.3,0l0-0.3c0.5-4.3,1.1-8.9,1.8-14c1.8-0.3,3.5-0.5,5.3-0.8
			c0.6,4.8,1.2,9.1,1.7,13.4L134.4,59z M128.2,39.2l0.5-4.3c0.4-3.2,0.8-6.5,1.2-9.7c0.3,2.2,0.6,4.5,0.9,6.7l0.9,6.8
			C130.4,38.9,129.5,39,128.2,39.2z"
                    />
                    <path
                        fill="#1D1D1B"
                        d="M167.2,56.4l-0.1-0.5c-2.2-9.2-4.6-18.6-7.2-28.7c2.4-9.6,4.7-18.3,6.9-26.7l0.1-0.5l-0.5,0
			c-2.4,0-4.9,0.1-7.3,0.2l-0.3,0l-0.1,0.3c-1.1,4.4-2.1,8.7-3.1,13.1c-1.3-4.2-2.5-8.4-3.7-12.6l-0.1-0.3l-0.3,0
			c-2.3,0.2-4.7,0.4-7.1,0.7l-0.5,0.1l0.1,0.4c2,6.7,4.1,13.5,6.1,20.1l1.8,5.8l-2.2,7.8c-2,7.1-4,14.5-6.1,21.7l-0.2,0.5l0.6-0.1
			c2.5-0.3,5-0.5,7.4-0.7l0.3,0l0.1-0.3c1.3-4.9,2.6-9.9,3.8-14.8c1.1,4.8,2.3,9.7,3.3,14.3l0.1,0.3l0.3,0c2.5-0.1,5-0.2,7.4-0.3
			L167.2,56.4z"
                    />
                    <path
                        fill="#1D1D1B"
                        d="M25.8,22.7l-0.3,0l0,0.3c-0.7,7.4-1.4,14.8-2,22.1c-1.1-7.8-2.1-14.8-3.1-21.5l-0.1-0.4l-0.4,0
			c-2.2,0.2-4.3,0.3-6.5,0.5l-0.3,0l0,0.3c-0.5,5.5-1,10.9-1.4,16.4l-0.5,5.1c-1-7.2-2.1-14.4-3.1-21.3L8,24.1l-0.4,0
			c-2.4,0.1-4.8,0.1-7.2,0.1l-0.5,0l0.1,0.4c2.9,19.5,5.7,39,8.3,57.2l0,0.3l0.4,0c2-0.1,4.1-0.2,6.1-0.4l0.3,0l0-0.3
			c0.8-7.3,1.6-14.9,2.5-23.2l0.4,3.7c0.7,6.3,1.4,12.7,2.2,19l0,0.4l0.4,0c2-0.2,4-0.4,6-0.7l0.3,0l0-0.3c1.1-11.7,2.4-23.5,3.7-35
			c0.8-7.5,1.7-15.3,2.5-23l0.1-0.5l-0.5,0.1C30.5,22.2,28.1,22.5,25.8,22.7z"
                    />
                </g>
                <g>
                    <path
                        fill="#1D1D1B"
                        d="M177,0.9c-0.6-0.6-1.3-0.9-2.2-0.9c-0.9,0-1.7,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2c0,0.9,0.3,1.7,0.9,2.2
			c0.6,0.6,1.3,0.9,2.2,0.9c0.9,0,1.7-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2C177.8,2.2,177.5,1.5,177,0.9z M177.3,3.1
			c0,0.8-0.2,1.4-0.7,1.9h0c-0.5,0.5-1.1,0.7-1.8,0.7c-0.8,0-1.4-0.2-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.9c0-0.8,0.2-1.4,0.7-1.9
			c0.5-0.5,1.1-0.7,1.8-0.7c0.8,0,1.4,0.2,1.8,0.7C177.1,1.7,177.3,2.3,177.3,3.1z"
                    />
                    <path
                        fill="#1D1D1B"
                        d="M175.8,3c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.9-0.3h-1.1v3.3h0.6V3.4h0.3
			l0.8,1.3h0.7l-0.9-1.3C175.5,3.3,175.7,3.2,175.8,3z M174.3,1.9h0.5c0.4,0,0.6,0.2,0.6,0.5c0,0.4-0.2,0.5-0.6,0.5h-0.5V1.9z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default LogoBlack;
