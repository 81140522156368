export const NO_SHARE = 'noShare=true';
export const CONTROLS = 'controls';
export const AUTOPLAY = 'autoPlay';
export const LOOP = 'loop';
export const SUBTITLES = 'showSubtitles';
export const SKIN_BAREBONES_SLICK = 'SkinBarebonesSlick';
export const THIRD_PARTY_ACCESS_PARAMS = {
    yp: ['youtube', 'yt', 'yp'], // yt is what's stored in vidData.json
    vimeoPlayer: ['vimeoPlayer', 'vimeo'], // vimeoPlayer is what's stored in vidData.json
};
