import styled from 'styled-components';

export const DebuggerContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    pointer-events: none;
`;

export const DebuggerWrapper = styled.div`
  width: 80%
  min-width: 370px;
  max-width: 970px;
  height:80%;
  min-height: 240px;
  max-height: 635px;
  padding-bottom: 20px;
  background: #fffffff2;
  color: #1d1d1b;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 300;
  box-shadow: 0 20px 20px 0 rgba(20, 20, 20, 0.1);
  z-index: 2;
  pointer-events: auto;
`;

export const StyledNavBar = styled.div`
    display: flex;
    padding: 15px 15px 15px 60px;
    box-shadow: 0 1px 1px 0 rgba(20, 20, 20, 0.1);
    max-height: 62px;
`;

export const Section = styled.section`
  display: table;
  flex-grow: 1;
  width: 50%;
  height: 100%;
  padding-left: 60px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-right: 1px solid #1414141a

  ${props =>
      props.right &&
      `
    padding-left: 40px;
    padding-right: 60px;
  `}
  h3 {
    margin-bottom: 40px;
  }

  li {
    border-bottom: 1px solid #1414141a
    margin-left: 35px;

    div {
      -webkit-user-select: text;
    }
  }

  @media (max-width: 768px) {
    width: auto;
    padding-left: 60px;
  }
`;

export const ItemDiv = styled.div`
    display: inline-block;
    width: 50%;
    font-weight: 600;
    margin: 1.2em 0;
    vertical-align: top;

    ${props =>
        props.value &&
        `
    font-weight: 300;
    text-align: right;
    cursor: pointer;
  `};
`;

export const StyledTable = styled.table`
    font-size: 11px;
    color: #1d1d1b;
    text-align: left;
    width: 100%;
    position: relative;

    th {
        background-color: #f3f3f3f2;
        color: #9d9d9c;
        box-shadow: 0 -1px 0 0 rgba(35, 39, 54, 0.1);
        padding: 2em 0.75em;
        white-space: nowrap;
        position: sticky;
        top: 0;
        z-index: 1;

        &:first-child {
            padding-left: 60px;
        }

        &:last-child {
            padding-right: 60px;
        }
    }

    td {
        padding: 2em 0.75em;
        -webkit-user-select: text;

        &:first-child {
            padding-left: 60px;
        }

        &:nth-child(4),
        &:nth-child(5) {
            cursor: pointer;
        }

        &:nth-child(6) {
            max-width: 130px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;
        }

        &:last-child {
            padding-right: 60px;
            display: flex;
        }
    }

    tr {
        box-shadow: 0 1px 1px 0 rgba(20, 20, 20, 0.1);
    }
`;
