import React from 'react';
import colors from '../constants/colors';

const DomainLock = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="80" height="67" viewBox="0 0 80 67">
    <path fill={colors.white} fillRule="nonzero" d="M28.951 47.798l8.507-8.317-8.507-8.318c-.609-.616-.6-1.597.02-2.203a1.633 1.633 0 0 1 2.253-.019l8.506 8.318 8.507-8.318a1.633 1.633 0 0 1 2.253.02c.62.605.628 1.586.02 2.202l-8.507 8.318 8.506 8.317c.418.395.586.979.439 1.528A1.59 1.59 0 0 1 49.8 50.45a1.632 1.632 0 0 1-1.563-.429l-8.507-8.317-8.506 8.317a1.633 1.633 0 0 1-2.253-.019 1.547 1.547 0 0 1-.02-2.203zM79.5 4.75v57c-.003 2.622-2.137 4.747-4.77 4.75H4.77C2.137 66.497.003 64.372 0 61.75v-57C.003 2.128 2.137.003 4.77 0h69.96c2.633.003 4.767 2.128 4.77 4.75zM3 12.5h22.5V3H4.607C3.72 3 3 3.709 3 4.583V12.5zM76.5 16h-73v45.917c0 .874.71 1.583 1.587 1.583h69.826c.876 0 1.587-.709 1.587-1.583V16zM76 4.583C76 3.71 75.291 3 74.417 3H28.5v9.5H76V4.583zM6.5 9.5h3v-3h-3v3zm6.5 0h3v-3h-3v3zm6 0h3v-3h-3v3z" />
  </svg>
);

export default DomainLock;
