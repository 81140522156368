import React from 'react';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import type { Props } from './button.types';

const ButtonWrapper = styled.div`
    background: ${colors.heavyMetal};
    padding: 1em 0;
    width: 10em;
    color: ${colors.white};
    cursor: pointer;
    text-align: center;
    transition: all 0.1s ease-in;
    margin-left: auto;
    &:hover {
        outline: 1px solid ${colors.heavyMetal};
        background: transparent;
        color: ${colors.heavyMetal};
    }
`;

const Button = ({ onClick, children }: Props) => (
    <ButtonWrapper onClick={onClick}>{children}</ButtonWrapper>
);

export default Button;
