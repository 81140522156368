export const getTimeFromFrame = (frame, fps, totalFrameCount) => {
    const calc = (frames, increment) => Math.floor(frames / fps / increment);
    const format = input => (input.toString().length < 2 ? `0${input}` : input.toString());
    const hours = calc(frame, 3600);
    const minutes = calc(frame, 60) % 60;
    const seconds = Math.floor((frame / fps) % 60);
    const frames = Math.round(frame % fps);

    const timeFromFrame = {
        hours,
        minutes,
        seconds,
        frames,
        formattedTime: `${format(hours)}:${format(minutes)}:${format(seconds)}:${format(frames)}`,
    };

    if (totalFrameCount) {
        const totalHours = calc(totalFrameCount, 3600);
        const totalMinutes = calc(totalFrameCount, 60) % 60;

        timeFromFrame.truncatedTime = `${(totalHours > 0 ? `${format(hours)}:` : '') +
            (totalMinutes > 0 ? `${format(minutes)}:` : '')}${format(seconds)}:${format(frames)}`;
    }

    return timeFromFrame;
};
