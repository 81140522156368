import type { Node } from 'react';

const openFullscreen = (elem: Object) => {
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
    }
};

const closeFullscreen = () => {
    if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
    } else if (window.document.mozCancelFullScreen) {
        /* Firefox */
        window.document.mozCancelFullScreen();
    } else if (window.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        window.document.webkitExitFullscreen();
    } else if (window.document.msExitFullscreen) {
        /* IE/Edge */
        window.document.msExitFullscreen();
    }
};

export const isFullScreen = (fullScreenWrapper: Node) => {
    const fullScreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;

    return fullScreenWrapper === fullScreenElement;
};

// eslint-disable-next-line import/prefer-default-export
export const toggleFullScreen = (wrapper: Object) => {
    const { fullScreenWrapper, innerWrapper } = wrapper;

    if (!fullScreenWrapper || !innerWrapper) {
        return null;
    }

    if (!isFullScreen(fullScreenWrapper)) {
        openFullscreen(fullScreenWrapper);
    } else {
        closeFullscreen();
    }

    // Focus video wrapper unless toggleFullScreen triggered by keyboard
    const focusedElement = document.activeElement;

    if (!focusedElement || !focusedElement.classList.contains('focus-visible')) {
        innerWrapper.focus();
    }
};

export const getBrowserVisibilityValues = () => {
    const browserVisibility = {
        hidden: '',
        visibilityChange: '',
    };

    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        browserVisibility.hidden = 'hidden';
        browserVisibility.visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        browserVisibility.hidden = 'msHidden';
        browserVisibility.visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        browserVisibility.hidden = 'webkitHidden';
        browserVisibility.visibilityChange = 'webkitvisibilitychange';
    }

    return browserVisibility;
};
