import React from 'react';
import type { Props } from './notification.types';

const Notification = ({ type = 'alert-success', message = '' }: Props) =>
    message.length > 0 ? (
        <div className={`alert ${type} m-2`} role="alert">
            {message}
        </div>
    ) : (
        ''
    );

export default Notification;
