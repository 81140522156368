import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { Props, State } from './checks.types'

import DomainLock from '../../assets/domain-lock';
import RegionLock from '../../assets/region-lock';
import LockScreen from '../../component/lockScreen'
import checks from '../../state/checks/checks.actions'
import options from '../../state/options/options.actions'
import passwordActions from '../../state/password/password.actions'
import PasswordProtected from '../../component/passwordProtected'
import Notification from '../../component/notification'
import { getUrlParams, getEmbedOptions } from '../../component/loadOptions/loadOptions.helpers';
import LoadingSpinner from '../../component/common/loadingSpinner';
import Logger from '../../utils/Logger';

// TODO Move request to service and src to endpoints
import { isOfflinePackage } from '../../constants/appConfig';

const mapStateToProps = (state: Object) => ({
  ...state.checks,
  notify: { ...state.notification },
})

const mapDispatchToProps = (dispatch: any) => ({
  getChecks(id: number, password: string) {
    dispatch(checks.get(id, password))
  },

  setVideoId(id: number) {
    dispatch(options.setVideoId(id))
  },

  passwordCheck(e, id, password) {
    if (e) {
      e.preventDefault()
    }
    dispatch(passwordActions.passwordCheck(id, password))
  },

  passwordClear() {
    dispatch(passwordActions.passwordClear());
  },

  setUserAgent() {
    dispatch(options.setUserAgent());
  },

  overrideEmbedLoc(embedLoc: string) {
    dispatch(options.overrideEmbedLoc(embedLoc));
  },

  setPlayerParams(params: Object) {
    dispatch(options.setPlayerParams(params));
  },
})

const Checks = () => (ComposedComponent: any) => {
  class Checks extends Component<Props, State> {
    constructor(props: Object) {
      super(props);

      this.state = {
        password: '',
        id: this.props.match.params.id,
      }

      const {
        location: { search },
      } = props;

      const query = getUrlParams(search);
      this.options = getEmbedOptions(query);
    }

    componentDidMount() {
      const pathname = this.props.location.pathname;
      let vidId = this.state.id;
      
      if (isOfflinePackage && pathname) {
        const offlinePackageVidIdRegEx = /.+\/(\d+)(\/index\.html)?/;
        const [, urlVidId] = pathname.match(offlinePackageVidIdRegEx);
        vidId = parseInt(urlVidId);
        this.setState({ id: vidId });
      }

      this.onComponentDidMount(vidId);
    }

    onComponentDidMount = (id) => {
      const {
        getChecks,
        setVideoId,
        setUserAgent,
        overrideEmbedLoc,
        setPlayerParams,
        location: { search },
      } = this.props

      this.options.debug && Logger.enable();

      const query = getUrlParams(search);

      getChecks(id, query.password);
      setVideoId(id);
      setUserAgent();
      query.embedLoc && overrideEmbedLoc(query.embedLoc);
      setPlayerParams(this.options);
    }

    clearForm = () => {
      const { passwordClear } = this.props;

      this.setState({
        password: '',
      })

      if (this.passwordInput && document.activeElement !== this.passwordInput) {
        this.passwordInput.focus();
      }

      passwordClear();
    }

    _onChange = (e) => {
      const {
        notify: { type },
      } = this.props;

      if (type === 'alert-danger') {
        this.clearForm();
      }

      this.setState({
        password: e.target.value,
      })
    }

    render = () => {
      const {
        passwordCheck,
        passwordProtected,
        countryLock,
        domainLock,
        notify: { type, message },
      } = this.props
      const { password, id } = this.state

      const { clearLoaders } = this.options;

      return (
        <>
          <Notification
            type={type}
            message={message}
          />
          {(() => {
            if (domainLock || countryLock) {
              return domainLock
                ? <LockScreen Icon={<DomainLock />} message="This video is not available on this domain." />
                : <LockScreen Icon={<RegionLock />} message="This video is not available in your region." />
            }
            switch (passwordProtected) {
            case true:
              return (
                <PasswordProtected
                  clear={this.clearForm}
                  inputRef={(el) => { this.passwordInput = el }}
                  input={this.passwordInput}
                  submit={(e) => {
                    passwordCheck(e, id, password);
                  }}
                  onChange={this._onChange}
                  value={password}
                  warning={type === 'alert-danger'}
                />
              )
            case false:
              return (
                <ComposedComponent
                  {...this.props}
                  id={id}
                />
              )
            default:
              return !clearLoaders ? <LoadingSpinner transparent /> : ''
            }
          })()}
        </>
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Checks)
}

export default Checks
