const initialState = {
    loaded: false,
    activeOverlay: {},
    activeVideo: {
        hotspots: [],
        hotspotDesign: {},
        video: {
            renditionsArray: [],
        },
        subtitles: [],
        timeTriggers: [],
    },
    mainVideo: {},
    endFrameVideo: {},
    videoType: '', // For endframe video
    endFrameEnded: false,
    videoQuality: null,
    id: null,
    uuId: '',
    isFullScreen: false,
    isOverlayClosed: true,
    embedOverlay: false,
    forceSpriteIdShown: false,
    videoRenditions: [],
    isEndFrameShown: false,
    playerParams: {},
    hasHls: false,
    isImage: false,
    imageSource: '',
};

export default initialState;
