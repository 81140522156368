import React from 'react';
import { connect } from 'react-redux';
import type { Props } from './imageLoader.types';
import { ImageWrapper } from './imageLoader.styles';

const ImageLoader = ({ src }: Props) => <ImageWrapper src={src} />;

const mapStateToProps = (state: Object) => ({
    src: state.options.imageSource,
});

export default connect(mapStateToProps)(ImageLoader);
