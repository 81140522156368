import React, { Fragment } from 'react';
import ClearIcon from '../../assets/clear-icon';
import EyeClosed from '../../assets/eye-closed';
import EyeOpen from '../../assets/eye-open';
import PasswordLocked from '../../assets/password-locked';
import ScreenReaderOnly from '../common/screenReaderOnly';
import {
    PasswordProtectedStyled,
    PasswordMessage,
    InputContainer,
    Label,
    Input,
    ShowHideButton,
    SubmitButton,
    ButtonText,
} from './passwordProtected.styles';
import type { Props, State } from './passwordProtected.types';

class PasswordProtected extends React.Component<Props, State> {
    state = { passwordHidden: true };

    handleShowHide = () => {
        const { passwordHidden } = this.state;
        const { input } = this.props;

        this.setState({ passwordHidden: !passwordHidden });

        if (input) {
            input.focus();
        }
    };

    render = () => {
        const { clear, onChange, inputRef, submit, value, warning } = this.props;

        const { passwordHidden } = this.state;

        const clearWhenWarned = () => warning && clear();

        return (
            <PasswordProtectedStyled as="form" onSubmit={submit}>
                <PasswordLocked warning={warning} />
                <PasswordMessage>This video is password protected</PasswordMessage>
                <InputContainer>
                    <Label htmlFor="ww-player-password" reposition={value && value.length > 0}>
                        Enter password
                    </Label>
                    <Input
                        autoComplete="off"
                        id="ww-player-password"
                        onFocus={clearWhenWarned}
                        onChange={onChange}
                        placeholder=""
                        ref={inputRef}
                        type={passwordHidden ? 'password' : 'text'}
                        value={value}
                    />
                    <ShowHideButton onClick={this.handleShowHide} role="button" tabIndex="0">
                        {passwordHidden ? <EyeClosed /> : <EyeOpen />}
                    </ShowHideButton>
                </InputContainer>
                <SubmitButton
                    disabled={value.length === 0}
                    onClick={warning ? clear : submit}
                    warning={warning}
                    type="submit"
                >
                    {warning ? (
                        <Fragment>
                            <ScreenReaderOnly>Clear password</ScreenReaderOnly>
                            <ClearIcon />
                            <ButtonText>INCORRECT</ButtonText>
                        </Fragment>
                    ) : (
                        'SUBMIT'
                    )}
                </SubmitButton>
            </PasswordProtectedStyled>
        );
    };
}

export default PasswordProtected;
