import React from 'react';
import type { RowProps } from '../debugger.types';
import Button from '../../../component/common/button';
import GoToHotSpotIcon from '../../../assets/go-to-hotspot';
import OpenOverlayIcon from '../../../assets/open-overlay';
import copyToClipboard from '../../../utils/copyToClipboard';
import { getTimeFromFrame } from '../../../utils/timeFormatConverter';

const getFormattedRowUnit = props => {
    const { hotspot, timeTrigger, clickAction, fps } = props;

    const { show, hide, pauseOnClick = false } = hotspot || timeTrigger || {};
    const { isStatic = false, spriteId = '-' } = hotspot || {};
    const inPoint = getTimeFromFrame(isStatic ? Number(show) * fps : show, fps).formattedTime;
    const outPoint =
        hide !== undefined
            ? getTimeFromFrame(isStatic ? Number(hide) * fps : hide, fps).formattedTime
            : '-';

    let actionType = clickAction || '-';
    const { type } = timeTrigger || {};

    switch (type) {
        case undefined:
            break;
        case 0:
            actionType = 'seek';
            break;
        case 1:
            actionType = 'pause';
            break;
        case 2:
            actionType = 'deprecated';
            break;
        case 3:
            actionType = 'overlay';
            break;
        default:
            break;
    }

    return {
        actionType,
        isStatic,
        inPoint,
        outPoint,
        spriteId,
        show,
        pauseOnClick,
    };
};

const Row = (props: RowProps) => {
    const {
        hasPlayed,
        InteractionType,
        overlayId = '-',
        url,
        name,
        targetOverlay,
        toggleOverlay,
        play,
        skip,
        pause,
        close,
    } = props;

    const {
        actionType,
        spriteId,
        isStatic,
        show,
        inPoint,
        outPoint,
        pauseOnClick,
    } = getFormattedRowUnit(props);

    const goToTag = () => {
        // Close debugger, close overlay, seek
        close();
        if (!hasPlayed) play();
        toggleOverlay({});
        skip(show, isStatic ? 'sec' : 'fps');
    };

    const openOverlay = () => {
        // Go to tag, open overlay, change video playback
        goToTag();
        if (targetOverlay) toggleOverlay(targetOverlay);
        if (pauseOnClick) pause();
    };

    return (
        <tr>
            <td>{name}</td>
            <td>{InteractionType}</td>
            <td>{actionType}</td>
            <td onClick={copyToClipboard}>{overlayId}</td>
            <td onClick={copyToClipboard}>{spriteId}</td>
            <td>
                {url ? (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {url}
                    </a>
                ) : (
                    '-'
                )}
            </td>
            <td>{inPoint}</td>
            <td>{outPoint}</td>
            <td>
                <Button label="jump" title="Skip to hotspot" onClick={() => goToTag()}>
                    <GoToHotSpotIcon />
                </Button>

                {actionType === 'overlay' && (
                    <Button label="jump" title="Open overlay" onClick={() => openOverlay()}>
                        <OpenOverlayIcon />
                    </Button>
                )}
            </td>
        </tr>
    );
};

export default Row;
