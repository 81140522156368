export const DB = 'wirewax';

export const KEYS = {
    AWS_ACCESS_KEY: 'AKIASCR5PHCG5ZMVCKUG',
    AWS_SECRET_KEY: '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',
};

export const QUEUES = {
    QUIZ_RESULTS_QUEUE:
        'https://sqs.eu-west-1.amazonaws.com/142936586381/dolphin-quiz-dolphinQuizProd-6LEC5KAOGVBV',
};

export const USER = 'wirewax-user';
export const QUIZ_SCORE_KEY = 'wirewax-quiz-score';
