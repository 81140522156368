import {
    PLAY,
    PAUSE,
    SET_CURRENT_FRAME,
    TOGGLE_MUTE,
    SET_VOLUME,
    SELECT_SUBTITLE,
    PLAYER_READY,
    SET_DIMENSION,
    SEEK,
    END_FRAME_ANIMATION_ENDED,
    END_FRAME_ANIMATION_PLAYING,
    SET_MOUSE_POSITION,
} from './video.constant';
import utils from '../../utils/index';

const play = (): Object => ({
    type: PLAY,
});

const pause = (): Object => ({
    type: PAUSE,
});

const mute = (isMute: boolean): Object => ({
    type: TOGGLE_MUTE,
    isMute,
});

const selectSubtitle = (lang: string): Object => ({
    type: SELECT_SUBTITLE,
    lang,
});

const setCurrentFrame = (frame: number, time: number): Object => ({
    type: SET_CURRENT_FRAME,
    frame,
    time,
});

const seek = (frame: number, time: number): Object => ({
    type: SEEK,
    frame,
    time,
});

const playerReady = (): Object => ({
    type: PLAYER_READY,
});

const setVolume = (volume: number): Object => ({
    type: SET_VOLUME,
    volume: utils.clamp(volume, 0, 1),
});

const setDimension = (dimension: Object): Object => ({
    type: SET_DIMENSION,
    dimension,
});

const endFrameAnimationPlaying = (animationPlaying: boolean): Object => ({
    type: END_FRAME_ANIMATION_PLAYING,
    animationPlaying,
});

const endFrameAnimationEnded = (animationEnded: boolean): Object => ({
    type: END_FRAME_ANIMATION_ENDED,
    animationEnded,
});

const setMousePosition = (position: Object): Object => ({
    type: SET_MOUSE_POSITION,
    position,
});

export default {
    play,
    pause,
    setCurrentFrame,
    mute,
    setVolume,
    selectSubtitle,
    playerReady,
    setDimension,
    seek,
    endFrameAnimationEnded,
    endFrameAnimationPlaying,
    setMousePosition,
};
