import React from 'react';
import colors from '../constants/colors';

const EyeClosed = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
    <g fill="none" fillRule="evenodd" stroke={colors.white} strokeLinecap="round" strokeLinejoin="round" opacity=".25">
      <path d="M13.5 4.732A16.79 16.79 0 0 1 15.5 7S12 12 8 12a5.35 5.35 0 0 1-1.504-.225M4.275 10.725C2.048 9.243.5 7 .5 7S4 2 8 2c1.326 0 2.605.553 3.712 1.288" />
      <path fill="#D8D8D8" d="M1 14L15 0" />
    </g>
  </svg>
);

export default EyeClosed;
