import React, { useState, useEffect } from 'react';
import videojs from 'video.js';
import type { Props } from './videoInVideo.types';
import { VideoInVideoWrapper, VideoElement, TrackElement } from './videoInVideo.styles';

const VideoInVideo = ({ endComponent, videoSrc, ...rest }: Props) => {
    const [videoNode, setVideoNode] = useState();
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(
        () => {
            if (videoNode) {
                const video = videojs(videoNode, { ...rest });

                video.on('ended', () => endComponent && setShouldRender(true));
            }
        },
        [videoNode, shouldRender]
    );

    return shouldRender ? (
        endComponent
    ) : (
        <VideoInVideoWrapper data-vjs-player>
            <VideoElement ref={setVideoNode} src={videoSrc} className="video-in-video">
                <TrackElement kind="captions" />
            </VideoElement>
        </VideoInVideoWrapper>
    );
};

export default VideoInVideo;
