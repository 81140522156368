import React, { useState } from 'react';
import {
    AnimationWrapper,
    LogoWrapper,
    ButtonWrapper,
    BreakerComponent,
} from './endFrameAnimation.styles';
import WirewaxTaglineLogo from '../../../../../assets/wirewax-tagline-logo';
import CommonButton from '../../../../../component/common/commonButton';
import type { Props } from './endFrameAnimation.types';
import colors from '../../../../../styles/colors';

const EndFrameAnimation = ({
    restart,
    width,
    height,
    endFrameAnimationEnded,
    endFrameAnimationPlaying,
}: Props) => {
    const buttonProps = {
        width: '24%',
        height: '46%',
        // fontSize: '3em',
        mobileWidth: '90%',
        textClass: 'animated-text',
        border: `3px solid ${colors.heavyMetal}`,
    };

    const leftButtonStyle = {
        background: 'transparent',
        hoverColor: colors.white,
        color: colors.heavyMetal,
        hoverBackground: colors.heavyMetal,
    };

    const rightButtonStyle = {
        hoverColor: colors.heavyMetal,
        hoverBackground: colors.primary,
    };

    const [display, setDisplay] = useState('flex');

    return (
        <AnimationWrapper display={display} height={height} width={width}>
            <LogoWrapper>
                <WirewaxTaglineLogo />
            </LogoWrapper>
            <ButtonWrapper>
                <CommonButton
                    className="animated-button left"
                    onClick={() => {
                        setDisplay('none');
                        endFrameAnimationEnded(true);
                        endFrameAnimationPlaying(false);
                        restart();
                    }}
                    {...buttonProps}
                    {...leftButtonStyle}
                >
                    REPLAY
                </CommonButton>
                <BreakerComponent />
                <CommonButton
                    className="animated-button right"
                    onClick={() => window.open('https://www.wirewax.com/contact?ref=embedder')}
                    {...buttonProps}
                    {...rightButtonStyle}
                >
                    REQUEST A TRIAL
                </CommonButton>
            </ButtonWrapper>
        </AnimationWrapper>
    );
};

export default EndFrameAnimation;
