import styled from 'styled-components';

export const EndFrameWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: black;
    z-index: 10;

    .focus-visible {
        outline: none;
    }

    .video-in-video {
        display: flex;
    }
`;

export const EndFrameDiv = styled.div``;
