export const standardSizes = {
    xsmall: 300,
    small: 480,
    medium: 768,
    large: 1024,
    xlarge: 1200,
    xxlarge: 1800,
};

const breakpoints = {
    xs: `@media only screen and (min-width: ${standardSizes.xsmall}px)`,
    sm: `@media only screen and (min-width: ${standardSizes.small}px)`,
    md: `@media only screen and (min-width: ${standardSizes.medium}px)`,
    lg: `@media only screen and (min-width: ${standardSizes.large}px)`,
    xl: `@media only screen and (min-width: ${standardSizes.xlarge}px)`,
    xxl: `@media only screen and (min-width: ${standardSizes.xxlarge}px)`,

    maxXs: `@media only screen and (mn-width: ${standardSizes.xsmall}px)`,
    maxSm: `@media only screen and (max-width: ${standardSizes.small}px)`,
    maxMd: `@media only screen and (max-width: ${standardSizes.medium}px)`,
    maxLg: `@media only screen and (max-width: ${standardSizes.large}px)`,
    maxXl: `@media only screen and (max-width: ${standardSizes.xlarge}px)`,
    maxXxl: `@media only screen and (max-width: ${standardSizes.xxlarge}px)`,
};

export default breakpoints;
