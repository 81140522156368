import React from 'react';

type WirewaxNameLogoProps = {
  height?: string,
  width?: string,
  viewBox?: string,
  fill?: string,
};

const defaults = {
  height: '100',
  width: '170',
  viewBox: '60 50 500 309.6',
  fill: '',
};

const WirewaxNameLogo = ({ height, width, viewBox, fill }: WirewaxNameLogoProps) => (
  <svg
    className="wirewax-name-logo"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox={viewBox}
    width={width}
    height={height}
    fill={fill}
  >
    <g>
      <g>
        <path
          className="st0"
          d="M203.6,227.1c0-22.9,0-46.7-0.2-70l0-1l-1.1,0.2c-5.5,0.9-11,1.7-16.3,2.5l-0.8,0.1l0,0.8
        c0.3,23.3,0.4,47.1,0.5,70.1c0.1,21.2,0.2,43.1,0.4,64.6l0,1l1.1-0.2c5.1-0.8,10.5-1.7,15.8-2.6l0.8-0.1l0-0.7
        C203.5,270.1,203.6,248.2,203.6,227.1z"
        />
        <path
          className="st0"
          d="M253,152c-3.5-2.7-8.5-3.5-14-2.4c-9.5,1.8-18.8,3.5-27.7,5.1l-0.8,0.1l0,0.8c0.2,23.3,0.1,47,0.1,69.9
            c-0.1,21.2-0.2,43,0,64.6l0,1.1l1.1-0.2c5.1-0.9,10.5-2,15.9-3.1l0.8-0.2l0-0.7c-0.1-21.2,0-42.4,0.2-62.2
            c2.3-0.4,3.7-0.7,6.2-1.2c2.2-0.4,3.3-0.4,3.9,0c0.7,0.6,0.8,2.4,0.8,4.4c-0.2,16.9-0.3,36.8-0.2,56.5l0,1.1l1.1-0.2
            c5.2-1.1,10.6-2.3,16.3-3.5l0.7-0.2l0-0.7c0-19.7,0.1-39.7,0.3-56.5c0.6-6-1.4-10-6.2-12c5.8-4,6.5-10.3,6.4-15.5
            c0.1-12.2,0.2-22.9,0.2-32.8C258.2,158.9,256.4,154.6,253,152z M239.7,202.2c0,4.2-0.3,4.5-3.8,5.2c-2.9,0.6-4.5,0.9-7.1,1.4
            c0.1-16.4,0.2-29.1,0.1-41.2c1-0.2,1.9-0.4,2.7-0.5c1.5-0.3,2.8-0.6,4.8-0.9c1-0.2,1.8,0,2.5,0.5c0.6,0.5,1,1.3,1,2.3
            C239.8,179.8,239.8,190.4,239.7,202.2z"
        />
        <path
          className="st0"
          d="M266,144.2l-0.7,0.2l0,0.7c0,23.2-0.2,46.7-0.3,69.5c-0.2,21-0.3,42.8-0.3,64.2v1.1l1.2-0.3
            c13.1-3,26.1-6.1,39.3-9.3l0.7-0.2l0-15.9l-1.2,0.3c-7.2,1.7-14.4,3.4-21.6,5.1c0-14.8,0.1-29.3,0.2-46.5
            c6.2-1.4,12.4-2.8,18.6-4.2l0.7-0.2l0-15.8l-1.2,0.3c-6,1.4-12.1,2.7-18.1,4.1c0.1-16.2,0.1-28.9,0.2-40.8
            c7.2-1.6,14.5-3.1,21.7-4.7l0.7-0.2l0-15.9l-1.2,0.2C291.8,138.7,279.1,141.4,266,144.2z"
        />
        <path
          className="st0"
          d="M372,255.1l0.1-0.6c2.4-19.4,4.7-39.1,7-58.2c2.9-25,6-50.8,9.2-76.1l0.2-1.2l-1.3,0.2
            c-5.4,1-11.1,2-16.8,3.1l-0.7,0.1l-0.1,0.6c-2,17.5-3.9,34.9-5.7,51.5c-2.6-19.1-4.6-34.4-6.4-48.8l-0.1-1l-1,0.2
            c-5.1,1-10.2,2.1-15.3,3.1l-0.7,0.1l-0.1,0.6c-1.9,17.2-3.8,34.2-5.6,50.9c-2.3-17.1-4.3-32.8-6.2-48l-0.1-1l-1,0.2
            c-5.7,1.2-11.3,2.4-17,3.7l-0.8,0.2l0.1,0.8c3.3,26,6.7,52.4,10,77.9c2.2,16.9,4.4,33.8,6.5,50.7l0.1,1l1-0.2c5-1.2,10-2.3,15-3.5
            l0.6-0.1l0.1-0.6c2.1-16.2,4.4-33.7,7.1-54.8c1.9,18,3.7,34.7,5.4,51.6l0.1,1l1.1-0.2c4.9-1.1,9.9-2.1,14.6-3.1L372,255.1z"
        />
        <path
          className="st0"
          d="M419.8,247.1l1-0.1c5.6-0.8,11.3-1.4,16.9-2.1l0.9-0.1l-0.1-0.9c-2.6-19.8-5.3-40-8-59.5
            c-3.1-22.8-6.4-46.5-9.3-69.7l-0.1-0.9l-1,0.1c-6.6,0.9-13.6,2-21.4,3.3l-0.7,0.1l-0.1,0.7c-3.1,24.3-6.1,49.1-9,73.1
            c-2.4,20-4.9,40.7-7.4,61l-0.2,1.2l1.3-0.2c5.2-1,10.4-1.9,15.6-2.8l0.7-0.1l0.1-0.7c1.3-10.1,2.7-20.9,4.2-33.1
            c4.2-0.7,8.4-1.3,12.5-1.9c1.4,11.2,2.7,21.6,4,31.6L419.8,247.1z M405.2,200.5l1.3-10.1c0.9-7.6,1.9-15.3,2.8-22.9
            c0.7,5.3,1.4,10.5,2.1,15.8l2.1,16C410.3,199.7,408.3,200,405.2,200.5z"
        />
        <path
          className="st0"
          d="M497.4,240.9l-0.3-1.1c-5.3-21.6-10.8-43.8-17-67.9c5.8-22.7,11.1-43.3,16.2-63.1l0.3-1.1l-1.2,0
            c-5.7,0.1-11.5,0.2-17.3,0.5l-0.7,0l-0.2,0.7c-2.5,10.3-5,20.6-7.4,30.9c-3-9.9-5.9-19.9-8.8-29.8l-0.2-0.7l-0.8,0.1
            c-5.5,0.4-11.2,1-16.8,1.6l-1.1,0.1l0.3,1c4.7,15.9,9.6,31.9,14.4,47.5l4.2,13.8l-5.2,18.5c-4.7,16.8-9.6,34.2-14.4,51.3l-0.4,1.3
            l1.4-0.1c5.8-0.6,11.7-1.1,17.5-1.6l0.7-0.1l0.2-0.6c3-11.7,6-23.4,9.1-35c2.7,11.4,5.3,22.8,7.9,33.8l0.2,0.7l0.8,0
            c5.9-0.3,11.7-0.5,17.5-0.6L497.4,240.9z"
        />
        <path
          className="st0"
          d="M163.3,161.5l-0.8,0.1l-0.1,0.7c-1.6,17.4-3.2,35-4.8,52.1c-2.5-18.4-4.9-35.1-7.2-50.8l-0.1-0.8l-0.9,0.1
            c-5.1,0.4-10.2,0.8-15.2,1.1l-0.8,0.1l-0.1,0.8c-1.1,12.9-2.3,25.8-3.4,38.8l-1.1,12.1c-2.5-17.1-5-33.9-7.4-50.3l-0.1-0.8l-0.8,0
            c-5.7,0.2-11.4,0.3-17,0.3l-1.1,0l0.2,1c6.8,46,13.5,92.1,19.7,135.1l0.1,0.8l0.9,0c4.8-0.2,9.6-0.5,14.4-0.9l0.8-0.1l0.1-0.7
            c1.8-17.1,3.7-35.1,5.9-54.8l1,8.7c1.7,14.9,3.4,29.9,5.1,44.8l0.1,0.9l0.9-0.1c4.7-0.5,9.5-1,14.3-1.6l0.8-0.1l0.1-0.7
            c2.7-27.5,5.8-55.5,8.7-82.6c1.9-17.8,4-36.2,5.8-54.3l0.1-1.1l-1.2,0.2C174.5,160.2,168.8,160.9,163.3,161.5z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M520.4,109.4c-1.3-1.4-3.1-2.1-5.3-2.1c-2.2,0-3.9,0.7-5.3,2.1c-1.3,1.4-2,3.2-2,5.3c0,2.2,0.7,3.9,2,5.3
            c1.3,1.4,3.1,2.1,5.3,2.1c2.1,0,3.9-0.7,5.3-2.1c1.3-1.4,2-3.2,2-5.3C522.4,112.6,521.7,110.8,520.4,109.4z M521.1,114.7
            c0,1.9-0.5,3.4-1.6,4.5h0c-1.1,1.1-2.5,1.7-4.4,1.7c-1.8,0-3.3-0.6-4.4-1.7c-1.1-1.1-1.6-2.6-1.6-4.5c0-1.9,0.5-3.4,1.6-4.5
            c1.1-1.1,2.5-1.7,4.4-1.7c1.8,0,3.3,0.6,4.4,1.7C520.6,111.4,521.1,112.9,521.1,114.7z"
        />
        <path
          className="st0"
          d="M517.6,114.6c0.3-0.4,0.5-1,0.5-1.6c0-0.6-0.2-1.2-0.7-1.6c-0.5-0.4-1.2-0.7-2.1-0.7h-2.6v7.8h1.4v-3.1h0.7
            l1.9,3.1h1.7l-2.1-3.2C516.9,115.2,517.3,114.9,517.6,114.6z M514.1,111.8h1.2c1,0,1.4,0.4,1.4,1.2c0,0.8-0.4,1.2-1.4,1.2h-1.2
            V111.8z"
        />
      </g>
    </g>
  </svg>
);

WirewaxNameLogo.defaultProps = defaults;
export default WirewaxNameLogo;
