import React from 'react';
import StyledTab from './Tab.styles';
import type { Props } from './Tab.types';

const Tab = (props: Props) => {
    const { label, currentTab, onClick, children } = props;
    const isFocused = currentTab === label;

    return (
        <StyledTab value={label} isFocused={isFocused} label={label} onClick={onClick}>
            {children || label}
        </StyledTab>
    );
};

export default Tab;
