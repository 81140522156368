import { connect } from 'react-redux';
import EmbedOverlay from './embedOverlay.ui';

const mapStateToProps = (state: Object) => ({
    video: state.options.activeVideo.video,
    vidId: state.options.id,
    isImage: state.options.isImage,
});

export default connect(mapStateToProps)(EmbedOverlay);
