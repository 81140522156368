/**
 * Based on the proposed CSS :focus-visible pseudo-selector,
 * this prototype adds a focus-visible class to the focused element,
 * in situations in which the :focus-visible pseudo-selector should match.
 *
 * This polyfill will ensure that focus states
 * (be it Chrome's blue outline or a custom focus states)
 * are only VISIBLE to the user when she or he uses the keyboard to access them.
 *
 * It is purely stylistic and does not affect element focus in any way.
 *
 * https://github.com/WICG/focus-visible
 *
 * ^ WICG is THE authority on matters of accessibility.
 */

const focusVisible = '.focus-visible';

export default focusVisible;
