import React, { lazy, Suspense } from 'react';
// import VideoPlayer from '@wirewaxorg/player';
import PlayIcon from '../playIcon';
import UnMute from '../unMute';
// import { ControlText, Button } from "../loadOptions/loadOptions.styles";
import type { Props } from './videoLoader.types';
import LoadingSpinner from '../common/loadingSpinner';

const VideoPlayer = lazy(() => import('../../features/player'));

const VideoLoader = React.forwardRef(
    (
        {
            customPlayer,
            playIconAction,
            showMuteButton,
            hideMainPlayButton,
            hideMainPlayButtonIcon,
            muteIconAction,
            endFrameEnded,
            restart,
            options,
            options: { clearLoaders = false },
            ...rest
        }: Props,
        ref
    ) => (
        <>
            <Suspense fallback={clearLoaders ? null : <LoadingSpinner transparent />}>
                <VideoPlayer {...rest} options={options} ref={ref} customPlayer={customPlayer} />
            </Suspense>
            {showMuteButton && !customPlayer && <UnMute unMute={muteIconAction} />}
            {!hideMainPlayButton &&
                !customPlayer && (
                    <PlayIcon
                        onClick={playIconAction}
                        hideMainPlayButtonIcon={hideMainPlayButtonIcon}
                    />
                )}
            {/* FIXME: Un styled Restart button, remove it for now */}
            {/* {endFrameEnded && !customPlayer && (
                <Button className="restart" onClick={restart}>
                <span aria-hidden="true" />
                <ControlText aria-live="polite">restart</ControlText>
                </Button>
            )} */}
        </>
    )
);

VideoLoader.displayName = 'VideoLoader';
export default VideoLoader;
