import '@babel/polyfill';
import 'focus-visible';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import dotenv from 'dotenv';

import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import loadOptions from './component/loadOptions/loadOptions.container';
import GlobalStyle from './constants/globalStyle';
import { store, history } from './store';
import ErrorScreen from './component/errorScreen';
import { error as errorUrl } from './constants/urls';
import { isOfflinePackage } from './constants/appConfig';
import endpoints from './constants/endpoints';

import 'url-polyfill';

dotenv.config();

const root = document.getElementById('root');

if (root !== null) {
    ReactDOM.render(
        <>
            <GlobalStyle />
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path={errorUrl} component={ErrorScreen} />
                        {isOfflinePackage && (
                        <Route path={endpoints.offlinePackageUrl} component={loadOptions} />
                        )}
                        <Route exact path="/:id" component={loadOptions} />
                        <Route component={() => <ErrorScreen noId />} />
                    </Switch>
                </ConnectedRouter>
                <ReduxToastr
                    timeOut={4000}
                    newestOnTop
                    preventDuplicates
                    position="bottom-center"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar={false}
                />
            </Provider>
        </>,
        root
    );
}
