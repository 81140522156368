import axios from 'axios';
import { clear, notify } from '../notification/notification.actions';
import {
    PASSWORD_INCORRECT,
    PASSWORD_VERIFIED,
    PASSWORD,
    PASSWORD_UPDATE,
} from './password.constant';
import api from '../api/api.actions';
import urls from '../../constants/endpoints';

const passwordVerified = (): Object => ({
    type: PASSWORD_VERIFIED,
});

const passwordIncorrect = (): Object => ({
    type: PASSWORD_INCORRECT,
});

const passwordUpdate = (passwordProtected: boolean) => ({
    type: PASSWORD_UPDATE,
    passwordProtected,
});

const passwordCheck = (id: number, password: string): Object => async (dispatch: Function) => {
    dispatch(api.pending(PASSWORD));

    const url = urls.password(id);

    await axios
        .get(url, { params: { password } })
        .then(() => {
            dispatch(passwordVerified());
            dispatch(clear());
        })
        .catch(() => {
            dispatch(passwordUpdate(true));
            dispatch(notify('alert-danger', 'Password incorrect'));
        });
};

const passwordClear = () => (dispatch: Function) => {
    dispatch(clear());
};

export default {
    passwordCheck,
    passwordVerified,
    passwordIncorrect,
    passwordUpdate,
    passwordClear,
};
