import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import FONTS from 'features/overlay/constants/fonts';

const FontPreloader = () => {
    const debug = window.location.href.includes('debug');

    const fonts = useSelector(state => {
        const overlayPanels = state.options.activeVideo.overlays.map(
            overlay => overlay.overlayPanels
        );

        const fontSettings = overlayPanels
            .reduce((acc, cur) => {
                const reducedSettings = cur.reduce(
                    (acc, cur) => (cur.settings ? acc.concat(cur.settings) : acc),
                    []
                );

                return acc.concat(reducedSettings);
            }, [])
            .filter(item => item.name === 'Font Family')
            .map(item => item.value);

        const fontNames = [...new Set(fontSettings)].map(value => {
            const font = FONTS.find(fontFamily => fontFamily.className === value);

            return font && font.label;
        });

        return fontNames;
    }, shallowEqual);

    if (debug)
        return (
            <div
                style={{
                    position: 'absolute',
                    zIndex: 1000,
                    top: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                    padding: '2rem',
                    fontSize: '2rem',
                }}
            >
                <table>
                    <thead>
                        <tr>
                            <td>font id</td>
                            <td>font label</td>
                        </tr>
                    </thead>
                    <tbody>
                        {FONTS.map(font => (
                            <tr key={font.id || font.label}>
                                <td style={{ width: '30%' }}>{font.id || font.label}</td>
                                <td style={{ fontFamily: font.label }}>{font.label}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );

    if (fonts.length === 0) return null;

    return (
        <div
            style={{
                position: 'absolute',
                zIndex: -1,
                bottom: 0,
                visibility: 'hidden',
                width: 0,
                height: 0,
            }}
        >
            {fonts.map(fontName => (
                <span key={fontName} style={{ fontFamily: fontName }}>
                    {fontName}
                </span>
            ))}
        </div>
    );
};

export default React.memo(FontPreloader);
