/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const hotspotSlice = createSlice({
    name: 'hotspots',
    initialState: {
        sprites: [],
        spriteConnections: [],
    },
    reducers: {
        initHotspotState: (state, action) => {
            const {
                video: { fps },
                hotspots,
                interactions,
            } = action.payload;

            const validatedHotspots = hotspots.map(item => {
                const { show, hide, isStatic } = item;
                const inFrame = isStatic ? Number(show) * fps : Number(show);
                const outFrame = isStatic ? Number(hide) * fps : Number(hide);
                const inSeconds = isStatic ? Number(show) : Number(show) / fps;
                const outSeconds = isStatic ? Number(hide) : Number(hide) / fps;

                return {
                    ...item,
                    inSeconds,
                    outSeconds,
                    inFrame,
                    outFrame,
                };
            });

            state.spriteConnections = validatedHotspots.reduce((byId, hotspot) => {
                const { nameLink } = hotspot;
                const interaction = interactions[nameLink];

                const { hotspotDesign } = interaction;

                byId[hotspot.spriteId] = {
                    ...hotspot,
                    ...interaction,
                    hotspotDesign,
                };

                return byId;
            }, {});

            state.sprites = Object.keys(state.spriteConnections).map(item => Number(item)); // FIXME: Not a good idea here
        },
    },
});

export const { initHotspotState } = hotspotSlice.actions;

export default hotspotSlice.reducer;
