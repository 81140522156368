/* eslint-disable no-case-declarations */
import initialState from './options.state';
import {
    VIDEO,
    END_FRAME,
    SWITCH_VIDEO,
    TOGGLE_OVERLAY,
    TOGGLE_EMBED_OVERLAY,
    END_FRAME_ENDED,
    SET_VIDEO_QUALITY,
    SET_VIDEO_ID,
    FULL_SCREEN,
    CLOSE_OVERLAY_BOOLEAN,
    FORCE_SPRITE_ID_SHOWN,
    TOGGLE_CONTROLS_VISIBILITY,
    SET_USER_AGENT,
    SET_VIDEO_RENDITIONS,
    SET_OVERLAY_CONTENT,
    SET_ACTION_LINK,
    OVERRIDE_EMBED_LOC,
    SET_PLAYER_PARAMS,
} from './options.constant';
import { FULFILLED } from '../api/api.constant';
import {
    getResolutionArray,
    getDefaultImageSource,
    shouldActivateEndframe,
} from '../../utils/vidDocUtils';
import utils from '../../utils';
import { WHITE_LABEL_TYPES } from '../../constants/whiteLabel';
import { getThirdPartyPlayerOptions } from '../../component/loadOptions/loadOptions.helpers';

type State = any;

type Action = Object;

const options = (state: State = initialState, action: Action) => {
    switch (action.type) {
        case `${VIDEO}_${FULFILLED}`:
            const mainVideo = action.payload;

            mainVideo.video.aspectRatio = mainVideo.video.aspectRatio || 1.77;

            // NOTE: a half normalization effort
            const {
                video: { renditionsArray = [], mediaType, whiteLabel, tpPlayers },
                subtitles = [],
            } = action.payload;

            // Compute renditions
            const videoRenditions = getResolutionArray(renditionsArray);
            const hasHls = renditionsArray.find(rendition => rendition.ext === 'm3u8') || false;

            // Compute interactive image states
            const imageSource = getDefaultImageSource(renditionsArray);
            const isImage = !!mediaType;

            // Compute whiteLabel
            const isBannerShown =
                typeof whiteLabel === 'boolean'
                    ? utils.whiteLabelChecker(whiteLabel)
                    : whiteLabel !== WHITE_LABEL_TYPES.NO_BRANDING;
            const isEndFrameShown = shouldActivateEndframe(whiteLabel, state.videoType);


            // Compute third party plugin
            const { customPlayer, customPlayerVidId } = state.playerParams;

            // VidData.json third Party Player config has priority
            const { tpPlayer, tpVidId } = getThirdPartyPlayerOptions(customPlayer, tpPlayers);

            const validatedSubtitles = subtitles.map(item => {
                if (item.lang === 'iw') {
                    return {
                        ...item,
                        label: 'Hebrew',
                    };
                }

                if (item.lang === 'de-CH') {
                    return {
                        ...item,
                        label: 'German (Switzerland)',
                    };
                }

                return item;
            });

            return {
                ...state,
                mainVideo,
                activeVideo: {
                    ...action.payload,
                    subtitles: validatedSubtitles,
                },
                loaded: true,
                hasHls,
                videoRenditions,
                isImage,
                imageSource,
                isBannerShown,
                isEndFrameShown,
                tpPlayer,
                tpVidId: customPlayerVidId || tpVidId,
            };
        case SWITCH_VIDEO:
            return {
                ...state,
                activeVideo: { ...initialState.activeVideo, ...action.video },
                videoType: action.videoType,
                endFrameEnded: false,
                isEndFrameShown: shouldActivateEndframe(
                    state.activeVideo.video.whiteLabel,
                    action.videoType
                ),
            };
        case `${VIDEO}_${END_FRAME}_${FULFILLED}`:
            return {
                ...state,
                endFrameVideo: action.payload,
            };
        case TOGGLE_OVERLAY:
            const isOverlayClosed = action.overlay && Object.keys(action.overlay).length === 0;

            return {
                ...state,
                activeOverlay: action.overlay || {},
                isOverlayClosed,
            };
        case TOGGLE_EMBED_OVERLAY:
            return {
                ...state,
                embedOverlay: action.open,
            };
        case END_FRAME_ENDED:
            return {
                ...state,
                endFrameEnded: true,
            };
        case SET_VIDEO_QUALITY:
            return {
                ...state,
                videoQuality: action.quality,
            };
        case SET_VIDEO_ID:
            return {
                ...state,
                id: action.id,
                uuId: action.uuId,
            };
        case FULL_SCREEN:
            return {
                ...state,
                isFullScreen: action.isFullScreen,
            };
        case CLOSE_OVERLAY_BOOLEAN:
            return {
                ...state,
                isOverlayClosed: true,
            };
        case FORCE_SPRITE_ID_SHOWN:
            return {
                ...state,
                forceSpriteIdShown: true,
            };
        case TOGGLE_CONTROLS_VISIBILITY:
            return {
                ...state,
                showControls: action.showControls,
            };
        case SET_USER_AGENT:
            return {
                ...state,
                userAgent: action.userAgent,
            };
        case SET_VIDEO_RENDITIONS:
            return {
                ...state,
                videoRenditions: action.videoRenditions,
            };
        case SET_OVERLAY_CONTENT:
            const resolvedPanels = state.activeOverlay.overlayPanels.map(overlayPanel => {
                if (overlayPanel.id === action.payload.id) {
                    return {
                        ...overlayPanel,
                        content: action.payload.resolvedData.resolvedContent,
                        settings: action.payload.resolvedData.resolvedSettings,
                        isDynamic: true,
                        isResolved: true,
                    };
                }

                return overlayPanel;
            });

            const resolvedActiveOverlay = {
                ...state.activeOverlay,
                overlayPanels: resolvedPanels,
            };

            return {
                ...state,
                activeOverlay: resolvedActiveOverlay,
                activeVideo: {
                    ...state.activeVideo,
                    overlays: state.activeVideo.overlays.map(overlay => {
                        if (overlay.overlayId === action.payload.overlayId) {
                            return resolvedActiveOverlay;
                        }

                        return overlay;
                    }),
                },
            };
        case SET_ACTION_LINK:
            return { ...state };
        case OVERRIDE_EMBED_LOC:
            return {
                overrideEmbedLoc: action.payload.embedLoc,
                ...state,
            };
        case SET_PLAYER_PARAMS:
            return {
                ...state,
                playerParams: action.payload.playerParams,
            };
        default:
            return state;
    }
};

export default options;
