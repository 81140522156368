import utf8 from 'utf8';
import { base64encode } from 'nodejs-base64';
import {
    DB,
    QUEUES,
    IMPRESSIONS,
    USER,
    METRICS_INTERVAL_MOBILE,
    METRICS_INTERVAL_DESKTOP,
    INTERACTION_EVENT_TYPES,
} from './metrics.constant';
import { send } from './metrics.sqs';
import { uuId } from '../../utils/uuId';

let videoHasStartedPlaying = 0;
let interactedInThisSession = false;
let isFirstInteraction = false; // Used to record in this session
let hasInteractedBefore = false; // True if this video has interacted in history

/**
 * Get video interaction history from localStorage
 * If no data, return an empty object
 * @return history Object or default to empty Object
 */
export const getVideoHistory = (): Object => {
    const encodedInteractionHistory = localStorage.getItem(IMPRESSIONS);
    let interactionHistory = {};

    if (encodedInteractionHistory) {
        interactionHistory = JSON.parse(decodeURI(encodedInteractionHistory));
    }

    return interactionHistory;
};

/**
 * Record hasInteracted data to localStorage
 * @param {*} vidId
 * @param {*} hasInteracted
 * This function is used to replace updateUniqueInteractionInPlayerCookie() in player4
 * ref: https://github.com/wireWAX/ww4Player/blob/eeddee0eea8eb47141f65a4e1d6e387c422b6617/node/public/javascripts/apiService.js#L538-L556
 */
export const setVideoHistory = (vidId: number, hasInteracted: boolean = false) => {
    // Get current localStorage data
    const interactionHistory = getVideoHistory();
    const { videos } = interactionHistory;

    const updatedHistory = {
        videos: {
            ...videos,
            [vidId]: hasInteracted ? 2 : 1, // 2 means has interacted
        },
    };

    const encodedHistory = encodeURI(JSON.stringify(updatedHistory));

    localStorage.setItem(IMPRESSIONS, encodedHistory); // 'wirewax-player-impressions'
};

export const setUserData = (uuid: string) => {
    const userUuId = encodeURI(JSON.stringify({ uuid }));

    // instead of cookie, using localStorage to userUuId
    localStorage.setItem(USER, userUuId);
};

export const checkIsFirstActive = (vidId: number): boolean => {
    // Ref: https://github.com/wireWAX/ww4Player/blob/eeddee0eea8eb47141f65a4e1d6e387c422b6617/node/public/javascripts/apiService.js#L624-L649
    let firstActive = false;

    if (interactedInThisSession && !hasInteractedBefore && !isFirstInteraction) {
        // If this is the first time to interact in this video's playback history
        // and also the first interaction after the video page reloaded
        firstActive = true;

        // In one video session, video history will be only set once after interaction happened
        isFirstInteraction = true;
        setVideoHistory(vidId, true); // in history object this vidId will have be set with value '2'
    } else if (!hasInteractedBefore && isFirstInteraction) {
        firstActive = true;
    }

    return firstActive;
};

export const isFirstView = (videos: Object = {}, id: number) => !(videos[id] > 0);

export const getUserUuId = (): string => {
    const userStorage = localStorage.getItem(USER);
    let userData = {};
    let userUuId;

    if (userStorage) {
        userData = JSON.parse(decodeURI(userStorage));
    }

    // Check local storage of user uuid. If it doesn't exist, set a new uuid and return the value
    if (userData && userData.uuid) {
        userUuId = userData.uuid;
    } else {
        userUuId = uuId();
        setUserData(userUuId);
    }

    return userUuId;
};

const getVidId = input => (typeof input === 'number' ? input : parseInt(input, 10));

const types = {
    desktop: 2,
    mobile: 4,
    dwell: 5,
};

export const encodeForSqs = (object: Object) => base64encode(utf8.encode(JSON.stringify(object)));

/**
 * EMBEDDER IMPRESSION
 */

export const embedderImpression = (
    uuId: string,
    vidId: number,
    userUuId: string,
    embedLoc: string
) => ({
    data: {
        vidId: getVidId(vidId),
        uuId,
        userAgent: window.navigator.userAgent,
        embedLoc,
    },
    type: 0,
    isPlayer5: true,
});

/**
 * BIG IMPRESSION
 */

export const impression = (
    uuId: string,
    vidId: number,
    userUuId: string,
    embedLoc: string,
    startedPlaying: number = 0,
    type: number = 1,
    secs: number = new Date().getTime() / 1000
) => {
    // Checking video interaction history
    const videoHistory = getVideoHistory();
    const { videos } = videoHistory;

    // videos[vidId] value meaning:
    // undefined - this video never opened before
    // '1' - this video opened, but never interacted, used for 'isFirstView' below
    // '2' - this video opened, and interacted, used for 'isFirstActive`
    if (videos && videos[vidId] === 2) {
        hasInteractedBefore = true; // no need to update video history
    } else {
        setVideoHistory(vidId, false); // in history object this vidId will have be set with value '1'
    }

    return {
        data: {
            uuId,
            vidId: getVidId(vidId),
            playerType: 3,
            userAgent: window.navigator.userAgent,
            embedLoc,
            startedPlaying,
            when: {
                sec: secs,
                usec: 0,
            },
            userUuId,
            isFirstView: isFirstView(videos, vidId),
        },
        db: DB,
        type,
        isPlayer5: true,
    };
};

/**
 * UI and BASE METRICS for play, pause, seek, click hotspot, and close overlay
 * N.B. Time triggers not currently recorded
 */

export const uiMetric = (
    name: string = '',
    eventId: number,
    frame: number,
    time: number,
    secs: number,
    extra: Object = {}
) => ({
    uiEvents: [
        {
            ...extra,
            eventId,
            name,
            frame: Math.floor(frame),
            time: Math.floor(time),
            when: {
                sec: secs,
                usec: 0,
            },
        },
    ],
});

export const baseMetric = (uuId: string, vidId: number, type: number = 3) => ({
    keys: { uuId, vidId: getVidId(vidId).toString() },
    db: DB,
    type,
    isPlayer5: true,
});

/**
 * METRIC BODY for play, pause, and seek
 */

export const metricBody = (
    name: string,
    eventId: number,
    frame: number,
    time: number,
    uuId: string,
    vidId: number,
    extra: Object = {}
) => ({
    ...baseMetric(uuId, vidId),
    data: {
        ...uiMetric(name, eventId, frame, time, new Date().getTime() / 1000, extra),
        isFirstActive: checkIsFirstActive(vidId),
    },
});

/**
 * HOTSPOT INTERACTION
 */

export const hotspotIsClicked = (
    isMobile: bool,
    nameLink: number,
    spriteId: number,
    time: number,
    uuId: string,
    vidId: number,
    secs: number = new Date().getTime() / 1000
) => {
    interactedInThisSession = true;

    return {
        ...baseMetric(uuId, vidId),
        data: {
            interactions: [
                {
                    btnLink: null,
                    nameLink,
                    spriteId,
                    time: parseInt(time, 10),
                    type: isMobile ? types.mobile : types.desktop,
                    when: {
                        sec: secs,
                        usec: 0,
                    },
                },
            ],
            isFirstActive: checkIsFirstActive(vidId),
        },
    };
};

export const hotspotClickout = (
    overlayId: number,
    action: string,
    href: string,
    nameLink: ?number,
    spriteId: ?number,
    uuId: string,
    vidId: number,
    playTime: number
) => {
    interactedInThisSession = true;

    return {
        ...baseMetric(uuId, vidId),
        data: {
            widgets: [
                {
                    widgetRef: overlayId,
                    actionType: INTERACTION_EVENT_TYPES.clickthrough,
                    nameLink,
                    identifier: undefined,
                    href,
                    playTime,
                    spriteId,
                },
            ],
            isFirstActive: checkIsFirstActive(vidId),
        },
    };
};

/**
 * CLOSE OVERLAY
 */

let dwellStart;

export const overlayIsOpened = () => {
    dwellStart = Date.now();
};

export const overlayIsClosed = (
    nameLink: ?number,
    spriteId: ?number,
    time: number,
    uuId: string,
    vidId: number
) => {
    // Dwell time in seconds (converted from milliseconds)
    const dwellTime = Math.floor((Date.now() - dwellStart) / 1000);

    interactedInThisSession = true;

    return {
        ...baseMetric(uuId, vidId),
        data: {
            interactions: [
                {
                    btnLink: null,
                    dwellTime,
                    nameLink,
                    spriteId,
                    timestamp: time,
                    type: types.dwell,
                },
            ],
            isFirstActive: checkIsFirstActive(vidId),
        },
    };
};

/**
 * OVERLAY EVENTS
 */

export const overlayEvent = (
    overlayId: number,
    action: string,
    value: string,
    nameLink: ?number,
    spriteId: ?number,
    uuId: string,
    vidId: number,
    playTime: number
) => {
    interactedInThisSession = true;

    switch (action) {
        case 'addToCart':
            return {
                ...baseMetric(uuId, vidId),
                data: {
                    widgets: [
                        {
                            widgetRef: overlayId,
                            actionType: INTERACTION_EVENT_TYPES[action],
                            identifier: `${action}:${value}`,
                            nameLink,
                            playTime,
                            spriteId,
                        },
                    ],
                    isFirstActive: checkIsFirstActive(vidId),
                },
            };
        case 'clickthrough':
            return {
                ...baseMetric(uuId, vidId),
                data: {
                    widgets: [
                        {
                            widgetRef: overlayId,
                            actionType: INTERACTION_EVENT_TYPES[action],
                            href: value,
                            identifier: '',
                            nameLink,
                            playTime,
                            spriteId,
                        },
                    ],
                    isFirstActive: checkIsFirstActive(vidId),
                },
            };
        default:
            break;
    }
};

/**
 * HANDSHAKE (sent first time video begins to play)
 */

// Compare to impression, this doc doesn't include "isFirstView" & "userUuid"
export const handShakeImpression = (
    uuId: string,
    vidId: number,
    userUuId: string,
    embedLoc: string,
    startedPlaying: number = 0,
    type: number = 2,
    secs: number = new Date().getTime() / 1000
) => {
    // Checking video interaction history
    const videoHistory = getVideoHistory();
    const { videos } = videoHistory;

    // videos[vidId] value meaning:
    // undefined - this video never opened before
    // '1' - this video opened, but never interacted, used for 'isFirstView' below
    // '2' - this video opened, and interacted, used for 'isFirstActive`
    if (videos && videos[vidId] === 2) {
        hasInteractedBefore = true; // no need to update video history
    } else {
        setVideoHistory(vidId, false); // in history object this vidId will have be set with value '1'
    }

    return {
        data: {
            uuId,
            vidId: getVidId(vidId),
            playerType: 3,
            userAgent: window.navigator.userAgent,
            embedLoc,
            startedPlaying,
            when: {
                sec: secs,
                usec: 0,
            },
        },
        db: DB,
        type,
        isPlayer5: true,
    };
};

export const handshake = (uuId: string, vidId: number, userUuId: string, embedLoc: string) => {
    if (videoHasStartedPlaying === 0) {
        videoHasStartedPlaying = 1;

        send(
            handShakeImpression(uuId, vidId, userUuId, embedLoc, videoHasStartedPlaying, 2),
            QUEUES.METRICS_TEST
        );
    }
};

/**
 * Mouse Metrics
 */

export const mouseMetrics = (frame: number, time: number, mousePosition: Object = {}) => {
    const { x, y } = mousePosition;

    return {
        mouse: [
            {
                when: {
                    sec: new Date().getTime() / 1000,
                    usec: 0,
                },
                frame,
                time,
                f: frame,
                x,
                y,
            },
        ],
    };
};

/**
 * METRICS INTERVAL (sends metrics every x seconds when video is playing)
 */

let playerInterval;

export const playerActive = (
    uuId: string,
    id: number,
    frame: number,
    time: number,
    mousePosition: Object,
    isMobile: boolean
) => {
    const METRICS_INTERVAL = isMobile ? METRICS_INTERVAL_MOBILE : METRICS_INTERVAL_DESKTOP;

    clearInterval(playerInterval);
    playerInterval = setInterval(() => {
        send(
            {
                ...baseMetric(uuId, id),
                data: {
                    ...mouseMetrics(frame, time, mousePosition),
                    isFirstActive: checkIsFirstActive(id),
                },
            },
            QUEUES.SUB_DOCUMENT_TEST
        );
    }, METRICS_INTERVAL);
};

export const playerInactive = () => clearInterval(playerInterval);

export const videoHasEnded = (vidId: number) => ({ isFirstActive: checkIsFirstActive(vidId) });
