import React, { PureComponent, Fragment } from 'react';
import type { Props, State } from './embedOverlay.types';
import IconClose from '../../assets/icon-close';
import {
    EmbedWrapper,
    EmbedContainer,
    HeaderContainer,
    HeaderText,
    CloseIcon,
    Body,
    Section,
    EmbedCode,
    VidInfo,
    Item,
    Heading,
    VidId,
    VidOptions,
    StyledInput,
    InputContainer,
    Separator,
    CheckboxLabel,
    Checkbox,
    CopyUrl,
    Notification,
    FooterSection,
} from './embedOverlay.styles';
import embedToggles from './embedOverlay.constants';
import Fade from '../../utils/fade';
import utils from '../../utils';
import Button from './button';

class EmbedOverlay extends PureComponent<Props, State> {
    state = {
        dimensions: {
            width: 1920,
            height: 1080,
        },
        embedCode: '',
        embedOptions: {
            scaleResponsively: true,
            loop: false,
            autoPlay: false,
            shareButtons: false,
            videoControls: false,
            subtitles: false,
            yp: false,
            vimeoPlayer: false,
        },
        copied: false,
        src: '',
        tpPlayer: '',
    };

    componentDidMount = () => {
        const { vidId } = this.props;

        if (vidId) {
            this.createEmbedCode();
            this.checkTpPlayers();
        }

        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.checkKey);
    };

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.checkKey);
    };

    handleClickOutside = ({ target }: Object) => {
        const { closeEmbedOverlay } = this.props;

        if (this.embedWrapper && !this.embedWrapper.contains(target)) {
            closeEmbedOverlay();
        }
    };

    checkTpPlayers = () => {
        const { video } = this.props;

        if (video && video.tpPlayers && video.tpPlayers.length) {
            const { tpPlayer } = video.tpPlayers[0];

            this.setState({ tpPlayer });
        }
    };

    checkKey = ({ key, keyCode }: Object) => {
        const { closeEmbedOverlay } = this.props;

        const escapeKeyPressed = key === 'Escape' || key === 'Esc' || keyCode === 27;

        if (escapeKeyPressed) {
            closeEmbedOverlay();
        }
    };

    createEmbedCode = () => {
        const {
            dimensions: { width, height },
            embedOptions,
        } = this.state;

        const { vidId } = this.props;

        let embedCode;
        const additionalArgs = [];

        Object.keys(embedOptions).forEach(option => {
            if (embedOptions[option]) {
                switch (option) {
                    case 'loop':
                        additionalArgs.push('loop=true');
                        break;
                    case 'autoPlay':
                        additionalArgs.push('autoStart=true');

                        break;
                    case 'shareButtons':
                        additionalArgs.push('noShare=true');

                        break;
                    case 'videoControls':
                        additionalArgs.push('skin=SkinBarebonesSlick');

                        break;
                    case 'subtitles':
                        additionalArgs.push('showSubtitles=true');
                        break;
                    case 'yp':
                        additionalArgs.push('player=yp');
                        break;
                    case 'vimeoPlayer':
                        additionalArgs.push('player=vimeoPlayer');
                        break;
                    default:
                        break;
                }
            }
        });

        let paramsList = '';

        if (additionalArgs.length) {
            paramsList += '?';
            additionalArgs.forEach((arg, i) => {
                paramsList += arg;

                if (i !== additionalArgs.length - 1) {
                    paramsList += '&';
                }
            });
        }

        const src = `https://embedder.wirewax.com/${vidId}/${paramsList}`;

        this.setState({ src: `embedder.wirewax.com/${vidId}/${paramsList}` });

        if (embedOptions.scaleResponsively) {
            embedCode = `<div style="position: relative; width: 100%; height: 0; padding-bottom: 56.25%"><iframe style="position: absolute; top: 0; left: 0;" width="100%" height="100%" src="${src}" frameborder="0" scrolling="yes" allowfullscreen></iframe></div>`;
        } else {
            embedCode = `<iframe width="${width}" height="${height}" src="${src}" frameborder="0" scrolling="yes" allowfullscreen></iframe>`;
        }

        this.setState({ embedCode });
    };

    copyEmbedCode = () => {
        const { embedCode } = this.state;

        utils.copyToClipboard(embedCode);
        this.toggleCopiedNotification();
    };

    copyUrl = () => {
        const { src } = this.state;

        const urlWithHTTPS = `https://${src}`;

        utils.copyToClipboard(urlWithHTTPS);
        this.toggleCopiedNotification();
    };

    toggleCopiedNotification = () => {
        this.setState({ copied: true });

        setTimeout(() => {
            this.setState({ copied: false });
        }, 1200);
    };

    updateDimensions = (type: string, value: number) => {
        const { dimensions } = this.state;
        let { width, height } = dimensions;

        if (type === 'height') {
            height = value;
            const gcd = height / 9;

            width = Math.round(gcd * 16);
        } else if (type === 'width') {
            width = value;
            const gcd = width / 16;

            height = Math.round(gcd * 9);
        }

        this.setState(
            {
                dimensions: {
                    width,
                    height,
                },
            },
            () => this.createEmbedCode()
        );
    };

    toggleEmbedOption = (event: Object) => {
        const {
            target: { value },
        } = event;

        const { embedOptions } = this.state;
        const currentValue = embedOptions[value];

        this.setState(
            {
                embedOptions: {
                    ...embedOptions,
                    [value]: !currentValue,
                },
            },
            () => this.createEmbedCode()
        );
    };

    embedWrapper: Node;

    render() {
        const { embedCode, copied, embedOptions, dimensions, src, tpPlayer } = this.state;

        const { vidId, closeEmbedOverlay, isImage } = this.props;

        const filteredToggles = embedToggles.filter(toggle => {
            if (tpPlayer && tpPlayer === 'yp') {
                return toggle.value !== 'vimeoPlayer';
            }
            if (tpPlayer && tpPlayer === 'vimeoPlayer') {
                return toggle.value !== 'yp';
            }

            return toggle.value !== 'yp' && toggle.value !== 'vimeoPlayer';
        });

        return (
            <EmbedWrapper>
                <EmbedContainer
                    ref={el => {
                        this.embedWrapper = el;
                    }}
                >
                    <HeaderContainer>
                        <HeaderText>{`Embed ${isImage ? 'Image' : 'Video'}`}</HeaderText>
                        <CloseIcon onClick={closeEmbedOverlay}>
                            <IconClose />
                        </CloseIcon>
                    </HeaderContainer>
                    <Body>
                        <Section>
                            <VidInfo>
                                <Item>
                                    <Heading>{`${isImage ? 'Image' : 'Video'} ID`}</Heading>
                                    <VidId>{vidId}</VidId>
                                </Item>
                                <Item>
                                    <Heading>Size</Heading>
                                    {embedOptions.scaleResponsively ? (
                                        <InputContainer>
                                            <StyledInput
                                                name="disabled-width"
                                                disabled
                                                value={dimensions.width}
                                                placeholder="W"
                                            />
                                            <Separator disabled>X</Separator>
                                            <StyledInput
                                                name="disabled-height"
                                                disabled
                                                value={dimensions.height}
                                                placeholder="H"
                                            />
                                        </InputContainer>
                                    ) : (
                                        <InputContainer>
                                            <StyledInput
                                                name="width"
                                                type="number"
                                                placeholder="W"
                                                value={dimensions.width}
                                                onKeyDown={evt =>
                                                    evt.key === 'e' && evt.preventDefault()
                                                }
                                                onChange={event =>
                                                    this.updateDimensions(
                                                        'width',
                                                        event.target.value
                                                    )
                                                }
                                            />
                                            <Separator>X</Separator>
                                            <StyledInput
                                                name="height"
                                                type="number"
                                                placeholder="H"
                                                value={dimensions.height}
                                                onKeyDown={evt =>
                                                    evt.key === 'e' && evt.preventDefault()
                                                }
                                                onChange={event =>
                                                    this.updateDimensions(
                                                        'height',
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </InputContainer>
                                    )}
                                </Item>
                                <Heading>{src}</Heading>
                                <CopyUrl onClick={this.copyUrl}>copy url</CopyUrl>
                            </VidInfo>
                            <VidOptions>
                                {filteredToggles.map(
                                    toggle =>
                                        (!isImage || (isImage && !toggle.hideIfImage)) && (
                                            <Fragment key={toggle.value}>
                                                <Checkbox
                                                    type="checkbox"
                                                    aria-checked={embedOptions[toggle.value]}
                                                    checked={embedOptions[toggle.value]}
                                                    id={toggle.label}
                                                    onChange={this.toggleEmbedOption}
                                                    name="embed option"
                                                    value={toggle.value}
                                                />
                                                <CheckboxLabel
                                                    checked={embedOptions[toggle.value]}
                                                    htmlFor={toggle.label}
                                                    onClick={this.toggleEmbedOption}
                                                >
                                                    {toggle.label}
                                                </CheckboxLabel>
                                            </Fragment>
                                        )
                                )}
                            </VidOptions>
                        </Section>
                        <EmbedCode>{embedCode}</EmbedCode>
                        <FooterSection>
                            <Fade show={copied}>
                                <Notification>Copied to clipboard</Notification>
                            </Fade>
                            <Button onClick={this.copyEmbedCode}>COPY CODE</Button>
                        </FooterSection>
                    </Body>
                </EmbedContainer>
            </EmbedWrapper>
        );
    }
}

export default EmbedOverlay;
