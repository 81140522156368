import React, { lazy, Suspense, memo, useEffect, useState } from 'react';
import allSettled from 'promise.allsettled';
import LoadingSpinner from '../common/loadingSpinner';
import type { Props } from './overlay.types';

const WWOverlay = lazy(() => import('../../features/overlay'));

const Overlay = (props: Props) => {
    const {
        closeOverlayAndAnimateOut,
        overlayEvent,
        fps,
        overlay,
        play,
        skip,
        toggleOverlay,
        isOverlayClosed,
        isImage,
        assetWidth,
        assetHeight,
        id,
        playerWidth,
        playerHeight,
        dynamicData,
        apis,
        overlaysDynamicData,
        fetchDynamicData,
        receiveDynamicData,
        setOverlayContent,
        customParams,
        customPlayer,
        volume,
    } = props;

    const [fetchingStatus, setFetchingStatus] = useState(0);
    const [isInFullscreen, setIsInFullscreen] = useState(false);

    // In this overlay wrapper, check if the overlay come with APIs
    // Yes, fetch data, then pass all data to ww-overlay
    // No, skip to render

    useEffect(() => {
        // 1. Check if it's Dynamic Overlay
        const { overlayId } = overlay;
        // console.log('%c Dynamic Overlay :: Query dynamic data by OverlayId', 'background: #00ff00', { overlayId, mapping });

        const { apiList } = overlaysDynamicData[overlayId] || {};

        if (apiList && apiList.length > 0) {
            const apiWaitingList = Object.values(apis).filter(
                api => apiList.indexOf(api.id) !== -1 && api.status !== 200
            );

            const fetch = list => {
                // console.log('%c Dynamic Overlay :: Start Fetching Data', 'background: #00ff00; color: #ff00ff');
                const fetchResolve = list.map(api => fetchDynamicData(api, customParams));

                return fetchResolve;
            };

            if (apiWaitingList.length > 0) {
                allSettled(fetch(apiWaitingList))
                    .then(value => {
                        const resolvedValue = value
                            .filter(apiPromise => apiPromise.status === 'fulfilled')
                            .map(item => item.value);

                        receiveDynamicData(resolvedValue);
                        setFetchingStatus(1); // 1 - Fetched
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.error(error.message);
                    });
            } else {
                setFetchingStatus(2); // 2 - Previously Fetched
            }
        } else {
            setFetchingStatus(3); // 3 - Skip Fetching (Non-DCO overlay)
        }
    }, []);

    useEffect(() => {
        const listener = e => {
            const tagName = e.target && e.target.tagName;

            if (tagName !== 'IFRAME') {
                return;
            }

            const isFullscreenIframe = document.fullscreenElement === e.target;

            setIsInFullscreen(isFullscreenIframe);
        };

        document.addEventListener('fullscreenchange', listener);

        return () => document.removeEventListener('fullscreenchange', listener);
    }, []);

    // console.log('%c Dynamic Overlay :: Render Overlay', 'background: #00ff00');
    return (
        <Suspense fallback="">
            {fetchingStatus > 0 ? (
                <WWOverlay
                    className="overlay"
                    eventListener={overlayEvent}
                    fps={fps}
                    overlay={overlay}
                    play={play}
                    skip={skip}
                    toggleOverlay={toggleOverlay}
                    isOverlayClosed={isOverlayClosed}
                    closeOverlayAndAnimateOut={closeOverlayAndAnimateOut}
                    isImage={isImage}
                    assetWidth={assetWidth}
                    assetHeight={assetHeight}
                    id={id}
                    playerWidth={playerWidth}
                    playerHeight={playerHeight}
                    dynamicData={dynamicData}
                    setOverlayContent={setOverlayContent}
                    customPlayer={customPlayer}
                    isInFullscreen={isInFullscreen}
                    mainVideoVolume={volume}
                />
            ) : (
                <LoadingSpinner transparent />
            )}
        </Suspense>
    );
};

export default memo(Overlay);
