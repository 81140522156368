const lock = (items: Array<string> = [], item: string = ''): boolean => items.includes(item);

const getDomain = (location: string): string =>
    location
        .split('.')
        .slice(-2)
        .join('.');

const formatToPercentage = (decimal: number): string => `${decimal}%`;

const isVisible = (frame: number, show: number, hide: number): boolean =>
    frame >= show && frame <= hide;

const formatTime = (sec: number) => `${Math.floor(sec / 60)}:${Math.floor(sec % 60)}`;

const whiteLabelChecker = (whitelabel: any) => !whitelabel || whitelabel === 3;

const copyToClipboard = (valueToCopy: string) => {
    const textarea = document.createElement('textarea');

    textarea.id = 'temp_element';
    textarea.style.height = 0;
    document.body.appendChild(textarea);
    textarea.value = valueToCopy;
    const selector = document.querySelector('#temp_element');

    selector.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
};

const getTagBySpriteId = (hotspots: Array<Object>, spriteId: number): Object =>
    hotspots.find(hotspot => hotspot.spriteId === spriteId);

const getOverlayByOverlayId = (overlays: Array<Object>, overlayId: number): Object =>
    overlays.find(overlay => overlay.overlayId === overlayId);

const isEmpty = (obj: any) => {
    if (obj === null) return true;
    if (obj === undefined) return true;

    return Object.entries(obj).length === 0;
};

const clamp = (val: number, min: number, max: number): number => Math.min(Math.max(val, min), max);

const getValueByMapping = (apiJson: Object, mapping: string) =>
    mapping.split('.').reduce((acc, cur) => acc[cur], apiJson);

const canAccessLocalStorage = () => {
    try {
        localStorage.getItem('test');

        return true;
    } catch {
        return false;
    }
};


export default {
    lock,
    getDomain,
    formatToPercentage,
    isVisible,
    formatTime,
    whiteLabelChecker,
    copyToClipboard,
    getTagBySpriteId,
    getOverlayByOverlayId,
    isEmpty,
    clamp,
    getValueByMapping,
    canAccessLocalStorage,
};
