import styled from 'styled-components';
import FocusCircle from '../../assets/focus-circle';
import focusVisible from '../../constants/focusVisible';
import breakpoints from '../../styles/breakpoints';

const PLAY_BUTTON_WIDTH = 23;
const PLAY_BUTTON_WIDTH_MOBILE = 14;

export const ButtonWrapper = styled.div`
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
`;

export const PlayButton = styled.button`
    cursor: pointer;
    opacity: 0.8;
    padding-top: ${PLAY_BUTTON_WIDTH}em;
    position: relative;
    transition: opacity 100ms ease, transform 100ms ease-out;
    width: ${PLAY_BUTTON_WIDTH}em;
    transform: scale(1);
    svg {
        path {
            transform-origin: center;
            transition: transform 100ms ease-out;
            transform: scale(0.9);
        }
    }

    ${props =>
        props.focus &&
        `{
        opacity: 1;
        transform: scale(0.9);
        svg {
            path {
                transform-origin: center;
                transform: scale(1.1);
            }
        }
    }`}

    &${focusVisible} {
        opacity: 1;
        outline: none;

        ${FocusCircle} {
            opacity: 1;
        }
    }

    ${breakpoints.maxLg} {
        padding-top: ${PLAY_BUTTON_WIDTH_MOBILE}em;
        width: ${PLAY_BUTTON_WIDTH_MOBILE}em;
    }
`;

export const PlayIconWrapper = styled.span`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;

export const ControlText = styled.span`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`;
