/* eslint-disable no-await-in-loop */
import axios from 'axios';
import { SET_DYNAMIC_DATA, RECEIVE_DYNAMIC_DATA, FAILED_FETCHING_DATA } from './dynamic.constant';

const setDynamicData = (payload: Object) => ({
    type: SET_DYNAMIC_DATA,
    payload,
});

const receiveDynamicData = (payload: Object) => ({
    type: RECEIVE_DYNAMIC_DATA,
    payload,
});

const failedFetchingData = (payload: Object) => ({
    type: FAILED_FETCHING_DATA,
    payload,
    error: true,
});

const fetchDynamicData = (api: Object, customParams: ?(string[])) => {
    const { apiUrl, id } = api;

    const withSearch = apiUrl.indexOf('?') > -1;
    const withExtraAmpersand = apiUrl.indexOf('&') > -1;

    let appendChar = '?';

    if (withSearch && withExtraAmpersand) {
        appendChar = '';
    } else if (withSearch && !withExtraAmpersand) {
        appendChar = '&';
    }

    const reducedApiUrl = customParams
        ? customParams.reduce((prv, cur) => `${prv}${cur}&`, `${apiUrl}${appendChar}`)
        : apiUrl;

    return async dispatch => {
        try {
            const data = await axios(reducedApiUrl);

            return { id, data };
        } catch (error) {
            dispatch(failedFetchingData({ id, error }));
            throw error;
        }
    };
};

export { fetchDynamicData, setDynamicData, receiveDynamicData };
