import React from 'react';
import styled from 'styled-components';
import whiteSpinner from '../../../static/whiteSpinner.svg';
import greySpinner from '../../../static/greySpinner.svg';
// const whiteSpinner = "https://ww5player.s3-eu-west-1.amazonaws.com/PlayerLoader.svg";
// const greySpinner = "https://ww5player.s3-eu-west-1.amazonaws.com/PlayerLoader_grey.svg";

type Props = {
    transparent: boolean,
};

const Spinner = styled.div`
    display: block;
    position: absolute;
    height: 200px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    background-image: url(${({ transparent }) => (transparent ? greySpinner : whiteSpinner)});
`;

const LoadingSpinner = ({ transparent }: Props) => <Spinner transparent={transparent} />;

export default LoadingSpinner;
