import { IFRAME_LISTENERS } from '../../constants/iframeAPI';
import { post } from './eventDispatchManager.utils';
import utils from '../../utils';
import { PLAYER_READY, PLAY, PAUSE, SEEK, SET_VOLUME } from '../../state/video/video.constant';
import {
    END_FRAME_ENDED,
    SET_VIDEO_QUALITY,
    OVERLAY_EVENT,
    HOTSPOT_IS_CLICKED,
    TOGGLE_OVERLAY,
} from '../../state/options/options.constant';
import Logger from '../../utils/Logger';

/**
 * Event Dispatch Manager Middleware
 * - Filter Redux Actions based on ww4player iframe API: https://github.com/wireWAX/ww4Player/blob/47d72fd70895eaff41119aa68298678d59d14d72/node/public/javascripts/wirewax-iframe-api.js
 * - Post filtered action event name & data to parent window
 * */

const eventDispatchManager = (store: Object) => (next: Function) => (action: Object) => {
    // Logging to console
    if (
        action.type !== 'SET_MOUSE_POSITION' &&
        action.type !== 'SET_CURRENT_FRAME' &&
        action.type !== 'TOGGLE_CONTROLS_VISIBILITY'
    ) {
        Logger.info('Action', action);
    }

    const state = store.getState();

    const {
        video: { playing },
        options: {
            activeOverlay,
            activeVideo: {
                interactions,
                timeTriggers,
                hotspotDesign,
                video: { vidName, duration }, // Global hotspot presetId
            },
            id,
            uuId,
        },
    } = state;

    const { presetId } = hotspotDesign || {}; // Sometimes hotspotDesign is set to null from hobnob

    // Escape SET_VIDEO_QUALITY before video first play
    if (!playing && action.type === SET_VIDEO_QUALITY) {
        return next(action);
    }

    // Escape END_FRAME_ENDED action except the first time
    // TODO: Add VIDEO_END state to player
    if (!playing && action.type === END_FRAME_ENDED) {
        return next(action);
    }

    // Validate posting data with uuid and vidId, and default data to empty object
    const dataValidator = (name: string, data: string | Object = {}) => ({
        name,
        data,
        uniqueViewId: uuId,
        vidId: parseInt(id, 10),
        vidName,
        duration,
    });

    // postMessage that includes special data handling
    const postOverlayMessage = () => {
        let eventName;
        let targetOverlay = {};

        // Overlay Show/close has same Action type: TOGGLE_OVERLAY, can be divided by Action payload
        if (!utils.isEmpty(action.overlay) && utils.isEmpty(activeOverlay)) {
            // To show overlay: TargetOverlay is in redux action payload & activeOverlay is {}
            eventName = IFRAME_LISTENERS.WIDGET_SHOWN;
            targetOverlay = action.overlay;
        } else if (utils.isEmpty(action.overlay) && !utils.isEmpty(activeOverlay)) {
            // To close overlay: TargetOverlay is activeOverlay & redux action payload is {}
            eventName = IFRAME_LISTENERS.WIDGET_CLOSED;
            targetOverlay = activeOverlay;
        } else {
            // Exception: Some APIs dispatch overlay related Actions without checking.
            // Or force to close overlays with {type:"TOGGLE_OVERLAY" overlay: {}}, while activeOverlay is {}
            // eg. IFRAME PLAY API
            return;
        }

        // Exception: Some overlays have no associated hotspots
        // eg. Time Trigger to open an overlay
        if (!targetOverlay.hotspotData) {
            const { overlayId } = targetOverlay;
            const targetTimeTrigger = timeTriggers.find(
                timeTrigger => timeTrigger.overlayId === overlayId
            );

            post(dataValidator(eventName, targetTimeTrigger));

            return;
        }

        // Default Overlay setup: Overlays associated with hotspots
        const {
            hotspotData: { nameLink, spriteId },
        } = targetOverlay;

        const { name, customNameRef = '' } = interactions[nameLink];

        // Hotspot Design (presetId), default to Global Hotspot presetId when the individual presetId is empty {}
        const hotspotPresetId = utils.isEmpty(hotspotDesign)
            ? interactions[nameLink] &&
              interactions[nameLink].hotspotDesign &&
              interactions[nameLink].hotspotDesign.presetId
            : presetId;

        const data = {
            customNameRef,
            nameLink,
            spriteId,
            tagId: hotspotPresetId,
            tagName: name,
        };

        post(dataValidator(eventName, data));
    };

    const postHotspotMessage = () => {
        const { spriteId, nameLink } = action;
        const {
            name,
            customNameRef = '',
            interaction: { clickAction },
        } = interactions[nameLink];

        const hotspotPresetId = utils.isEmpty(hotspotDesign)
            ? interactions[nameLink] &&
              interactions[nameLink].hotspotDesign &&
              interactions[nameLink].hotspotDesign.presetId
            : presetId;

        let clickActionRef = 'none';

        switch (clickAction) {
            case 'url':
                clickActionRef = interactions[nameLink].interaction.settings.url;
                break;
            default:
                clickActionRef = 'none';
                break;
        }

        // TODO: TagId refers to global Hotspot Design, which is only used as CS custom hotspot, should be deprecated
        const data = dataValidator(IFRAME_LISTENERS.TAG_CLICK, {
            tagData: {
                spriteId,
                nameLink,
                customNameRef,
                tagName: name,
                tagId: hotspotPresetId,
                clickAction,
                clickActionRef,
            },
        });

        post(data);
    };

    const postSeekMessage = () => {
        const { time } = action;

        post(dataValidator(IFRAME_LISTENERS.HAS_SEEKED, time));
    };

    const postVolumeMessage = () => {
        const { volume } = action;
        const data = { volume };

        post(dataValidator(IFRAME_LISTENERS.VOLUME_CHANGE, data));
    };

    const postRenditionMessage = () => {
        const { quality } = action;
        const data = { quality };

        post(dataValidator(IFRAME_LISTENERS.RENDITION_CHANGED, data));
    };

    const postOverlayEventMessage = () => {
        const { value, overlayId } = action;

        switch (action.action) {
            case 'addToCart':
                post(dataValidator(IFRAME_LISTENERS.ADD_TO_CART, action.value)); // NOTE: NBC and NOW//WITH are using this, NOT deprecated yet

                // NOTE: Post addToCart to OVERLAY_EVENT channel unify API system
                post(
                    dataValidator(IFRAME_LISTENERS.OVERLAY_EVENT, {
                        action: action.action,
                        value,
                        overlayId,
                    })
                );
                break;
            case 'clickthrough':
                post(
                    dataValidator(IFRAME_LISTENERS.OVERLAY_EVENT, {
                        action: action.action,
                        value,
                        overlayId,
                    })
                );
                break;
            default:
                break;
        }

        // action.action === 'addToCart'
        //   && post(dataValidator(IFRAME_LISTENERS.ADD_TO_CART, action.value));
    };

    // Map Redux Actions to iFrame API
    const actionToPostMap = new Map([
        [PLAYER_READY, () => post(dataValidator(IFRAME_LISTENERS.PLAYER_READY))],
        [PLAY, () => post(dataValidator(IFRAME_LISTENERS.HAS_PLAYED))],
        [PAUSE, () => post(dataValidator(IFRAME_LISTENERS.HAS_PAUSED))],
        [SEEK, () => postSeekMessage()],
        [OVERLAY_EVENT, () => postOverlayEventMessage()],
        [END_FRAME_ENDED, () => post(dataValidator(IFRAME_LISTENERS.VIDEO_END))],
        [SET_VOLUME, () => postVolumeMessage()],
        [HOTSPOT_IS_CLICKED, () => postHotspotMessage()],
        [TOGGLE_OVERLAY, () => postOverlayMessage()],
        [SET_VIDEO_QUALITY, () => postRenditionMessage()],
    ]);

    const iframeDispatcher = actionToPostMap.get(action.type);

    if (iframeDispatcher) {
        iframeDispatcher.call(this);
    }

    return next(action);
};

export default eventDispatchManager;
