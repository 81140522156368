import { useEffect, useState } from 'react';
import { IFRAME_TRIGGERS, IFRAME_LISTENERS } from '../../constants/iframeAPI';
import useEventListener from '../../hooks/useEventListener';
import utils from '../../utils/index';
import type { Props } from './eventManager.types';

/**
 * Event Manager Component
 * - Listening to iframe window parent postMessage
 * - Trigger player playback functions, which will control video playback and dispatch Redux Actions
 * - Dispatch Redux Actions directly
 */

const EventManager = (props: Props) => {
    const {
        toggleUnmuteButton,
        autoplay,
        play,
        pause,
        mute,
        skip,
        video,
        id,
        uuId,
        setVolume,
        volume,
        interactions,
        hotspots,
        hotspotIsClicked,
        overlays,
        activeOverlay,
        toggleOverlay,
        setVideoQuality,
        playing,
        hasPlayed,
        videoQuality,
        currentTime,
        fullScreen,
    } = props;

    const [state, setState] = useState({
        eventName: null,
        eventData: null,
    });

    // Validate posting data with uuid and vidId, and default data to empty object
    const dataValidator = (name: string, data: string | Object = {}) => ({
        name,
        data,
        uniqueViewId: uuId,
        vidId: parseInt(id, 10),
        vidName: video.vidName,
        duration: video.duration,
    });

    const iframePlay = () => {
        // TODO Add autoplay allow list in Studio Embed
        toggleOverlay({});

        // Escape play if video is already playing
        if (playing) {
            return;
        }

        // Video not played yet, and no autoplay param :: mute video and play
        if (!hasPlayed && !autoplay) {
            toggleUnmuteButton(true);
            mute();
            play();
        } else {
            play();
        }
    };

    const seek = () => {
        const { eventData } = state;

        if (eventData && eventData > video.duration) {
            console.error('Seeking time goes beyond video length');
        } else {
            skip(eventData, 'sec');
        }
    };

    const postMessage = message => {
        const target = window.parent;
        const targetOrigin = '*';

        target.postMessage(message, targetOrigin);
    };

    const changeVolume = () => {
        const { eventData } = state;

        if (eventData > 1 || eventData < 0) {
            console.error('Volume range is a float between 0 and 1');
        } else if (eventData >= 0 && eventData <= 1) {
            setVolume(eventData);
        }
    };

    const muteVolume = () => {
        if (volume > 0) {
            mute();
        }
    };

    const unmuteVolume = () => {
        if (volume <= 0) {
            mute();
        }
    };

    const goToTag = () => {
        const { eventData } = state;
        const targetHotspot = utils.getTagBySpriteId(hotspots, eventData);

        if (!targetHotspot) {
            console.error(`No hotspots/tags with spriteId ${eventData}`);

            return;
        }
        skip(targetHotspot.show, 'sec');
    };

    const openTag = () => {
        const { eventData } = state;

        const targetHotspot = utils.getTagBySpriteId(hotspots, eventData);

        if (!targetHotspot) {
            console.error(`No hotspots/tags with spriteId ${eventData}`);

            return;
        }
        const { nameLink, spriteId } = targetHotspot;

        hotspotIsClicked(nameLink, spriteId);
        goToTag();

        const {
            interaction: {
                settings: { overlayId },
            },
        } = interactions[nameLink];

        const targetOverlay = utils.getOverlayByOverlayId(overlays, overlayId);

        if (targetOverlay) {
            targetOverlay.hotspotData = {
                nameLink,
                spriteId,
            };
            toggleOverlay(targetOverlay);

            pause();
        } else {
            console.error('no overlay is associated with this hotspot');
        }
    };

    const closeWidget = () => {
        if (Object.entries(activeOverlay).length !== 0) {
            play();
            toggleOverlay({});
        }
    };

    const changeRendition = () => {
        const { eventData } = state;

        if (videoQuality.toString() !== eventData) {
            // && playing // NOTE: took this out to align with player experience
            setVideoQuality(eventData);
        }
    };

    const postCurrentTime = () => {
        const eventName = IFRAME_LISTENERS.RETURN_CURRENT_TIME;
        const eventData = currentTime;

        setState({ eventName, eventData });
        const message = dataValidator(eventName, { currentTime: eventData });

        postMessage(message);
    };

    const postReadyStatus = () => {
        setState({ eventName: IFRAME_LISTENERS.PLAYER_READY });
        const message = dataValidator(IFRAME_LISTENERS.PLAYER_READY);

        postMessage(message);
    };

    const toggleFullscreen = () => {
        fullScreen();
    };

    const iframeToActionMap = new Map([
        [IFRAME_TRIGGERS.PLAY, () => iframePlay()],
        [IFRAME_TRIGGERS.PAUSE, () => pause()],
        [IFRAME_TRIGGERS.SEEK, () => seek()],
        [IFRAME_TRIGGERS.IS_PLAYER_READY, () => postReadyStatus()],
        [IFRAME_TRIGGERS.GET_CURRENT_TIME, () => postCurrentTime()],
        [IFRAME_TRIGGERS.GO_TO_TAG, () => goToTag()],
        [IFRAME_TRIGGERS.OPEN_TAG, () => openTag()],
        [IFRAME_TRIGGERS.CHANGE_VOLUME, () => changeVolume()],
        [IFRAME_TRIGGERS.MUTE_VOLUME, () => muteVolume()],
        [IFRAME_TRIGGERS.UNMUTE_VOLUME, () => unmuteVolume()],
        [IFRAME_TRIGGERS.CLOSE_WIDGET, () => closeWidget()],
        [IFRAME_TRIGGERS.CHANGE_RENDITION, () => changeRendition()],
        [IFRAME_TRIGGERS.ENTER_FULLSCREEN, () => toggleFullscreen()],
    ]);

    const actionsAllowedBeforeHasPlayed = [
        IFRAME_LISTENERS.PLAYER_READY,
        IFRAME_TRIGGERS.PLAY,
        IFRAME_TRIGGERS.IS_PLAYER_READY,
        IFRAME_LISTENERS.RETURN_CURRENT_TIME,
        IFRAME_TRIGGERS.GET_CURRENT_TIME,
        IFRAME_TRIGGERS.MUTE_VOLUME,
        IFRAME_TRIGGERS.UNMUTE_VOLUME,
        IFRAME_TRIGGERS.CHANGE_VOLUME,
        IFRAME_TRIGGERS.TOGGLE_FULLSCREEN,
        null, // Escape initial state of null
    ];

    // Runs every time state changes
    useEffect(
        () => {
            const { eventName } = state;

            if (hasPlayed || actionsAllowedBeforeHasPlayed.includes(eventName)) {
                const actionDispatcher = iframeToActionMap.get(eventName);

                if (actionDispatcher) {
                    actionDispatcher.call(this);
                }
            } else {
                // TODO: Design another interface to send error messages to parent window
                // console.error(eventName, 'can only be executed after video has played once')
            }
        },
        [state]
    );

    const onReceiveMessage = event => {
        // check the origin, only accept message come from a different origin (parent window)
        if (event && event.data && event.origin !== window.origin) {
            const { data } = event;
            const eventName = data.name || data;
            const eventData = data.data;

            setState({ eventName, eventData });
        }
    };

    useEventListener('message', onReceiveMessage, window);

    return null;
};

export default EventManager;
