import React from 'react';
import colors from '../constants/colors';
import FocusCircle from './focus-circle';

const IconPlay = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle cx="15" cy="15" r="15" fill={colors.white} transform="translate(4 4)"/>
      <path fill="#292929" d="M22.376 19.416l-4.599 3.066a.5.5 0 0 1-.777-.416v-6.132a.5.5 0 0 1 .777-.416l4.599 3.066a.5.5 0 0 1 0 .832z"/>
      <FocusCircle />
    </g>
  </svg>
);

export default IconPlay;
