import initialState from './checks.state';
import { DOMAIN_LOCKED, COUNTRY_LOCKED, VIDEO_VERSION } from './checks.constant';
import { PASSWORD_UPDATE, PASSWORD_VERIFIED } from '../password/password.constant';

type State = Object;

type Action = Object;

const checks = (state: State = initialState, action: Action): Object => {
    switch (action.type) {
        case PASSWORD_UPDATE:
            return {
                ...state,
                passwordProtected: action.passwordProtected,
            };
        case PASSWORD_VERIFIED:
            return {
                ...state,
                passwordProtected: false,
            };
        case DOMAIN_LOCKED:
            return {
                ...state,
                domainLock: action.lock,
            };
        case COUNTRY_LOCKED:
            return {
                ...state,
                countryLock: action.lock,
            };
        case VIDEO_VERSION:
            return {
                ...state,
                version: action.version,
            };
        default:
            return state;
    }
};

export default checks;
