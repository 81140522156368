import React from 'react';
import colors from '../styles/colors';

const IconTwitter = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill={colors.codGray} fillRule="nonzero" d="M16.5 6.195c-.335.488-.77.927-1.28 1.317v.293c0 .841-.161 1.683-.484 2.536a7.187 7.187 0 0 1-1.404 2.318 7.121 7.121 0 0 1-2.274 1.682c-.907.44-1.939.659-3.12.659-.72 0-1.416-.098-2.074-.305A8.528 8.528 0 0 1 4 13.854c.1.012.199.024.298.024h.323c1.218 0 2.274-.354 3.169-1.06a2.507 2.507 0 0 1-1.504-.513 2.398 2.398 0 0 1-.882-1.244c.174.037.323.061.472.061.124 0 .249-.012.348-.024.112-.013.211-.037.336-.061a2.577 2.577 0 0 1-1.479-.866 2.395 2.395 0 0 1-.584-1.598v-.036c.174.085.348.158.547.207.186.06.41.085.646.085a2.257 2.257 0 0 1-.857-.878 2.487 2.487 0 0 1-.299-1.207c0-.451.112-.866.336-1.28a7.153 7.153 0 0 0 2.323 1.865c.907.464 1.901.72 2.958.769a2.561 2.561 0 0 1-.063-.55c0-.353.063-.682.2-.987.136-.317.322-.585.558-.817a2.77 2.77 0 0 1 .808-.549c.31-.134.634-.195.994-.195.385 0 .733.073 1.044.22.31.146.584.341.832.573.286-.037.572-.122.858-.232.285-.11.546-.232.783-.354a2.769 2.769 0 0 1-1.131 1.378c.273-.024.522-.073.77-.146.236-.061.472-.146.696-.244z"/>
  </svg>
);

export default IconTwitter;
