import * as React from 'react';
import { ButtonContainer, ButtonText } from './commonButton.styles';
import type { CommonButtonProps } from './commonButton.types';

const CommonButton = ({
    onClick,
    fontSize,
    children,
    textClass,
    largeFont,
    mediumFont,
    mobileFont,
    ...rest
}: CommonButtonProps) => (
    <ButtonContainer onClick={onClick} {...rest}>
        <ButtonText
            fontSize={fontSize}
            largeFont={largeFont}
            className={textClass}
            mobileFont={mobileFont}
            mediumFont={mediumFont}
        >
            {children}
        </ButtonText>
    </ButtonContainer>
);

export default CommonButton;
