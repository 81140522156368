import React from "react";

const IconClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 31.112 31.112"
    version="1.1"
    height="100%"
    width="100%"
  >
    <path
      d="M31.112 1.414L29.698 0 15.556 14.142 1.414 0 0 1.414l14.142 14.142L0 29.698l1.414 1.414L15.556 16.97l14.142 14.142 1.414-1.414L16.97 15.556z"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
);

export default IconClose;
