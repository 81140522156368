import React from 'react';
import { connect } from 'react-redux';
import { StyledDiv } from './mediaClickArea.styles';

const MediaClickArea = ({ isImage, onClick }) => (
    <StyledDiv className="videoOverlay" onClick={isImage ? null : onClick} />
);

const MapStateToProps = (state: Object) => ({
    isImage: state.options.isImage,
});

export default connect(MapStateToProps)(MediaClickArea);