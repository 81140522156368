const FONTS = [
    {
        label: 'Amatic SC',
        fontValue: 'FONT_AMATIC_SC',
        className: 'font-family-amatic_sc',
        googleFontSlug: 'Amatic+SC',
        id: 100,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_APERCU',
        className: 'font-family-apercu',
        fontFaces: [],
        id: 101,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_ARIAL',
        className: 'font-family-arial',
        id: 102,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_AVENIR',
        className: 'font-family-avenir',
        fontFaces: [],
        id: 103,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_CENTURY_GOTHIC',
        className: 'font-family-century-gothic',
        id: 104,
        isStandardFont: true,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_CLEARVIEWATT',
        className: 'font-family-ClearviewATT',
        fontFaces: [],
        id: 105,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_CLEARVIEWATT_BOOK',
        className: 'font-family-ClearviewATT_book',
        id: 106,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_CLEARVIEWATT_LIGHT',
        className: 'font-family-ClearviewATT_light',
        fontFaces: [],
        id: 107,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_COURIER_NEW',
        className: 'font-family-courier-new',
        id: 108,
        isStandardFont: true,
    },
    {
        label: 'EB Garamond',
        fontValue: 'FONT_EB_GARAMOND',
        className: 'font-family-eb-garamond',
        googleFontSlug: 'EB+Garamond',
        id: 109,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_EMPRINT',
        className: 'font-family-emprint',
        fontFaces: [],
        id: 110,
        isStandardFont: true,
    },
    {
        label: 'futura-pt',
        fontValue: 'FONT_FUTURA_TEE',
        className: 'font-family-futura-tee',
        fontFaces: [],
        id: 111,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_GEORGIA',
        className: 'font-family-georgia',
        fontFaces: [],
        id: 112,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_GOTHAM',
        className: 'font-family-gotham',
        fontFaces: [],
        id: 113,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_HELVETICA',
        className: 'font-family-helvetica',
        fontFaces: [],
        id: 114,
        isStandardFont: true,
    },
    {
        label: 'Josefin Sans',
        fontValue: 'FONT_JOSEFIN_SANS',
        className: 'font-family-josefin-sans',
        googleFontSlug: 'Josefin+Sans',
        id: 115,
        isStandardFont: true,
    },
    {
        label: 'Josefin Slab',
        fontValue: 'FONT_JOSEFIN_SLAB',
        className: 'font-family-josefin-slab',
        googleFontSlug: 'Josefin+Slab',
        id: 116,
        isStandardFont: true,
    },
    {
        label: 'Lato',
        fontValue: 'FONT_LATO',
        className: 'font-family-lato',
        googleFontSlug: 'Lato',
        id: 117,
        isStandardFont: true,
    },
    {
        label: 'Lobster',
        fontValue: 'FONT_LOBSTER',
        className: 'font-family-lobster',
        googleFontSlug: 'Lobster',
        id: 118,
        isStandardFont: true,
    },
    {
        label: 'Merriweather',
        fontValue: 'FONT_MERRIWEATHER',
        className: 'font-family-merriweather',
        googleFontSlug: 'Merriweather',
        id: 119,
        isStandardFont: true,
    },
    {
        label: 'Montserrat',
        fontValue: 'FONT_MONTSERRAT',
        className: 'font-family-montserrat',
        googleFontSlug: 'Montserrat',
        id: 120,
        isStandardFont: true,
    },
    {
        label: 'Muli',
        fontValue: 'FONT_MULI',
        className: 'font-family-muli',
        googleFontSlug: 'Muli',
        id: 121,
        isStandardFont: true,
    },
    {
        label: 'Nunito',
        fontValue: 'FONT_NUNITO',
        className: 'font-family-nunito',
        fontFaces: [
            {
                slug: 'NunitoSans-Regular/NunitoSans-Regular',
            },
            {
                slug: 'Nunito/Nunito-Bold',
                fontStyles: [
                    {
                        name: 'font-style',
                        value: 'italic',
                    },
                ],
            },
            {
                slug: 'Nunito/Nunito-Bold',
                fontStyles: [
                    {
                        name: 'font-weight',
                        value: '700',
                    },
                ],
            },
            {
                slug: 'Nunito/Nunito-BoldItalic',
                fontStyles: [
                    {
                        name: 'font-weight',
                        value: '700',
                    },
                    {
                        name: 'font-style',
                        value: 'italic',
                    },
                ],
            },
        ],
        id: 122,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_OMNES_ATT_II_LIGHT',
        className: 'font-family-Omnes_ATT_II_light',
        fontFaces: [],
        id: 123,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_OMNES_ATT_II',
        className: 'font-family-Omnes_ATT_II',
        fontFaces: [],
        id: 124,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_OMNES_ATT_II_MEDIUM',
        className: 'font-family-Omnes_ATT_II_medium',
        fontFaces: [],
        id: 125,
        isStandardFont: true,
    },
    {
        label: 'Open Sans',
        fontValue: 'FONT_OPEN_SANS',
        className: 'font-family-open-sans',
        googleFontSlug: 'Open+Sans',
        id: 126,
        isStandardFont: true,
    },
    {
        label: 'Pacifico',
        fontValue: 'FONT_PACIFICO',
        className: 'font-family-pacifico',
        googleFontSlug: 'Pacifico',
        id: 127,
        isStandardFont: true,
    },
    {
        label: 'Playfair Display',
        fontValue: 'FONT_PLAYFAIR_DISPLAY',
        className: 'font-family-playfair-display',
        googleFontSlug: 'Playfair+Display',
        id: 128,
        isStandardFont: true,
    },
    {
        label: 'Permanent Marker',
        fontValue: 'FONT_PERMANENT_MARKER',
        className: 'font-family-permanent-marker',
        googleFontSlug: 'Permanent+Marker',
        id: 129,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_PROXIMA_NOVA',
        className: 'font-family-proxima-nova',
        fontFaces: [],
        id: 130,
        isStandardFont: true,
    },
    {
        label: 'Quicksand',
        fontValue: 'FONT_QUICKSAND',
        className: 'font-family-quicksand',
        googleFontSlug: 'Quicksand',
        id: 131,
        isStandardFont: true,
    },
    {
        label: 'PT Serif',
        fontValue: 'FONT_PT_SERIF',
        className: 'font-family-pt-serif',
        googleFontSlug: 'PT+Serif',
        id: 132,
        isStandardFont: true,
    },
    {
        label: 'Raleway',
        fontValue: 'FONT_RALEWAY',
        className: 'font-family-raleway',
        googleFontSlug: 'Raleway',
        id: 133,
        isStandardFont: true,
    },
    {
        label: 'Roboto',
        fontValue: 'FONT_ROBOTO',
        className: 'font-family-roboto',
        googleFontSlug: 'Roboto',
        id: 134,
        isStandardFont: true,
    },
    {
        label: 'Satisfy',
        fontValue: 'FONT_SATISFY',
        className: 'font-family-satisfy',
        googleFontSlug: 'Satisfy',
        id: 135,
        isStandardFont: true,
    },
    {
        label: 'Source Sans Pro',
        fontValue: 'FONT_SOURCE_SANS_PRO',
        className: 'font-family-source-sans-pro',
        googleFontSlug: 'Source+Sans+Pro',
        id: 136,
        isStandardFont: true,
    },
    {
        label: 'Special Elite',
        fontValue: 'FONT_SPECIAL_ELITE',
        className: 'font-family-special-elite',
        googleFontSlug: 'Special+Elite',
        id: 137,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_STAG_ATT',
        className: 'font-family-Stag_ATT',
        fontFaces: [],
        id: 138,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_STAG_ATT',
        className: 'font-family-Stag_ATT',
        fontFaces: [],
        id: 139,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_STAG_ATT_LIGHT',
        className: 'font-family-Stag_ATT_light',
        fontFaces: [],
        id: 140,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_STAG_ATT_BOOK',
        className: 'font-family-Stag_ATT_book',
        fontFaces: [],
        id: 141,
        isStandardFont: true,
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_TAHOMA',
        className: 'font-family-tahoma',
        fontFaces: [],
        id: 142,
        isStandardFont: true,
    },
    {
        label: 'Verdana',
        fontValue: 'FONT_VERDANA',
        className: 'font-family-verdana',
        id: 143,
        isStandardFont: true,
    },
    {
        label: 'VanCondensedBold',
        fontValue: 'VanCondensedBold',
        className: 'font-family-VanCondensedBold',
        id: 144,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'VanCondensedBold/VanCondensedBold',
            },
        ],
    },
    {
        label: 'GeographRegular',
        fontValue: 'GeographRegular',
        className: 'font-family-GeographRegular',
        id: 145,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'GeographRegular/GeographRegular',
            },
        ],
    },
    {
        label: 'GeographMedium',
        fontValue: 'GeographMedium',
        className: 'font-family-GeographMedium',
        id: 146,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'GeographMedium/GeographMedium',
            },
        ],
    },
    {
        label: 'NotoSansBold',
        fontValue: 'NotoSansBold',
        className: 'font-family-NotoSansBold',
        id: 147,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'NotoSansBold/NotoSansBold',
            },
        ],
    },
    {
        label: 'NotoSansRegular',
        fontValue: 'NotoSansRegular',
        className: 'font-family-NotoSansRegular',
        id: 148,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'NotoSansRegular/NotoSansRegular',
            },
        ],
    },
    {
        label: 'NotoSansMedium',
        fontValue: 'NotoSansMedium',
        className: 'font-family-NotoSansMedium',
        id: 149,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'NotoSansMedium/NotoSansMedium',
            },
        ],
    },
    {
        label: 'NotoSansItalic',
        fontValue: 'NotoSansItalic',
        className: 'font-family-NotoSansItalic',
        id: 150,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'NotoSansItalic/NotoSansItalic',
            },
        ],
    },
    {
        label: 'LoraRegular',
        fontValue: 'LoraRegular',
        className: 'font-family-LoraRegular',
        id: 151,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'LoraRegular/LoraRegular',
            },
        ],
    },
    {
        label: 'SofiaProBold',
        fontValue: 'SofiaProBold',
        className: 'font-family-SofiaProBold',
        id: 153,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'SofiaProBold/SofiaProBold',
            },
        ],
    },
    {
        label: 'Noto Sans',
        fontValue: 'BentonSansBold',
        className: 'font-family-BentonSansBold',
        id: 154,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'BentonSansBook',
        className: 'font-family-BentonSansBook',
        id: 155,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'BentonSansRegular',
        className: 'font-family-BentonSansRegular',
        id: 156,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'GraphikRegular',
        className: 'font-family-GraphikRegular',
        id: 157,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Flama-ExtraboldItalic',
        className: 'font-family-Flama-ExtraboldItalic',
        id: 158,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Flama-Extrabold',
        className: 'font-family-Flama-Extrabold',
        id: 159,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Flama-Book',
        className: 'font-family-Flama-Book',
        id: 160,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Flama-BookItalic',
        className: 'font-family-Flama-BookItalic',
        id: 161,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Black-Italic',
        id: 162,
        className: 'font-family-Averta-Black-Italic',
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Black',
        className: 'font-family-Averta-Black',
        id: 163,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Bold-Italic',
        className: 'font-family-Averta-Bold-Italic',
        id: 164,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Bold',
        className: 'font-family-Averta-Bold',
        id: 165,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Extra-Bold-Italic',
        className: 'font-family-Averta-Extra-Bold-Italic',
        id: 166,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Extra-Bold',
        className: 'font-family-Averta-Extra-Bold',
        id: 167,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Extra-Thin-Italic',
        className: 'font-family-Averta-Extra-Thin-Italic',
        id: 168,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Extra-Thin',
        className: 'font-family-Averta-Extra-Thin',
        id: 169,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Light-Italic',
        className: 'font-family-Averta-Light-Italic',
        id: 170,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Light',
        className: 'font-family-Averta-Light',
        id: 171,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Regular-Italic',
        className: 'font-family-Averta-Regular-Italic',
        id: 172,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Regular',
        className: 'font-family-Averta-Regular',
        id: 173,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Semi-Bold-Italic',
        className: 'font-family-Averta-Semi-Bold-Italic',
        id: 174,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Semi-Bold',
        className: 'font-family-Averta-Semi-Bold',
        id: 175,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Thin-Italic',
        className: 'font-family-Averta-Thin-Italic',
        id: 176,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Averta-Thin',
        className: 'font-family-Averta-Thin',
        id: 177,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'CR Icons Regular',
        fontValue: 'CR-Icons-Regular',
        className: 'font-family-CR-Icons-Regular',
        id: 178,
        isStandardFont: false,
        fontFaces: [
            {
                slug: 'Averta/CR-Icons-Regular',
            },
        ],
    },
    {
        label: 'Noto Sans',
        fontValue: 'GoodSans-Black',
        className: 'font-family-GoodSans-Black',
        id: 179,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'GoodSans-Light',
        className: 'font-family-GoodSans-Light',
        id: 180,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'GoodSans-Regular',
        className: 'font-family-GoodSans-Regular',
        id: 181,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'GoodSans-Thin',
        className: 'font-family-GoodSans-Thin',
        id: 182,
        isStandardFont: false,
        fontFaces: [],
    },
    {
        label: 'Volvo Novum Bold',
        fontValue: 'Volvo-Novum-Bold',
        className: 'font-family-Volvo-Novum-Bold',
        isStandardFont: false,
        id: 183,
        fontFaces: [
            {
                slug: 'Volvo_Novum_Bold/Volvo_Novum_Bold',
            },
        ],
    },
    {
        label: 'Volvo Novum Light',
        fontValue: 'Volvo-Novum-Light',
        className: 'font-family-Volvo-Novum-Light',
        isStandardFont: false,
        id: 184,
        fontFaces: [
            {
                slug: 'Volvo_Novum_Light/Volvo_Novum_Light',
            },
        ],
    },
    {
        label: 'Volvo Novum Medium',
        fontValue: 'Volvo-Novum-Medium',
        className: 'font-family-Volvo-Novum-Medium',
        isStandardFont: false,
        id: 185,
        fontFaces: [
            {
                slug: 'Volvo_Novum_Medium/Volvo_Novum_Medium',
            },
        ],
    },
    {
        label: 'Volvo Novum Regular',
        fontValue: 'Volvo-Novum-Regular',
        className: 'font-family-Volvo-Novum-Regular',
        isStandardFont: false,
        id: 186,
        fontFaces: [
            {
                slug: 'Volvo_Novum_Regular/Volvo_Novum_Regular',
            },
        ],
    },
    {
        label: 'Volvo Novum Semi Light Italic',
        fontValue: 'Volvo-Novum-Semi-Light-Italic',
        className: 'font-family-Volvo-Novum-Semi-Light-Italic',
        isStandardFont: false,
        id: 187,
        fontFaces: [
            {
                slug: 'Volvo_Novum_Semi_Light_Italic/Volvo_Novum_SemiLightItalic',
            },
        ],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Gibson-Bold',
        className: 'font-family-gibson-bold',
        isStandardFont: false,
        id: 188,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Gibson-Light',
        className: 'font-family-gibson-light',
        isStandardFont: false,
        id: 189,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Gibson-Regular',
        className: 'font-family-gibson-regular',
        isStandardFont: false,
        id: 190,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_UniversLight',
        className: 'font-family-UniversLight',
        isStandardFont: false,
        id: 191,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'FONT_UniversBold',
        className: 'font-family-UniversBold',
        isStandardFont: false,
        id: 192,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Halyard-Display-Medium',
        className: 'font-family-Halyard-Display-Medium',
        isStandardFont: false,
        id: 193,
        fontFaces: [],
    },
    {
        label: 'GT Cinetype Syfy Bold',
        fontValue: 'GTCinetypeSyfy-Bold',
        className: 'font-family-GTCinetypeSyfy-Bold',
        isStandardFont: false,
        id: 194,
        fontFaces: [
            {
                slug: 'GTCinetypeSyfy-Bold/GTCinetypeSyfy-Bold',
            },
        ],
    },
    {
        label: 'GT Cinetype Syfy Regular',
        fontValue: 'GTCinetypeSyfy-Regular',
        className: 'font-family-GTCinetypeSyfy-Regular',
        isStandardFont: false,
        id: 195,
        fontFaces: [
            {
                slug: 'GTCinetypeSyfy-Regular/GTCinetypeSyfy-Regular',
            },
        ],
    },
    {
        label: 'Georgia',
        fontValue: 'Georgia',
        className: 'font-family-Georgia',
        isStandardFont: false,
        id: 196,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'ChronicleDisplaySemiBold',
        className: 'font-family-ChronicleDisplaySemiBold',
        isStandardFont: false,
        id: 197,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'ChronicleDisplayLight',
        className: 'font-family-ChronicleDisplayLight',
        isStandardFont: false,
        id: 198,
        fontFaces: [],
    },
    {
        label: 'SYFYHero Italic',
        fontValue: 'SYFYHero-Italic',
        className: 'font-family-SYFYHero-Italic',
        isStandardFont: false,
        id: 199,
        fontFaces: [
            {
                slug: 'SYFYHero-Italic/SYFYHero-Italic',
            },
        ],
    },
    {
        label: 'SYFYHero Regular',
        fontValue: 'SYFYHero-Regular',
        className: 'font-family-SYFYHero-Regular',
        isStandardFont: false,
        id: 200,
        fontFaces: [
            {
                slug: 'SYFYHero-Regular/SYFYHero-Regular',
            },
        ],
    },
    {
        label: 'Noto Sans',
        fontValue: 'HelveticaNeueBold',
        className: 'font-family-HelveticaNeueBold',
        isStandardFont: false,
        id: 201,
        fontFaces: [],
    },
    {
        label: 'TESCO Modern Bold',
        fontValue: 'TESCOModern-Bold',
        className: 'font-family-TESCOModern-Bold',
        isStandardFont: false,
        id: 202,
        fontFaces: [
            {
                slug: 'TESCO_Modern/TESCO_Modern-Bold',
            },
        ],
    },
    {
        label: 'TESCO Modern Light',
        fontValue: 'TESCOModern-Light',
        className: 'font-family-TESCOModern-Light',
        isStandardFont: false,
        id: 203,
        fontFaces: [
            {
                slug: 'TESCO_Modern/TESCO_Modern-Light',
            },
        ],
    },
    {
        label: 'TESCO Modern Medium',
        fontValue: 'TESCOModern-Medium',
        className: 'font-family-TESCOModern-Medium',
        isStandardFont: false,
        id: 204,
        fontFaces: [
            {
                slug: 'TESCO_Modern/TESCO_Modern-Medium',
            },
        ],
    },
    {
        label: 'TESCO Modern Regular',
        fontValue: 'TESCOModern-Regular',
        className: 'font-family-TESCOModern-Regular',
        isStandardFont: false,
        id: 205,
        fontFaces: [
            {
                slug: 'TESCO_Modern/TESCO_Modern-Regular',
            },
        ],
    },
    {
        label: 'Montserrat Light',
        fontValue: 'Montserrat-Light',
        className: 'font-family-montserrat-light',
        isStandardFont: false,
        id: 206,
        fontFaces: [
            {
                slug: 'Montserrat/Montserrat-Light',
            },
        ],
    },
    {
        label: 'Raleway Medium',
        fontValue: 'Raleway-Medium',
        className: 'font-family-raleway-medium',
        isStandardFont: false,
        id: 207,
        fontFaces: [
            {
                slug: 'Raleway-Medium/Raleway-Medium',
            },
        ],
    },
    {
        label: 'Malgun Regular',
        fontValue: 'MatchesFashion_Malgun-Regular',
        className: 'font-family-MatchesFashion_Malgun-Regular',
        isStandardFont: false,
        id: 208,
        fontFaces: [
            {
                slug: 'MatchesFashion_Malgun-Regular/MatchesFashion_Malgun-Regular',
            },
        ],
    },
    {
        label: 'Malgun Bold',
        fontValue: 'MatchesFashion_Malgun-Bold',
        className: 'font-family-MatchesFashion_Malgun-Bold',
        isStandardFont: false,
        id: 209,
        fontFaces: [
            {
                slug: 'MatchesFashion_Malgun-Bold/MatchesFashion_Malgun-Bold',
            },
        ],
    },
    {
        label: 'Chronicle Display Black',
        fontValue: 'ChronicleDisplay-Black',
        className: 'font-family-ChronicleDisplay-Black',
        isStandardFont: true,
        id: 210,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-Black/ChronicleDisplay-Black',
            },
        ],
    },
    {
        label: 'Chronicle Display SemiItalic',
        fontValue: 'ChronicleDisplay-SemiItalic',
        className: 'font-family-ChronicleDisplay-SemiItalic',
        isStandardFont: true,
        id: 211,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-SemiItalic/ChronicleDisplay-SemiItalic',
            },
        ],
    },
    {
        label: 'Chronicle Display XLight Italic',
        fontValue: 'ChronicleDisplay-XLightItalic',
        className: 'font-family-ChronicleDisplay-XLightItalic',
        isStandardFont: true,
        id: 212,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-XLightItalic/ChronicleDisplay-XLightItalic',
            },
        ],
    },
    {
        label: 'Chronicle Display Roman',
        fontValue: 'ChronicleDisplay-Roman',
        className: 'font-family-ChronicleDisplay-Roman',
        isStandardFont: true,
        id: 213,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-Roman/ChronicleDisplay-Roman',
            },
        ],
    },
    {
        label: 'Chronicle Display XLight',
        fontValue: 'ChronicleDisplay-XLight',
        className: 'font-family-ChronicleDisplay-XLight',
        isStandardFont: true,
        id: 214,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-XLight/ChronicleDisplay-XLight',
            },
        ],
    },
    {
        label: 'Chronicle Display Black Italic',
        fontValue: 'ChronicleDisplay-BlackItalic',
        className: 'font-family-ChronicleDisplay-BlackItalic',
        isStandardFont: true,
        id: 215,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-BlackItalic/ChronicleDisplay-BlackItalic',
            },
        ],
    },
    {
        label: 'Chronicle Display Bold',
        fontValue: 'ChronicleDisplay-Bold',
        className: 'font-family-ChronicleDisplay-Bold',
        isStandardFont: true,
        id: 216,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-Bold/ChronicleDisplay-Bold',
            },
        ],
    },
    {
        label: 'Chronicle Display Bold Italic',
        fontValue: 'ChronicleDisplay-BoldItalic',
        className: 'font-family-ChronicleDisplay-BoldItalic',
        isStandardFont: true,
        id: 217,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-BoldItalic/ChronicleDisplay-BoldItalic',
            },
        ],
    },
    {
        label: 'Chronicle Display Italic',
        fontValue: 'ChronicleDisplay-Italic',
        className: 'font-family-ChronicleDisplay-Italic',
        isStandardFont: true,
        id: 218,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-Italic/ChronicleDisplay-Italic',
            },
        ],
    },
    {
        label: 'Chronicle Display Light Italic',
        fontValue: 'ChronicleDisplay-LightItalic',
        className: 'font-family-ChronicleDisplay-LightItalic',
        isStandardFont: true,
        id: 219,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-LightItalic/ChronicleDisplay-LightItalic',
            },
        ],
    },
    {
        label: 'Chronicle Display Light Italic',
        fontValue: 'ChronicleDisplay-LightItalic',
        className: 'font-family-ChronicleDisplay-LightItalic',
        isStandardFont: true,
        id: 220,
        fontFaces: [
            {
                slug: 'ChronicleDisplay-LightItalic/ChronicleDisplay-LightItalic',
            },
        ],
    },
    {
        label: 'Blair ITC Medium',
        fontValue: 'MatchesFashion_BlairITC-Medium',
        className: 'font-family-MatchesFashion_BlairITC-Medium',
        isStandardFont: false,
        id: 221,
        fontFaces: [
            {
                slug: 'MatchesFashion_BlairITC-Medium/MatchesFashion_BlairITC-Medium',
            },
        ],
    },
    {
        label: 'Blair ITC Bold',
        fontValue: 'MatchesFashion_BlairITC-Bold',
        className: 'font-family-MatchesFashion_BlairITC-Bold',
        isStandardFont: false,
        id: 222,
        fontFaces: [
            {
                slug: 'MatchesFashion_BlairITC-Bold/MatchesFashion_BlairITC-Bold',
            },
        ],
    },
    {
        label: 'Berthold Akzidenz Grotesk Bold',
        fontValue: 'MatchesFashion_BertholdAkzidenzGrotesk-Bold',
        className: 'font-family-MatchesFashion_BertholdAkzidenzGrotesk-Bold',
        isStandardFont: false,
        id: 223,
        fontFaces: [
            {
                slug:
                    'MatchesFashion_BertholdAkzidenzGrotesk-Bold/MatchesFashion_BertholdAkzidenzGrotesk-Bold',
            },
        ],
    },
    {
        label: 'Berthold Akzidenz Grotesk Light',
        fontValue: 'MatchesFashion_BertholdAkzidenzGrotesk-Light',
        className: 'font-family-MatchesFashion_BertholdAkzidenzGrotesk-Light',
        isStandardFont: false,
        id: 224,
        fontFaces: [
            {
                slug:
                    'MatchesFashion_BertholdAkzidenzGrotesk-Light/MatchesFashion_BertholdAkzidenzGrotesk-Light',
            },
        ],
    },
    {
        label: 'Berthold Akzidenz Grotesk Medium',
        fontValue: 'MatchesFashion_BertholdAkzidenzGrotesk-Medium',
        className: 'font-family-MatchesFashion_BertholdAkzidenzGrotesk-Medium',
        isStandardFont: false,
        id: 225,
        fontFaces: [
            {
                slug:
                    'MatchesFashion_BertholdAkzidenzGrotesk-Medium/MatchesFashion_BertholdAkzidenzGrotesk-Medium',
            },
        ],
    },
    {
        label: 'Univers Next Pro Bold',
        fontValue: 'Unicef_UniversNextPro-Bold',
        className: 'font-family-Unicef_UniversNextPro-Bold',
        isStandardFont: false,
        id: 226,
        fontFaces: [
            {
                slug: 'Unicef_UniversNextPro/Unicef_UniversNextPro-Bold',
            },
        ],
    },
    {
        label: 'Univers Next Pro Bold Italic',
        fontValue: 'Unicef_UniversNextPro-BoldItalic',
        className: 'font-family-Unicef_UniversNextPro-BoldItalic',
        isStandardFont: false,
        id: 227,
        fontFaces: [
            {
                slug: 'Unicef_UniversNextPro/Unicef_UniversNextPro-BoldItalic',
            },
        ],
    },
    {
        label: 'Univers Next Pro Heavy Condensed',
        fontValue: 'Unicef_UniversNextPro-HeavyCond',
        className: 'font-family-Unicef_UniversNextPro-HeavyCond',
        isStandardFont: false,
        id: 228,
        fontFaces: [
            {
                slug: 'Unicef_UniversNextPro/Unicef_UniversNextPro-HeavyCond',
            },
        ],
    },
    {
        label: 'Univers Next Pro Light',
        fontValue: 'Unicef_UniversNextPro-Light',
        className: 'font-family-Unicef_UniversNextPro-Light',
        isStandardFont: false,
        id: 229,
        fontFaces: [
            {
                slug: 'Unicef_UniversNextPro/Unicef_UniversNextPro-Light',
            },
        ],
    },
    {
        label: 'Univers Next Pro Medium',
        fontValue: 'Unicef_UniversNextPro-Medium',
        className: 'font-family-Unicef_UniversNextPro-Medium',
        isStandardFont: false,
        id: 230,
        fontFaces: [
            {
                slug: 'Unicef_UniversNextPro/Unicef_UniversNextPro-Medium',
            },
        ],
    },
    {
        label: 'Univers Next Pro Medium Italic',
        fontValue: 'Unicef_UniversNextPro-MediumItalic',
        className: 'font-family-Unicef_UniversNextPro-MediumItalic',
        isStandardFont: false,
        id: 231,
        fontFaces: [
            {
                slug: 'Unicef_UniversNextPro/Unicef_UniversNextPro-MediumItalic',
            },
        ],
    },
    {
        label: 'Univers Next Pro Regular',
        fontValue: 'Unicef_UniversNextPro-Regular',
        className: 'font-family-Unicef_UniversNextPro-Regular',
        isStandardFont: false,
        id: 232,
        fontFaces: [
            {
                slug: 'Unicef_UniversNextPro/Unicef_UniversNextPro-Regular',
            },
        ],
    },
    {
        label: 'Univers Next Typewriter Pro Bold',
        fontValue: 'Unicef_UniversNextTypewrtrPro-Bd',
        className: 'font-family-Unicef_UniversNextTypewrtrPro-Bd',
        isStandardFont: false,
        id: 233,
        fontFaces: [
            {
                slug: 'Unicef_UniversNextTypewrtrPro/Unicef_UniversNextTypewrtrPro-Bd',
            },
        ],
    },
    {
        label: 'Univers Next Typewriter Pro Regular',
        fontValue: 'Unicef_UniversNextTypewrtrPro-Rg',
        className: 'font-family-Unicef_UniversNextTypewrtrPro-Rg',
        isStandardFont: false,
        id: 234,
        fontFaces: [
            {
                slug: 'Unicef_UniversNextTypewrtrPro/Unicef_UniversNextTypewrtrPro-Rg',
            },
        ],
    },
    {
        label: 'Hiragino Mincho Pro',
        fontValue: 'MatchesFashion_HiraginoMinchoPro',
        className: 'font-family-MatchesFashion_HiraginoMinchoPro',
        isStandardFont: false,
        id: 235,
        fontFaces: [
            {
                slug: 'MatchesFashion_HiraginoMinchoPro/MatchesFashion_HiraginoMinchoPro',
            },
        ],
    },
    {
        label: 'Hiragino Sans',
        fontValue: 'MatchesFashion_HiraginoSans',
        className: 'font-family-MatchesFashion_HiraginoSans',
        isStandardFont: false,
        id: 236,
        fontFaces: [
            {
                slug: 'MatchesFashion_HiraginoSans/MatchesFashion_HiraginoSans',
            },
        ],
    },
    {
        label: 'Noto Sans',
        fontValue: 'HelveticaBold',
        className: 'font-family-HelveticaBold',
        isStandardFont: true,
        id: 237,
        fontFaces: [],
    },
    {
        label: 'Vodafone Lt Regular',
        fontValue: 'VodafoneLt-Regular',
        className: 'font-family-VodafoneLt-Regular',
        isStandardFont: false,
        id: 238,
        fontFaces: [
            {
                slug: 'Vodafone/VodafoneLt-Regular',
            },
        ],
    },
    {
        label: 'Vodafone Rg Bold',
        fontValue: 'VodafoneRg-Bold',
        className: 'font-family-VodafoneRg-Bold',
        isStandardFont: false,
        id: 239,
        fontFaces: [
            {
                slug: 'Vodafone/VodafoneRg-Bold',
            },
        ],
    },
    {
        label: 'PremicesFilms Paco',
        fontValue: 'PremicesFilmsPaco',
        className: 'font-family-PremicesFilmsPaco',
        isStandardFont: false,
        id: 240,
        fontFaces: [
            {
                slug: 'PremicesFilmsPaco/PremicesFilmsPaco',
            },
        ],
    },
    {
        label: 'PremicesFilms Paco Italic',
        fontValue: 'PremicesFilmsPaco-Italic',
        className: 'font-family-PremicesFilmsPaco-Italic',
        isStandardFont: false,
        id: 241,
        fontFaces: [
            {
                slug: 'PremicesFilmsPaco/PremicesFilmsPaco-Italic',
            },
        ],
    },
    {
        label: 'Sharecare Offerings Regular',
        fontValue: 'Sharecare_Offerings-Regular',
        className: 'font-family-Sharecare_Offerings-Regular',
        isStandardFont: false,
        id: 242,
        fontFaces: [
            {
                slug: 'Sharecare_Offerings-Regular/Sharecare_Offerings-Regular',
            },
        ],
    },
    {
        label: 'Sharecare Offerings Regular Italic',
        fontValue: 'Sharecare_Offerings-RegularItalic',
        className: 'font-family-Sharecare_Offerings-RegularItalic',
        isStandardFont: false,
        id: 243,
        fontFaces: [
            {
                slug: 'Sharecare_Offerings-RegularItalic/Sharecare_Offerings-RegularItalic',
            },
        ],
    },
    {
        label: 'Sharecare Helvetica Neue Medium',
        fontValue: 'Sharecare_HelveticaNeue-Medium',
        className: 'font-family-Sharecare_HelveticaNeue-Medium',
        isStandardFont: false,
        id: 244,
        fontFaces: [
            {
                slug: 'Sharecare_HelveticaNeue-Medium/Sharecare_HelveticaNeue-Medium',
            },
        ],
    },
    {
        label: 'Sharecare Helvetica Neue Thin',
        fontValue: 'Sharecare_HelveticaNeue-Thin',
        className: 'font-family-Sharecare_HelveticaNeue-Thin',
        isStandardFont: false,
        id: 245,
        fontFaces: [
            {
                slug: 'Sharecare_HelveticaNeue-Thin/Sharecare_HelveticaNeue-Thin',
            },
        ],
    },
    {
        label: 'Vfutura Bold',
        fontValue: 'Vfutura-Bold',
        className: 'font-family-vfutura-bold',
        isStandardFont: false,
        id: 246,
        fontFaces: [
            {
                slug: 'Vfutura/Vfutura-Bold',
            },
        ],
    },
    {
        label: 'Vfutura Heavy',
        fontValue: 'Vfutura-Heavy',
        className: 'font-family-vfutura-heavy',
        isStandardFont: false,
        id: 247,
        fontFaces: [
            {
                slug: 'Vfutura/Vfutura-Heavy',
            },
        ],
    },
    {
        label: 'Vfutura Light',
        fontValue: 'Vfutura-Light',
        className: 'font-family-vfutura-light',
        isStandardFont: false,
        id: 248,
        fontFaces: [
            {
                slug: 'Vfutura/Vfutura-Light',
            },
        ],
    },
    {
        label: 'Vfutura Medium',
        fontValue: 'Vfutura-Medium',
        className: 'font-family-vfutura-medium',
        isStandardFont: false,
        id: 249,
        fontFaces: [
            {
                slug: 'Vfutura/Vfutura-Medium',
            },
        ],
    },
    {
        label: 'Vfutura Regular',
        fontValue: 'Vfutura-Regular',
        className: 'font-family-vfutura-regular',
        isStandardFont: false,
        id: 250,
        fontFaces: [
            {
                slug: 'Vfutura/Vfutura-Regular',
            },
        ],
    },
    {
        label: 'Vitra Icons',
        fontValue: 'Vitra-Icons',
        className: 'font-family-vitra-icons',
        isStandardFont: false,
        id: 251,
        fontFaces: [
            {
                slug: 'Vfutura/Vitra-Icons',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSansDisplay Regular',
        fontValue: 'QUADMARK_GoogleSansDisplay-Regular',
        className: 'font-family-QUADMARK_GoogleSansDisplay-Regular',
        isStandardFont: false,
        id: 252,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSansDisplay-Regular',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSansDisplay MediumItalic',
        fontValue: 'QUADMARK_GoogleSansDisplay-MediumItalic',
        className: 'font-family-QUADMARK_GoogleSansDisplay-MediumItalic',
        isStandardFont: false,
        id: 253,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSansDisplay-MediumItalic',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSansDisplay Medium',
        fontValue: 'QUADMARK_GoogleSansDisplay-Medium',
        className: 'font-family-QUADMARK_GoogleSansDisplay-Medium',
        isStandardFont: false,
        id: 254,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSansDisplay-Medium',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSansDisplay Italic',
        fontValue: 'QUADMARK_GoogleSansDisplay-Italic',
        className: 'font-family-QUADMARK_GoogleSansDisplay-Italic',
        isStandardFont: false,
        id: 255,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSansDisplay-Italic',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSansDisplay BoldItalic',
        fontValue: 'QUADMARK_GoogleSansDisplay-BoldItalic',
        className: 'font-family-QUADMARK_GoogleSansDisplay-BoldItalic',
        isStandardFont: false,
        id: 256,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSansDisplay-BoldItalic',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSansDisplay Bold',
        fontValue: 'QUADMARK_GoogleSansDisplay-Bold',
        className: 'font-family-QUADMARK_GoogleSansDisplay-Bold',
        isStandardFont: false,
        id: 257,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSansDisplay-Bold',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSans Regular',
        fontValue: 'QUADMARK_GoogleSans-Regular',
        className: 'font-family-QUADMARK_GoogleSans-Regular',
        isStandardFont: false,
        id: 258,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSans-Regular',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSans MediumItalic',
        fontValue: 'QUADMARK_GoogleSans-MediumItalic',
        className: 'font-family-QUADMARK_GoogleSans-MediumItalic',
        isStandardFont: false,
        id: 259,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSans-MediumItalic',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSans Medium',
        fontValue: 'QUADMARK_GoogleSans-Medium',
        className: 'font-family-QUADMARK_GoogleSans-Medium',
        isStandardFont: false,
        id: 260,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSans-Medium',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSans Italic',
        fontValue: 'QUADMARK_GoogleSans-Italic',
        className: 'font-family-QUADMARK_GoogleSans-Italic',
        isStandardFont: false,
        id: 261,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSans-Italic',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSans BoldItalic',
        fontValue: 'QUADMARK_GoogleSans-BoldItalic',
        className: 'font-family-QUADMARK_GoogleSans-BoldItalic',
        isStandardFont: false,
        id: 262,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSans-BoldItalic',
            },
        ],
    },
    {
        label: 'QUADMARK GoogleSans Bold',
        fontValue: 'QUADMARK_GoogleSans-Bold',
        className: 'font-family-QUADMARK_GoogleSans-Bold',
        isStandardFont: false,
        id: 263,
        fontFaces: [
            {
                slug: 'QUADMARK/QUADMARK_GoogleSans-Bold',
            },
        ],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Gotham-Book',
        className: 'font-family-Gotham-Book',
        isStandardFont: false,
        id: 264,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Gotham-Light',
        className: 'font-family-Gotham-Light',
        isStandardFont: false,
        id: 265,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Gotham-Medium',
        className: 'font-family-Gotham-Medium',
        isStandardFont: false,
        id: 266,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Tungsten-Semibold',
        className: 'font-family-Tungsten-Semibold',
        isStandardFont: false,
        id: 267,
        fontFaces: [],
    },
    {
        label: 'Bravo CircularStd Black',
        fontValue: 'Bravo_CircularStd-Black',
        className: 'font-family-Bravo_CircularStd-Black',
        isStandardFont: false,
        id: 268,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularStd-Black',
            },
        ],
    },
    {
        label: 'Bravo CircularStd BlackItalic',
        fontValue: 'Bravo_CircularStd-BlackItalic',
        className: 'font-family-Bravo_CircularStd-BlackItalic',
        isStandardFont: false,
        id: 269,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularStd-BlackItalic',
            },
        ],
    },
    {
        label: 'Bravo CircularStd Bold',
        fontValue: 'Bravo_CircularStd-Bold',
        className: 'font-family-Bravo_CircularStd-Bold',
        isStandardFont: false,
        id: 270,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularStd-Bold',
            },
        ],
    },
    {
        label: 'Bravo CircularStd BoldItalic',
        fontValue: 'Bravo_CircularStd-BoldItalic',
        className: 'font-family-Bravo_CircularStd-BoldItalic',
        isStandardFont: false,
        id: 271,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularStd-BoldItalic',
            },
        ],
    },
    {
        label: 'Bravo CircularStd Book',
        fontValue: 'Bravo_CircularStd-Book',
        className: 'font-family-Bravo_CircularStd-Book',
        isStandardFont: false,
        id: 272,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularStd-Book',
            },
        ],
    },
    {
        label: 'Bravo CircularStd BookItalic',
        fontValue: 'Bravo_CircularStd-BookItalic',
        className: 'font-family-Bravo_CircularStd-BookItalic',
        isStandardFont: false,
        id: 273,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularStd-BookItalic',
            },
        ],
    },
    {
        label: 'Bravo CircularStd Medium',
        fontValue: 'Bravo_CircularStd-Medium',
        className: 'font-family-Bravo_CircularStd-Medium',
        isStandardFont: false,
        id: 274,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularStd-Medium',
            },
        ],
    },
    {
        label: 'Bravo CircularStd MediumItalic',
        fontValue: 'Bravo_CircularStd-MediumItalic',
        className: 'font-family-Bravo_CircularStd-MediumItalic',
        isStandardFont: false,
        id: 275,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularStd-MediumItalic',
            },
        ],
    },
    {
        label: 'Bravo CircularTT Black',
        fontValue: 'Bravo_CircularTT-Black',
        className: 'font-family-Bravo_CircularTT-Black',
        isStandardFont: false,
        id: 276,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularTT-Black',
            },
        ],
    },
    {
        label: 'Bravo CircularTT BlackItalic',
        fontValue: 'Bravo_CircularTT-BlackItalic',
        className: 'font-family-Bravo_CircularTT-BlackItalic',
        isStandardFont: false,
        id: 277,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularTT-BlackItalic',
            },
        ],
    },
    {
        label: 'Bravo CircularTT Bold',
        fontValue: 'Bravo_CircularTT-Bold',
        className: 'font-family-Bravo_CircularTT-Bold',
        isStandardFont: false,
        id: 278,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularTT-Bold',
            },
        ],
    },
    {
        label: 'Bravo CircularTT BoldItalic',
        fontValue: 'Bravo_CircularTT-BoldItalic',
        className: 'font-family-Bravo_CircularTT-BoldItalic',
        isStandardFont: false,
        id: 279,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularTT-BoldItalic',
            },
        ],
    },
    {
        label: 'Bravo CircularTT Book',
        fontValue: 'Bravo_CircularTT-Book',
        className: 'font-family-Bravo_CircularTT-Book',
        isStandardFont: false,
        id: 280,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularTT-Book',
            },
        ],
    },
    {
        label: 'Bravo CircularTT BookItalic',
        fontValue: 'Bravo_CircularTT-BookItalic',
        className: 'font-family-Bravo_CircularTT-BookItalic',
        isStandardFont: false,
        id: 281,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularTT-BookItalic',
            },
        ],
    },
    {
        label: 'Bravo CircularTT Medium',
        fontValue: 'Bravo_CircularTT-Medium',
        className: 'font-family-Bravo_CircularTT-Medium',
        isStandardFont: false,
        id: 282,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularTT-Medium',
            },
        ],
    },
    {
        label: 'Bravo CircularTT MediumItalic',
        fontValue: 'Bravo_CircularTT-MediumItalic',
        className: 'font-family-Bravo_CircularTT-MediumItalic',
        isStandardFont: false,
        id: 283,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_CircularTT-MediumItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Black',
        fontValue: 'Bravo_MarkforBravoPro-Black',
        className: 'font-family-Bravo_MarkforBravoPro-Black',
        isStandardFont: false,
        id: 284,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Black',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro BlackItalic',
        fontValue: 'Bravo_MarkforBravoPro-BlackItalic',
        className: 'font-family-Bravo_MarkforBravoPro-BlackItalic',
        isStandardFont: false,
        id: 285,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-BlackItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Bold',
        fontValue: 'Bravo_MarkforBravoPro-Bold',
        className: 'font-family-Bravo_MarkforBravoPro-Bold',
        isStandardFont: false,
        id: 286,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Bold',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro BoldItalic',
        fontValue: 'Bravo_MarkforBravoPro-BoldItalic',
        className: 'font-family-Bravo_MarkforBravoPro-BoldItalic',
        isStandardFont: false,
        id: 287,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-BoldItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Book',
        fontValue: 'Bravo_MarkforBravoPro-Book',
        className: 'font-family-Bravo_MarkforBravoPro-Book',
        isStandardFont: false,
        id: 288,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Book',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro BookItalic',
        fontValue: 'Bravo_MarkforBravoPro-BookItalic',
        className: 'font-family-Bravo_MarkforBravoPro-BookItalic',
        isStandardFont: false,
        id: 289,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-BookItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Extlight',
        fontValue: 'Bravo_MarkforBravoPro-Extlight',
        className: 'font-family-Bravo_MarkforBravoPro-Extlight',
        isStandardFont: false,
        id: 290,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Extlight',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro ExtlightItalic',
        fontValue: 'Bravo_MarkforBravoPro-ExtlightItalic',
        className: 'font-family-Bravo_MarkforBravoPro-ExtlightItalic',
        isStandardFont: false,
        id: 291,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-ExtlightItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Hairline',
        fontValue: 'Bravo_MarkforBravoPro-Hairline',
        className: 'font-family-Bravo_MarkforBravoPro-Hairline',
        isStandardFont: false,
        id: 292,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Hairline',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro HairlineItalic',
        fontValue: 'Bravo_MarkforBravoPro-HairlineItalic',
        className: 'font-family-Bravo_MarkforBravoPro-HairlineItalic',
        isStandardFont: false,
        id: 293,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-HairlineItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Heavy',
        fontValue: 'Bravo_MarkforBravoPro-Heavy',
        className: 'font-family-Bravo_MarkforBravoPro-Heavy',
        isStandardFont: false,
        id: 294,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Heavy',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro HeavyItalic',
        fontValue: 'Bravo_MarkforBravoPro-HeavyItalic',
        className: 'font-family-Bravo_MarkforBravoPro-HeavyItalic',
        isStandardFont: false,
        id: 295,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-HeavyItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Italic',
        fontValue: 'Bravo_MarkforBravoPro-Italic',
        className: 'font-family-Bravo_MarkforBravoPro-Italic',
        isStandardFont: false,
        id: 296,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Italic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Light',
        fontValue: 'Bravo_MarkforBravoPro-Light',
        className: 'font-family-Bravo_MarkforBravoPro-Light',
        isStandardFont: false,
        id: 297,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Light',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro LightItalic',
        fontValue: 'Bravo_MarkforBravoPro-LightItalic',
        className: 'font-family-Bravo_MarkforBravoPro-LightItalic',
        isStandardFont: false,
        id: 298,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-LightItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Medium',
        fontValue: 'Bravo_MarkforBravoPro-Medium',
        className: 'font-family-Bravo_MarkforBravoPro-Medium',
        isStandardFont: false,
        id: 299,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Medium',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro MediumItalic',
        fontValue: 'Bravo_MarkforBravoPro-MediumItalic',
        className: 'font-family-Bravo_MarkforBravoPro-MediumItalic',
        isStandardFont: false,
        id: 300,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-MediumItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro Thin',
        fontValue: 'Bravo_MarkforBravoPro-Thin',
        className: 'font-family-Bravo_MarkforBravoPro-Thin',
        isStandardFont: false,
        id: 301,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-Thin',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro ThinItalic',
        fontValue: 'Bravo_MarkforBravoPro-ThinItalic',
        className: 'font-family-Bravo_MarkforBravoPro-ThinItalic',
        isStandardFont: false,
        id: 302,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro-ThinItalic',
            },
        ],
    },
    {
        label: 'Bravo MarkforBravoPro',
        fontValue: 'Bravo_MarkforBravoPro',
        className: 'font-family-Bravo_MarkforBravoPro',
        isStandardFont: false,
        id: 303,
        fontFaces: [
            {
                slug: 'Bravo/Bravo_MarkforBravoPro',
            },
        ],
    },
    {
        label: 'EUCANPRODUCTIONS SegoeUI Bold',
        fontValue: 'EUCANPRODUCTIONS_SegoeUI-Bold',
        className: 'font-family-EUCANPRODUCTIONS_SegoeUI-Bold',
        isStandardFont: false,
        id: 304,
        fontFaces: [
            {
                slug: 'EUCANPRODUCTIONS/EUCANPRODUCTIONS_SegoeUI-Bold',
            },
        ],
    },
    {
        label: 'EUCANPRODUCTIONS SegoeUI Light',
        fontValue: 'EUCANPRODUCTIONS_SegoeUI-Light',
        className: 'font-family-EUCANPRODUCTIONS_SegoeUI-Light',
        isStandardFont: false,
        id: 305,
        fontFaces: [
            {
                slug: 'EUCANPRODUCTIONS/EUCANPRODUCTIONS_SegoeUI-Light',
            },
        ],
    },
    {
        label: 'EUCANPRODUCTIONS SegoeUI Regular',
        fontValue: 'EUCANPRODUCTIONS_SegoeUI-Regular',
        className: 'font-family-EUCANPRODUCTIONS_SegoeUI-Regular',
        isStandardFont: false,
        id: 306,
        fontFaces: [
            {
                slug: 'EUCANPRODUCTIONS/EUCANPRODUCTIONS_SegoeUI-Regular',
            },
        ],
    },
    {
        label: 'EUCANPRODUCTIONS SegoeUI SemiBold',
        fontValue: 'EUCANPRODUCTIONS_SegoeUI-SemiBold',
        className: 'font-family-EUCANPRODUCTIONS_SegoeUI-SemiBold',
        isStandardFont: false,
        id: 307,
        fontFaces: [
            {
                slug: 'EUCANPRODUCTIONS/EUCANPRODUCTIONS_SegoeUI-SemiBold',
            },
        ],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Akkurat-Bold',
        className: 'font-family-Akkurat-Bold',
        isStandardFont: false,
        id: 308,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Akkurat-Light',
        className: 'font-family-Akkurat-Light',
        isStandardFont: false,
        id: 309,
        fontFaces: [],
    },
    {
        label: 'AndersonGrotesk Black',
        fontValue: 'AndersonGrotesk-Black',
        className: 'font-family-AndersonGrotesk-Black',
        isStandardFont: false,
        id: 310,
        fontFaces: [
            {
                slug: 'AndersonGrotesk/AndersonGrotesk-Black',
            },
        ],
    },
    {
        label: 'AndersonGrotesk Regular',
        fontValue: 'AndersonGrotesk-Regular',
        className: 'font-family-AndersonGrotesk-Regular',
        isStandardFont: false,
        id: 311,
        fontFaces: [
            {
                slug: 'AndersonGrotesk/AndersonGrotesk-Regular',
            },
        ],
    },
    {
        label: 'Arial Black',
        fontValue: 'Arial-Black',
        className: 'font-family-Arial-Black',
        isStandardFont: false,
        id: 312,
        fontFaces: [],
    },
    {
        label: 'Arial Italic',
        fontValue: 'Arial-Italic',
        className: 'font-family-Arial-Italic',
        isStandardFont: false,
        id: 313,
        fontFaces: [],
    },
    {
        label: 'Arial Regular',
        fontValue: 'Arial-Regular',
        className: 'font-family-Arial-Regular',
        isStandardFont: false,
        id: 314,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'HelveticaNeueLTStd-Bold',
        className: 'font-family-HelveticaNeueLTStd-Bold',
        isStandardFont: false,
        id: 315,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'HelveticaNeueLTStd-Light',
        className: 'font-family-HelveticaNeueLTStd-Light',
        isStandardFont: false,
        id: 316,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'HelveticaNeueLTStd-Medium',
        className: 'font-family-HelveticaNeueLTStd-Medium',
        isStandardFont: false,
        id: 317,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'HelveticaNeueLTStd-Roman',
        className: 'font-family-HelveticaNeueLTStd-Roman',
        isStandardFont: false,
        id: 318,
        fontFaces: [],
    },
    {
        label: 'Karla Bold',
        fontValue: 'Karla-Bold',
        className: 'font-family-Karla-Bold',
        isStandardFont: false,
        id: 319,
        fontFaces: [
            {
                slug: 'Karla/Karla-Bold',
            },
        ],
    },
    {
        label: 'Karla Regular',
        fontValue: 'Karla-Regular',
        className: 'font-family-Karla-Regular',
        isStandardFont: false,
        id: 320,
        fontFaces: [
            {
                slug: 'Karla/Karla-Regular',
            },
        ],
    },
    {
        label: 'Lora Bold',
        fontValue: 'Lora-Bold',
        className: 'font-family-Lora-Bold',
        isStandardFont: false,
        id: 321,
        fontFaces: [
            {
                slug: 'Lora/Lora-Bold',
            },
        ],
    },
    {
        label: 'Lora BoldItalic',
        fontValue: 'Lora-BoldItalic',
        className: 'font-family-Lora-BoldItalic',
        isStandardFont: false,
        id: 322,
        fontFaces: [
            {
                slug: 'Lora/Lora-BoldItalic',
            },
        ],
    },
    {
        label: 'Lora Italic',
        fontValue: 'Lora-Italic',
        className: 'font-family-Lora-Italic',
        isStandardFont: false,
        id: 323,
        fontFaces: [
            {
                slug: 'Lora/Lora-Italic',
            },
        ],
    },
    {
        label: 'Lora Regular',
        fontValue: 'Lora-Regular',
        className: 'font-family-Lora-Regular',
        isStandardFont: false,
        id: 324,
        fontFaces: [
            {
                slug: 'Lora/Lora-Regular',
            },
        ],
    },
    {
        label: 'Manrope Bold',
        fontValue: 'Manrope-Bold',
        className: 'font-family-Manrope-Bold',
        isStandardFont: false,
        id: 325,
        fontFaces: [
            {
                slug: 'Manrope/Manrope-Bold',
            },
        ],
    },
    {
        label: 'Manrope Medium',
        fontValue: 'Manrope-Medium',
        className: 'font-family-Manrope-Medium',
        isStandardFont: false,
        id: 326,
        fontFaces: [
            {
                slug: 'Manrope/Manrope-Medium',
            },
        ],
    },
    {
        label: 'Manrope Regular',
        fontValue: 'Manrope-Regular',
        className: 'font-family-Manrope-Regular',
        isStandardFont: false,
        id: 327,
        fontFaces: [
            {
                slug: 'Manrope/Manrope-Regular',
            },
        ],
    },
    {
        label: 'NeueMetana Bold',
        fontValue: 'NeueMetana-Bold',
        className: 'font-family-NeueMetana-Bold',
        isStandardFont: false,
        id: 328,
        fontFaces: [
            {
                slug: 'NeueMetana/NeueMetana-Bold',
            },
        ],
    },
    {
        label: 'NeueMetana Regular',
        fontValue: 'NeueMetana-Regular',
        className: 'font-family-NeueMetana-Regular',
        isStandardFont: false,
        id: 329,
        fontFaces: [
            {
                slug: 'NeueMetana/NeueMetana-Regular',
            },
        ],
    },
    {
        label: 'Noto Sans',
        fontValue: 'NeueMontreal-Bold',
        className: 'font-family-NeueMontreal-Bold',
        isStandardFont: false,
        id: 330,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'NeueMontreal-Light',
        className: 'font-family-NeueMontreal-Light',
        isStandardFont: false,
        id: 331,
        fontFaces: [],
    },
    {
        label: 'Ofissina',
        fontValue: 'Ofissina',
        className: 'font-family-Ofissina',
        isStandardFont: false,
        id: 332,
        fontFaces: [
            {
                slug: 'Ofissina/Ofissina',
            },
        ],
    },
    {
        label: 'Orkney Bold',
        fontValue: 'Orkney-Bold',
        className: 'font-family-Orkney-Bold',
        isStandardFont: false,
        id: 333,
        fontFaces: [
            {
                slug: 'Orkney/Orkney-Bold',
            },
        ],
    },
    {
        label: 'Orkney Regular',
        fontValue: 'Orkney-Regular',
        className: 'font-family-Orkney-Regular',
        isStandardFont: false,
        id: 334,
        fontFaces: [
            {
                slug: 'Orkney/Orkney-Regular',
            },
        ],
    },
    {
        label: 'Anton',
        className: 'font-family-anton',
        googleFontSlug: 'Anton&display=swap',
    },
    {
        label: 'Archivo Narrow',
        className: 'font-family-archivo-narrow',
        googleFontSlug: 'Archivo+Narrow&display=swap',
    },
    {
        label: 'Assistant',
        className: 'font-family-assistant',
        googleFontSlug: 'Assistant&display',
    },
    {
        label: 'Baloo',
        className: 'font-family-baloo',
        googleFontSlug: 'Baloo&display=swap',
    },
    {
        label: 'Bangers',
        className: 'font-family-bangers',
        googleFontSlug: 'Bangers&display=swap',
    },
    {
        label: 'BowlbyOneSC',
        className: 'font-family-bowlby-one-sc',
        googleFontSlug: 'Bowlby+One+SC',
    },
    {
        label: 'Bungee',
        className: 'font-family-bungee',
        googleFontSlug: 'Bungee&display=swap',
    },
    {
        label: 'Bungee Shade',
        className: 'font-family-bungee-shade',
        googleFontSlug: 'Bungee+Shade&display=swap',
    },
    { label: 'Cabin', className: 'font-family-cabin', googleFontSlug: 'Cabin&display=swap' },
    { label: 'Caveat', className: 'font-family-caveat', googleFontSlug: 'Caveat&display=swap' },
    { label: 'Chivo', className: 'font-family-chivo', googleFontSlug: 'Chivo&display=swap' },
    { label: 'Cinzel', className: 'font-family-cinzel', googleFontSlug: 'Cinzel&display=swap' },
    {
        label: 'Codystar',
        className: 'font-family-codystar',
        googleFontSlug: 'Codystar&display=swap',
    },
    { label: 'Coiny', className: 'font-family-coiny', googleFontSlug: 'Coiny&display=swap' },
    {
        label: 'Comfortaa',
        className: 'font-family-comfortaa',
        googleFontSlug: 'Comfortaa&display=swap',
    },
    {
        label: 'Cormorant Garamond',
        className: 'font-family-cormorant-garamond',
        googleFontSlug: 'Cormorant+Garamond&display',
    },
    {
        label: 'Darker Grotesque',
        className: 'font-family-darker-grotesque',
        googleFontSlug: 'Darker+Grotesque&display=swap',
    },
    {
        label: 'DM Serif Text',
        className: 'font-family-dm-serif-text',
        googleFontSlug: 'DM+Serif+Text&display=swap',
    },
    { label: 'Domine', className: 'font-family-domine', googleFontSlug: 'Domine&display=swap' },
    {
        label: 'Fredreick The Great',
        className: 'font-family-fredreick-the-great',
        googleFontSlug: 'Fredericka+the+Great&display=swap',
    },
    // {
    //   label: 'Helvetica Neue Bold',
    //   className: 'font-family-helvetica-neue-bold',
    //   googleFontSlug: '',
    // },
    // {
    //   label: 'Helvetica Neue Roman',
    //   className: 'font-family-helvetica-neue-roman',
    //   googleFontSlug: '',
    // },
    { label: 'Knewave', className: 'font-family-knewave', googleFontSlug: 'Knewave&display=swap' },
    {
        label: 'Krona One',
        className: 'font-family-krona-one',
        googleFontSlug: 'Krona+One&display=swap',
    },
    {
        label: 'Libre Franklin',
        className: 'font-family-libre-franklin',
        googleFontSlug: 'Libre+Franklin&display=swap',
    },
    { label: 'Lobster', className: 'font-family-lobster', googleFontSlug: 'Lobster&display=swap' },
    { label: 'Lora', className: 'font-family-lora', googleFontSlug: 'Lora&display=swap' },
    {
        label: 'Montserrat',
        className: 'font-family-montserrat',
        googleFontSlug: 'Montserrat&display=swap',
    },
    {
        label: 'MPlus 1p',
        className: 'font-family-mplus-1p',
        googleFontSlug: 'M+PLUS+1p&display=swap',
    },
    {
        label: 'Noto Sans',
        className: 'font-family-noto-sans',
        googleFontSlug: 'Noto+Sans&display=swap',
    },
    {
        label: 'Noto Serif',
        className: 'font-family-noto-serif',
        googleFontSlug: 'Noto+Serif&display=swap',
    },
    {
        label: 'Open Sans',
        className: 'font-family-open-sans',
        googleFontSlug: 'Open+Sans&display=swap',
    },
    { label: 'Oswald', className: 'font-family-oswald', googleFontSlug: 'Oswald&display=swap' },
    {
        label: 'Philosopher',
        className: 'font-family-philosopher',
        googleFontSlug: 'Philosopher&display=swap',
    },
    {
        label: 'Playfair Display',
        className: 'font-family-playfair-display',
        googleFontSlug: 'Playfair+Display&display=swap',
    },
    { label: 'Poppins', className: 'font-family-poppins', googleFontSlug: 'Poppins&display=swap' },
    {
        label: 'Press Start 2p',
        className: 'font-family-press-start-2p',
        googleFontSlug: 'Press+Start+2P&display=swap',
    },
    {
        label: 'PT Sans Narrow',
        className: 'font-family-pt-sans-narrow',
        googleFontSlug: 'PT+Sans+Narrow&display=swap',
    },
    {
        label: 'Racing Sans One',
        className: 'font-family-racing-sans-one',
        googleFontSlug: 'Racing+Sans+One&display=swap',
    },
    { label: 'Raleway', className: 'font-family-raleway', googleFontSlug: 'Raleway&display=swap' },
    {
        label: 'Roboto Mono',
        className: 'font-family-roboto-mono',
        googleFontSlug: 'Roboto+Mono&display=swap',
    },
    {
        label: 'Roboto Slab',
        className: 'font-family-roboto-slab',
        googleFontSlug: 'Roboto+Slab&display=swap',
    },
    { label: 'Rubik', className: 'font-family-rubik', googleFontSlug: 'Rubik&display=swap' },
    {
        label: 'Rubik Mono One',
        className: 'font-family-rubik-mono-one',
        googleFontSlug: 'Rubik+Mono+One&display=swap',
    },
    {
        label: 'Sacramento',
        className: 'font-family-sacramento',
        googleFontSlug: 'Sacramento&display=swap',
    },
    {
        label: 'Saira Stencil One',
        className: 'font-family-saira-stencil-one',
        googleFontSlug: 'Saira+Stencil+One&display=swap',
    },
    { label: 'Sniglet', className: 'font-family-sniglet', googleFontSlug: 'Sniglet&display=swap' },
    {
        label: 'Staatliches',
        className: 'font-family-staatliches',
        googleFontSlug: 'Staatliches&display=swap',
    },
    {
        label: 'suez one',
        className: 'font-family-suez-one',
        googleFontSlug: 'Suez+One&display=swap',
    },
    {
        label: 'Titan one',
        className: 'font-family-titan-one',
        googleFontSlug: 'Titan+One&display=swap',
    },
    {
        label: 'Vollkorn',
        className: 'font-family-vollkorn',
        googleFontSlug: 'Vollkorn&display=swap',
    },
    {
        label: 'Noto Sans',
        fontValue: 'Brandon-Black',
        className: 'font-family-Brandon-Black',
        isStandardFont: false,
        id: 335,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Brandon-Bold',
        className: 'font-family-Brandon-Bold',
        isStandardFont: false,
        id: 336,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Brandon-Medium',
        className: 'font-family-Brandon-Medium',
        isStandardFont: false,
        id: 337,
        fontFaces: [],
    },
    {
        label: 'Noto Sans',
        fontValue: 'Brandon-Regular',
        className: 'font-family-Brandon-Regular',
        isStandardFont: false,
        id: 338,
        fontFaces: [],
    },
    {
        label: 'Palatino Bold',
        fontValue: 'Palatino-Bold',
        className: 'font-family-Palatino-Bold',
        isStandardFont: false,
        id: 339,
        fontFaces: [
            {
                slug: 'Palatino/Palatino-Bold',
            },
        ],
    },
    {
        label: 'Palatino Regular',
        fontValue: 'Palatino-Regular',
        className: 'font-family-Palatino-Regular',
        isStandardFont: false,
        id: 340,
        fontFaces: [
            {
                slug: 'Palatino/Palatino-Regular',
            },
        ],
    },
    {
        label: 'Cormorant Regular',
        fontValue: 'Cormorant-Regular',
        className: 'font-family-Cormorant-Regular',
        isStandardFont: false,
        id: 341,
        fontFaces: [
            {
                slug: 'Cormorant/Cormorant-Regular',
            },
        ],
    },
    {
        label: 'Cormorant Medium',
        fontValue: 'Cormorant-Medium',
        className: 'font-family-Cormorant-Medium',
        isStandardFont: false,
        id: 342,
        fontFaces: [
            {
                slug: 'Cormorant/Cormorant-Medium',
            },
        ],
    },
    {
        label: 'Cormorant Bold',
        fontValue: 'Cormorant-Bold',
        className: 'font-family-Cormorant-Bold',
        isStandardFont: false,
        id: 343,
        fontFaces: [
            {
                slug: 'Cormorant/Cormorant-Bold',
            },
        ],
    },
    {
        label: 'Audi Type Bold',
        fontValue: 'AudiType-Bold',
        className: 'font-family-AudiType-Bold',
        isStandardFont: false,
        id: 344,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-Bold',
            },
        ],
    },
    {
        label: 'Audi Type Bold Italic',
        fontValue: 'AudiType-BoldItalic',
        className: 'font-family-AudiType-BoldItalic',
        isStandardFont: false,
        id: 345,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-BoldItalic',
            },
        ],
    },
    {
        label: 'Audi Type Extended Bold',
        fontValue: 'AudiType-ExtendedBold',
        className: 'font-family-AudiType-ExtendedBold',
        isStandardFont: false,
        id: 346,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-ExtendedBold',
            },
        ],
    },
    {
        label: 'Audi Type Extended Bold Italic',
        fontValue: 'AudiType-ExtendedBoldItalic',
        className: 'font-family-AudiType-ExtendedBoldItalic',
        isStandardFont: false,
        id: 347,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-ExtendedBoldItalic',
            },
        ],
    },
    {
        label: 'Audi Type Extended Italic',
        fontValue: 'AudiType-ExtendedItalic',
        className: 'font-family-AudiType-ExtendedItalic',
        isStandardFont: false,
        id: 348,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-ExtendedItalic',
            },
        ],
    },
    {
        label: 'Audi Type Extended Normal',
        fontValue: 'AudiType-ExtendedNormal',
        className: 'font-family-AudiType-ExtendedNormal',
        isStandardFont: false,
        id: 349,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-ExtendedNormal',
            },
        ],
    },
    {
        label: 'Audi Type Italic',
        fontValue: 'AudiType-Italic',
        className: 'font-family-AudiType-Italic',
        isStandardFont: false,
        id: 350,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-Italic',
            },
        ],
    },
    {
        label: 'Audi Type Normal',
        fontValue: 'AudiType-Normal',
        className: 'font-family-AudiType-Normal',
        isStandardFont: false,
        id: 351,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-Normal',
            },
        ],
    },
    {
        label: 'Audi Type Wide Bold',
        fontValue: 'AudiType-Wide Bold',
        className: 'font-family-AudiType-WideBold',
        isStandardFont: false,
        id: 352,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-WideBold',
            },
        ],
    },
    {
        label: 'Audi Type Wide Light',
        fontValue: 'AudiType-WideLight',
        className: 'font-family-AudiType-WideLight',
        isStandardFont: false,
        id: 353,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-WideLight',
            },
        ],
    },
    {
        label: 'Audi Type Wide Normal',
        fontValue: 'AudiType-WideNormal',
        className: 'font-family-AudiType-WideNormal',
        isStandardFont: false,
        id: 354,
        fontFaces: [
            {
                slug: 'AudiType/AudiType-WideNormal',
            },
        ],
    },
    {
        label: 'Walmart Bogle Bold',
        fontValue: 'Walmart-Bogle-Bold',
        className: 'font-family-Walmart-Bogle-Bold',
        isStandardFont: false,
        id: 355,
        fontFaces: [
            {
                slug: 'WALMART/WALMART_Bogle-Bold',
            },
        ],
    },
    {
        label: 'Walmart Bogle Regular',
        fontValue: 'Walmart-Bogle-Regular',
        className: 'font-family-Walmart-Bogle-Regular',
        isStandardFont: false,
        id: 356,
        fontFaces: [
            {
                slug: 'WALMART/WALMART_Bogle-Regular',
            },
        ],
    },
    {
        label: 'Amazon Ember Regular',
        fontValue: 'Amazon-Ember-Regular',
        className: 'font-family-Amazon-Ember-Regular',
        isStandardFont: false,
        id: 357,
        fontFaces: [
            {
                slug: 'Amazon_Ember/Amazon_Ember-Regular',
            },
        ],
    },
    {
        label: 'Amazon Ember Light',
        fontValue: 'Amazon-Ember-Light',
        className: 'font-family-Amazon-Ember-Light',
        isStandardFont: false,
        id: 358,
        fontFaces: [
            {
                slug: 'Amazon_Ember/Amazon_Ember-Light',
            },
        ],
    },
    {
        label: 'Amazon Ember Bold',
        fontValue: 'Amazon-Ember-Bold',
        className: 'font-family-Amazon-Ember-Bold',
        isStandardFont: false,
        id: 359,
        fontFaces: [
            {
                slug: 'Amazon_Ember/Amazon_Ember-Bold',
            },
        ],
    },
    {
        label: 'COLADA EffraHeavy Regular',
        fontValue: 'COLADA-EffraHeavy-Regular',
        className: 'font-family-COLADA-EffraHeavy-Regular',
        isStandardFont: false,
        id: 360,
        fontFaces: [
            {
                slug: 'COLADA/COLADA_EffraHeavy-Regular',
            },
        ],
    },
    {
        label: 'CondeNast_Gotham-Ultra',
        fontValue: 'CondeNast_Gotham-Ultra',
        className: 'font-family-CondeNast_Gotham-Ultra',
        isStandardFont: false,
        id: 361,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Gotham-Ultra',
            },
        ],
    },
    {
        label: 'CondeNast_Canela-Light',
        fontValue: 'CondeNast_Canela-Light',
        className: 'font-family-CondeNast_Canela-Light',
        isStandardFont: false,
        id: 362,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Canela-Light',
            },
        ],
    },
    {
        label: 'CondeNast_Canela-Medium',
        fontValue: 'CondeNast_Canela-Medium',
        className: 'font-family-CondeNast_Canela-Medium',
        isStandardFont: false,
        id: 363,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Canela-Medium',
            },
        ],
    },
    {
        label: 'CondeNast_Canela-Regular',
        fontValue: 'CondeNast_Canela-Regular',
        className: 'font-family-CondeNast_Canela-Regular',
        isStandardFont: false,
        id: 364,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Canela-Regular',
            },
        ],
    },
    {
        label: 'CondeNast_FarnhamDisplay-Regular',
        fontValue: 'CondeNast_FarnhamDisplay-Regular',
        className: 'font-family-CondeNast_FarnhamDisplay-Regular',
        isStandardFont: false,
        id: 365,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FarnhamDisplay-Regular',
            },
        ],
    },
    {
        label: 'CondeNast_FarnhamDisplay-RegItalicSwash',
        fontValue: 'CondeNast_FarnhamDisplay-RegItalicSwash',
        className: 'font-family-CondeNast_FarnhamDisplay-RegItalicSwash',
        isStandardFont: false,
        id: 366,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FarnhamDisplay-RegItalSwash',
            },
        ],
    },
    {
        label: 'CondeNast_FarnhamDisplay-Light',
        fontValue: 'CondeNast_FarnhamDisplay-Light',
        className: 'font-family-CondeNast_FarnhamDisplay-Light',
        isStandardFont: false,
        id: 367,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FarnhamDisplay-Light',
            },
        ],
    },
    {
        label: 'CondeNast_FarnhamDisplay-Medium',
        fontValue: 'CondeNast_FarnhamDisplay-Medium',
        className: 'font-family-CondeNast_FarnhamDisplay-Medium',
        isStandardFont: false,
        id: 368,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FarnhamDisplay-Medium',
            },
        ],
    },
    {
        label: 'CondeNast_WorkSans-Regular',
        fontValue: 'CondeNast_WorkSans-Regular',
        className: 'font-family-CondeNast_WorkSans-Regular',
        isStandardFont: false,
        id: 369,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_WorkSans-Regular',
            },
        ],
    },
    {
        label: 'CondeNast_WorkSans-SemiBold',
        fontValue: 'CondeNast_WorkSans-SemiBold',
        className: 'font-family-CondeNast_WorkSans-SemiBold',
        isStandardFont: false,
        id: 370,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_WorkSans-SemiBold',
            },
        ],
    },
    {
        label: 'CondeNast_WorkSans-Medium',
        fontValue: 'CondeNast_WorkSans-Medium',
        className: 'font-family-CondeNast_WorkSans-Medium',
        isStandardFont: false,
        id: 371,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_WorkSans-Medium',
            },
        ],
    },
    {
        label: 'CondeNast_WorkSans-Light',
        fontValue: 'CondeNast_WorkSans-Light',
        className: 'font-family-CondeNast_WorkSans-Light',
        isStandardFont: false,
        id: 372,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_WorkSans-Light',
            },
        ],
    },
    {
        label: 'CondeNast_ChillGill-Compressed',
        fontValue: 'CondeNast_ChillGill-Compressed',
        className: 'font-family-CondeNast_ChillGill-Compressed',
        isStandardFont: false,
        id: 373,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_CNTChillGill-Compressed',
            },
        ],
    },
    {
        label: 'CondeNast_A2Regular-Medium',
        fontValue: 'CondeNast_A2Regular-Medium',
        className: 'font-family-CondeNast_A2Regular-Medium',
        isStandardFont: false,
        id: 374,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_A2Regular-Medium',
            },
        ],
    },
    {
        label: 'CondeNast_A2Regular-Regular',
        fontValue: 'CondeNast_A2Regular-Regular',
        className: 'font-family-CondeNast_A2Regular-Regular',
        isStandardFont: false,
        id: 375,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_A2Regular-Regular',
            },
        ],
    },
    {
        label: 'CondeNast_GQ-Icons',
        fontValue: 'CondeNast_GQ-Icons',
        className: 'font-family-CondeNast_GQ-Icons',
        isStandardFont: false,
        id: 376,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GQ-Icons',
            },
        ],
    },
    {
        label: 'CondeNast_MillerDisplay-Roman',
        fontValue: 'CondeNast_MillerDisplay-Roman',
        className: 'font-family-CondeNast_MillerDisplay-Roman',
        isStandardFont: false,
        id: 377,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_MillerDisplay-Roman',
            },
        ],
    },
    {
        label: 'CondeNast_Signpainter-Houseslant',
        fontValue: 'CondeNast_Signpainter-Houseslant',
        className: 'font-family-CondeNast_Signpainter-Houseslant',
        isStandardFont: false,
        id: 378,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Signpainter-houseslant',
            },
        ],
    },
    {
        label: 'CondeNast_Signpainter-Housebrush',
        fontValue: 'CondeNast_Signpainter-Housebrush',
        className: 'font-family-CondeNast_Signpainter-Housebrush',
        isStandardFont: false,
        id: 379,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Signpainter-housebrush',
            },
        ],
    },
    {
        label: 'CondeNast_TitlingGothicNarrow-Medium',
        fontValue: 'CondeNast_TitlingGothicNarrow-Medium',
        className: 'font-family-CondeNast_TitlingGothicNarrow-Medium',
        isStandardFont: false,
        id: 380,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_TitlingGothicNarrow-Medium',
            },
        ],
    },
    {
        label: 'CondeNast_TitlingGothicNarrow-Regular',
        fontValue: 'CondeNast_TitlingGothicNarrow-Regular',
        className: 'font-family-CondeNast_TitlingGothicNarrow-Regular',
        isStandardFont: false,
        id: 381,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_TitlingGothicNarrow-Regular',
            },
        ],
    },
    {
        label: 'CondeNast_TitlingGothicNarrow-Standard',
        fontValue: 'CondeNast_TitlingGothicNarrow-Standard',
        className: 'font-family-CondeNast_TitlingGothicNarrow-Standard',
        isStandardFont: false,
        id: 382,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_TitlingGothicNarrow-Standard',
            },
        ],
    },
    {
        label: 'CondeNast_ITCFranklinGothic-Book',
        fontValue: 'CondeNast_ITCFranklinGothic-Book',
        className: 'font-family-CondeNast_ITCFranklinGothic-Book',
        isStandardFont: false,
        id: 383,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FranklinGothic-Book',
            },
        ],
    },
    {
        label: 'CondeNast_ITCFranklinGothic-Heavy',
        fontValue: 'CondeNast_ITCFranklinGothic-Heavy',
        className: 'font-family-CondeNast_ITCFranklinGothic-Heavy',
        isStandardFont: false,
        id: 384,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FranklinGothic-Heavy',
            },
        ],
    },
    {
        label: 'CondeNast_ITCFranklinGothic-Medium',
        fontValue: 'CondeNast_ITCFranklinGothic-Medium',
        className: 'font-family-CondeNast_ITCFranklinGothic-Medium',
        isStandardFont: false,
        id: 385,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FranklinGothic-Medium',
            },
        ],
    },
    {
        label: 'CondeNast_Savoy-Roman',
        fontValue: 'CondeNast_Savoy-Roman',
        className: 'font-family-CondeNast_Savoy-Roman',
        isStandardFont: false,
        id: 386,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Savoy-Roman',
            },
        ],
    },
    {
        label: 'CondeNast_VogueAvantGarde-Medium',
        fontValue: 'CondeNast_VogueAvantGarde-Medium',
        className: 'font-family-CondeNast_VogueAvantGarde-Medium',
        isStandardFont: false,
        id: 387,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_VogueAvantGarde-Medium',
            },
        ],
    },
    {
        label: 'CondeNast_VogueAvantGarde-Book',
        fontValue: 'CondeNast_VogueAvantGarde-Book',
        className: 'font-family-CondeNast_VogueAvantGarde-Book',
        isStandardFont: false,
        id: 388,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_VogueAvantGarde-Book',
            },
        ],
    },
    {
        label: 'CondeNast_VogueDisplay',
        fontValue: 'CondeNast_VogueDisplay',
        className: 'font-family-CondeNast_VogueDisplay',
        isStandardFont: false,
        id: 389,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_VogueDisplay',
            },
        ],
    },
    {
        label: 'CondeNast_VogueDisplay-SemiBoldItalic',
        fontValue: 'CondeNast_VogueDisplay-SemiBoldItalic',
        className: 'font-family-CondeNast_VogueDisplay-SemiBoldItalic',
        isStandardFont: false,
        id: 390,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_VogueDisplay-SemiBoldItalic',
            },
        ],
    },
    {
        label: 'CondeNast_BrandonText-Light',
        fontValue: 'CondeNast_BrandonText-Light',
        className: 'font-family-CondeNast_BrandonText-Light',
        isStandardFont: false,
        id: 391,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BrandonText-Light',
            },
        ],
    },
    {
        label: 'CondeNast_Ambroise-Bold',
        fontValue: 'CondeNast_Ambroise-Bold',
        className: 'font-family-CondeNast_Ambroise-Bold',
        isStandardFont: false,
        id: 392,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Ambroise-Bold',
            },
        ],
    },
    {
        label: 'CondeNast_LabGrotesque-Regular',
        fontValue: 'CondeNast_LabGrotesque-Regular',
        className: 'font-family-CondeNast_LabGrotesque-Regular',
        isStandardFont: false,
        id: 393,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_LabGrotesque-Regular',
            },
        ],
    },
    {
        label: 'CondeNast_LabGrotesque-Thin',
        fontValue: 'CondeNast_LabGrotesque-Thin',
        className: 'font-family-CondeNast_LabGrotesque-Thin',
        isStandardFont: false,
        id: 394,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_LabGrotesque-Thin',
            },
        ],
    },
    {
        label: 'CondeNast_LabGrotesque-Medium',
        fontValue: 'CondeNast_LabGrotesque-Medium',
        className: 'font-family-CondeNast_LabGrotesque-Medium',
        isStandardFont: false,
        id: 395,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_LabGrotesque-Medium',
            },
        ],
    },
    {
        label: 'CondeNast_BrandonGrotesque-Thin',
        fontValue: 'CondeNast_BrandonGrotesque-Thin',
        className: 'font-family-CondeNast_BrandonGrotesque-Thin',
        isStandardFont: false,
        id: 396,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BrandonGrotesque-Thin',
            },
        ],
    },
    {
        label: 'CondeNast_BrandonGrotesque-Regular',
        fontValue: 'CondeNast_BrandonGrotesque-Regular',
        className: 'font-family-CondeNast_BrandonGrotesque-Regular',
        isStandardFont: false,
        id: 397,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BrandonGrotesque-Regular',
            },
        ],
    },
    {
        label: 'CondeNast_BreveText-Medium',
        fontValue: 'CondeNast_BreveText-Medium',
        className: 'font-family-CondeNast_BreveText-Medium',
        isStandardFont: false,
        id: 398,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BreveText-Medium',
            },
        ],
    },
    {
        label: 'CondeNast_BreveText-Light',
        fontValue: 'CondeNast_BreveText-Light',
        className: 'font-family-CondeNast_BreveText-Light',
        isStandardFont: false,
        id: 399,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BreveText-Light',
            },
        ],
    },
    {
        label: 'CondeNast_WiredMono-Regular',
        fontValue: 'CondeNast_WiredMono-Regular',
        className: 'font-family-CondeNast_WiredMono-Regular',
        isStandardFont: false,
        id: 400,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_WiredMono-Regular',
            },
        ],
    },
    {
        label: 'CondeNast_WiredMono-Thin',
        fontValue: 'CondeNast_WiredMono-Thin',
        className: 'font-family-CondeNast_WiredMono-Thin',
        isStandardFont: false,
        id: 401,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_WiredMono-Thin',
            },
        ],
    },
    {
        label: 'Zalando_Tiempos-Regular',
        fontValue: 'Zalando_Tiempos-Regular',
        className: 'font-family-Zalando_Tiempos-Regular',
        isStandardFont: false,
        id: 402,
        fontFaces: [
            {
                slug: 'Zalando/Zalando_Tiempos-Regular',
            },
        ],
    },
    {
        label: 'Zalando_Helvetica-Medium',
        fontValue: 'Zalando_Helvetica-Medium',
        className: 'font-family-Zalando_Helvetica-Medium',
        isStandardFont: false,
        id: 9403, // FIXME: 403 id dup, prefixed it with 9
        fontFaces: [
            {
                slug: 'Zalando/Zalando_Helvetica-Medium',
            },
        ],
    },
    {
        label: 'CondeNast A2Regular Black',
        fontValue: 'CondeNast_A2Regular-Black',
        className: 'font-family-CondeNast_A2Regular-Black',
        isStandardFont: false,
        id: 403,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_A2Regular-Black',
            },
        ],
    },
    {
        label: 'CondeNast A2Regular Extrabold',
        fontValue: 'CondeNast_A2Regular-Extrabold',
        className: 'font-family-CondeNast_A2Regular-Extrabold',
        isStandardFont: false,
        id: 404,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_A2Regular-Extrabold',
            },
        ],
    },
    {
        label: 'CondeNast A2Regular Light',
        fontValue: 'CondeNast_A2Regular-Light',
        className: 'font-family-CondeNast_A2Regular-Light',
        isStandardFont: false,
        id: 405,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_A2Regular-Light',
            },
        ],
    },
    {
        label: 'CondeNast A2Regular Semibold',
        fontValue: 'CondeNast_A2Regular-Semibold',
        className: 'font-family-CondeNast_A2Regular-Semibold',
        isStandardFont: false,
        id: 406,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_A2Regular-Semibold',
            },
        ],
    },
    {
        label: 'CondeNast BrandonGrotesque Black',
        fontValue: 'CondeNast_BrandonGrotesque-Black',
        className: 'font-family-CondeNast_BrandonGrotesque-Black',
        isStandardFont: false,
        id: 407,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BrandonGrotesque-Black',
            },
        ],
    },
    {
        label: 'CondeNast BrandonGrotesque Light',
        fontValue: 'CondeNast_BrandonGrotesque-Light',
        className: 'font-family-CondeNast_BrandonGrotesque-Light',
        isStandardFont: false,
        id: 408,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BrandonGrotesque-Light',
            },
        ],
    },
    {
        label: 'CondeNast BrandonGrotesque Medium',
        fontValue: 'CondeNast_BrandonGrotesque-Medium',
        className: 'font-family-CondeNast_BrandonGrotesque-Medium',
        isStandardFont: false,
        id: 409,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BrandonGrotesque-Medium',
            },
        ],
    },
    {
        label: 'CondeNast BrandonText Black',
        fontValue: 'CondeNast_BrandonText-Black',
        className: 'font-family-CondeNast_BrandonText-Black',
        isStandardFont: false,
        id: 410,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BrandonText-Black',
            },
        ],
    },
    {
        label: 'CondeNast BrandonText Medium',
        fontValue: 'CondeNast_BrandonText-Medium',
        className: 'font-family-CondeNast_BrandonText-Medium',
        isStandardFont: false,
        id: 411,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BrandonText-Medium',
            },
        ],
    },
    {
        label: 'CondeNast BrandonText Thin',
        fontValue: 'CondeNast_BrandonText-Thin',
        className: 'font-family-CondeNast_BrandonText-Thin',
        isStandardFont: false,
        id: 412,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BrandonText-Thin',
            },
        ],
    },
    {
        label: 'CondeNast BreveText Black',
        fontValue: 'CondeNast_BreveText-Black',
        className: 'font-family-CondeNast_BreveText-Black',
        isStandardFont: false,
        id: 413,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BreveText-Black',
            },
        ],
    },
    {
        label: 'CondeNast BreveText Book',
        fontValue: 'CondeNast_BreveText-Book',
        className: 'font-family-CondeNast_BreveText-Book',
        isStandardFont: false,
        id: 414,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BreveText-Book',
            },
        ],
    },
    {
        label: 'CondeNast BreveText SemiBold',
        fontValue: 'CondeNast_BreveText-SemiBold',
        className: 'font-family-CondeNast_BreveText-SemiBold',
        isStandardFont: false,
        id: 415,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_BreveText-SemiBold',
            },
        ],
    },
    {
        label: 'CondeNast Canela Black',
        fontValue: 'CondeNast_Canela-Black',
        className: 'font-family-CondeNast_Canela-Black',
        isStandardFont: false,
        id: 416,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Canela-Black',
            },
        ],
    },
    {
        label: 'CondeNast Canela Thin',
        fontValue: 'CondeNast_Canela-Thin',
        className: 'font-family-CondeNast_Canela-Thin',
        isStandardFont: false,
        id: 417,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Canela-Thin',
            },
        ],
    },
    {
        label: 'CondeNast FarnhamDisplay Black',
        fontValue: 'CondeNast_FarnhamDisplay-Black',
        className: 'font-family-CondeNast_FarnhamDisplay-Black',
        isStandardFont: false,
        id: 418,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FarnhamDisplay-Black',
            },
        ],
    },
    {
        label: 'CondeNast FarnhamDisplay BlackSC',
        fontValue: 'CondeNast_FarnhamDisplay-BlackSC',
        className: 'font-family-CondeNast_FarnhamDisplay-BlackSC',
        isStandardFont: false,
        id: 419,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FarnhamDisplay-BlackSC',
            },
        ],
    },
    {
        label: 'CondeNast FarnhamDisplay Bold',
        fontValue: 'CondeNast_FarnhamDisplay-Bold',
        className: 'font-family-CondeNast_FarnhamDisplay-Bold',
        isStandardFont: false,
        id: 420,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FarnhamDisplay-Bold',
            },
        ],
    },
    {
        label: 'CondeNast FarnhamDisplay BoldSC',
        fontValue: 'CondeNast_FarnhamDisplay-BoldSC',
        className: 'font-family-CondeNast_FarnhamDisplay-BoldSC',
        isStandardFont: false,
        id: 421,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FarnhamDisplay-BoldSC',
            },
        ],
    },
    {
        label: 'CondeNast FranklinGothic Demi',
        fontValue: 'CondeNast_FranklinGothic-Demi',
        className: 'font-family-CondeNast_FranklinGothic-Demi',
        isStandardFont: false,
        id: 422,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_FranklinGothic-Demi',
            },
        ],
    },
    {
        label: 'CondeNast Gotham Black',
        fontValue: 'CondeNast_Gotham-Black',
        className: 'font-family-CondeNast_Gotham-Black',
        isStandardFont: false,
        id: 423,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Gotham-Black',
            },
        ],
    },
    {
        label: 'CondeNast Gotham ExtraLight',
        fontValue: 'CondeNast_Gotham-ExtraLight',
        className: 'font-family-CondeNast_Gotham-ExtraLight',
        isStandardFont: false,
        id: 424,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Gotham-ExtraLight',
            },
        ],
    },
    {
        label: 'CondeNast Gotham Light',
        fontValue: 'CondeNast_Gotham-Light',
        className: 'font-family-CondeNast_Gotham-Light',
        isStandardFont: false,
        id: 425,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Gotham-Light',
            },
        ],
    },
    {
        label: 'CondeNast LabGrotesque Black',
        fontValue: 'CondeNast_LabGrotesque-Black',
        className: 'font-family-CondeNast_LabGrotesque-Black',
        isStandardFont: false,
        id: 426,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_LabGrotesque-Black',
            },
        ],
    },
    {
        label: 'CondeNast LabGrotesque Italic',
        fontValue: 'CondeNast_LabGrotesque-Italic',
        className: 'font-family-CondeNast_LabGrotesque-Italic',
        isStandardFont: false,
        id: 427,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_LabGrotesque-Italic',
            },
        ],
    },
    {
        label: 'CondeNast LabGrotesque Light',
        fontValue: 'CondeNast_LabGrotesque-Light',
        className: 'font-family-CondeNast_LabGrotesque-Light',
        isStandardFont: false,
        id: 428,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_LabGrotesque-Light',
            },
        ],
    },
    {
        label: 'CondeNast Miller Display',
        fontValue: 'CondeNast_Miller-Display-Italic',
        className: 'font-family-CondeNast_Miller-Display-Italic',
        isStandardFont: false,
        id: 429,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Miller-Display-Italic',
            },
        ],
    },
    {
        label: 'CondeNast Savoy Bold',
        fontValue: 'CondeNast_Savoy-Bold',
        className: 'font-family-CondeNast_Savoy-Bold',
        isStandardFont: false,
        id: 430,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Savoy-Bold',
            },
        ],
    },
    {
        label: 'CondeNast Savoy Italic',
        fontValue: 'CondeNast_Savoy-Italic',
        className: 'font-family-CondeNast_Savoy-Italic',
        isStandardFont: false,
        id: 431,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_Savoy-Italic',
            },
        ],
    },
    {
        label: 'CondeNast TitlingGothicFBCompressed Standard',
        fontValue: 'CondeNast_TitlingGothicFBCompressed-Standard',
        className: 'font-family-CondeNast_TitlingGothicFBCompressed-Standard',
        isStandardFont: false,
        id: 432,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_TitlingGothicFBCompressed-Standard',
            },
        ],
    },
    {
        label: 'CondeNast TitlingGothicNarrow Regular',
        fontValue: 'CondeNast_TitlingGothicNarrow-Regular',
        className: 'font-family-CondeNast_TitlingGothicNarrow-Regular',
        isStandardFont: false,
        id: 433,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_TitlingGothicNarrow-Regular',
            },
        ],
    },
    {
        label: 'CondeNast VogueAvantGarde Bold',
        fontValue: 'CondeNast_VogueAvantGarde-Bold',
        className: 'font-family-CondeNast_VogueAvantGarde-Bold',
        isStandardFont: false,
        id: 434,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_VogueAvantGarde-Bold',
            },
        ],
    },
    {
        label: 'CondeNast VogueAvantGarde DemiBold',
        fontValue: 'CondeNast_VogueAvantGarde-DemiBold',
        className: 'font-family-CondeNast_VogueAvantGarde-DemiBold',
        isStandardFont: false,
        id: 435,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_VogueAvantGarde-DemiBold',
            },
        ],
    },
    {
        label: 'CondeNast VogueDisplay Italic',
        fontValue: 'CondeNast_VogueDisplay-Italic',
        className: 'font-family-CondeNast_VogueDisplay-Italic',
        isStandardFont: false,
        id: 436,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_VogueDisplay-Italic',
            },
        ],
    },
    {
        label: 'CondeNast VogueDisplay ThinItalic',
        fontValue: 'CondeNast_VogueDisplay-ThinItalic',
        className: 'font-family-CondeNast_VogueDisplay-ThinItalic',
        isStandardFont: false,
        id: 437,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_VogueDisplay-ThinItalic',
            },
        ],
    },
    {
        label: 'CondeNast WiredMono Bold',
        fontValue: 'CondeNast_WiredMono-Bold',
        className: 'font-family-CondeNast_WiredMono-Bold',
        isStandardFont: false,
        id: 438,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_WiredMono-Bold',
            },
        ],
    },
    {
        label: 'CondeNast WiredMono Light',
        fontValue: 'CondeNast_WiredMono-Light',
        className: 'font-family-CondeNast_WiredMono-Light',
        isStandardFont: false,
        id: 439,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_WiredMono-Light',
            },
        ],
    },
    {
        label: 'CondeNast WorkSans Light',
        fontValue: 'CondeNast_WorkSans-Light',
        className: 'font-family-CondeNast_WorkSans-Light',
        isStandardFont: false,
        id: 440,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_WorkSans-Light',
            },
        ],
    },
    {
        label: 'Netflix Balboa Bold',
        fontValue: 'Netflix_Balboa-Bold',
        className: 'font-family-Netflix_Balboa-Bold',
        isStandardFont: false,
        id: 441,
        fontFaces: [
            {
                slug: 'Netflix/Netflix_Balboa-Bold/Netflix_Balboa-Bold',
            },
        ],
    },
    {
        label: 'Netflix Balboa Light',
        fontValue: 'Netflix_Balboa-Light',
        className: 'font-family-Netflix_Balboa-Light',
        isStandardFont: false,
        id: 442,
        fontFaces: [
            {
                slug: 'Netflix/Netflix_Balboa-Light/Netflix_Balboa-Light',
            },
        ],
    },
    {
        label: 'Netflix NetflixSans Bold',
        fontValue: 'Netflix_NetflixSans-Bold',
        className: 'font-family-Netflix_NetflixSans-Bold',
        isStandardFont: false,
        id: 443,
        fontFaces: [
            {
                slug: 'Netflix/Netflix_NetflixSans-Bold/Netflix_NetflixSans-Bold',
            },
        ],
    },
    {
        label: 'Netflix NetflixSans Light',
        fontValue: 'Netflix_NetflixSans-Light',
        className: 'font-family-Netflix_NetflixSans-Light',
        isStandardFont: false,
        id: 444,
        fontFaces: [
            {
                slug: 'Netflix/Netflix_NetflixSans-Light/Netflix_NetflixSans-Light',
            },
        ],
    },
    {
        label: 'TaytaHub SamsungSharpSans Bold',
        fontValue: 'TaytaHub_SamsungSharpSans-Bold',
        className: 'font-family-TaytaHub_SamsungSharpSans-Bold',
        isStandardFont: false,
        id: 445,
        fontFaces: [
            {
                slug: 'TaytaHub_SamsungSharpSans/TaytaHub_SamsungSharpSans-Bold',
            },
        ],
    },
    {
        label: 'TaytaHub SamsungSharpSans Medium',
        fontValue: 'TaytaHub_SamsungSharpSans-Medium',
        className: 'font-family-TaytaHub_SamsungSharpSans-Medium',
        isStandardFont: false,
        id: 446,
        fontFaces: [
            {
                slug: 'TaytaHub_SamsungSharpSans/TaytaHub_SamsungSharpSans-Medium',
            },
        ],
    },
    {
        label: 'TaytaHub SamsungSharpSans Regular',
        fontValue: 'TaytaHub_SamsungSharpSans-Regular',
        className: 'font-family-TaytaHub_SamsungSharpSans-Regular',
        isStandardFont: false,
        id: 447,
        fontFaces: [
            {
                slug: 'TaytaHub_SamsungSharpSans/TaytaHub_SamsungSharpSans-Regular',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica Black',
        fontValue: 'CondeNast_GTAmerica-Black',
        className: 'font-family-CondeNast_GTAmerica-Black',
        isStandardFont: false,
        id: 448,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-Black',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica BlackItalic',
        fontValue: 'CondeNast_GTAmerica-BlackItalic',
        className: 'font-family-CondeNast_GTAmerica-BlackItalic',
        isStandardFont: false,
        id: 449,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-BlackItalic',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica Bold',
        fontValue: 'CondeNast_GTAmerica-Bold',
        className: 'font-family-CondeNast_GTAmerica-Bold',
        isStandardFont: false,
        id: 450,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-Bold',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica BoldItalic',
        fontValue: 'CondeNast_GTAmerica-BoldItalic',
        className: 'font-family-CondeNast_GTAmerica-BoldItalic',
        isStandardFont: false,
        id: 451,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-BoldItalic',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica Light',
        fontValue: 'CondeNast_GTAmerica-Light',
        className: 'font-family-CondeNast_GTAmerica-Light',
        isStandardFont: false,
        id: 452,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-Light',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica LightItalic',
        fontValue: 'CondeNast_GTAmerica-LightItalic',
        className: 'font-family-CondeNast_GTAmerica-LightItalic',
        isStandardFont: false,
        id: 453,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-LightItalic',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica Medium',
        fontValue: 'CondeNast_GTAmerica-Medium',
        className: 'font-family-CondeNast_GTAmerica-Medium',
        isStandardFont: false,
        id: 454,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-Medium',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica MediumItalic',
        fontValue: 'CondeNast_GTAmerica-MediumItalic',
        className: 'font-family-CondeNast_GTAmerica-MediumItalic',
        isStandardFont: false,
        id: 455,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-MediumItalic',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica Regular',
        fontValue: 'CondeNast_GTAmerica-Regular',
        className: 'font-family-CondeNast_GTAmerica-Regular',
        isStandardFont: false,
        id: 456,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-Regular',
            },
        ],
    },
    {
        label: 'CondeNast GTAmerica RegularItalic',
        fontValue: 'CondeNast_GTAmerica-RegularItalic',
        className: 'font-family-CondeNast_GTAmerica-RegularItalic',
        isStandardFont: false,
        id: 457,
        fontFaces: [
            {
                slug: 'CondeNast/CondeNast_GTAmerica-RegularItalic',
            },
        ],
    },
    {
        label: 'Smyle SamsungSharpSans Bold',
        fontValue: 'Smyle_SamsungSharpSans-Bold',
        className: 'font-family-Smyle_SamsungSharpSans-Bold',
        isStandardFont: false,
        id: 458,
        fontFaces: [
            {
                slug: 'Smyle_SamsungSharpSans/Smyle_SamsungSharpSans-Bold',
            },
        ],
    },
    {
        label: 'Smyle SamsungSharpSans Medium',
        fontValue: 'Smyle_SamsungSharpSans-Medium',
        className: 'font-family-Smyle_SamsungSharpSans-Medium',
        isStandardFont: false,
        id: 459,
        fontFaces: [
            {
                slug: 'Smyle_SamsungSharpSans/Smyle_SamsungSharpSans-Medium',
            },
        ],
    },
    {
        label: 'Smyle SamsungSharpSans Regular',
        fontValue: 'Smyle_SamsungSharpSans-Regular',
        className: 'font-family-Smyle_SamsungSharpSans-Regular',
        isStandardFont: false,
        id: 460,
        fontFaces: [
            {
                slug: 'Smyle_SamsungSharpSans/Smyle_SamsungSharpSans-Regular',
            },
        ],
    },
    {
        label: 'TaskUs CircularXX Bold',
        fontValue: 'TaskUs_CircularXX-Bold',
        className: 'font-family-TaskUs_CircularXX-Bold',
        isStandardFont: false,
        id: 461,
        fontFaces: [{ slug: 'TaskUs/TaskUs_CircularXX-Bold' }],
    },
    {
        label: 'TaskUs CircularXX Book',
        fontValue: 'TaskUs_CircularXX-Book',
        className: 'font-family-TaskUs_CircularXX-Book',
        isStandardFont: false,
        id: 462,
        fontFaces: [{ slug: 'TaskUs/TaskUs_CircularXX-Book' }],
    },
    {
        label: 'TaskUs CircularXX Medium',
        fontValue: 'TaskUs_CircularXX-Medium',
        className: 'font-family-TaskUs_CircularXX-Medium',
        isStandardFont: false,
        id: 463,
        fontFaces: [{ slug: 'TaskUs/TaskUs_CircularXX-Medium' }],
    },
    {
        label: 'Yahoo YahooSans ExtraBold',
        fontValue: 'Yahoo_YahooSans-ExtraBold',
        className: 'font-family-Yahoo_YahooSans-ExtraBold',
        isStandardFont: false,
        id: 464,
        fontFaces: [{ slug: 'Yahoo/Yahoo_YahooSans-ExtraBold' }],
    },
    {
        label: 'Yahoo YahooSans Light',
        fontValue: 'Yahoo_YahooSans-Light',
        className: 'font-family-Yahoo_YahooSans-Light',
        isStandardFont: false,
        id: 465,
        fontFaces: [{ slug: 'Yahoo/Yahoo_YahooSans-Light' }],
    },
    {
        label: 'Yahoo YahooSans Medium',
        fontValue: 'Yahoo_YahooSans-Medium',
        className: 'font-family-Yahoo_YahooSans-Medium',
        isStandardFont: false,
        id: 466,
        fontFaces: [{ slug: 'Yahoo/Yahoo_YahooSans-Medium' }],
    },
    {
        label: 'Yahoo YahooSans Semibold',
        fontValue: 'Yahoo_YahooSans-Semibold',
        className: 'font-family-Yahoo_YahooSans-Semibold',
        isStandardFont: false,
        id: 467,
        fontFaces: [{ slug: 'Yahoo/Yahoo_YahooSans-Semibold' }],
    },
    {
        label: 'CondeNast CrimsonText Italic',
        fontValue: 'CondeNast_CrimsonText-Italic',
        className: 'font-family-CondeNast_CrimsonText-Italic',
        isStandardFont: false,
        id: 468,
        fontFaces: [{ slug: 'CondeNast/CondeNast_CrimsonText-Italic' }],
    },
    {
        label: 'CondeNast CrimsonText Regular',
        fontValue: 'CondeNast_CrimsonText-Regular',
        className: 'font-family-CondeNast_CrimsonText-Regular',
        isStandardFont: false,
        id: 469,
        fontFaces: [{ slug: 'CondeNast/CondeNast_CrimsonText-Regular' }],
    },
    {
        label: 'CondeNast FuturaPT Bold',
        fontValue: 'CondeNast_FuturaPT-Bold',
        className: 'font-family-CondeNast_FuturaPT-Bold',
        isStandardFont: false,
        id: 470,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FuturaPT-Bold' }],
    },
    {
        label: 'CondeNast FuturaPT BoldOblique',
        fontValue: 'CondeNast_FuturaPT-BoldOblique',
        className: 'font-family-CondeNast_FuturaPT-BoldOblique',
        isStandardFont: false,
        id: 471,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FuturaPT-BoldOblique' }],
    },
    {
        label: 'CondeNast FuturaPT Book',
        fontValue: 'CondeNast_FuturaPT-Book',
        className: 'font-family-CondeNast_FuturaPT-Book',
        isStandardFont: false,
        id: 472,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FuturaPT-Book' }],
    },
    {
        label: 'CondeNast FuturaPT BookOblique',
        fontValue: 'CondeNast_FuturaPT-BookOblique',
        className: 'font-family-CondeNast_FuturaPT-BookOblique',
        isStandardFont: false,
        id: 473,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FuturaPT-BookOblique' }],
    },
    {
        label: 'CondeNast FuturaPT Demi',
        fontValue: 'CondeNast_FuturaPT-Demi',
        className: 'font-family-CondeNast_FuturaPT-Demi',
        isStandardFont: false,
        id: 474,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FuturaPT-Demi' }],
    },
    {
        label: 'CondeNast FuturaPT DemiOblique',
        fontValue: 'CondeNast_FuturaPT-DemiOblique',
        className: 'font-family-CondeNast_FuturaPT-DemiOblique',
        isStandardFont: false,
        id: 475,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FuturaPT-DemiOblique' }],
    },
    {
        label: 'CondeNast FuturaPT Medium',
        fontValue: 'CondeNast_FuturaPT-Medium',
        className: 'font-family-CondeNast_FuturaPT-Medium',
        isStandardFont: false,
        id: 476,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FuturaPT-Medium' }],
    },
    {
        label: 'CondeNast FuturaPT MediumOblique',
        fontValue: 'CondeNast_FuturaPT-MediumOblique',
        className: 'font-family-CondeNast_FuturaPT-MediumOblique',
        isStandardFont: false,
        id: 477,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FuturaPT-MediumOblique' }],
    },
    {
        label: 'CondeNast Lato Black',
        fontValue: 'CondeNast_Lato-Black',
        className: 'font-family-CondeNast_Lato-Black',
        isStandardFont: false,
        id: 478,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-Black' }],
    },
    {
        label: 'CondeNast Lato BlackItalic',
        fontValue: 'CondeNast_Lato-BlackItalic',
        className: 'font-family-CondeNast_Lato-BlackItalic',
        isStandardFont: false,
        id: 479,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-BlackItalic' }],
    },
    {
        label: 'CondeNast Lato Bold',
        fontValue: 'CondeNast_Lato-Bold',
        className: 'font-family-CondeNast_Lato-Bold',
        isStandardFont: false,
        id: 480,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-Bold' }],
    },
    {
        label: 'CondeNast Lato BoldItalic',
        fontValue: 'CondeNast_Lato-BoldItalic',
        className: 'font-family-CondeNast_Lato-BoldItalic',
        isStandardFont: false,
        id: 481,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-BoldItalic' }],
    },
    {
        label: 'CondeNast Lato Italic',
        fontValue: 'CondeNast_Lato-Italic',
        className: 'font-family-CondeNast_Lato-Italic',
        isStandardFont: false,
        id: 482,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-Italic' }],
    },
    {
        label: 'CondeNast Lato Light',
        fontValue: 'CondeNast_Lato-Light',
        className: 'font-family-CondeNast_Lato-Light',
        isStandardFont: false,
        id: 483,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-Light' }],
    },
    {
        label: 'CondeNast Lato LightItalic',
        fontValue: 'CondeNast_Lato-LightItalic',
        className: 'font-family-CondeNast_Lato-LightItalic',
        isStandardFont: false,
        id: 484,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-LightItalic' }],
    },
    {
        label: 'CondeNast Lato Regular',
        fontValue: 'CondeNast_Lato-Regular',
        className: 'font-family-CondeNast_Lato-Regular',
        isStandardFont: false,
        id: 485,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-Regular' }],
    },
    {
        label: 'CondeNast Lato RegularItalic',
        fontValue: 'CondeNast_Lato-RegularItalic',
        className: 'font-family-CondeNast_Lato-RegularItalic',
        isStandardFont: false,
        id: 486,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-RegularItalic' }],
    },
    {
        label: 'CondeNast Lato Semibold',
        fontValue: 'CondeNast_Lato-Semibold',
        className: 'font-family-CondeNast_Lato-Semibold',
        isStandardFont: false,
        id: 487,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-Semibold' }],
    },
    {
        label: 'CondeNast Lato SemiboldItalic',
        fontValue: 'CondeNast_Lato-SemiboldItalic',
        className: 'font-family-CondeNast_Lato-SemiboldItalic',
        isStandardFont: false,
        id: 488,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Lato-SemiboldItalic' }],
    },
    {
        label: 'CondeNast Radley Italic',
        fontValue: 'CondeNast_Radley-Italic',
        className: 'font-family-CondeNast_Radley-Italic',
        isStandardFont: false,
        id: 489,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Radley-Italic' }],
    },
    {
        label: 'CondeNast Radley Regular',
        fontValue: 'CondeNast_Radley-Regular',
        className: 'font-family-CondeNast_Radley-Regular',
        isStandardFont: false,
        id: 490,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Radley-Regular' }],
    },
    {
        label: 'CondeNast SourceSerifPro Bold',
        fontValue: 'CondeNast_SourceSerifPro-Bold',
        className: 'font-family-CondeNast_SourceSerifPro-Bold',
        isStandardFont: false,
        id: 491,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SourceSerifPro-Bold' }],
    },
    {
        label: 'CondeNast SourceSerifPro Regular',
        fontValue: 'CondeNast_SourceSerifPro-Regular',
        className: 'font-family-CondeNast_SourceSerifPro-Regular',
        isStandardFont: false,
        id: 492,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SourceSerifPro-Regular' }],
    },
    {
        label: 'CondeNast SourceSerifPro Semibold',
        fontValue: 'CondeNast_SourceSerifPro-Semibold',
        className: 'font-family-CondeNast_SourceSerifPro-Semibold',
        isStandardFont: false,
        id: 493,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SourceSerifPro-Semibold' }],
    },
    {
        label: 'Universus Ubuntu Regular',
        fontValue: 'Universus_Ubuntu-Regular',
        className: 'font-family-Universus_Ubuntu-Regular',
        isStandardFont: false,
        id: 494,
        fontFaces: [{ slug: 'Universus/Universus_Ubuntu-Regular' }],
    },
    {
        label: 'Universus Ubuntu Italic',
        fontValue: 'Universus_Ubuntu-Italic',
        className: 'font-family-Universus_Ubuntu-Italic',
        isStandardFont: false,
        id: 495,
        fontFaces: [{ slug: 'Universus/Universus_Ubuntu-Italic' }],
    },
    {
        label: 'Universus Ubuntu Bold',
        fontValue: 'Universus_Ubuntu-Bold',
        className: 'font-family-Universus_Ubuntu-Bold',
        isStandardFont: false,
        id: 496,
        fontFaces: [{ slug: 'Universus/Universus_Ubuntu-Bold' }],
    },
    {
        label: 'Radancy Campton Bold',
        fontValue: 'Radancy_Campton-Bold',
        className: 'font-family-Radancy_Campton-Bold',
        isStandardFont: false,
        id: 497,
        fontFaces: [{ slug: 'Radancy/Radancy_Campton-Bold' }],
    },
    {
        label: 'Radancy Campton Book',
        fontValue: 'Radancy_Campton-Book',
        className: 'font-family-Radancy_Campton-Book',
        isStandardFont: false,
        id: 498,
        fontFaces: [{ slug: 'Radancy/Radancy_Campton-Book' }],
    },
    {
        label: 'Radancy Campton Light',
        fontValue: 'Radancy_Campton-Light',
        className: 'font-family-Radancy_Campton-Light',
        isStandardFont: false,
        id: 499,
        fontFaces: [{ slug: 'Radancy/Radancy_Campton-Light' }],
    },
    {
        label: 'Radancy Campton Medium',
        fontValue: 'Radancy_Campton-Medium',
        className: 'font-family-Radancy_Campton-Medium',
        isStandardFont: false,
        id: 500,
        fontFaces: [{ slug: 'Radancy/Radancy_Campton-Medium' }],
    },
    {
        label: 'Radancy Campton Semibold',
        fontValue: 'Radancy_Campton-Semibold',
        className: 'font-family-Radancy_Campton-Semibold',
        isStandardFont: false,
        id: 501,
        fontFaces: [{ slug: 'Radancy/Radancy_Campton-Semibold' }],
    },
    {
        label: 'Radancy Graphik Semibold',
        fontValue: 'Radancy_Graphik-Semibold',
        className: 'font-family-Radancy_Graphik-Semibold',
        isStandardFont: false,
        id: 502,
        fontFaces: [{ slug: 'Radancy/Radancy_Graphik-Semibold' }],
    },
    {
        label: 'Radancy Graphik Regular',
        fontValue: 'Radancy_Graphik-Regular',
        className: 'font-family-Radancy_Graphik-Regular',
        isStandardFont: false,
        id: 503,
        fontFaces: [{ slug: 'Radancy/Radancy_Graphik-Regular' }],
    },
    {
        label: 'Radancy MaaxMono Regular',
        fontValue: 'Radancy_MaaxMono-Regular',
        className: 'font-family-Radancy_MaaxMono-Regular',
        isStandardFont: false,
        id: 504,
        fontFaces: [{ slug: 'Radancy/Radancy_MaaxMono-Regular' }],
    },
    {
        label: 'Radancy MaaxMono Bold',
        fontValue: 'Radancy_MaaxMono-Bold',
        className: 'font-family-Radancy_MaaxMono-Bold',
        isStandardFont: false,
        id: 505,
        fontFaces: [{ slug: 'Radancy/Radancy_MaaxMono-Bold' }],
    },
    {
        label: 'Netflix Tusker',
        fontValue: 'Netflix_Tusker',
        className: 'font-family-Netflix_Tusker',
        isStandardFont: false,
        id: 506,
        fontFaces: [{ slug: 'Netflix/Netflix_Tusker' }],
    },
    {
        label: 'Netflix Essonnes Italic',
        fontValue: 'Netflix_Essonnes-Italic',
        className: 'font-family-Netflix_Essonnes-Italic',
        isStandardFont: false,
        id: 507,
        fontFaces: [{ slug: 'Netflix/Netflix_Essonnes-Italic' }],
    },
    {
        label: 'Netflix Essonnes Regular',
        fontValue: 'Netflix_Essonnes-Regular',
        className: 'font-family-Netflix_Essonnes-Regular',
        isStandardFont: false,
        id: 508,
        fontFaces: [{ slug: 'Netflix/Netflix_Essonnes-Regular' }],
    },
    {
        label: 'CondeNast Renner Black',
        fontValue: 'CondeNast_Renner-Black',
        className: 'font-family-CondeNast_Renner-Black',
        isStandardFont: false,
        id: 509,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-Black' }],
    },
    {
        label: 'CondeNast Renner BlackItalic',
        fontValue: 'CondeNast_Renner-BlackItalic',
        className: 'font-family-CondeNast_Renner-BlackItalic',
        isStandardFont: false,
        id: 510,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-BlackItalic' }],
    },
    {
        label: 'CondeNast Renner Bold',
        fontValue: 'CondeNast_Renner-Bold',
        className: 'font-family-CondeNast_Renner-Bold',
        isStandardFont: false,
        id: 511,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-Bold' }],
    },
    {
        label: 'CondeNast Renner BoldItalic',
        fontValue: 'CondeNast_Renner-BoldItalic',
        className: 'font-family-CondeNast_Renner-BoldItalic',
        isStandardFont: false,
        id: 512,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-BoldItalic' }],
    },
    {
        label: 'CondeNast Renner Book',
        fontValue: 'CondeNast_Renner-Book',
        className: 'font-family-CondeNast_Renner-Book',
        isStandardFont: false,
        id: 513,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-Book' }],
    },
    {
        label: 'CondeNast Renner BookItalic',
        fontValue: 'CondeNast_Renner-BookItalic',
        className: 'font-family-CondeNast_Renner-BookItalic',
        isStandardFont: false,
        id: 514,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-BookItalic' }],
    },
    {
        label: 'CondeNast Renner Light',
        fontValue: 'CondeNast_Renner-Light',
        className: 'font-family-CondeNast_Renner-Light',
        isStandardFont: false,
        id: 515,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-Light' }],
    },
    {
        label: 'CondeNast Renner LightItalic',
        fontValue: 'CondeNast_Renner-LightItalic',
        className: 'font-family-CondeNast_Renner-LightItalic',
        isStandardFont: false,
        id: 516,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-LightItalic' }],
    },
    {
        label: 'CondeNast Renner Medium',
        fontValue: 'CondeNast_Renner-Medium',
        className: 'font-family-CondeNast_Renner-Medium',
        isStandardFont: false,
        id: 517,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-Medium' }],
    },
    {
        label: 'CondeNast Renner MediumItalic',
        fontValue: 'CondeNast_Renner-MediumItalic',
        className: 'font-family-CondeNast_Renner-MediumItalic',
        isStandardFont: false,
        id: 518,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-MediumItalic' }],
    },
    {
        label: 'CondeNast Renner Thin',
        fontValue: 'CondeNast_Renner-Thin',
        className: 'font-family-CondeNast_Renner-Thin',
        isStandardFont: false,
        id: 519,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-Thin' }],
    },
    {
        label: 'CondeNast Renner ThinItalic',
        fontValue: 'CondeNast_Renner-ThinItalic',
        className: 'font-family-CondeNast_Renner-ThinItalic',
        isStandardFont: false,
        id: 520,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Renner-ThinItalic' }],
    },
    {
        label: 'CondeNast BatonTurbo Bold',
        fontValue: 'CondeNast_BatonTurbo-Bold',
        className: 'font-family-CondeNast_BatonTurbo-Bold',
        isStandardFont: false,
        id: 521,
        fontFaces: [{ slug: 'CondeNast/CondeNast_BatonTurbo-Bold' }],
    },
    {
        label: 'CondeNast BigCaslonFB Italic',
        fontValue: 'CondeNast_BigCaslonFB-Italic',
        className: 'font-family-CondeNast_BigCaslonFB-Italic',
        isStandardFont: false,
        id: 522,
        fontFaces: [{ slug: 'CondeNast/CondeNast_BigCaslonFB-Italic' }],
    },
    {
        label: 'CondeNast BigCaslonFB Roman',
        fontValue: 'CondeNast_BigCaslonFB-Roman',
        className: 'font-family-CondeNast_BigCaslonFB-Roman',
        isStandardFont: false,
        id: 523,
        fontFaces: [{ slug: 'CondeNast/CondeNast_BigCaslonFB-Roman' }],
    },
    {
        label: 'CondeNast Caslon540LTStd Italic',
        fontValue: 'CondeNast_Caslon540LTStd-Italic',
        className: 'font-family-CondeNast_Caslon540LTStd-Italic',
        isStandardFont: false,
        id: 524,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Caslon540LTStd-Italic' }],
    },
    {
        label: 'CondeNast Caslon540LTStd Roman',
        fontValue: 'CondeNast_Caslon540LTStd-Roman',
        className: 'font-family-CondeNast_Caslon540LTStd-Roman',
        isStandardFont: false,
        id: 525,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Caslon540LTStd-Roman' }],
    },
    {
        label: 'CondeNast ElMessiri Bold',
        fontValue: 'CondeNast_ElMessiri-Bold',
        className: 'font-family-CondeNast_ElMessiri-Bold',
        isStandardFont: false,
        id: 526,
        fontFaces: [{ slug: 'CondeNast/CondeNast_ElMessiri-Bold' }],
    },
    {
        label: 'CondeNast ElMessiri Medium',
        fontValue: 'CondeNast_ElMessiri-Medium',
        className: 'font-family-CondeNast_ElMessiri-Medium',
        isStandardFont: false,
        id: 527,
        fontFaces: [{ slug: 'CondeNast/CondeNast_ElMessiri-Medium' }],
    },
    {
        label: 'CondeNast ElMessiri Regular',
        fontValue: 'CondeNast_ElMessiri-Regular',
        className: 'font-family-CondeNast_ElMessiri-Regular',
        isStandardFont: false,
        id: 528,
        fontFaces: [{ slug: 'CondeNast/CondeNast_ElMessiri-Regular' }],
    },
    {
        label: 'CondeNast ElMessiri SemiBold',
        fontValue: 'CondeNast_ElMessiri-SemiBold',
        className: 'font-family-CondeNast_ElMessiri-SemiBold',
        isStandardFont: false,
        id: 529,
        fontFaces: [{ slug: 'CondeNast/CondeNast_ElMessiri-SemiBold' }],
    },
    {
        label: 'CondeNast Fabriga Bold',
        fontValue: 'CondeNast_Fabriga-Bold',
        className: 'font-family-CondeNast_Fabriga-Bold',
        isStandardFont: false,
        id: 530,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Fabriga-Bold' }],
    },
    {
        label: 'CondeNast Fabriga BoldItalic',
        fontValue: 'CondeNast_Fabriga-BoldItalic',
        className: 'font-family-CondeNast_Fabriga-BoldItalic',
        isStandardFont: false,
        id: 531,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Fabriga-BoldItalic' }],
    },
    {
        label: 'CondeNast Fabriga Italic',
        fontValue: 'CondeNast_Fabriga-Italic',
        className: 'font-family-CondeNast_Fabriga-Italic',
        isStandardFont: false,
        id: 532,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Fabriga-Italic' }],
    },
    {
        label: 'CondeNast Fabriga Medium',
        fontValue: 'CondeNast_Fabriga-Medium',
        className: 'font-family-CondeNast_Fabriga-Medium',
        isStandardFont: false,
        id: 533,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Fabriga-Medium' }],
    },
    {
        label: 'CondeNast Fabriga MediumItalic',
        fontValue: 'CondeNast_Fabriga-MediumItalic',
        className: 'font-family-CondeNast_Fabriga-MediumItalic',
        isStandardFont: false,
        id: 534,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Fabriga-MediumItalic' }],
    },
    {
        label: 'CondeNast Fabriga Regular',
        fontValue: 'CondeNast_Fabriga-Regular',
        className: 'font-family-CondeNast_Fabriga-Regular',
        isStandardFont: false,
        id: 535,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Fabriga-Regular' }],
    },
    {
        label: 'CondeNast FlamaUltracondensed Bold',
        fontValue: 'CondeNast_FlamaUltracondensed-Bold',
        className: 'font-family-CondeNast_FlamaUltracondensed-Bold',
        isStandardFont: false,
        id: 536,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FlamaUltracondensed-Bold' }],
    },
    {
        label: 'CondeNast FlamaUltracondensed Book',
        fontValue: 'CondeNast_FlamaUltracondensed-Book',
        className: 'font-family-CondeNast_FlamaUltracondensed-Book',
        isStandardFont: false,
        id: 537,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FlamaUltracondensed-Book' }],
    },
    {
        label: 'CondeNast FlamaUltracondensed Medium',
        fontValue: 'CondeNast_FlamaUltracondensed-Medium',
        className: 'font-family-CondeNast_FlamaUltracondensed-Medium',
        isStandardFont: false,
        id: 538,
        fontFaces: [{ slug: 'CondeNast/CondeNast_FlamaUltracondensed-Medium' }],
    },
    {
        label: 'CondeNast GTSectra Black',
        fontValue: 'CondeNast_GTSectra-Black',
        className: 'font-family-CondeNast_GTSectra-Black',
        isStandardFont: false,
        id: 539,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-Black' }],
    },
    {
        label: 'CondeNast GTSectra BlackItalic',
        fontValue: 'CondeNast_GTSectra-BlackItalic',
        className: 'font-family-CondeNast_GTSectra-BlackItalic',
        isStandardFont: false,
        id: 540,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-BlackItalic' }],
    },
    {
        label: 'CondeNast GTSectra Bold',
        fontValue: 'CondeNast_GTSectra-Bold',
        className: 'font-family-CondeNast_GTSectra-Bold',
        isStandardFont: false,
        id: 541,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-Bold' }],
    },
    {
        label: 'CondeNast GTSectra BoldItalic',
        fontValue: 'CondeNast_GTSectra-BoldItalic',
        className: 'font-family-CondeNast_GTSectra-BoldItalic',
        isStandardFont: false,
        id: 542,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-BoldItalic' }],
    },
    {
        label: 'CondeNast GTSectra Book',
        fontValue: 'CondeNast_GTSectra-Book',
        className: 'font-family-CondeNast_GTSectra-Book',
        isStandardFont: false,
        id: 543,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-Book' }],
    },
    {
        label: 'CondeNast GTSectra BookItalic',
        fontValue: 'CondeNast_GTSectra-BookItalic',
        className: 'font-family-CondeNast_GTSectra-BookItalic',
        isStandardFont: false,
        id: 544,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-BookItalic' }],
    },
    {
        label: 'CondeNast GTSectra Medium',
        fontValue: 'CondeNast_GTSectra-Medium',
        className: 'font-family-CondeNast_GTSectra-Medium',
        isStandardFont: false,
        id: 545,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-Medium' }],
    },
    {
        label: 'CondeNast GTSectra MediumItalic',
        fontValue: 'CondeNast_GTSectra-MediumItalic',
        className: 'font-family-CondeNast_GTSectra-MediumItalic',
        isStandardFont: false,
        id: 546,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-MediumItalic' }],
    },
    {
        label: 'CondeNast GTSectra Regular',
        fontValue: 'CondeNast_GTSectra-Regular',
        className: 'font-family-CondeNast_GTSectra-Regular',
        isStandardFont: false,
        id: 547,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-Regular' }],
    },
    {
        label: 'CondeNast GTSectra RegularItalic',
        fontValue: 'CondeNast_GTSectra-RegularItalic',
        className: 'font-family-CondeNast_GTSectra-RegularItalic',
        isStandardFont: false,
        id: 548,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTSectra-RegularItalic' }],
    },
    {
        label: 'CondeNast GTWalsheim Black',
        fontValue: 'CondeNast_GTWalsheim-Black',
        className: 'font-family-CondeNast_GTWalsheim-Black',
        isStandardFont: false,
        id: 549,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-Black' }],
    },
    {
        label: 'CondeNast GTWalsheim BlackOblique',
        fontValue: 'CondeNast_GTWalsheim-BlackOblique',
        className: 'font-family-CondeNast_GTWalsheim-BlackOblique',
        isStandardFont: false,
        id: 550,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-BlackOblique' }],
    },
    {
        label: 'CondeNast GTWalsheim Bold',
        fontValue: 'CondeNast_GTWalsheim-Bold',
        className: 'font-family-CondeNast_GTWalsheim-Bold',
        isStandardFont: false,
        id: 551,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-Bold' }],
    },
    {
        label: 'CondeNast GTWalsheim BoldItalic',
        fontValue: 'CondeNast_GTWalsheim-BoldItalic',
        className: 'font-family-CondeNast_GTWalsheim-BoldItalic',
        isStandardFont: false,
        id: 552,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-BoldItalic' }],
    },
    {
        label: 'CondeNast GTWalsheim Light',
        fontValue: 'CondeNast_GTWalsheim-Light',
        className: 'font-family-CondeNast_GTWalsheim-Light',
        isStandardFont: false,
        id: 553,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-Light' }],
    },
    {
        label: 'CondeNast GTWalsheim LightOblique',
        fontValue: 'CondeNast_GTWalsheim-LightOblique',
        className: 'font-family-CondeNast_GTWalsheim-LightOblique',
        isStandardFont: false,
        id: 554,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-LightOblique' }],
    },
    {
        label: 'CondeNast GTWalsheim Medium',
        fontValue: 'CondeNast_GTWalsheim-Medium',
        className: 'font-family-CondeNast_GTWalsheim-Medium',
        isStandardFont: false,
        id: 555,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-Medium' }],
    },
    {
        label: 'CondeNast GTWalsheim MediumOblique',
        fontValue: 'CondeNast_GTWalsheim-MediumOblique',
        className: 'font-family-CondeNast_GTWalsheim-MediumOblique',
        isStandardFont: false,
        id: 556,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-MediumOblique' }],
    },
    {
        label: 'CondeNast GTWalsheim Regular',
        fontValue: 'CondeNast_GTWalsheim-Regular',
        className: 'font-family-CondeNast_GTWalsheim-Regular',
        isStandardFont: false,
        id: 557,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-Regular' }],
    },
    {
        label: 'CondeNast GTWalsheim RegularOblique',
        fontValue: 'CondeNast_GTWalsheim-RegularOblique',
        className: 'font-family-CondeNast_GTWalsheim-RegularOblique',
        isStandardFont: false,
        id: 558,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-RegularOblique' }],
    },
    {
        label: 'CondeNast GTWalsheim Thin',
        fontValue: 'CondeNast_GTWalsheim-Thin',
        className: 'font-family-CondeNast_GTWalsheim-Thin',
        isStandardFont: false,
        id: 559,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-Thin' }],
    },
    {
        label: 'CondeNast GTWalsheim ThinOblique',
        fontValue: 'CondeNast_GTWalsheim-ThinOblique',
        className: 'font-family-CondeNast_GTWalsheim-ThinOblique',
        isStandardFont: false,
        id: 560,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-ThinOblique' }],
    },
    {
        label: 'CondeNast GTWalsheim UltraBold',
        fontValue: 'CondeNast_GTWalsheim-UltraBold',
        className: 'font-family-CondeNast_GTWalsheim-UltraBold',
        isStandardFont: false,
        id: 561,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-UltraBold' }],
    },
    {
        label: 'CondeNast GTWalsheim UltraBoldOblique',
        fontValue: 'CondeNast_GTWalsheim-UltraBoldOblique',
        className: 'font-family-CondeNast_GTWalsheim-UltraBoldOblique',
        isStandardFont: false,
        id: 562,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-UltraBoldOblique' }],
    },
    {
        label: 'CondeNast GTWalsheim UltraLight',
        fontValue: 'CondeNast_GTWalsheim-UltraLight',
        className: 'font-family-CondeNast_GTWalsheim-UltraLight',
        isStandardFont: false,
        id: 563,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-UltraLight' }],
    },
    {
        label: 'CondeNast GTWalsheim UltraLightOblique',
        fontValue: 'CondeNast_GTWalsheim-UltraLightOblique',
        className: 'font-family-CondeNast_GTWalsheim-UltraLightOblique',
        isStandardFont: false,
        id: 564,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheim-UltraLightOblique' }],
    },
    {
        label: 'CondeNast Graphik Medium',
        fontValue: 'CondeNast_Graphik-Medium',
        className: 'font-family-CondeNast_Graphik-Medium',
        isStandardFont: false,
        id: 565,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Graphik-Medium' }],
    },
    {
        label: 'CondeNast Graphik MediumItalic',
        fontValue: 'CondeNast_Graphik-MediumItalic',
        className: 'font-family-CondeNast_Graphik-MediumItalic',
        isStandardFont: false,
        id: 566,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Graphik-MediumItalic' }],
    },
    {
        label: 'CondeNast Konnect Black',
        fontValue: 'CondeNast_Konnect-Black',
        className: 'font-family-CondeNast_Konnect-Black',
        isStandardFont: false,
        id: 567,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-Black' }],
    },
    {
        label: 'CondeNast Konnect BlackItalic',
        fontValue: 'CondeNast_Konnect-BlackItalic',
        className: 'font-family-CondeNast_Konnect-BlackItalic',
        isStandardFont: false,
        id: 568,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-BlackItalic' }],
    },
    {
        label: 'CondeNast Konnect Bold',
        fontValue: 'CondeNast_Konnect-Bold',
        className: 'font-family-CondeNast_Konnect-Bold',
        isStandardFont: false,
        id: 569,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-Bold' }],
    },
    {
        label: 'CondeNast Konnect BoldItalic',
        fontValue: 'CondeNast_Konnect-BoldItalic',
        className: 'font-family-CondeNast_Konnect-BoldItalic',
        isStandardFont: false,
        id: 570,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-BoldItalic' }],
    },
    {
        label: 'CondeNast Konnect ExtraBold',
        fontValue: 'CondeNast_Konnect-ExtraBold',
        className: 'font-family-CondeNast_Konnect-ExtraBold',
        isStandardFont: false,
        id: 571,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-ExtraBold' }],
    },
    {
        label: 'CondeNast Konnect ExtraBoldItalic',
        fontValue: 'CondeNast_Konnect-ExtraBoldItalic',
        className: 'font-family-CondeNast_Konnect-ExtraBoldItalic',
        isStandardFont: false,
        id: 572,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-ExtraBoldItalic' }],
    },
    {
        label: 'CondeNast Konnect Hairline',
        fontValue: 'CondeNast_Konnect-Hairline',
        className: 'font-family-CondeNast_Konnect-Hairline',
        isStandardFont: false,
        id: 573,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-Hairline' }],
    },
    {
        label: 'CondeNast Konnect HairlineItalic',
        fontValue: 'CondeNast_Konnect-HairlineItalic',
        className: 'font-family-CondeNast_Konnect-HairlineItalic',
        isStandardFont: false,
        id: 574,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-HairlineItalic' }],
    },
    {
        label: 'CondeNast Konnect Italic',
        fontValue: 'CondeNast_Konnect-Italic',
        className: 'font-family-CondeNast_Konnect-Italic',
        isStandardFont: false,
        id: 575,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-Italic' }],
    },
    {
        label: 'CondeNast Konnect Light',
        fontValue: 'CondeNast_Konnect-Light',
        className: 'font-family-CondeNast_Konnect-Light',
        isStandardFont: false,
        id: 576,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-Light' }],
    },
    {
        label: 'CondeNast Konnect LightItalic',
        fontValue: 'CondeNast_Konnect-LightItalic',
        className: 'font-family-CondeNast_Konnect-LightItalic',
        isStandardFont: false,
        id: 577,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-LightItalic' }],
    },
    {
        label: 'CondeNast Konnect Medium',
        fontValue: 'CondeNast_Konnect-Medium',
        className: 'font-family-CondeNast_Konnect-Medium',
        isStandardFont: false,
        id: 578,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-Medium' }],
    },
    {
        label: 'CondeNast Konnect MediumItalic',
        fontValue: 'CondeNast_Konnect-MediumItalic',
        className: 'font-family-CondeNast_Konnect-MediumItalic',
        isStandardFont: false,
        id: 579,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-MediumItalic' }],
    },
    {
        label: 'CondeNast Konnect Regular',
        fontValue: 'CondeNast_Konnect-Regular',
        className: 'font-family-CondeNast_Konnect-Regular',
        isStandardFont: false,
        id: 580,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-Regular' }],
    },
    {
        label: 'CondeNast Konnect SemiBold',
        fontValue: 'CondeNast_Konnect-SemiBold',
        className: 'font-family-CondeNast_Konnect-SemiBold',
        isStandardFont: false,
        id: 581,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-SemiBold' }],
    },
    {
        label: 'CondeNast Konnect SemiBoldItalic',
        fontValue: 'CondeNast_Konnect-SemiBoldItalic',
        className: 'font-family-CondeNast_Konnect-SemiBoldItalic',
        isStandardFont: false,
        id: 582,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-SemiBoldItalic' }],
    },
    {
        label: 'CondeNast Konnect Thin',
        fontValue: 'CondeNast_Konnect-Thin',
        className: 'font-family-CondeNast_Konnect-Thin',
        isStandardFont: false,
        id: 583,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-Thin' }],
    },
    {
        label: 'CondeNast Konnect ThinItalic',
        fontValue: 'CondeNast_Konnect-ThinItalic',
        className: 'font-family-CondeNast_Konnect-ThinItalic',
        isStandardFont: false,
        id: 584,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Konnect-ThinItalic' }],
    },
    {
        label: 'CondeNast SangBleuRepublic Bold',
        fontValue: 'CondeNast_SangBleuRepublic-Bold',
        className: 'font-family-CondeNast_SangBleuRepublic-Bold',
        isStandardFont: false,
        id: 585,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SangBleuRepublic-Bold' }],
    },
    {
        label: 'CondeNast SangBleuRepublic BoldItalic',
        fontValue: 'CondeNast_SangBleuRepublic-BoldItalic',
        className: 'font-family-CondeNast_SangBleuRepublic-BoldItalic',
        isStandardFont: false,
        id: 586,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SangBleuRepublic-BoldItalic' }],
    },
    {
        label: 'CondeNast SangBleuRepublic Book',
        fontValue: 'CondeNast_SangBleuRepublic-Book',
        className: 'font-family-CondeNast_SangBleuRepublic-Book',
        isStandardFont: false,
        id: 587,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SangBleuRepublic-Book' }],
    },
    {
        label: 'CondeNast SangBleuRepublic BookItalic',
        fontValue: 'CondeNast_SangBleuRepublic-BookItalic',
        className: 'font-family-CondeNast_SangBleuRepublic-BookItalic',
        isStandardFont: false,
        id: 588,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SangBleuRepublic-BookItalic' }],
    },
    {
        label: 'CondeNast SangBleuRepublic Medium',
        fontValue: 'CondeNast_SangBleuRepublic-Medium',
        className: 'font-family-CondeNast_SangBleuRepublic-Medium',
        isStandardFont: false,
        id: 589,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SangBleuRepublic-Medium' }],
    },
    {
        label: 'CondeNast SangBleuRepublic MediumItalic',
        fontValue: 'CondeNast_SangBleuRepublic-MediumItalic',
        className: 'font-family-CondeNast_SangBleuRepublic-MediumItalic',
        isStandardFont: false,
        id: 590,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SangBleuRepublic-MediumItalic' }],
    },
    {
        label: 'CondeNast SangBleuRepublic Regular',
        fontValue: 'CondeNast_SangBleuRepublic-Regular',
        className: 'font-family-CondeNast_SangBleuRepublic-Regular',
        isStandardFont: false,
        id: 591,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SangBleuRepublic-Regular' }],
    },
    {
        label: 'CondeNast SangBleuRepublic RegularItalic',
        fontValue: 'CondeNast_SangBleuRepublic-RegularItalic',
        className: 'font-family-CondeNast_SangBleuRepublic-RegularItalic',
        isStandardFont: false,
        id: 592,
        fontFaces: [{ slug: 'CondeNast/CondeNast_SangBleuRepublic-RegularItalic' }],
    },
    {
        label: 'CondeNast TiemposHeadline Black',
        fontValue: 'CondeNast_TiemposHeadline-Black',
        className: 'font-family-CondeNast_TiemposHeadline-Black',
        isStandardFont: false,
        id: 593,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-Black' }],
    },
    {
        label: 'CondeNast TiemposHeadline BlackItalic',
        fontValue: 'CondeNast_TiemposHeadline-BlackItalic',
        className: 'font-family-CondeNast_TiemposHeadline-BlackItalic',
        isStandardFont: false,
        id: 594,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-BlackItalic' }],
    },
    {
        label: 'CondeNast TiemposHeadline Bold',
        fontValue: 'CondeNast_TiemposHeadline-Bold',
        className: 'font-family-CondeNast_TiemposHeadline-Bold',
        isStandardFont: false,
        id: 595,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-Bold' }],
    },
    {
        label: 'CondeNast TiemposHeadline BoldItalic',
        fontValue: 'CondeNast_TiemposHeadline-BoldItalic',
        className: 'font-family-CondeNast_TiemposHeadline-BoldItalic',
        isStandardFont: false,
        id: 596,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-BoldItalic' }],
    },
    {
        label: 'CondeNast TiemposHeadline Light',
        fontValue: 'CondeNast_TiemposHeadline-Light',
        className: 'font-family-CondeNast_TiemposHeadline-Light',
        isStandardFont: false,
        id: 597,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-Light' }],
    },
    {
        label: 'CondeNast TiemposHeadline LightItalic',
        fontValue: 'CondeNast_TiemposHeadline-LightItalic',
        className: 'font-family-CondeNast_TiemposHeadline-LightItalic',
        isStandardFont: false,
        id: 598,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-LightItalic' }],
    },
    {
        label: 'CondeNast TiemposHeadline Medium',
        fontValue: 'CondeNast_TiemposHeadline-Medium',
        className: 'font-family-CondeNast_TiemposHeadline-Medium',
        isStandardFont: false,
        id: 599,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-Medium' }],
    },
    {
        label: 'CondeNast TiemposHeadline MediumItalic',
        fontValue: 'CondeNast_TiemposHeadline-MediumItalic',
        className: 'font-family-CondeNast_TiemposHeadline-MediumItalic',
        isStandardFont: false,
        id: 600,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-MediumItalic' }],
    },
    {
        label: 'CondeNast TiemposHeadline Regular',
        fontValue: 'CondeNast_TiemposHeadline-Regular',
        className: 'font-family-CondeNast_TiemposHeadline-Regular',
        isStandardFont: false,
        id: 601,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-Regular' }],
    },
    {
        label: 'CondeNast TiemposHeadline RegularItalic',
        fontValue: 'CondeNast_TiemposHeadline-RegularItalic',
        className: 'font-family-CondeNast_TiemposHeadline-RegularItalic',
        isStandardFont: false,
        id: 602,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-RegularItalic' }],
    },
    {
        label: 'CondeNast TiemposHeadline Semibold',
        fontValue: 'CondeNast_TiemposHeadline-Semibold',
        className: 'font-family-CondeNast_TiemposHeadline-Semibold',
        isStandardFont: false,
        id: 603,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-Semibold' }],
    },
    {
        label: 'CondeNast TiemposHeadline SemiboldItalic',
        fontValue: 'CondeNast_TiemposHeadline-SemiboldItalic',
        className: 'font-family-CondeNast_TiemposHeadline-SemiboldItalic',
        isStandardFont: false,
        id: 604,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposHeadline-SemiboldItalic' }],
    },
    {
        label: 'CondeNast TiemposText Bold',
        fontValue: 'CondeNast_TiemposText-Bold',
        className: 'font-family-CondeNast_TiemposText-Bold',
        isStandardFont: false,
        id: 605,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposText-Bold' }],
    },
    {
        label: 'CondeNast TiemposText BoldItalic',
        fontValue: 'CondeNast_TiemposText-BoldItalic',
        className: 'font-family-CondeNast_TiemposText-BoldItalic',
        isStandardFont: false,
        id: 606,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposText-BoldItalic' }],
    },
    {
        label: 'CondeNast TiemposText Medium',
        fontValue: 'CondeNast_TiemposText-Medium',
        className: 'font-family-CondeNast_TiemposText-Medium',
        isStandardFont: false,
        id: 607,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposText-Medium' }],
    },
    {
        label: 'CondeNast TiemposText MediumItalic',
        fontValue: 'CondeNast_TiemposText-MediumItalic',
        className: 'font-family-CondeNast_TiemposText-MediumItalic',
        isStandardFont: false,
        id: 608,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposText-MediumItalic' }],
    },
    {
        label: 'CondeNast TiemposText Regular',
        fontValue: 'CondeNast_TiemposText-Regular',
        className: 'font-family-CondeNast_TiemposText-Regular',
        isStandardFont: false,
        id: 609,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposText-Regular' }],
    },
    {
        label: 'CondeNast TiemposText RegularItalic',
        fontValue: 'CondeNast_TiemposText-RegularItalic',
        className: 'font-family-CondeNast_TiemposText-RegularItalic',
        isStandardFont: false,
        id: 610,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposText-RegularItalic' }],
    },
    {
        label: 'CondeNast TiemposText Semibold',
        fontValue: 'CondeNast_TiemposText-Semibold',
        className: 'font-family-CondeNast_TiemposText-Semibold',
        isStandardFont: false,
        id: 611,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposText-Semibold' }],
    },
    {
        label: 'CondeNast TiemposText SemiboldItalic',
        fontValue: 'CondeNast_TiemposText-SemiboldItalic',
        className: 'font-family-CondeNast_TiemposText-SemiboldItalic',
        isStandardFont: false,
        id: 612,
        fontFaces: [{ slug: 'CondeNast/CondeNast_TiemposText-SemiboldItalic' }],
    },
    {
        label: 'CondeNast VFDidot3 Bold',
        fontValue: 'CondeNast_VFDidot3-Bold',
        className: 'font-family-CondeNast_VFDidot3-Bold',
        isStandardFont: false,
        id: 613,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFDidot3-Bold' }],
    },
    {
        label: 'CondeNast VFDidot3 BoldItalic',
        fontValue: 'CondeNast_VFDidot3-BoldItalic',
        className: 'font-family-CondeNast_VFDidot3-BoldItalic',
        isStandardFont: false,
        id: 614,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFDidot3-BoldItalic' }],
    },
    {
        label: 'CondeNast VFDidot3 Light',
        fontValue: 'CondeNast_VFDidot3-Light',
        className: 'font-family-CondeNast_VFDidot3-Light',
        isStandardFont: false,
        id: 615,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFDidot3-Light' }],
    },
    {
        label: 'CondeNast VFDidot3 LightItalic',
        fontValue: 'CondeNast_VFDidot3-LightItalic',
        className: 'font-family-CondeNast_VFDidot3-LightItalic',
        isStandardFont: false,
        id: 616,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFDidot3-LightItalic' }],
    },
    {
        label: 'CondeNast VFDidot3 Regular',
        fontValue: 'CondeNast_VFDidot3-Regular',
        className: 'font-family-CondeNast_VFDidot3-Regular',
        isStandardFont: false,
        id: 617,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFDidot3-Regular' }],
    },
    {
        label: 'CondeNast VFDidot3 RegularItalic',
        fontValue: 'CondeNast_VFDidot3-RegularItalic',
        className: 'font-family-CondeNast_VFDidot3-RegularItalic',
        isStandardFont: false,
        id: 618,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFDidot3-RegularItalic' }],
    },
    {
        label: 'CondeNast VFDidot3 Semibold',
        fontValue: 'CondeNast_VFDidot3-Semibold',
        className: 'font-family-CondeNast_VFDidot3-Semibold',
        isStandardFont: false,
        id: 619,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFDidot3-Semibold' }],
    },
    {
        label: 'CondeNast VFDidot3 SemiboldItalic',
        fontValue: 'CondeNast_VFDidot3-SemiboldItalic',
        className: 'font-family-CondeNast_VFDidot3-SemiboldItalic',
        isStandardFont: false,
        id: 620,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFDidot3-SemiboldItalic' }],
    },
    {
        label: 'CondeNast VFSans Book',
        fontValue: 'CondeNast_VFSans-Book',
        className: 'font-family-CondeNast_VFSans-Book',
        isStandardFont: false,
        id: 621,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFSans-Book' }],
    },
    {
        label: 'CondeNast VFSans BookOblique',
        fontValue: 'CondeNast_VFSans-BookOblique',
        className: 'font-family-CondeNast_VFSans-BookOblique',
        isStandardFont: false,
        id: 622,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFSans-BookOblique' }],
    },
    {
        label: 'CondeNast VFSans Demi',
        fontValue: 'CondeNast_VFSans-Demi',
        className: 'font-family-CondeNast_VFSans-Demi',
        isStandardFont: false,
        id: 623,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFSans-Demi' }],
    },
    {
        label: 'CondeNast VFSans DemiOblique',
        fontValue: 'CondeNast_VFSans-DemiOblique',
        className: 'font-family-CondeNast_VFSans-DemiOblique',
        isStandardFont: false,
        id: 624,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFSans-DemiOblique' }],
    },
    {
        label: 'CondeNast VFSans Medium',
        fontValue: 'CondeNast_VFSans-Medium',
        className: 'font-family-CondeNast_VFSans-Medium',
        isStandardFont: false,
        id: 625,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFSans-Medium' }],
    },
    {
        label: 'CondeNast VFSans MediumOblique',
        fontValue: 'CondeNast_VFSans-MediumOblique',
        className: 'font-family-CondeNast_VFSans-MediumOblique',
        isStandardFont: false,
        id: 626,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VFSans-MediumOblique' }],
    },
    {
        label: 'CondeNast VogueAvantGarde Black',
        fontValue: 'CondeNast_VogueAvantGarde-Black',
        className: 'font-family-CondeNast_VogueAvantGarde-Black',
        isStandardFont: false,
        id: 627,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-Black' }],
    },
    {
        label: 'CondeNast VogueAvantGarde BlackOblique',
        fontValue: 'CondeNast_VogueAvantGarde-BlackOblique',
        className: 'font-family-CondeNast_VogueAvantGarde-BlackOblique',
        isStandardFont: false,
        id: 628,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-BlackOblique' }],
    },
    {
        label: 'CondeNast VogueAvantGarde BoldOblique',
        fontValue: 'CondeNast_VogueAvantGarde-BoldOblique',
        className: 'font-family-CondeNast_VogueAvantGarde-BoldOblique',
        isStandardFont: false,
        id: 629,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-BoldOblique' }],
    },
    {
        label: 'CondeNast VogueAvantGarde BookOblique',
        fontValue: 'CondeNast_VogueAvantGarde-BookOblique',
        className: 'font-family-CondeNast_VogueAvantGarde-BookOblique',
        isStandardFont: false,
        id: 630,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-BookOblique' }],
    },
    {
        label: 'CondeNast VogueAvantGarde DemiBoldOblique',
        fontValue: 'CondeNast_VogueAvantGarde-DemiBoldOblique',
        className: 'font-family-CondeNast_VogueAvantGarde-DemiBoldOblique',
        isStandardFont: false,
        id: 631,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-DemiBoldOblique' }],
    },
    {
        label: 'CondeNast VogueAvantGarde ExtraBold',
        fontValue: 'CondeNast_VogueAvantGarde-ExtraBold',
        className: 'font-family-CondeNast_VogueAvantGarde-ExtraBold',
        isStandardFont: false,
        id: 632,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-ExtraBold' }],
    },
    {
        label: 'CondeNast VogueAvantGarde ExtraBoldOblique',
        fontValue: 'CondeNast_VogueAvantGarde-ExtraBoldOblique',
        className: 'font-family-CondeNast_VogueAvantGarde-ExtraBoldOblique',
        isStandardFont: false,
        id: 633,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-ExtraBoldOblique' }],
    },
    {
        label: 'CondeNast VogueAvantGarde ExtraLight',
        fontValue: 'CondeNast_VogueAvantGarde-ExtraLight',
        className: 'font-family-CondeNast_VogueAvantGarde-ExtraLight',
        isStandardFont: false,
        id: 634,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-ExtraLight' }],
    },
    {
        label: 'CondeNast VogueAvantGarde ExtraLightOblique',
        fontValue: 'CondeNast_VogueAvantGarde-ExtraLightOblique',
        className: 'font-family-CondeNast_VogueAvantGarde-ExtraLightOblique',
        isStandardFont: false,
        id: 635,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-ExtraLightOblique' }],
    },
    {
        label: 'CondeNast VogueAvantGarde Heavy',
        fontValue: 'CondeNast_VogueAvantGarde-Heavy',
        className: 'font-family-CondeNast_VogueAvantGarde-Heavy',
        isStandardFont: false,
        id: 636,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-Heavy' }],
    },
    {
        label: 'CondeNast VogueAvantGarde HeavyOblique',
        fontValue: 'CondeNast_VogueAvantGarde-HeavyOblique',
        className: 'font-family-CondeNast_VogueAvantGarde-HeavyOblique',
        isStandardFont: false,
        id: 637,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-HeavyOblique' }],
    },
    {
        label: 'CondeNast VogueAvantGarde Light',
        fontValue: 'CondeNast_VogueAvantGarde-Light',
        className: 'font-family-CondeNast_VogueAvantGarde-Light',
        isStandardFont: false,
        id: 638,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-Light' }],
    },
    {
        label: 'CondeNast VogueAvantGarde LightOblique',
        fontValue: 'CondeNast_VogueAvantGarde-LightOblique',
        className: 'font-family-CondeNast_VogueAvantGarde-LightOblique',
        isStandardFont: false,
        id: 639,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-LightOblique' }],
    },
    {
        label: 'CondeNast VogueAvantGarde MediumOblique',
        fontValue: 'CondeNast_VogueAvantGarde-MediumOblique',
        className: 'font-family-CondeNast_VogueAvantGarde-MediumOblique',
        isStandardFont: false,
        id: 640,
        fontFaces: [{ slug: 'CondeNast/CondeNast_VogueAvantGarde-MediumOblique' }],
    },
    {
        label: 'CondeNast Walfork Demi',
        fontValue: 'CondeNast_Walfork-Demi',
        className: 'font-family-CondeNast_Walfork-Demi',
        isStandardFont: false,
        id: 641,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Walfork-Demi' }],
    },
    {
        label: 'CondeNast Walfork DemiOblique',
        fontValue: 'CondeNast_Walfork-DemiOblique',
        className: 'font-family-CondeNast_Walfork-DemiOblique',
        isStandardFont: false,
        id: 642,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Walfork-DemiOblique' }],
    },
    {
        label: 'CondeNast Walfork Heavy',
        fontValue: 'CondeNast_Walfork-Heavy',
        className: 'font-family-CondeNast_Walfork-Heavy',
        isStandardFont: false,
        id: 643,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Walfork-Heavy' }],
    },
    {
        label: 'CondeNast Walfork HeavyOblique',
        fontValue: 'CondeNast_Walfork-HeavyOblique',
        className: 'font-family-CondeNast_Walfork-HeavyOblique',
        isStandardFont: false,
        id: 644,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Walfork-HeavyOblique' }],
    },
    {
        label: 'CondeNast Walfork Regular',
        fontValue: 'CondeNast_Walfork-Regular',
        className: 'font-family-CondeNast_Walfork-Regular',
        isStandardFont: false,
        id: 645,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Walfork-Regular' }],
    },
    {
        label: 'CondeNast Walfork RegularOblique',
        fontValue: 'CondeNast_Walfork-RegularOblique',
        className: 'font-family-CondeNast_Walfork-RegularOblique',
        isStandardFont: false,
        id: 646,
        fontFaces: [{ slug: 'CondeNast/CondeNast_Walfork-RegularOblique' }],
    },
    {
        label: 'CondeNast DomaineDisplay Bold',
        fontValue: 'CondeNast_DomaineDisplay-Bold',
        className: 'font-family-CondeNast_DomaineDisplay-Bold',
        isStandardFont: false,
        id: 647,
        fontFaces: [{ slug: 'CondeNast/CondeNast_DomaineDisplay-Bold' }],
    },
    {
        label: 'CondeNast DomaineDisplay BoldItalic',
        fontValue: 'CondeNast_DomaineDisplay-BoldItalic',
        className: 'font-family-CondeNast_DomaineDisplay-BoldItalic',
        isStandardFont: false,
        id: 648,
        fontFaces: [{ slug: 'CondeNast/CondeNast_DomaineDisplay-BoldItalic' }],
    },
    {
        label: 'CondeNast DomaineDisplay Regular',
        fontValue: 'CondeNast_DomaineDisplay-Regular',
        className: 'font-family-CondeNast_DomaineDisplay-Regular',
        isStandardFont: false,
        id: 649,
        fontFaces: [{ slug: 'CondeNast/CondeNast_DomaineDisplay-Regular' }],
    },
    {
        label: 'CondeNast DomaineDisplay RegularItalic',
        fontValue: 'CondeNast_DomaineDisplay-RegularItalic',
        className: 'font-family-CondeNast_DomaineDisplay-RegularItalic',
        isStandardFont: false,
        id: 650,
        fontFaces: [{ slug: 'CondeNast/CondeNast_DomaineDisplay-RegularItalic' }],
    },
    {
        label: 'CondeNast GTWalsheimPro Black',
        fontValue: 'CondeNast_GTWalsheimPro-Black',
        className: 'font-family-CondeNast_GTWalsheimPro-Black',
        isStandardFont: false,
        id: 651,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheimPro-Black' }],
    },
    {
        label: 'CondeNast GTWalsheimPro BlackOblique',
        fontValue: 'CondeNast_GTWalsheimPro-BlackOblique',
        className: 'font-family-CondeNast_GTWalsheimPro-BlackOblique',
        isStandardFont: false,
        id: 652,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheimPro-BlackOblique' }],
    },
    {
        label: 'CondeNast GTWalsheimPro Bold',
        fontValue: 'CondeNast_GTWalsheimPro-Bold',
        className: 'font-family-CondeNast_GTWalsheimPro-Bold',
        isStandardFont: false,
        id: 653,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheimPro-Bold' }],
    },
    {
        label: 'CondeNast GTWalsheimPro BoldOblique',
        fontValue: 'CondeNast_GTWalsheimPro-BoldOblique',
        className: 'font-family-CondeNast_GTWalsheimPro-BoldOblique',
        isStandardFont: false,
        id: 654,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheimPro-BoldOblique' }],
    },
    {
        label: 'CondeNast GTWalsheimPro Light',
        fontValue: 'CondeNast_GTWalsheimPro-Light',
        className: 'font-family-CondeNast_GTWalsheimPro-Light',
        isStandardFont: false,
        id: 655,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheimPro-Light' }],
    },
    {
        label: 'CondeNast GTWalsheimPro LightOblique',
        fontValue: 'CondeNast_GTWalsheimPro-LightOblique',
        className: 'font-family-CondeNast_GTWalsheimPro-LightOblique',
        isStandardFont: false,
        id: 656,
        fontFaces: [{ slug: 'CondeNast/CondeNast_GTWalsheimPro-LightOblique' }],
    },
    {
        label: 'Ichi NHaasGroteskDSPro Bold',
        fontValue: 'Ichi_NHaasGroteskDSPro-Bold',
        className: 'font-family-Ichi_NHaasGroteskDSPro-Bold',
        isStandardFont: false,
        id: 657,
        fontFaces: [{ slug: 'Ichi/Ichi_NHaasGroteskDSPro-Bold' }],
    },
    {
        label: 'Ichi NHaasGroteskDSPro Regular',
        fontValue: 'Ichi_NHaasGroteskDSPro-Regular',
        className: 'font-family-Ichi_NHaasGroteskDSPro-Regular',
        isStandardFont: false,
        id: 658,
        fontFaces: [{ slug: 'Ichi/Ichi_NHaasGroteskDSPro-Regular' }],
    },
    {
        label: 'ToyotaMaterialHandlingEurope Titillium Light',
        fontValue: 'ToyotaMaterialHandlingEurope_Titillium-Light',
        className: 'font-family-ToyotaMaterialHandlingEurope_Titillium-Light',
        isStandardFont: false,
        id: 659,
        fontFaces: [
            { slug: 'ToyotaMaterialHandlingEurope/ToyotaMaterialHandlingEurope_Titillium-Light' },
        ],
    },
    {
        label: 'ToyotaMaterialHandlingEurope Titillium Regular',
        fontValue: 'ToyotaMaterialHandlingEurope_Titillium-Regular',
        className: 'font-family-ToyotaMaterialHandlingEurope_Titillium-Regular',
        isStandardFont: false,
        id: 660,
        fontFaces: [
            { slug: 'ToyotaMaterialHandlingEurope/ToyotaMaterialHandlingEurope_Titillium-Regular' },
        ],
    },
    {
        label: 'ToyotaMaterialHandlingEurope Titillium Semibold',
        fontValue: 'ToyotaMaterialHandlingEurope_Titillium-Semibold',
        className: 'font-family-ToyotaMaterialHandlingEurope_Titillium-Semibold',
        isStandardFont: false,
        id: 661,
        fontFaces: [
            {
                slug:
                    'ToyotaMaterialHandlingEurope/ToyotaMaterialHandlingEurope_Titillium-Semibold',
            },
        ],
    },
    {
        label: 'ToyotaMaterialHandlingEurope Titillium Thin',
        fontValue: 'ToyotaMaterialHandlingEurope_Titillium-Thin',
        className: 'font-family-ToyotaMaterialHandlingEurope_Titillium-Thin',
        isStandardFont: false,
        id: 662,
        fontFaces: [
            { slug: 'ToyotaMaterialHandlingEurope/ToyotaMaterialHandlingEurope_Titillium-Thin' },
        ],
    },
    {
        label: 'NationalGeographic TradeGothicLTCondensed Bold',
        fontValue: 'NationalGeographic_TradeGothicLTCondensed-Bold',
        className: 'font-family-NationalGeographic_TradeGothicLTCondensed-Bold',
        isStandardFont: false,
        id: 663,
        fontFaces: [{ slug: 'NationalGeographic/NationalGeographic_TradeGothicLTCondensed-Bold' }],
    },
    {
        label: 'TheFA FSDillon Bold',
        fontValue: 'TheFA_FSDillon-Bold',
        className: 'font-family-TheFA_FSDillon-Bold',
        isStandardFont: false,
        id: 664,
        fontFaces: [{ slug: 'TheFA/TheFA_FSDillon-Bold' }],
    },
    {
        label: 'TheFA FSDillon BoldItalic',
        fontValue: 'TheFA_FSDillon-BoldItalic',
        className: 'font-family-TheFA_FSDillon-BoldItalic',
        isStandardFont: false,
        id: 665,
        fontFaces: [{ slug: 'TheFA/TheFA_FSDillon-BoldItalic' }],
    },
    {
        label: 'TheFA FSDillon Italic',
        fontValue: 'TheFA_FSDillon-Italic',
        className: 'font-family-TheFA_FSDillon-Italic',
        isStandardFont: false,
        id: 666,
        fontFaces: [{ slug: 'TheFA/TheFA_FSDillon-Italic' }],
    },
    {
        label: 'TheFA FSDillon Medium',
        fontValue: 'TheFA_FSDillon-Medium',
        className: 'font-family-TheFA_FSDillon-Medium',
        isStandardFont: false,
        id: 667,
        fontFaces: [{ slug: 'TheFA/TheFA_FSDillon-Medium' }],
    },
    {
        label: 'TheFA FSDillon MediumItalic',
        fontValue: 'TheFA_FSDillon-MediumItalic',
        className: 'font-family-TheFA_FSDillon-MediumItalic',
        isStandardFont: false,
        id: 668,
        fontFaces: [{ slug: 'TheFA/TheFA_FSDillon-MediumItalic' }],
    },
    {
        label: 'TheFA FSDillon Regular',
        fontValue: 'TheFA_FSDillon-Regular',
        className: 'font-family-TheFA_FSDillon-Regular',
        isStandardFont: false,
        id: 669,
        fontFaces: [{ slug: 'TheFA/TheFA_FSDillon-Regular' }],
    },
    {
        label: 'Smyle SamsungOne Medium Condensed',
        fontValue: 'Smyle_SamsungOne-500C',
        className: 'font-family-Smyle_SamsungOne-500C',
        isStandardFont: false,
        id: 670,
        fontFaces: [{ slug: 'Smyle_SamsungOne/Smyle_SamsungOne-500C' }],
    },
    {
        label: 'Smyle SamsungOne Semi Bold',
        fontValue: 'Smyle_SamsungOne-600',
        className: 'font-family-Smyle_SamsungOne-600',
        isStandardFont: false,
        id: 671,
        fontFaces: [{ slug: 'Smyle_SamsungOne/Smyle_SamsungOne-600' }],
    },
    {
        label: 'Smyle SamsungOne Semi Bold Condensed',
        fontValue: 'Smyle_SamsungOne-600C',
        className: 'font-family-Smyle_SamsungOne-600C',
        isStandardFont: false,
        id: 672,
        fontFaces: [{ slug: 'Smyle_SamsungOne/Smyle_SamsungOne-600C' }],
    },
    {
        label: 'Smyle SamsungOne Bold',
        fontValue: 'Smyle_SamsungOne-700',
        className: 'font-family-Smyle_SamsungOne-700',
        isStandardFont: false,
        id: 673,
        fontFaces: [{ slug: 'Smyle_SamsungOne/Smyle_SamsungOne-700' }],
    },
    {
        label: 'Smyle SamsungOne Bold Condensed',
        fontValue: 'Smyle_SamsungOne-700C',
        className: 'font-family-Smyle_SamsungOne-700C',
        isStandardFont: false,
        id: 674,
        fontFaces: [{ slug: 'Smyle_SamsungOne/Smyle_SamsungOne-700C' }],
    },
    {
        label: 'Smyle SamsungOne Extra Bold',
        fontValue: 'Smyle_SamsungOne-800',
        className: 'font-family-Smyle_SamsungOne-800',
        isStandardFont: false,
        id: 675,
        fontFaces: [{ slug: 'Smyle_SamsungOne/Smyle_SamsungOne-800' }],
    },
    {
        label: 'Smyle SamsungOne Extra Bold Condensed',
        fontValue: 'Smyle_SamsungOne-800C',
        className: 'font-family-Smyle_SamsungOne-800C',
        isStandardFont: false,
        id: 676,
        fontFaces: [{ slug: 'Smyle_SamsungOne/Smyle_SamsungOne-800C' }],
    },
    {
        label: 'NewHonorSociety AkzidGrtskNext Bold',
        fontValue: 'NewHonorSociety_AkzidGrtskNext-Bold',
        className: 'font-family-NewHonorSociety_AkzidGrtskNext-Bold',
        isStandardFont: false,
        id: 677,
        fontFaces: [{ slug: 'NewHonorSociety/NewHonorSociety_AkzidGrtskNext-Bold' }],
    },
    {
        label: 'NewHonorSociety AkzidGrtskNext BoldExt',
        fontValue: 'NewHonorSociety_AkzidGrtskNext-BoldExt',
        className: 'font-family-NewHonorSociety_AkzidGrtskNext-BoldExt',
        isStandardFont: false,
        id: 678,
        fontFaces: [{ slug: 'NewHonorSociety/NewHonorSociety_AkzidGrtskNext-BoldExt' }],
    },
    {
        label: 'NewHonorSociety AkzidGrtskNext Regular',
        fontValue: 'NewHonorSociety_AkzidGrtskNext-Regular',
        className: 'font-family-NewHonorSociety_AkzidGrtskNext-Regular',
        isStandardFont: false,
        id: 679,
        fontFaces: [{ slug: 'NewHonorSociety/NewHonorSociety_AkzidGrtskNext-Regular' }],
    },
    {
        label: 'FixedOpsDigital AcuraBespoke Bold',
        fontValue: 'FixedOpsDigital_AcuraBespoke-Bold',
        className: 'font-family-FixedOpsDigital_AcuraBespoke-Bold',
        isStandardFont: false,
        id: 680,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_AcuraBespoke-Bold' }],
    },
    {
        label: 'FixedOpsDigital AudiType ExtendedBold',
        fontValue: 'FixedOpsDigital_AudiType-ExtendedBold',
        className: 'font-family-FixedOpsDigital_AudiType-ExtendedBold',
        isStandardFont: false,
        id: 681,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_AudiType-ExtendedBold' }],
    },
    {
        label: 'FixedOpsDigital AudiType ExtendedNormal',
        fontValue: 'FixedOpsDigital_AudiType-ExtendedNormal',
        className: 'font-family-FixedOpsDigital_AudiType-ExtendedNormal',
        isStandardFont: false,
        id: 682,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_AudiType-ExtendedNormal' }],
    },
    {
        label: 'FixedOpsDigital AudiTypeScreen Bold',
        fontValue: 'FixedOpsDigital_AudiTypeScreen-Bold',
        className: 'font-family-FixedOpsDigital_AudiTypeScreen-Bold',
        isStandardFont: false,
        id: 683,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_AudiTypeScreen-Bold' }],
    },
    {
        label: 'FixedOpsDigital AudiTypeScreen Light',
        fontValue: 'FixedOpsDigital_AudiTypeScreen-Light',
        className: 'font-family-FixedOpsDigital_AudiTypeScreen-Light',
        isStandardFont: false,
        id: 684,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_AudiTypeScreen-Light' }],
    },
    {
        label: 'FixedOpsDigital AudiTypeScreen Normal',
        fontValue: 'FixedOpsDigital_AudiTypeScreen-Normal',
        className: 'font-family-FixedOpsDigital_AudiTypeScreen-Normal',
        isStandardFont: false,
        id: 685,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_AudiTypeScreen-Normal' }],
    },
    {
        label: 'FixedOpsDigital AvenirNextLTPro Bold',
        fontValue: 'FixedOpsDigital_AvenirNextLTPro-Bold',
        className: 'font-family-FixedOpsDigital_AvenirNextLTPro-Bold',
        isStandardFont: false,
        id: 686,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_AvenirNextLTPro-Bold' }],
    },
    {
        label: 'FixedOpsDigital AvenirNextLTPro Light',
        fontValue: 'FixedOpsDigital_AvenirNextLTPro-Light',
        className: 'font-family-FixedOpsDigital_AvenirNextLTPro-Light',
        isStandardFont: false,
        id: 687,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_AvenirNextLTPro-Light' }],
    },
    {
        label: 'FixedOpsDigital AvenirNextLTPro Regular',
        fontValue: 'FixedOpsDigital_AvenirNextLTPro-Regular',
        className: 'font-family-FixedOpsDigital_AvenirNextLTPro-Regular',
        isStandardFont: false,
        id: 688,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_AvenirNextLTPro-Regular' }],
    },
    {
        label: 'FixedOpsDigital AvenirNextRoundedW01Demi Regular',
        fontValue: 'FixedOpsDigital_AvenirNextRoundedW01Demi-Regular',
        className: 'font-family-FixedOpsDigital_AvenirNextRoundedW01Demi-Regular',
        isStandardFont: false,
        id: 689,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_AvenirNextRoundedW01Demi-Regular',
            },
        ],
    },
    {
        label: 'FixedOpsDigital BMWTypeWebBoldAll Regular',
        fontValue: 'FixedOpsDigital_BMWTypeWebBoldAll-Regular',
        className: 'font-family-FixedOpsDigital_BMWTypeWebBoldAll-Regular',
        isStandardFont: false,
        id: 690,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_BMWTypeWebBoldAll-Regular',
            },
        ],
    },
    {
        label: 'FixedOpsDigital BMWTypeWebLightAll Regular',
        fontValue: 'FixedOpsDigital_BMWTypeWebLightAll-Regular',
        className: 'font-family-FixedOpsDigital_BMWTypeWebLightAll-Regular',
        isStandardFont: false,
        id: 691,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_BMWTypeWebLightAll-Regular',
            },
        ],
    },
    {
        label: 'FixedOpsDigital CadillacSansA Bold',
        fontValue: 'FixedOpsDigital_CadillacSansA-Bold',
        className: 'font-family-FixedOpsDigital_CadillacSansA-Bold',
        isStandardFont: false,
        id: 692,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CadillacSansA-Bold' }],
    },
    {
        label: 'FixedOpsDigital CadillacSansA Book',
        fontValue: 'FixedOpsDigital_CadillacSansA-Book',
        className: 'font-family-FixedOpsDigital_CadillacSansA-Book',
        isStandardFont: false,
        id: 693,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CadillacSansA-Book' }],
    },
    {
        label: 'FixedOpsDigital CadillacSansA ExtraBold',
        fontValue: 'FixedOpsDigital_CadillacSansA-ExtraBold',
        className: 'font-family-FixedOpsDigital_CadillacSansA-ExtraBold',
        isStandardFont: false,
        id: 694,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CadillacSansA-ExtraBold' }],
    },
    {
        label: 'FixedOpsDigital CadillacSansA Light',
        fontValue: 'FixedOpsDigital_CadillacSansA-Light',
        className: 'font-family-FixedOpsDigital_CadillacSansA-Light',
        isStandardFont: false,
        id: 695,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CadillacSansA-Light' }],
    },
    {
        label: 'FixedOpsDigital CadillacSansA Medium',
        fontValue: 'FixedOpsDigital_CadillacSansA-Medium',
        className: 'font-family-FixedOpsDigital_CadillacSansA-Medium',
        isStandardFont: false,
        id: 696,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CadillacSansA-Medium' }],
    },
    {
        label: 'FixedOpsDigital CadillacSansA SemiBold',
        fontValue: 'FixedOpsDigital_CadillacSansA-SemiBold',
        className: 'font-family-FixedOpsDigital_CadillacSansA-SemiBold',
        isStandardFont: false,
        id: 697,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CadillacSansA-SemiBold' }],
    },
    {
        label: 'FixedOpsDigital CadillacSansA Thin',
        fontValue: 'FixedOpsDigital_CadillacSansA-Thin',
        className: 'font-family-FixedOpsDigital_CadillacSansA-Thin',
        isStandardFont: false,
        id: 698,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CadillacSansA-Thin' }],
    },
    {
        label: 'FixedOpsDigital CorporateA Bold',
        fontValue: 'FixedOpsDigital_CorporateA-Bold',
        className: 'font-family-FixedOpsDigital_CorporateA-Bold',
        isStandardFont: false,
        id: 699,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CorporateA-Bold' }],
    },
    {
        label: 'FixedOpsDigital CorporateA Light',
        fontValue: 'FixedOpsDigital_CorporateA-Light',
        className: 'font-family-FixedOpsDigital_CorporateA-Light',
        isStandardFont: false,
        id: 700,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CorporateA-Light' }],
    },
    {
        label: 'FixedOpsDigital CorporateA Regular',
        fontValue: 'FixedOpsDigital_CorporateA-Regular',
        className: 'font-family-FixedOpsDigital_CorporateA-Regular',
        isStandardFont: false,
        id: 701,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_CorporateA-Regular' }],
    },
    {
        label: 'FixedOpsDigital DesignKOTF Bold',
        fontValue: 'FixedOpsDigital_DesignKOTF-Bold',
        className: 'font-family-FixedOpsDigital_DesignKOTF-Bold',
        isStandardFont: false,
        id: 702,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_DesignKOTF-Bold' }],
    },
    {
        label: 'FixedOpsDigital DesignKOTF Light',
        fontValue: 'FixedOpsDigital_DesignKOTF-Light',
        className: 'font-family-FixedOpsDigital_DesignKOTF-Light',
        isStandardFont: false,
        id: 703,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_DesignKOTF-Light' }],
    },
    {
        label: 'FixedOpsDigital DesignKOTF Regular',
        fontValue: 'FixedOpsDigital_DesignKOTF-Regular',
        className: 'font-family-FixedOpsDigital_DesignKOTF-Regular',
        isStandardFont: false,
        id: 704,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_DesignKOTF-Regular' }],
    },
    {
        label: 'FixedOpsDigital FieldworkGeo Demibold',
        fontValue: 'FixedOpsDigital_FieldworkGeo-Demibold',
        className: 'font-family-FixedOpsDigital_FieldworkGeo-Demibold',
        isStandardFont: false,
        id: 705,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_FieldworkGeo-Demibold' }],
    },
    {
        label: 'FixedOpsDigital FieldworkGeo Regular',
        fontValue: 'FixedOpsDigital_FieldworkGeo-Regular',
        className: 'font-family-FixedOpsDigital_FieldworkGeo-Regular',
        isStandardFont: false,
        id: 706,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_FieldworkGeo-Regular' }],
    },
    {
        label: 'FixedOpsDigital FordAntenna Bold',
        fontValue: 'FixedOpsDigital_FordAntenna-Bold',
        className: 'font-family-FixedOpsDigital_FordAntenna-Bold',
        isStandardFont: false,
        id: 707,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_FordAntenna-Bold' }],
    },
    {
        label: 'FixedOpsDigital FordAntenna ExtraLight',
        fontValue: 'FixedOpsDigital_FordAntenna-ExtraLight',
        className: 'font-family-FixedOpsDigital_FordAntenna-ExtraLight',
        isStandardFont: false,
        id: 708,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_FordAntenna-ExtraLight' }],
    },
    {
        label: 'FixedOpsDigital FordAntenna Light',
        fontValue: 'FixedOpsDigital_FordAntenna-Light',
        className: 'font-family-FixedOpsDigital_FordAntenna-Light',
        isStandardFont: false,
        id: 709,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_FordAntenna-Light' }],
    },
    {
        label: 'FixedOpsDigital FordAntenna Medium',
        fontValue: 'FixedOpsDigital_FordAntenna-Medium',
        className: 'font-family-FixedOpsDigital_FordAntenna-Medium',
        isStandardFont: false,
        id: 710,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_FordAntenna-Medium' }],
    },
    {
        label: 'FixedOpsDigital FordAntenna Regular',
        fontValue: 'FixedOpsDigital_FordAntenna-Regular',
        className: 'font-family-FixedOpsDigital_FordAntenna-Regular',
        isStandardFont: false,
        id: 711,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_FordAntenna-Regular' }],
    },
    {
        label: 'FixedOpsDigital FoundryMonolineOT3 Bold',
        fontValue: 'FixedOpsDigital_FoundryMonolineOT3-Bold',
        className: 'font-family-FixedOpsDigital_FoundryMonolineOT3-Bold',
        isStandardFont: false,
        id: 712,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_FoundryMonolineOT3-Bold' }],
    },
    {
        label: 'FixedOpsDigital GenesisHead Light',
        fontValue: 'FixedOpsDigital_GenesisHead-Light',
        className: 'font-family-FixedOpsDigital_GenesisHead-Light',
        isStandardFont: false,
        id: 713,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_GenesisHead-Light' }],
    },
    {
        label: 'FixedOpsDigital GenesisText Regular',
        fontValue: 'FixedOpsDigital_GenesisText-Regular',
        className: 'font-family-FixedOpsDigital_GenesisText-Regular',
        isStandardFont: false,
        id: 714,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_GenesisText-Regular' }],
    },
    {
        label: 'FixedOpsDigital HyundaiSansHeadOffice Bold',
        fontValue: 'FixedOpsDigital_HyundaiSansHeadOffice-Bold',
        className: 'font-family-FixedOpsDigital_HyundaiSansHeadOffice-Bold',
        isStandardFont: false,
        id: 715,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansHeadOffice-Bold',
            },
        ],
    },
    {
        label: 'FixedOpsDigital HyundaiSansHeadOffice Light',
        fontValue: 'FixedOpsDigital_HyundaiSansHeadOffice-Light',
        className: 'font-family-FixedOpsDigital_HyundaiSansHeadOffice-Light',
        isStandardFont: false,
        id: 716,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansHeadOffice-Light',
            },
        ],
    },
    {
        label: 'FixedOpsDigital HyundaiSansHeadOffice Medium',
        fontValue: 'FixedOpsDigital_HyundaiSansHeadOffice-Medium',
        className: 'font-family-FixedOpsDigital_HyundaiSansHeadOffice-Medium',
        isStandardFont: false,
        id: 717,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansHeadOffice-Medium',
            },
        ],
    },
    {
        label: 'FixedOpsDigital HyundaiSansHeadOffice Regular',
        fontValue: 'FixedOpsDigital_HyundaiSansHeadOffice-Regular',
        className: 'font-family-FixedOpsDigital_HyundaiSansHeadOffice-Regular',
        isStandardFont: false,
        id: 718,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansHeadOffice-Regular',
            },
        ],
    },
    {
        label: 'FixedOpsDigital HyundaiSansTextOffice Bold',
        fontValue: 'FixedOpsDigital_HyundaiSansTextOffice-Bold',
        className: 'font-family-FixedOpsDigital_HyundaiSansTextOffice-Bold',
        isStandardFont: false,
        id: 719,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansTextOffice-Bold',
            },
        ],
    },
    {
        label: 'FixedOpsDigital HyundaiSansTextOffice BoldItalic',
        fontValue: 'FixedOpsDigital_HyundaiSansTextOffice-BoldItalic',
        className: 'font-family-FixedOpsDigital_HyundaiSansTextOffice-BoldItalic',
        isStandardFont: false,
        id: 720,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansTextOffice-BoldItalic',
            },
        ],
    },
    {
        label: 'FixedOpsDigital HyundaiSansTextOffice Italic',
        fontValue: 'FixedOpsDigital_HyundaiSansTextOffice-Italic',
        className: 'font-family-FixedOpsDigital_HyundaiSansTextOffice-Italic',
        isStandardFont: false,
        id: 721,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansTextOffice-Italic',
            },
        ],
    },
    {
        label: 'FixedOpsDigital HyundaiSansTextOffice Medium',
        fontValue: 'FixedOpsDigital_HyundaiSansTextOffice-Medium',
        className: 'font-family-FixedOpsDigital_HyundaiSansTextOffice-Medium',
        isStandardFont: false,
        id: 722,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansTextOffice-Medium',
            },
        ],
    },
    {
        label: 'FixedOpsDigital HyundaiSansTextOffice MediumItalic',
        fontValue: 'FixedOpsDigital_HyundaiSansTextOffice-MediumItalic',
        className: 'font-family-FixedOpsDigital_HyundaiSansTextOffice-MediumItalic',
        isStandardFont: false,
        id: 723,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansTextOffice-MediumItalic',
            },
        ],
    },
    {
        label: 'FixedOpsDigital HyundaiSansTextOffice Regular',
        fontValue: 'FixedOpsDigital_HyundaiSansTextOffice-Regular',
        className: 'font-family-FixedOpsDigital_HyundaiSansTextOffice-Regular',
        isStandardFont: false,
        id: 724,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_HyundaiSansTextOffice-Regular',
            },
        ],
    },
    {
        label: 'FixedOpsDigital ITCAvantGardeGothic DemiCondensed',
        fontValue: 'FixedOpsDigital_ITCAvantGardeGothic-DemiCondensed',
        className: 'font-family-FixedOpsDigital_ITCAvantGardeGothic-DemiCondensed',
        isStandardFont: false,
        id: 725,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_ITCAvantGardeGothic-DemiCondensed',
            },
        ],
    },
    {
        label: 'FixedOpsDigital InfinitiBrand Bold',
        fontValue: 'FixedOpsDigital_InfinitiBrand-Bold',
        className: 'font-family-FixedOpsDigital_InfinitiBrand-Bold',
        isStandardFont: false,
        id: 726,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_InfinitiBrand-Bold' }],
    },
    {
        label: 'FixedOpsDigital InfinitiBrand Light',
        fontValue: 'FixedOpsDigital_InfinitiBrand-Light',
        className: 'font-family-FixedOpsDigital_InfinitiBrand-Light',
        isStandardFont: false,
        id: 727,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_InfinitiBrand-Light' }],
    },
    {
        label: 'FixedOpsDigital InfinitiBrand Regular',
        fontValue: 'FixedOpsDigital_InfinitiBrand-Regular',
        className: 'font-family-FixedOpsDigital_InfinitiBrand-Regular',
        isStandardFont: false,
        id: 728,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_InfinitiBrand-Regular' }],
    },
    {
        label: 'FixedOpsDigital Jaguar Bold',
        fontValue: 'FixedOpsDigital_Jaguar-Bold',
        className: 'font-family-FixedOpsDigital_Jaguar-Bold',
        isStandardFont: false,
        id: 729,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_Jaguar-Bold' }],
    },
    {
        label: 'FixedOpsDigital LouisGlobal2 Bold',
        fontValue: 'FixedOpsDigital_LouisGlobal2-Bold',
        className: 'font-family-FixedOpsDigital_LouisGlobal2-Bold',
        isStandardFont: false,
        id: 730,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_LouisGlobal2-Bold' }],
    },
    {
        label: 'FixedOpsDigital LouisGlobal2 Heavy',
        fontValue: 'FixedOpsDigital_LouisGlobal2-Heavy',
        className: 'font-family-FixedOpsDigital_LouisGlobal2-Heavy',
        isStandardFont: false,
        id: 731,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_LouisGlobal2-Heavy' }],
    },
    {
        label: 'FixedOpsDigital LouisGlobal2 Reg',
        fontValue: 'FixedOpsDigital_LouisGlobal2-Reg',
        className: 'font-family-FixedOpsDigital_LouisGlobal2-Reg',
        isStandardFont: false,
        id: 732,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_LouisGlobal2-Reg' }],
    },
    {
        label: 'FixedOpsDigital MazdaType Bold',
        fontValue: 'FixedOpsDigital_MazdaType-Bold',
        className: 'font-family-FixedOpsDigital_MazdaType-Bold',
        isStandardFont: false,
        id: 733,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_MazdaType-Bold' }],
    },
    {
        label: 'FixedOpsDigital MazdaType BoldItalic',
        fontValue: 'FixedOpsDigital_MazdaType-BoldItalic',
        className: 'font-family-FixedOpsDigital_MazdaType-BoldItalic',
        isStandardFont: false,
        id: 734,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_MazdaType-BoldItalic' }],
    },
    {
        label: 'FixedOpsDigital MazdaType Italic',
        fontValue: 'FixedOpsDigital_MazdaType-Italic',
        className: 'font-family-FixedOpsDigital_MazdaType-Italic',
        isStandardFont: false,
        id: 735,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_MazdaType-Italic' }],
    },
    {
        label: 'FixedOpsDigital MazdaType Medium',
        fontValue: 'FixedOpsDigital_MazdaType-Medium',
        className: 'font-family-FixedOpsDigital_MazdaType-Medium',
        isStandardFont: false,
        id: 736,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_MazdaType-Medium' }],
    },
    {
        label: 'FixedOpsDigital MazdaType MediumItalic',
        fontValue: 'FixedOpsDigital_MazdaType-MediumItalic',
        className: 'font-family-FixedOpsDigital_MazdaType-MediumItalic',
        isStandardFont: false,
        id: 737,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_MazdaType-MediumItalic' }],
    },
    {
        label: 'FixedOpsDigital MazdaType Regular',
        fontValue: 'FixedOpsDigital_MazdaType-Regular',
        className: 'font-family-FixedOpsDigital_MazdaType-Regular',
        isStandardFont: false,
        id: 738,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_MazdaType-Regular' }],
    },
    {
        label: 'FixedOpsDigital NissanBrand Bold',
        fontValue: 'FixedOpsDigital_NissanBrand-Bold',
        className: 'font-family-FixedOpsDigital_NissanBrand-Bold',
        isStandardFont: false,
        id: 739,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_NissanBrand-Bold' }],
    },
    {
        label: 'FixedOpsDigital NissanBrand Light',
        fontValue: 'FixedOpsDigital_NissanBrand-Light',
        className: 'font-family-FixedOpsDigital_NissanBrand-Light',
        isStandardFont: false,
        id: 740,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_NissanBrand-Light' }],
    },
    {
        label: 'FixedOpsDigital PorscheNext Bold',
        fontValue: 'FixedOpsDigital_PorscheNext-Bold',
        className: 'font-family-FixedOpsDigital_PorscheNext-Bold',
        isStandardFont: false,
        id: 741,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_PorscheNext-Bold' }],
    },
    {
        label: 'FixedOpsDigital PorscheNext BoldItalic',
        fontValue: 'FixedOpsDigital_PorscheNext-BoldItalic',
        className: 'font-family-FixedOpsDigital_PorscheNext-BoldItalic',
        isStandardFont: false,
        id: 742,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_PorscheNext-BoldItalic' }],
    },
    {
        label: 'FixedOpsDigital PorscheNext Italic',
        fontValue: 'FixedOpsDigital_PorscheNext-Italic',
        className: 'font-family-FixedOpsDigital_PorscheNext-Italic',
        isStandardFont: false,
        id: 743,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_PorscheNext-Italic' }],
    },
    {
        label: 'FixedOpsDigital PorscheNext Regular',
        fontValue: 'FixedOpsDigital_PorscheNext-Regular',
        className: 'font-family-FixedOpsDigital_PorscheNext-Regular',
        isStandardFont: false,
        id: 744,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_PorscheNext-Regular' }],
    },
    {
        label: 'FixedOpsDigital PorscheNext Thin',
        fontValue: 'FixedOpsDigital_PorscheNext-Thin',
        className: 'font-family-FixedOpsDigital_PorscheNext-Thin',
        isStandardFont: false,
        id: 745,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_PorscheNext-Thin' }],
    },
    {
        label: 'FixedOpsDigital PorscheNext ThinItalic',
        fontValue: 'FixedOpsDigital_PorscheNext-ThinItalic',
        className: 'font-family-FixedOpsDigital_PorscheNext-ThinItalic',
        isStandardFont: false,
        id: 746,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_PorscheNext-ThinItalic' }],
    },
    {
        label: 'FixedOpsDigital Quicksand Bold',
        fontValue: 'FixedOpsDigital_Quicksand-Bold',
        className: 'font-family-FixedOpsDigital_Quicksand-Bold',
        isStandardFont: false,
        id: 747,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_Quicksand-Bold' }],
    },
    {
        label: 'FixedOpsDigital Quicksand BoldItalic',
        fontValue: 'FixedOpsDigital_Quicksand-BoldItalic',
        className: 'font-family-FixedOpsDigital_Quicksand-BoldItalic',
        isStandardFont: false,
        id: 748,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_Quicksand-BoldItalic' }],
    },
    {
        label: 'FixedOpsDigital Quicksand Dash',
        fontValue: 'FixedOpsDigital_Quicksand-Dash',
        className: 'font-family-FixedOpsDigital_Quicksand-Dash',
        isStandardFont: false,
        id: 749,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_Quicksand-Dash' }],
    },
    {
        label: 'FixedOpsDigital Quicksand Italic',
        fontValue: 'FixedOpsDigital_Quicksand-Italic',
        className: 'font-family-FixedOpsDigital_Quicksand-Italic',
        isStandardFont: false,
        id: 750,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_Quicksand-Italic' }],
    },
    {
        label: 'FixedOpsDigital Quicksand Light',
        fontValue: 'FixedOpsDigital_Quicksand-Light',
        className: 'font-family-FixedOpsDigital_Quicksand-Light',
        isStandardFont: false,
        id: 751,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_Quicksand-Light' }],
    },
    {
        label: 'FixedOpsDigital Quicksand LightItalic',
        fontValue: 'FixedOpsDigital_Quicksand-LightItalic',
        className: 'font-family-FixedOpsDigital_Quicksand-LightItalic',
        isStandardFont: false,
        id: 752,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_Quicksand-LightItalic' }],
    },
    {
        label: 'FixedOpsDigital Quicksand Regular',
        fontValue: 'FixedOpsDigital_Quicksand-Regular',
        className: 'font-family-FixedOpsDigital_Quicksand-Regular',
        isStandardFont: false,
        id: 753,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_Quicksand-Regular' }],
    },
    {
        label: 'FixedOpsDigital StratumGMC Black',
        fontValue: 'FixedOpsDigital_StratumGMC-Black',
        className: 'font-family-FixedOpsDigital_StratumGMC-Black',
        isStandardFont: false,
        id: 754,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_StratumGMC-Black' }],
    },
    {
        label: 'FixedOpsDigital StratumNo1 Bold',
        fontValue: 'FixedOpsDigital_StratumNo1-Bold',
        className: 'font-family-FixedOpsDigital_StratumNo1-Bold',
        isStandardFont: false,
        id: 755,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_StratumNo1-Bold' }],
    },
    {
        label: 'FixedOpsDigital TitlingGothicFBComp Bold',
        fontValue: 'FixedOpsDigital_TitlingGothicFBComp-Bold',
        className: 'font-family-FixedOpsDigital_TitlingGothicFBComp-Bold',
        isStandardFont: false,
        id: 756,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_TitlingGothicFBComp-Bold',
            },
        ],
    },
    {
        label: 'FixedOpsDigital TitlingGothicFBComp Light',
        fontValue: 'FixedOpsDigital_TitlingGothicFBComp-Light',
        className: 'font-family-FixedOpsDigital_TitlingGothicFBComp-Light',
        isStandardFont: false,
        id: 757,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_TitlingGothicFBComp-Light',
            },
        ],
    },
    {
        label: 'FixedOpsDigital TitlingGothicFBComp Medium',
        fontValue: 'FixedOpsDigital_TitlingGothicFBComp-Medium',
        className: 'font-family-FixedOpsDigital_TitlingGothicFBComp-Medium',
        isStandardFont: false,
        id: 758,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_TitlingGothicFBComp-Medium',
            },
        ],
    },
    {
        label: 'FixedOpsDigital TitlingGothicFBComp Regular',
        fontValue: 'FixedOpsDigital_TitlingGothicFBComp-Regular',
        className: 'font-family-FixedOpsDigital_TitlingGothicFBComp-Regular',
        isStandardFont: false,
        id: 759,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_TitlingGothicFBComp-Regular',
            },
        ],
    },
    {
        label: 'FixedOpsDigital TitlingGothicFBComp Standard',
        fontValue: 'FixedOpsDigital_TitlingGothicFBComp-Standard',
        className: 'font-family-FixedOpsDigital_TitlingGothicFBComp-Standard',
        isStandardFont: false,
        id: 760,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_TitlingGothicFBComp-Standard',
            },
        ],
    },
    {
        label: 'FixedOpsDigital TitlingGothicFBComp Thin',
        fontValue: 'FixedOpsDigital_TitlingGothicFBComp-Thin',
        className: 'font-family-FixedOpsDigital_TitlingGothicFBComp-Thin',
        isStandardFont: false,
        id: 761,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_TitlingGothicFBComp-Thin',
            },
        ],
    },
    {
        label: 'FixedOpsDigital ToyotaType Bold',
        fontValue: 'FixedOpsDigital_ToyotaType-Bold',
        className: 'font-family-FixedOpsDigital_ToyotaType-Bold',
        isStandardFont: false,
        id: 762,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_ToyotaType-Bold' }],
    },
    {
        label: 'FixedOpsDigital ToyotaType Light',
        fontValue: 'FixedOpsDigital_ToyotaType-Light',
        className: 'font-family-FixedOpsDigital_ToyotaType-Light',
        isStandardFont: false,
        id: 763,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_ToyotaType-Light' }],
    },
    {
        label: 'FixedOpsDigital ToyotaType Semibold',
        fontValue: 'FixedOpsDigital_ToyotaType-Semibold',
        className: 'font-family-FixedOpsDigital_ToyotaType-Semibold',
        isStandardFont: false,
        id: 764,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_ToyotaType-Semibold' }],
    },
    {
        label: 'FixedOpsDigital VWHead Bold',
        fontValue: 'FixedOpsDigital_VWHead-Bold',
        className: 'font-family-FixedOpsDigital_VWHead-Bold',
        isStandardFont: false,
        id: 765,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VWHead-Bold' }],
    },
    {
        label: 'FixedOpsDigital VWHead Regular',
        fontValue: 'FixedOpsDigital_VWHead-Regular',
        className: 'font-family-FixedOpsDigital_VWHead-Regular',
        isStandardFont: false,
        id: 766,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VWHead-Regular' }],
    },
    {
        label: 'FixedOpsDigital VWText Bold',
        fontValue: 'FixedOpsDigital_VWText-Bold',
        className: 'font-family-FixedOpsDigital_VWText-Bold',
        isStandardFont: false,
        id: 767,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VWText-Bold' }],
    },
    {
        label: 'FixedOpsDigital VWText BoldItalic',
        fontValue: 'FixedOpsDigital_VWText-BoldItalic',
        className: 'font-family-FixedOpsDigital_VWText-BoldItalic',
        isStandardFont: false,
        id: 768,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VWText-BoldItalic' }],
    },
    {
        label: 'FixedOpsDigital VWText Light',
        fontValue: 'FixedOpsDigital_VWText-Light',
        className: 'font-family-FixedOpsDigital_VWText-Light',
        isStandardFont: false,
        id: 769,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VWText-Light' }],
    },
    {
        label: 'FixedOpsDigital VWText Regular',
        fontValue: 'FixedOpsDigital_VWText-Regular',
        className: 'font-family-FixedOpsDigital_VWText-Regular',
        isStandardFont: false,
        id: 770,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VWText-Regular' }],
    },
    {
        label: 'FixedOpsDigital VWText RegularItalic',
        fontValue: 'FixedOpsDigital_VWText-RegularItalic',
        className: 'font-family-FixedOpsDigital_VWText-RegularItalic',
        isStandardFont: false,
        id: 771,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VWText-RegularItalic' }],
    },
    {
        label: 'FixedOpsDigital VolvoBroadPro ',
        fontValue: 'FixedOpsDigital_VolvoBroadPro',
        className: 'font-family-FixedOpsDigital_VolvoBroadPro',
        isStandardFont: false,
        id: 772,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VolvoBroadPro' }],
    },
    {
        label: 'FixedOpsDigital VolvoNovum Italic',
        fontValue: 'FixedOpsDigital_VolvoNovum-Italic',
        className: 'font-family-FixedOpsDigital_VolvoNovum-Italic',
        isStandardFont: false,
        id: 773,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VolvoNovum-Italic' }],
    },
    {
        label: 'FixedOpsDigital VolvoNovum Light',
        fontValue: 'FixedOpsDigital_VolvoNovum-Light',
        className: 'font-family-FixedOpsDigital_VolvoNovum-Light',
        isStandardFont: false,
        id: 774,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VolvoNovum-Light' }],
    },
    {
        label: 'FixedOpsDigital VolvoNovum LightItalic',
        fontValue: 'FixedOpsDigital_VolvoNovum-LightItalic',
        className: 'font-family-FixedOpsDigital_VolvoNovum-LightItalic',
        isStandardFont: false,
        id: 775,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VolvoNovum-LightItalic' }],
    },
    {
        label: 'FixedOpsDigital VolvoNovum Medium',
        fontValue: 'FixedOpsDigital_VolvoNovum-Medium',
        className: 'font-family-FixedOpsDigital_VolvoNovum-Medium',
        isStandardFont: false,
        id: 776,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VolvoNovum-Medium' }],
    },
    {
        label: 'FixedOpsDigital VolvoNovum MediumItalic',
        fontValue: 'FixedOpsDigital_VolvoNovum-MediumItalic',
        className: 'font-family-FixedOpsDigital_VolvoNovum-MediumItalic',
        isStandardFont: false,
        id: 777,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VolvoNovum-MediumItalic' }],
    },
    {
        label: 'FixedOpsDigital VolvoNovum Regular',
        fontValue: 'FixedOpsDigital_VolvoNovum-Regular',
        className: 'font-family-FixedOpsDigital_VolvoNovum-Regular',
        isStandardFont: false,
        id: 778,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VolvoNovum-Regular' }],
    },
    {
        label: 'FixedOpsDigital VolvoNovum SemiLight',
        fontValue: 'FixedOpsDigital_VolvoNovum-SemiLight',
        className: 'font-family-FixedOpsDigital_VolvoNovum-SemiLight',
        isStandardFont: false,
        id: 779,
        fontFaces: [{ slug: 'FixedOpsDigital/FixedOpsDigital_VolvoNovum-SemiLight' }],
    },
    {
        label: 'FixedOpsDigital VolvoNovum SemiLightItalic',
        fontValue: 'FixedOpsDigital_VolvoNovum-SemiLightItalic',
        className: 'font-family-FixedOpsDigital_VolvoNovum-SemiLightItalic',
        isStandardFont: false,
        id: 780,
        fontFaces: [
            {
                slug: 'FixedOpsDigital/FixedOpsDigital_VolvoNovum-SemiLightItalic',
            },
        ],
    },
];

export default FONTS;
