const initialState = {
    isStarted: false,
    isPlaying: false,
    isPaused: true,
    isSeeked: false,
    isSeeking: false,
    seekTo: null,
};

export default initialState;
