import initialState from './notification.state';
import { NOTIFY, CLEAR_NOTIFICATION } from './notification.constant';

type State = Object;

type Action = Object;

const notification = (state: State = initialState, action: Action): Object => {
    switch (action.type) {
        case NOTIFY:
            return {
                ...state,
                message: action.payload.message,
                type: action.payload.type,
            };
        case CLEAR_NOTIFICATION:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default notification;
