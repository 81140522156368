import styled from 'styled-components';
import colors from '../../styles/colors';
import focusVisible from '../../styles/focusVisible';
import srOnly from '../../styles/srOnly';
import { setOpacity } from '../../styles/utils';

const boxShadow = `inset 0 0 0 1px ${setOpacity(colors.white, 0.85)}`;

const optionStyle = `
  color: ${colors.mineShaft};
  line-height: 25px;
  padding-left: 2em;
  position: relative;
  transition: color 300ms ease;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:after {
    border: 1px solid ${colors.black};
    border-radius: 20px;
    bottom: 0;
    content: '';
    left: 2px;
    opacity: 0;
    position: absolute;
    right: 2px;
    top: 0;
    transition: opacity 300ms ease;
  }
`;

const optionHoverStyle = `
  color: ${colors.black};
`;

const optionFocusStyle = `
  &:after {
    opacity: 1;
  }
`;

export const EmbedWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    color: ${colors.mineShaft};
    font-weight: 300;
    z-index: 2;
`;

export const EmbedContainer = styled.div`
    width: 60em;
    background: ${colors.transparentGray};
    border-radius: 0.5em;
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 3em;
    border-bottom: 1px solid ${colors.nobel};
`;

export const HeaderText = styled.div`
    font-size: 1.5em;
`;

export const CloseIcon = styled.div`
    width: 1em;
    margin-left: auto;
    cursor: pointer;
    svg {
        transition: transform 400ms ease;
        &:hover {
            transform: rotate(90deg);
        }
    }
`;

export const Body = styled.div`
    font-size: 1.2em;
    padding: 3em;
`;

export const Section = styled.div`
    display: flex;
`;

export const VidInfo = styled.div`
    width: 50%;
`;

export const Item = styled.div`
    margin-bottom: 2em;
`;

export const Heading = styled.h2`
    margin-bottom: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const CopyUrl = styled.span`
    /* margin-left: 1em; */
    cursor: pointer;
    font-size: 0.9em;
    text-decoration: underline;
`;

export const VidId = styled.p`
    font-size: 1.5em;
`;

export const VidOptions = styled.div`
    width: 50%;
`;

export const EmbedCode = styled.div`
    border: 1px solid ${colors.nobel};
    padding: 1em;
    margin-top: 2.5em;
    line-height: 2;
    min-height: 10em;
`;

export const StyledInput = styled.input`
    font-size: 1.5em;
    background: transparent;
    color: ${props => (props.disabled ? `${colors.mineShaft}60` : `${colors.mineShaft}`)};
    width: 2.5em;
    border: none;
    padding: 0;
    &:focus {
        outline: none;
    }
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

export const InputContainer = styled.div`
    /* display: flex;
  align-items: center; */
`;

export const Separator = styled.span`
    color: ${props => (props.disabled ? `${colors.mineShaft}60` : `${colors.mineShaft}`)};
    margin: 0 0.3em;
    font-size: 1.2em;
`;

export const CheckboxLabel = styled.label`
    ${optionStyle} cursor: pointer;

    ${({ icon }) =>
        !icon &&
        `
      &:before {
        border-radius: 100%;
        border: 1px solid ${colors.mineShaft};
        content: '';
        cursor: pointer;
        display: inline-block;
        height: 8px;
        margin-right: 10px; 
        position: relative;
        text-align: center;
        transition: all 300ms ease;
        width: 8px;
      }
    `} &:hover {
        ${optionHoverStyle};
    }
`;

export const Checkbox = styled.input`
    ${srOnly}

    &:checked + ${CheckboxLabel}:before {
      background-color: ${colors.black};
      box-shadow: ${boxShadow};
    }
  
    &:hover + ${CheckboxLabel}:before {
      border-color: ${colors.black}
    }
  
    &${focusVisible} + ${CheckboxLabel} {
      ${optionFocusStyle}
    }
  `;

export const Notification = styled.p`
    font-size: 0.9em;
`;

export const FooterSection = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5em;
`;
