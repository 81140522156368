const initialState = {
    playing: false,
    currentFrame: 0,
    prevFrame: false,
    currentTime: 0,
    mute: false,
    volume: 1,
    subtitle: 'Captions off',
    playerReady: false,
    width: 0,
    height: 0,
    hasPlayed: false,
    hasEnded: false,
    animationPlaying: false,
    animationEnded: false,
};

export default initialState;
