import React from 'react';
import WhitelabelBanner from '../whitelabelBanner';

const Whitelabel = () => (
    <WhitelabelBanner
        subText="Create your own interactive video today"
        buttonText="REQUEST A TRIAL"
        onClick={() => window.open('https://www.wirewax.com/contact?ref=embedder')}
    />
);

export default Whitelabel;
