import styled from 'styled-components';

const ScreenReaderOnly = styled.span`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    left: -99999px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -99999px;
    width: 1px;
`;

export default ScreenReaderOnly;
