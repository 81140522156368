import initialState from './dynamic.state';
import { SET_DYNAMIC_DATA, RECEIVE_DYNAMIC_DATA, FAILED_FETCHING_DATA } from './dynamic.constant';

const dynamic = (state = initialState, action) => {
    switch (action.type) {
        case SET_DYNAMIC_DATA: {
            return {
                ...state,
                apis: action.payload.apis,
                overlays: action.payload.overlays,
            };
        }
        case RECEIVE_DYNAMIC_DATA: {
            const apis = { ...state.apis };

            action.payload.forEach(api => {
                const { id, data } = api;

                apis[id] = {
                    ...apis[id],
                    status: 200,
                    data,
                };
            });

            return {
                ...state,
                apis,
            };
        }
        case FAILED_FETCHING_DATA: {
            const { id, error } = action.payload;
            const apis = { ...state.apis };

            if (error.response && apis[id].status !== error.response.status) {
                apis[id] = {
                    ...apis[id],
                    status: error.response ? error.response.status : 0,
                    error,
                };

                return {
                    ...state,
                    apis,
                };
            }

            return { ...state };
        }
        default: {
            return state;
        }
    }
};

export default dynamic;
