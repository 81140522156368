import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import fontFamilies from 'features/overlay/styles/fontFamilies';
import oswaldFont from '../fonts/Oswald';
import poppinsFont from '../fonts/Poppins';
import nunitoFont from '../fonts/Nunito';
import colors from '../colors';

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${fontFamilies};

  ${oswaldFont}
  ${poppinsFont}
  ${nunitoFont}

  html {
    // color: ${colors.white};
    // font-family: Poppins, sans-serif;
    // font-size: 11px;
    // font-weight: 300;
    // user-select: none;
    // -webkit-tap-highlight-color: transparent;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    // overflow: hidden;

    /** from overlay **/
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    strong {
      font-weight: 700;
    }

    em, i {
      font-style: italic;
    }

    .slick-slider {
      width: 100%
    }
    /** End of from overlay **/
  }

  #root {
    color: ${colors.white};
    font-family: Poppins, sans-serif;
    font-size: 11px;
    font-weight: 300;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  div, input, section {
    box-sizing: border-box;
  }

  /** from control **/
  div {
    box-sizing: border-box;

    &:before {
      box-sizing: border-box;
    }

    &:after {
      box-sizing: border-box;
    }
  }
  /** end of from control **/

  button {
    background-color: transparent;
    border: none;
    color: inherit;
    display: block;
    padding: 0;
    font-family: Poppins, sans-serif;

    ${'' /* needed to overwrite user agent font size of 13.3px */}
    font-size: inherit; 
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: 0;
  }

  .rrt-title {
    line-height: 1.3;
  }
`;

export default GlobalStyle;
