import React from 'react';
import { useSelector } from 'react-redux';

import EndFrame from './components/endFrame';
import WhiteLabel from './components/whitelabel';
import IeMessageBanner from './components/messageBanner/ieMessageBanner.ui';

const BusinessSuite = ({ onPlay, onRestart, isPlayingOnFullScreenToggle }) => {
    const isEndFrameShown = useSelector(state => state.options.isEndFrameShown);
    const isWhiteLabelShown = useSelector(state => {
        return state.business.isBannerShown && !state.video.animationPlaying;
    });

    const isIEMessageShown = useSelector(state => {
        const {
            playerParams: { noMessage },
            userAgent: { isIE },
        } = state.options;

        return isIE && !noMessage;
    });

    return (
        <>
            {/* End Frame - Branding Video */}
            {isEndFrameShown && (
                <EndFrame
                    isPlayingOnFullScreenToggle={isPlayingOnFullScreenToggle}
                    restart={onRestart}
                />
            )}

            {/* White Label - Branding Banner */}
            {isWhiteLabelShown && <WhiteLabel className="whitelabel" />}

            {/* Notification - IE no longer support */}
            {isIEMessageShown && <IeMessageBanner play={onPlay} />}
        </>
    );
};

export default BusinessSuite;
