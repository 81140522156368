import axios from 'axios';
import { initHotspotState } from '../../features/hotspots/hotspotsSlice';
import { setDynamicData } from '../dynamic/dynamic.action';
import api from '../api/api.actions';
import {
    VIDEO,
    // END_FRAME,
    TOGGLE_OVERLAY,
    TOGGLE_EMBED_OVERLAY,
    SWITCH_VIDEO,
    END_FRAME_ENDED,
    // END_FRAME_VIDEO,
    SET_VIDEO_QUALITY,
    SET_VIDEO_ID,
    HOTSPOT_IS_CLICKED,
    HOTSPOT_CLICKOUT,
    OVERLAY_EVENT,
    FULL_SCREEN,
    CLOSE_OVERLAY_BOOLEAN,
    FORCE_SPRITE_ID_SHOWN,
    TOGGLE_CONTROLS_VISIBILITY,
    SET_USER_AGENT,
    SET_VIDEO_RENDITIONS,
    SET_OVERLAY_CONTENT,
    SET_ACTION_LINK,
    OVERRIDE_EMBED_LOC,
    SET_PLAYER_PARAMS,
} from './options.constant';
import urls from '../../constants/endpoints';
import { uuId as uid } from '../../utils/uuId';
import Logger from '../../utils/Logger';
import { initBusinessState } from '../../features/businessSuite/businessSuiteSlice';

const get = (id: number, version: number = 0, type: string = '') => async (dispatch: Function) => {
    const video = `${VIDEO}${type ? `_${type}` : ''}`;

    dispatch(api.pending(video));

    const url = urls.video(id);

    Logger.info('options.action', '/GET video data');

    await axios
        .get(url, { params: { version } })
        .then(({ data }) => {
            Logger.info('options.action', 'vidData.json', data);

            const { videoData } = data;

            dispatch(api.fetched(video, videoData));
            dispatch(initHotspotState(videoData));
            dispatch(initBusinessState(videoData));

            if (videoData.dynamic) {
                dispatch(setDynamicData(videoData.dynamic));
            }
        })
        .catch(({ response }) => {
            dispatch(api.rejected(video, response, url));
        });
};

const setVideoId = (id: number, uuId: string = uid()) => ({
    type: SET_VIDEO_ID,
    id,
    uuId,
});

const switchVideo = (video: Object, videoType: string = ''): Object => ({
    type: SWITCH_VIDEO,
    video,
    videoType,
});

const endEndFrame = (): Object => ({
    type: END_FRAME_ENDED,
});

const setVideoQuality = (quality: string): Object => ({
    type: SET_VIDEO_QUALITY,
    quality,
});

const toggleOverlay = (overlay: Object): Object => ({
    type: TOGGLE_OVERLAY,
    overlay,
});

const toggleEmbedOverlay = (open: boolean): Object => ({
    type: TOGGLE_EMBED_OVERLAY,
    open,
});

const hotspotIsClicked = (nameLink: number, spriteId: number): Object => ({
    type: HOTSPOT_IS_CLICKED,
    nameLink,
    spriteId,
});

const hotspotClickout = (nameLink: number, spriteId: number, href: string): object => ({
    type: HOTSPOT_CLICKOUT,
    nameLink,
    spriteId,
    href,
});

const overlayEvent = (overlayId: number, action: string, value: string): Object => ({
    type: OVERLAY_EVENT,
    overlayId,
    action,
    value,
});

const fullScreen = (isFullScreen: boolean) => ({
    type: FULL_SCREEN,
    isFullScreen,
});

const closeOverlayBool = () => ({
    type: CLOSE_OVERLAY_BOOLEAN,
});

export const setForceSpriteIdShown = () => ({
    type: FORCE_SPRITE_ID_SHOWN,
});

const toggleControlsVisibility = (showControls: boolean): Object => ({
    type: TOGGLE_CONTROLS_VISIBILITY,
    showControls,
});

const setVideoRenditions = (videoRenditions: number[]): Object => ({
    type: SET_VIDEO_RENDITIONS,
    videoRenditions,
});

const setUserAgent = (userAgentString: string = navigator.userAgent) => {
    const UA = userAgentString;

    /** Microsoft */
    const isIE = /Trident/.test(UA);
    const isIE11 = !!/Trident.*rv[ :]*11\./.test(UA);
    const isEdge = /Edge/.test(UA);
    const isWindows = /Windows/.test(UA);

    /** Apple */
    const isTouchDevice = navigator.maxTouchPoints > 1;
    const isSafari = /Safari/.test(UA) && !/Chrome/.test(UA) && !/CriOS/.test(UA);
    const safariVersion =
        isSafari && (UA.match(/Version\/(\d\d)/) || UA.match(/Version\/(\d)/)) !== null
            ? parseInt((UA.match(/Version\/(\d\d)/) || UA.match(/Version\/(\d)/))[1], 10)
            : null;
    const isMac = /Macintosh/.test(UA) && !isTouchDevice;
    const isIPad = /iPad/.test(UA) || (/Macintosh/.test(UA) && isTouchDevice);
    const isIPhone = /iPhone/.test(UA) || /iPod/.test(UA);
    const isIOS = /iP(hone|od|ad)/.test(UA) || isIPad;
    const iOSVersion =
        isIOS && UA.match(/OS (\d+)_(\d+)_?(\d+)?/) !== null
            ? parseInt(UA.match(/OS (\d+)_(\d+)_?(\d+)?/)[1], 10)
            : null;

    /** Google */
    const isChromeOnIOS = /CriOS/.test(UA);
    const isChrome = (/Chrome/.test(UA) || isChromeOnIOS) && !/Edge/.test(UA);
    let chromeVersion = null;

    if (isChromeOnIOS) {
        chromeVersion = parseInt(UA.match(/CriOS\/(\d\d)/)[1], 10);
    } else if (isChrome) {
        chromeVersion = parseInt((UA.match(/Chrome\/(\d\d)/) || UA.match(/Chrome\/(\d)/))[1], 10);
    }

    const isAndroid = /Android/.test(UA);

    const isFrameNotAccurate = isIOS && (safariVersion >= 12 || chromeVersion >= 86);

    /** Mozilla */
    const isFirefox = /Firefox/.test(UA);
    const firefoxVersion = isFirefox ? parseInt(UA.match(/Firefox\/(\d\d)/)[1], 10) : null;

    /** In-app */
    const isInstagram = /Instagram/.test(UA);
    const isFB = /FBAN/.test(UA);

    /** General */
    const isMobile =
        isIOS || isIPad || isIPhone || isAndroid || isInstagram || isFB || /Mobile/.test(UA);

    return {
        type: SET_USER_AGENT,
        userAgent: {
            userAgent: UA,
            isIE,
            isIE11,
            isEdge,
            isSafari,
            safariVersion,
            isChrome,
            chromeVersion,
            isFirefox,
            firefoxVersion,
            isIOS,
            iOSVersion,
            isIPad,
            isIPhone,
            isAndroid,
            isWindows,
            isMac,
            isMobile,
            isInstagram,
            isFB,
            isTouchDevice,
            isFrameNotAccurate,
        },
    };
};

const setOverlayContent = (overlayId: Number, id: Number, resolvedData: Object) => ({
    type: SET_OVERLAY_CONTENT,
    payload: { overlayId, id, resolvedData },
});

const setActionLink = (overlayId: Number, resolvedContent: string | string[]) => ({
    type: SET_ACTION_LINK,
    payload: { overlayId, resolvedContent },
});

const overrideEmbedLoc = (embedLoc: string) => ({
    type: OVERRIDE_EMBED_LOC,
    payload: { embedLoc },
});

const setPlayerParams = (playerParams: Object) => ({
    type: SET_PLAYER_PARAMS,
    payload: { playerParams },
});

export default {
    get,
    toggleOverlay,
    switchVideo,
    endEndFrame,
    setVideoQuality,
    setVideoId,
    hotspotIsClicked,
    hotspotClickout,
    overlayEvent,
    fullScreen,
    closeOverlayBool,
    toggleEmbedOverlay,
    setForceSpriteIdShown,
    toggleControlsVisibility,
    setUserAgent,
    setVideoRenditions,
    setOverlayContent,
    setActionLink,
    overrideEmbedLoc,
    setPlayerParams,
};
