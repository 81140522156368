import React, { memo } from 'react';
import IconMuted from '../../assets/icon-muted';
import { UnMuteStyled, Text } from './unMute.styles';
import type { Props } from './unmute.types';

const UnMute = memo(({ unMute }: Props) => (
    <UnMuteStyled onClick={unMute}>
        <Text>TAP TO UNMUTE</Text>
        <IconMuted />
    </UnMuteStyled>
));

UnMute.displayName = 'UnMute';

export default UnMute;
