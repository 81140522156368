import React from 'react';
import colors from '../styles/colors';

const IconEmbed = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill={colors.codGray} fillRule="nonzero" d="M13.418 6.489l-1.008.988L14.983 10l-2.573 2.523 1.008.988L17 10l-3.582-3.511zm-6.836 0L3 10l3.582 3.511 1.008-.989L5.017 10 7.59 7.477 6.582 6.49zM8.184 14.5h1.511l2.018-9H10.2l-2.017 9z"/>
  </svg>
);

export default IconEmbed;
