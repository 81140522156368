import initialState from './playback.state';

type State = any;
type Action = Object;

const playback = (state: State = initialState, action: Action): Object => {
    switch (action.type) {
        case 'REDUX_PLAY': {
            return {
                ...state,
                isPlaying: true,
                isPaused: false,
                isStarted: true,
            };
        }
        case 'REDUX_PAUSE': {
            return {
                ...state,
                isPlaying: false,
                isPaused: true,
            };
        }
        case 'REDUX_SEEKTO': {
            return {
                ...state,
                seekTo: action.payload.to,
                isSeeking: true,
                isSeeked: false,
            };
        }
        case 'REDUX_SEEKED': {
            return {
                ...state,
                seekTo: null,
                isSeeking: false,
                isSeeked: true,
            };
        }
        default:
            return state;
    }
};

export default playback;
