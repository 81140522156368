import React from 'react'

const GoToHotSpotIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 9">
    <g fill="none" fillRule="evenodd" stroke="#1D1D1B" strokeLinecap="round" strokeLinejoin="round" opacity=".5">
      <path d="M12.5 1.5l3 3-3 3M5.5 4.5h10M7 7.623a4 4 0 1 1 0-6.246" />
    </g>
  </svg>

)

export default GoToHotSpotIcon;
