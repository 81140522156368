import styled from 'styled-components';

const StyledPanel = styled.div`
  display: flex;
  justify-content: space-evenly;
  overflow: auto;
  height: calc( 100% - 62px);

  & > div {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: ${props => (props.table ? 'auto' : 'hidden')};
`;

export default StyledPanel;
