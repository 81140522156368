// @flow

import React, { SyntheticEvent } from 'react';
import Draggable from 'react-draggable';
import { DebuggerContainer, DebuggerWrapper, StyledNavBar } from './debugger.styles';
import type { Props } from './debugger.types';
import Tab from './components/tab';
import TabPanel from './components/tabPanel';
import ExitIcon from '../../assets/exit-cross';
import InfoSection from './components/infoSection';
import Table from './components/table';
import Row from './components/row';
import { getTimeFromFrame } from '../../utils/timeFormatConverter';

// custom hooks
const useStateRef = initialValue => {
    const [value, setValue] = React.useState(initialValue);

    const ref = React.useRef(value);

    React.useEffect(
        () => {
            ref.current = value;
        },
        [value]
    );

    return [value, setValue, ref];
};

const Debugger = (props: Props) => {
    const [currentTab, setCurrentTab] = React.useState('Video Info');
    const [toggle, setToggle, toggleRef] = useStateRef(false);

    // Handle Keyboard Shortcut Shift + Ctrl + C
    const handleShortcut = (event: SyntheticEvent<>) => {
        const { shiftKey, ctrlKey, which } = event;

        // NOTE: filter out Non-SyntheticEvents with event.composed check
        if (event.composed && ctrlKey && shiftKey && which === 67) {
            toggleRef.current ? setToggle(false) : setToggle(true);
        }
    };

    // Handle Mouse Click different tabs: add listener on Component Did Mount
    React.useEffect(() => {
        document.addEventListener('keydown', handleShortcut);
    }, []);

    const handleClick = (event: SyntheticEvent<>) => {
        setCurrentTab(event.target.value);
    };

    const {
        hasPlayed,
        id,
        videoQuality,
        frame,
        currentTime,
        activeVideo: {
            clips,
            hotspots,
            interactions,
            overlays,
            subtitles,
            timeTriggers,
            video: { vidName, duration, fps, renditionsArray, tagId },
        },
        play,
        skip,
        toggleOverlay,
        pause,
    } = props;

    // Factor Video Info
    const staticHotspots = hotspots.filter(hotspot => hotspot.isStatic === true);
    const stickyHotspots = hotspots.filter(hotspot => hotspot.isStatic !== true);
    const availableRenditions = renditionsArray.map(rendition => rendition.displayName);
    const subtitleLanguages = subtitles.map(subtitle => subtitle.label || subtitle.lang);

    const videoInfo = {
        ID: id,
        'Video Title': vidName,
        Rendition: videoQuality,
        Duration: duration,
        FPS: fps,
        'Tag ID': tagId,
        'Total Clips': clips.length,
        'Total Static Hotspots': staticHotspots.length,
        'Total Sticky Hotspots': stickyHotspots.length,
        'Subtitle Languages': subtitleLanguages.join(', '),
        'Renditions Available': availableRenditions.join(', '),
    };

    // Factor Playback Info
    const defaultClip = { show: 0, hide: 0, index: 0 };
    const currentClip = clips.find(clip => clip.show <= frame && clip.hide > frame) || defaultClip;
    const { show, hide, index } = currentClip;
    const staticInCurrentClip = hotspots.filter(
        hotspot =>
            hotspot.isStatic &&
            (hotspot.show * fps >= currentClip.show - 1 || // Hotspots within the clip
                hotspot.hide * fps >= currentClip.show)
    ); // Hotspots go over the clip
    const stickyInCurrentClip = hotspots.filter(
        hotspot =>
            !hotspot.isStatic &&
            (hotspot.show >= currentClip.show - 1 || // Hotspots within the clip
                hotspot.hide >= currentClip.show - 1)
    ); // Hotspots go over the clip
    const hotspotsIncurrentClip = staticInCurrentClip.length + stickyInCurrentClip.length;

    const playbackInfo = {
        'Player Frame': frame || 0,
        'PTC Frame': '-', // Not available yet
        'Player Time Stamp': getTimeFromFrame(frame, fps).formattedTime,
        'Player CurrentTime': currentTime ? currentTime.toFixed(2) : 0,
        'Clip Frame': frame - show || 0,
        'Clip Index': index || 0,
        'Clip Start Frame': show || 0,
        'Clip Hide Frame': hide || 0,
        'Clip Duration': ((hide - show) / fps).toFixed(2) || 0,
        'Hotspots in clip': hotspotsIncurrentClip || 0,
    };

    // Add keydown listener for web accessibility, add click listener for test
    return (
        <div role="menuitem" tabIndex="-1" onKeyDown={event => handleShortcut(event)}>
            {toggle && (
                <DebuggerContainer>
                    <Draggable bounds="parent">
                        <DebuggerWrapper>
                            <StyledNavBar>
                                <Tab
                                    label="Video Info"
                                    currentTab={currentTab}
                                    onClick={handleClick}
                                />
                                <Tab
                                    label="Interactive Data"
                                    currentTab={currentTab}
                                    onClick={handleClick}
                                />
                                <Tab
                                    label="exit"
                                    currentTab={currentTab}
                                    onClick={() => setToggle(false)}
                                >
                                    <ExitIcon />
                                </Tab>
                            </StyledNavBar>

                            <TabPanel toggle={currentTab === 'Video Info'}>
                                <InfoSection label="Video Details" info={videoInfo} />
                                <InfoSection label="Playback" info={playbackInfo} />
                            </TabPanel>

                            <TabPanel toggle={currentTab === 'Interactive Data'} table>
                                <div>
                                    <Table>
                                        {hotspots.map(hotspot => {
                                            const { isStatic } = hotspot;

                                            const {
                                                name,
                                                interaction: { clickAction, settings },
                                            } = interactions[hotspot.nameLink];

                                            const { overlayId, url } = settings || {};

                                            const targetOverlay = overlays.find(
                                                overlay => overlay.overlayId === overlayId
                                            );

                                            return (
                                                <Row
                                                    hasPlayed={hasPlayed}
                                                    key={hotspot.spriteId}
                                                    hotspot={hotspot}
                                                    name={name}
                                                    clickAction={clickAction}
                                                    url={url}
                                                    InteractionType={
                                                        isStatic
                                                            ? 'Static hotspot'
                                                            : 'Sticky Hotspot'
                                                    }
                                                    fps={fps}
                                                    overlayId={overlayId}
                                                    targetOverlay={targetOverlay}
                                                    play={play}
                                                    skip={skip}
                                                    toggleOverlay={toggleOverlay}
                                                    pause={pause}
                                                    close={() => setToggle(false)}
                                                />
                                            );
                                        })}
                                        {timeTriggers.map(timeTrigger => {
                                            const { name, overlayId } = timeTrigger;
                                            const targetOverlay = overlays.find(
                                                overlay => overlay.overlayId === overlayId
                                            );

                                            return (
                                                <Row
                                                    hasPlayed={hasPlayed}
                                                    key={name}
                                                    timeTrigger={timeTrigger}
                                                    name={name}
                                                    InteractionType="Time Trigger"
                                                    fps={fps}
                                                    overlayId={overlayId}
                                                    targetOverlay={targetOverlay}
                                                    play={play}
                                                    skip={skip}
                                                    toggleOverlay={toggleOverlay}
                                                    pause={pause}
                                                    close={() => setToggle(false)}
                                                />
                                            );
                                        })}
                                    </Table>
                                </div>
                            </TabPanel>
                        </DebuggerWrapper>
                    </Draggable>
                </DebuggerContainer>
            )}
        </div>
    );
};

export default Debugger;
