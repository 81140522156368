import { PLAY, PAUSE, SEEK, SET_VOLUME } from '../../state/video/video.constant';

export const DB = 'metrics';

export const EVENT_IDS = {
    [PLAY]: 1,
    [PAUSE]: 2,
    [SEEK]: 13,
    [SET_VOLUME]: 7,
};

export const KEYS = {
    AWS_ACCESS_KEY: 'AKIAIFV7VXOGTTGPJZDQ',
    AWS_SECRET_KEY: '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',
};

export const METRICS_INTERVAL_MOBILE = 10000;
export const METRICS_INTERVAL_DESKTOP = 3000;

export const INTERACTION_EVENT_TYPES = {
    clickthrough: 1,
    addToCart: 20,
};

export const QUEUES = {
    EMBEDDER_IMPRESSIONS:
        'https://sqs.us-east-1.amazonaws.com/142936586381/wirewaxEmbedderImpressions',
    IMPRESSIONS: 'https://sqs.us-east-1.amazonaws.com/142936586381/wirewaxImpressions',
    METRICS_TEST: 'https://sqs.us-east-1.amazonaws.com/142936586381/wirewaxMetricsTest',
    SUB_DOCUMENT_TEST: 'https://sqs.us-east-1.amazonaws.com/142936586381/wirewaxSubDocumentTest',
};

export const IMPRESSIONS = 'wirewax-player-impressions';
export const USER = 'wirewax-user';
