import React from 'react';
import Play from '../../assets/play';
import { ButtonWrapper, PlayButton, PlayIconWrapper, ControlText } from './playIcon.styles';
import type { Props } from './playIcon.types';
import useDebounce from '../../hooks/useDebounce';

const PlayIcon = ({ onClick, hideMainPlayButtonIcon }: Props) => {
    const [focus, setFocus] = React.useState(false);
    const [debouncedFocus, setDebouncedFocus] = React.useState(false);

    useDebounce(
        () => {
            setDebouncedFocus(focus);
        },
        80,
        [focus]
    );

    const mouseoverHandler = () => {
        setFocus(true);
    };

    const mouseoutHandler = () => {
        setFocus(false);
    };

    return (
        <ButtonWrapper onClick={onClick}>
            {!hideMainPlayButtonIcon ? (
                <PlayButton
                    className="playButton"
                    aria-label="Play (k)"
                    focus={debouncedFocus}
                    onMouseEnter={mouseoverHandler}
                    onMouseLeave={mouseoutHandler}
                >
                    <PlayIconWrapper>
                        <Play />
                    </PlayIconWrapper>
                    <ControlText aria-live="polite">Play</ControlText>
                </PlayButton>
            ) : null}
        </ButtonWrapper>
    );
};

export default PlayIcon;
