/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import utils from '../../utils';
import { WHITE_LABEL_TYPES } from '../../constants/whiteLabel';

export const businessSlice = createSlice({
    name: 'business',
    initialState: {
        whiteLabel: 0,
        isBannerShown: false,
    },
    reducers: {
        initBusinessState: (state, action) => {
            const {
                video: { whiteLabel },
            } = action.payload;

            const isBannerShown =
                typeof whiteLabel === 'boolean'
                    ? utils.whiteLabelChecker(whiteLabel)
                    : whiteLabel !== WHITE_LABEL_TYPES.NO_BRANDING;

            state.whiteLabel = whiteLabel;
            state.isBannerShown = isBannerShown;
        },
    },
});

export const { initBusinessState } = businessSlice.actions;

export default businessSlice.reducer;
