import { REJECTED } from '../state/api/api.constant';
import { history } from '../store';
import { INCOMPATIBLE_VIDEO } from '../state/checks/checks.constant';
import { player4, error } from '../constants/urls';

const errorHandling = (store: Object) => (next: Function) => (action: Object) => {
    if (action.type === INCOMPATIBLE_VIDEO) {
        window.location.href = `${player4}${store.getState().router.location.pathname}`;

        return '';
    }

    if (action.type.includes(`_${REJECTED}`)) {
        history.push(error);
    }

    return next(action);
};

export default errorHandling;
