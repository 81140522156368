import { connect } from 'react-redux';
import App from './app.ui';
import options from '../state/options/options.actions';

const mapStateToProps = (state: Object) => ({
    isImage: state.options.isImage,
    customParams: state.options.playerParams.customParams, // Used by DCO
    activeOverlay: state.options.activeOverlay,
});

const mapDispatchToProps = (dispatch: any): Object => ({
    toggleOverlay: overlay => dispatch(options.toggleOverlay(overlay)),
    hotspotIsClicked: (nameLink, spriteId) =>
        dispatch(options.hotspotIsClicked(nameLink, spriteId)),
    hotspotClickout: (nameLink: number, spriteId: number, href: string) =>
        dispatch(options.hotspotClickout(nameLink, spriteId, href)),
    overlayEvent: (overlayId, action, value) =>
        dispatch(options.overlayEvent(overlayId, action, value)),
    setVideoRenditions: (renditions: number[]) => dispatch(options.setVideoRenditions(renditions)), // FIXME: Stephanop did this for handling error, which is not ideal, it's creating two sources for renditions: https://github.com/wireWAX/wwPlayer/blob/0e678330cf42a835a06228b10435bc0fda674653/src/video/players/defaultPlayer/defaultPlayer.js#L77
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
