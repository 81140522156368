const reduxPlay = (): Object => ({
    type: 'REDUX_PLAY',
});

const reduxPause = (): Object => ({
    type: 'REDUX_PAUSE',
});

const reduxSeekTo = (to: number): Object => ({
    type: 'REDUX_SEEKTO',
    payload: { to },
});

const reduxSeeked = (): Object => ({
    type: 'REDUX_SEEKED',
});

export default {
    reduxPlay,
    reduxPause,
    reduxSeekTo,
    reduxSeeked,
};
