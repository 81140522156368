import React, { useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import MessageBanner from '.';
import CommonButton from '../../../../component/common/commonButton';
import { TextWrapper, Spacer } from './messageBanner.styles';

const IeMessageBanner = ({ play, hasPlayed, whitelabel }) => {
    const [show, setShow] = useState(true);

    useLayoutEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 10000);
    }, []);

    return (
        <MessageBanner show={show} whitelabel={whitelabel}>
            <TextWrapper>
                For best interactive experience, please switch to a supported browser.
            </TextWrapper>
            <Spacer width={20} flexGrow={1} />
            <CommonButton
                width="300px"
                mediaWidthMd="150px"
                mediaWidthLg="300px"
                fontSize="2em"
                mobileFont="4em"
                border="2px black solid"
                onClick={() => {
                    setShow(false);
                    if (!hasPlayed) {
                        play();
                    }
                }}
            >
                CONTINUE
            </CommonButton>
            <Spacer width={10} />
            <CommonButton
                width="300px"
                mediaWidthMd="150px"
                mediaWidthLg="300px"
                fontSize="2em"
                mediumFont="10px"
                mobileFont="4em"
                border="2px black solid"
                onClick={() =>
                    window.open(
                        'https://wirewax.helpdocs.io/article/lth6fd307e-do-wirewax-videos-work-in-all-browsers?ref=embedder'
                    )
                }
            >
                SUPPORTED BROWSERS
            </CommonButton>
        </MessageBanner>
    );
};

const mapStateToProps = (state: Object) => ({
    hasPlayed: state.video.hasPlayed,
    whitelabel: state.options.activeVideo.video.whiteLabel,
});

export default connect(mapStateToProps)(IeMessageBanner);
