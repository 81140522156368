export const PLAY = 'PLAY';
export const PAUSE = 'PAUSE';
export const SET_CURRENT_FRAME = 'SET_CURRENT_FRAME';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const SET_VOLUME = 'SET_VOLUME';
export const SELECT_SUBTITLE = 'SELECT_SUBTITLE';
export const PLAYER_READY = 'PLAYER_READY';
export const SET_DIMENSION = 'SET_DIMENSION';
export const SEEK = 'SEEK';
export const END_FRAME_ANIMATION_PLAYING = 'END_FRAME_ANIMATION_PLAYING';
export const END_FRAME_ANIMATION_ENDED = 'END_FRAME_ANIMATION_ENDED';
export const SET_MOUSE_POSITION = 'SET_MOUSE_POSITION';
