import { PLAY, PAUSE, PLAYER_READY, SEEK, SET_VOLUME } from '../../state/video/video.constant';
import {
    END_FRAME_ENDED,
    HOTSPOT_IS_CLICKED,
    HOTSPOT_CLICKOUT,
    TOGGLE_OVERLAY,
    OVERLAY_EVENT,
} from '../../state/options/options.constant';
import {
    embedderImpression,
    impression,
    handshake,
    hotspotIsClicked,
    hotspotClickout,
    metricBody,
    overlayEvent,
    overlayIsClosed,
    overlayIsOpened,
    playerActive,
    playerInactive,
    videoHasEnded,
    getUserUuId,
} from './metrics.utils';
import { EVENT_IDS, QUEUES } from './metrics.constant';
import { send, sendUnauthenticated } from './metrics.sqs';
import utils from '../../utils';

const metrics = (store: Object) => (next: Function) => (action: Object) => {
    if (!utils.canAccessLocalStorage()) return next(action);

    const defaultEmbedLoc =
        window.location !== window.parent.location ? document.referrer : document.location.href;
    const { currentFrame, currentTime, mousePosition } = store.getState().video;
    const { activeOverlay, overrideEmbedLoc = defaultEmbedLoc } = store.getState().options;
    const { uuId, id, userAgent: { isMobile } = {} } = store.getState().options;

    const actionName = action.type.toLowerCase();
    let nameLink;
    let spriteId;

    if (activeOverlay && activeOverlay.hotspotData) {
        ({ nameLink } = activeOverlay.hotspotData);
        ({ spriteId } = activeOverlay.hotspotData);
    }

    switch (action.type) {
        case PLAY:
            playerActive(uuId, id, currentFrame, currentTime, mousePosition, isMobile);
            handshake(uuId, id, getUserUuId(), overrideEmbedLoc);
            send(
                metricBody(actionName, EVENT_IDS[action.type], currentFrame, currentTime, uuId, id),
                QUEUES.SUB_DOCUMENT_TEST
            );
            break;
        case PAUSE:
            playerInactive();
            send(
                metricBody(actionName, EVENT_IDS[action.type], currentFrame, currentTime, uuId, id),
                QUEUES.SUB_DOCUMENT_TEST
            );
            break;
        case PLAYER_READY:
            sendUnauthenticated(
                impression(uuId, id, getUserUuId(), overrideEmbedLoc),
                QUEUES.IMPRESSIONS
            );
            sendUnauthenticated(
                embedderImpression(uuId, id, getUserUuId(), overrideEmbedLoc),
                QUEUES.EMBEDDER_IMPRESSIONS
            );
            break;
        case SEEK:
            send(
                metricBody(
                    actionName,
                    EVENT_IDS[action.type],
                    currentFrame,
                    currentTime,
                    uuId,
                    id,
                    { result: `${currentTime.toFixed(2)},${action.time.toFixed(2)}` }
                ),
                QUEUES.SUB_DOCUMENT_TEST
            );
            break;
        case TOGGLE_OVERLAY:
            if (Object.entries(activeOverlay).length === 0) {
                overlayIsOpened();
            } else {
                send(
                    overlayIsClosed(nameLink, spriteId, currentTime, uuId, id),
                    QUEUES.SUB_DOCUMENT_TEST
                );
            }
            break;
        case OVERLAY_EVENT:
            const recordedEvents = ['clickthrough', 'addToCart'];

            if (recordedEvents.indexOf(action.action) === -1) {
                break;
            }

            send(
                overlayEvent(
                    action.overlayId,
                    action.action,
                    action.value,
                    nameLink,
                    spriteId,
                    uuId,
                    id,
                    currentTime
                ),
                QUEUES.SUB_DOCUMENT_TEST
            );
            break;
        case HOTSPOT_IS_CLICKED:
            send(
                hotspotIsClicked(isMobile, action.nameLink, action.spriteId, currentTime, uuId, id),
                QUEUES.SUB_DOCUMENT_TEST
            );
            break;
        case HOTSPOT_CLICKOUT:
            send(
                hotspotClickout(
                    action.overlayId,
                    action.action,
                    action.href,
                    action.nameLink,
                    action.spriteId,
                    uuId,
                    id,
                    currentTime
                ),
                QUEUES.SUB_DOCUMENT_TEST
            );
            break;
        case END_FRAME_ENDED:
            playerInactive();
            send(videoHasEnded(id), QUEUES.SUB_DOCUMENT_TEST);
            break;
        case SET_VOLUME:
            send(
                metricBody(
                    'adjustVolume', // To match old player metrics
                    EVENT_IDS[action.type],
                    currentFrame,
                    currentTime,
                    uuId,
                    id
                ),
                QUEUES.SUB_DOCUMENT_TEST
            );
            break;
        default:
    }

    return next(action);
};

export default metrics;
