import _ from 'lodash';
import { SKIN_BAREBONES_SLICK, THIRD_PARTY_ACCESS_PARAMS } from './loadOptions.constant';
import Logger from '../../utils/Logger';

/* eslint-disable import/prefer-default-export */
const getUrlParams = (search: string) => {
    if (search.length <= 0) {
        return {};
    }

    let query = new URLSearchParams(search);

    query = JSON.parse(`{
        "${query
            .toString()
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')}"
    }`);

    Object.keys(query).forEach(item => {
        if (query[item] === 'true') {
            query[item] = true;
        } else if (query[item] === 'false') {
            query[item] = false;
        }
    });

    return query;
};

const getEmbedOptions = (query: Object) => {
    const autoplay = query.autoPlay || query.autoStart || false;

    return {
        autoplay,
        hideAudioIcon: Boolean(autoplay) && Boolean(query.hideAudioIcon),
        embedLoc: query.embedLoc || null,
        spacebarKeyBindingOnly: query.skin ? query.skin === SKIN_BAREBONES_SLICK : false,
        noControls: (query.skin && query.skin === SKIN_BAREBONES_SLICK) || false,
        loop: query.loop || false,
        showSubtitles: query.showSubtitles || false,
        timeStamp: parseInt(query.timeStamp, 10) || 0,
        transparent: query.transparent || false,
        forceRendition: query.forceRendition || false,
        forceVisible: query.forceVisible || false,
        fullBleed: query.fullBleed || false,
        muted: query.muted || false,
        clearLoaders: query.clearLoaders || false,
        disableMobileFullScreen:
            query.disableMobileFullScreen === false ? false : query.disableMobileFullScreen || null,
        noCustomisations: query.noCustomisations || false,
        noShare: query.noShare === false ? false : query.noShare || null,
        customPlayer: query.player || false,
        customPlayerVidId: query.v || null,
        triggerSpriteId: query.spriteId || null,
        lang: query.lang || null,
        noMarkers: query.noMarkers === false ? false : query.noMarkers || null,
        customParams: query.customParams
            ? decodeURIComponent(query.customParams)
                  .replace(/\[|\]|/g, '')
                  .split(',')
            : [],
        debug: query.debug || false,
        noMessage: query.noMessage || false,
        overlayedVideo: query.overlayedvideo || false, // set in wwOverlay. Indicator that current instance is in overlay
        mainVideoMuted: query.mainVideoMuted || false, // set in wwOverlay. Current instance is in overlay and main video is muted
        preloadFonts: query.preloadFonts || false,
    };
};

const getPlayerConfiguration = (playerConfiguration: Object) => {
    return {
        hideAudioControls: playerConfiguration.hideAudioControls || false,
        hideClipControls: playerConfiguration.hideClipControls || false,
        hideFullscreenControls: playerConfiguration.hideFullscreenControls || false,
        hideHotspotDots: playerConfiguration.hideHotspotDots || false,
        hidePauseControls: playerConfiguration.hidePauseControls || false,
        hideRenditionControls: playerConfiguration.hideRenditionControls || false,
        hideScrubber: playerConfiguration.hideScrubber || false,
        hideShareControls: playerConfiguration.hideShareControls || false,
        mobileOrientation: playerConfiguration.mobileOrientation || false,
        preventKeyboardInput: playerConfiguration.preventKeyboardInput || false,
        preventPauseOnClick: playerConfiguration.preventPauseOnClick || false,
        hideMainPlayButton: playerConfiguration.hideMainPlayButton || false,
        hideSubtitleControls: playerConfiguration.hideSubtitleControls || false,
    };
};

const getThirdPartyPlayerOptionsFromVidData = (player: String, tpPlayersData: Array<Object>) => {
    return _.find(tpPlayersData, ['tpPlayer', player]);
};

const getThirdPartyPlayerKey = player => {
    const thirdPartyPlayerKey = _.findKey(THIRD_PARTY_ACCESS_PARAMS, item => item.includes(player));

    return thirdPartyPlayerKey;
};

const getThirdPartyPlayerOptions = (player: string, tpPlayersData: Array<Object>): Object => {
    if (_.isEmpty(player)) return {};

    const thirdPartyPlayerKey = getThirdPartyPlayerKey(player);

    if (_.isUndefined(thirdPartyPlayerKey)) {
        Logger.info(
            `WIREWAX :: ${player} param is not supported yet, please contact WIREWAX customer success team for further details`
        );

        return {};
    }

    const thirdPartyPlayerOptions = getThirdPartyPlayerOptionsFromVidData(
        thirdPartyPlayerKey,
        tpPlayersData
    );

    if (_.isUndefined(thirdPartyPlayerOptions)) {
        Logger.info('WIREWAX :: no valid third party player data in vidData.json');

        return {};
    }

    return thirdPartyPlayerOptions;
};

const excludeEmbed = (shareList, shouldExclude) =>
    shouldExclude ? shareList.filter(shareOption => shareOption.name !== 'Embed') : shareList;

export {
    getPlayerConfiguration,
    getUrlParams,
    getEmbedOptions,
    getThirdPartyPlayerOptions,
    excludeEmbed,
};
