import styled, { keyframes } from 'styled-components';
import breakpoints from '../../../../../styles/breakpoints';
import colors from '../../../../../styles/colors';

const easeOutCubic = 'cubic-bezier(0.215, 0.61, 0.355, 1)';

export const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const animateLogoBounce = keyframes`
  0% { transform: scale(1.4); }
  20% { transform: scale(1.80); }
  100% { transform: scale(1); }
`;

export const animateBox = keyframes`
  0% { transform: scale(0); }
  40% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

export const animateText = keyframes`
  0% {
    transform: translateY(-300%);
    font-size: 1.2em;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`;

export const AnimationWrapper = styled.div`
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
    background: ${colors.primary};
    left: ${({ width }) => (width ? 'auto' : '0')};
    top: ${({ height }) => (height ? 'auto' : '0')};
    width: ${({ width }) => `${width}px` || '100vw'};
    height: ${({ height }) => `${height}px` || '100vh'};
    display: ${({ display }) => display || 'flex'};
    margin: 0 auto;
`;

export const LogoWrapper = styled.div`
    margin-top: 5%;
    width: 100%;
    height: 60%;
    top: 100px;
    display: flex;
    justify-content: center;
    animation: ${animateLogoBounce} 1s ${easeOutCubic}, ${fadeIn} 0.2s ease-in;
    will-change: transform;

    ${breakpoints.maxSm} {
        margin-top: 0;
        top: 0;
    }

    .wirewax-tagline-logo {
        height: 100%;
        width: 100%;

        ${breakpoints.maxSm} {
            height: 90%;
            width: 70%;
        }
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;

    ${breakpoints.maxSm} {
      height: 72px;
      width: 86%;
      align-self: center;
  }

  .animated-button {
    animation: ${animateBox} 0.5s ${easeOutCubic} 0.25s both;
    will-change: transform;
  }

  .animated-text {
    opacity: 0;
    animation: ${animateText} 0.5s ${easeOutCubic} 0.55s both;
    will-change: transform;
    will-change: opacity;
  }
}`;

export const BreakerComponent = styled.div`
    width: 30px;
    height: 30px;
`;
