export const shareUrl = encodeURI('https://share.wirewax.com/')

export const shareWindow = {
    width: 626,
    height: 436,
};

export const shareUrls = {
    Facebook: 'https://www.facebook.com/sharer.php?u=',
    Twitter: 'https://twitter.com/intent/tweet?url=',
};
