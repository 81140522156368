import React from 'react';
import colors from '../constants/colors';

const EyeOpen = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12">
    <g fill="none" fillRule="evenodd" opacity=".25" transform="translate(0 1)">
      <path stroke={colors.white} strokeLinecap="round" strokeLinejoin="round" d="M14 2.732C15.23 3.9 16 5 16 5s-3.5 5-7.5 5a5.35 5.35 0 0 1-1.504-.225M4.775 8.725C2.548 7.243 1 5 1 5s3.5-5 7.5-5c1.326 0 2.605.553 3.712 1.288"/>
      <circle cx="8.5" cy="5" r="2" stroke={colors.white} />
    </g>
  </svg>
);

export default EyeOpen;
