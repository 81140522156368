import styled from 'styled-components';
import breakpoints from '../../../../styles/breakpoints';
import colors from '../../../../styles/colors';

export const StyledBannerWrapper = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: ${props => (props.show ? 'flex' : 'none')};
    overflow: hidden;
    position: absolute;
    justify-content: space-between;
    transition: all 300ms ease-in-out;
    background-color: ${props => colors[props.color]};
    z-index: 3;

    @media only screen and (min-width: 1500px) {
        height: 100px;
    }

    ${breakpoints.maxMd} {
        height: 60px;
    }

    ${breakpoints.maxSm} {
        display: none;
    }

    .wirewax-name-logo {
        height: 100%;
        width: 100%;
    }
`;

export const LogoWrapper = styled.div`
    width: 12%;
    height: 100%;
    cursor: pointer;

    ${breakpoints.maxMd} {
        width: 18%;
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-right: 2%;
    align-items: center;
    flex-grow: 2;
    justify-content: space-between;
`;

export const TextWrapper = styled.p`
    font-size: 20px;
    font-style: normal;
    text-align: left;
    font-weight: normal;
    line-height: 1.2;
    font-family: Poppins;
    font-stretch: normal;
    letter-spacing: normal;
    color: ${colors.heavyMetal};

    ${breakpoints.maxXl} {
        font-size: 20px;
    }

    ${breakpoints.maxLg} {
        width: 400px;
        font-size: 16px;
    }

    ${breakpoints.maxMd} {
        font-size: 13px;
    }
`;

export const Spacer = styled.div`
    width: ${props => `${props.width}px`};
    flex-grow: ${props => props.flexGrow || 0};
`;
