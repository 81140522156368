import { connect } from 'react-redux';
import options from '../../state/options/options.actions';
import videoActions from '../../state/video/video.actions';
import Debugger from './debugger.ui';

const mapStateToProps = (state: Object) => ({
    id: state.options.id,
    videoQuality: state.options.videoQuality,
    activeOverlay: state.options.activeOverlay,
    activeVideo: state.options.activeVideo,
    hasPlayed: state.video.hasPlayed,
    frame: state.video.currentFrame,
    currentTime: state.video.currentTime,
});

const mapDispatchToProps = (dispatch: any): Object => ({
    toggleOverlay: overlay => dispatch(options.toggleOverlay(overlay)),
    currentFrame: (frame: number, time: number) =>
        dispatch(videoActions.setCurrentFrame(frame, time)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Debugger);
