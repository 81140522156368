import React from 'react';
import colors from '../constants/colors';

const ClearIcon = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 21 22">
    <g fill="none" fillRule="evenodd" stroke={colors.white} strokeLinecap="round" strokeWidth="3">
      <path d="M1.5 2l18 17.5M19.5 2L2 20" />
    </g>
  </svg>
)

export default ClearIcon;
