import React from 'react';
import { StyledTable } from '../debugger.styles';

const Table = ({ children }: { children: any }) => (
    <StyledTable>
        <thead>
            <tr>
                <th title="Group name of the hotspot">Group Name</th>
                <th title="Interaction type">Type</th>
                <th title="Action type">Action</th>
                <th title="ID of overlay that's associated with the hotspot">Overlay ID</th>
                <th title="ID of hotspot instance that's encoded in database">Sprite ID</th>
                <th title="Hotspots with &quot;Go to link&quot; action will display a click out URL">
                    Click Out URL
                </th>
                <th title="Interaction start time stamp, hours:mins:seconds:frames">Time In</th>
                <th title="Interaction end time stamp, hours:mins:seconds:frames">Time out</th>
                <th>Jump To</th>
            </tr>
        </thead>
        <tbody>{children}</tbody>
    </StyledTable>
);

export default Table;
