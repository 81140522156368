import { connect } from 'react-redux';
import EventManager from './eventManager.ui';
import options from '../../state/options/options.actions';

const mapStateToProps = (state: Object) => ({
    embedOverlay: state.options.embedOverlay,
    hotspots: state.options.activeVideo.hotspots,
    interactions: state.options.activeVideo.interactions,
    overlays: state.options.activeVideo.overlays,
    currentTime: state.video.currentTime,
    playing: state.video.playing,
    hasPlayed: state.video.hasPlayed,
    volume: state.video.volume,
    video: state.options.activeVideo.video,
    activeOverlay: state.options.activeOverlay,
    id: state.options.id,
    uuId: state.options.uuId,
    videoQuality: state.options.videoQuality,
    autoplay: state.options.playerParams.autoplay,
});

const mapDispatchToProps = (dispatch: any): Object => ({
    toggleOverlay: overlay => dispatch(options.toggleOverlay(overlay)),
    hotspotIsClicked: (nameLink, spriteId) =>
        dispatch(options.hotspotIsClicked(nameLink, spriteId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventManager);
