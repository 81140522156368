import { END_FRAME } from '../state/options/options.constant';
import utils from './index';

const getResolutionArray = (renditionsArray: Array<Object> = []) => {
    return [...new Set(renditionsArray.map(x => x.h).filter(x => x))];
};

const getDefaultImageSource = (renditionsArray: Array<Object> = []) => {
    return renditionsArray && renditionsArray.length
        ? renditionsArray.filter(x => x.h === [...renditionsArray].pop().h)[0].src
        : '';
};

const shouldActivateEndframe = (check, videoType = '') =>
    videoType === END_FRAME && utils.whiteLabelChecker(check);

export { getResolutionArray, getDefaultImageSource, shouldActivateEndframe };
