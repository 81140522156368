import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import metrics from './metrics/metrics';
import quizResults from './quizResultsManager/quizResultsManager'
import errorHandling from './errorHandling';
import { history } from '../store';
import eventDispatcher from './eventDispatchManager/eventDispatchManager';

const middleware = applyMiddleware(
    promise(),
    thunk,
    metrics,
    // quizResults,
    eventDispatcher,
    errorHandling,
    routerMiddleware(history)
);

export default (process.env.NODE_ENV === 'development'
    ? composeWithDevTools(middleware)
    : middleware);
