const uuId = (): string => {
    let randFunction = Math.random;

    if (window.crypto && window.crypto.getRandomValues) {
        randFunction = () => {
            const array = new Uint32Array(1);

            window.crypto.getRandomValues(array);

            return array[0] / 4294967295;
        };
    }

    const dec2hex = [];

    for (let i = 0; i <= 15; i += 1) {
        dec2hex[i] = i.toString(16);
    }

    return (() => {
        let uuid = '';

        for (let i = 1; i <= 36; i += 1) {
            if (i === 9 || i === 14 || i === 19 || i === 24) {
                uuid += '-';
            } else if (i === 15) {
                uuid += 4;
            } else if (i === 20) {
                uuid += dec2hex[(randFunction() * 4 | 8)];
            } else {
                uuid += dec2hex[(randFunction() * 15 | 0)];
            }
        }

        return uuid;
    })();
};

export { uuId };
