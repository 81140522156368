import styled from 'styled-components';

export const StyledDiv = styled.div`
    bottom: 0;
    left: 0;
    margin: 0;
    max-width: 100%;
    right: 0;
    padding: 0;
    position: absolute;
    top: 0;
`;
