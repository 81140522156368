import styled from 'styled-components';
import colors from '../constants/colors';
import transitions from '../constants/transitions';

const FocusCircle = styled.circle.attrs({
  cx: 19,
  cy: 19,
  focusable: false,
  r: 17,
  stroke: colors.white,
  strokeWidth: 1,
})`
  opacity: 0;
  transition: ${transitions.focus};
`;

export default FocusCircle;
