import React from 'react'

const ExitIcon = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15">
    <g fill="none" fillRule="evenodd" stroke="#1C1C1C" strokeLinecap="square">
      <path d="M14.5.5l-14 14M14.5 14.5L.5.5" />
    </g>
  </svg>
)

export default ExitIcon;
