// Events to post to parent window
const IFRAME_LISTENERS = {
    PLAYER_READY: 'playerReady',
    HAS_PLAYED: 'hasPlayed',
    HAS_PAUSED: 'hasPaused',
    HAS_SEEKED: 'hasSeeked',
    ADD_TO_CART: 'addToCart',
    OVERLAY_EVENT: 'overlayEvent',
    VIDEO_END: 'videoEnd',
    VOLUME_CHANGE: 'volumeChange',
    TAG_CLICK: 'tagClick',
    RETURN_CURRENT_TIME: 'returnCurrentTime',
    CLIENT_CUSTOM_EVENT: 'clientCustomEvent', // REVIEW: Deprecated? This is CS only
    WIDGET_SHOWN: 'widgetShown',
    WIDGET_CLOSED: 'widgetClosed',
    RENDITION_CHANGED: 'renditionChanged',
    SCORM_EVENT: 'scormEvent', // REVIEW: Deprecated? This is CS only
};

// Events from parent window
const IFRAME_TRIGGERS = {
    PLAY: 'videoPlay',
    PAUSE: 'videoPause',
    SEEK: 'videoSeek',
    IS_PLAYER_READY: 'isPlayerReady',
    GET_CURRENT_TIME: 'getCurrentTime',
    GO_TO_TAG: 'goToTag',
    OPEN_TAG: 'openTag',
    CHANGE_VOLUME: 'changeVolume',
    MUTE_VOLUME: 'muteVolume',
    UNMUTE_VOLUME: 'unMuteVolume',
    CLOSE_WIDGET: 'closeWidget',
    DEVICE_ORIENTATION: 'deviceOrientation', // TODO: When mobile detection is ready
    DEVICE_MOTION: 'deviceMotion', // TODO: When mobile detection is ready
    DEVICE_SCREEN_ORIENTATION: 'deviceScreenOrientation', // TODO: When mobile detection is ready
    CLIENT_CUSTOM_TRIGGER: 'clientCustomTrigger', // REVIEW: Deprecated? This is CS only
    CHANGE_RENDITION: 'changeRendition',
    ENTER_FULLSCREEN: 'enterFullscreen',
};

export { IFRAME_LISTENERS, IFRAME_TRIGGERS };
