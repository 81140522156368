const embedToggles = [
    {
        value: 'scaleResponsively',
        label: 'Scale video responsively',
        hideIfImage: true,
    },
    {
        value: 'loop',
        label: 'Loop the video continuously',
        hideIfImage: true,
    },
    {
        value: 'shareButtons',
        label: 'Hide the share button',
        hideIfImage: false,
    },
    {
        value: 'videoControls',
        label: 'Hide the controls',
        hideIfImage: false,
    },
    {
        value: 'autoPlay',
        label: 'Play automatically when possible',
        hideIfImage: true,
    },
    {
        value: 'yp',
        label: 'Youtube Hosted',
        hideIfImage: true,
    },
    {
        value: 'vimeoPlayer',
        label: 'Vimeo Hosted',
        hideIfImage: true,
    },
];

export default embedToggles;
