import IconFacebook from './assets/icon-facebook';
import IconTwitter from './assets/icon-twitter';
import IconEmbed from './assets/icon-embed';

import { shareUrl, shareWindow, shareUrls } from './constants';

export const handleShareClick = (item: string, id: number): void => {
    const { width, height } = shareWindow;
    const url = `${shareUrls[item]}${shareUrl}${id}`;

    window.open(url, '', `width=${width},height=${height}`);
};

export const socialSharingList = [
    {
        icon: IconEmbed,
        name: 'Embed',
        label: 'Open Embed Settings',
    },
    {
        icon: IconFacebook,
        name: 'Facebook',
        label: 'Share to Facebook',
    },
    {
        icon: IconTwitter,
        name: 'Twitter',
        label: 'Share to Twitter',
    },
];
