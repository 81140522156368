import React, { PureComponent } from 'react';
import type { Node } from 'react';
import debounce from '../../utils/debounce';
import { isFullScreen as isWrapperFullScreen } from '../../utils/domEvents';
import type { Props, State } from './screenWrapper.types';
import { FullScreenWrapper, Wrapper } from './screenWrapper.styles';

const BASE_FONT_SIZE = 13.5;
const BASE_SCREEN_WIDTH = 1920;

// BASE_SCREEN_WIDTH_VERTICAL is double the width of iphone X width (375px)
// Our default hotspot text is set to 2em, means double the GLOBAL font-size
// In iphone X dimension, we expect to keep default hotspot font-size to be BASE_FONT_SIZE
const BASE_SCREEN_WIDTH_VERTICAL = 750;

class ScreenWrapper extends PureComponent<Props, State> {
    state = { fontSize: 13.5 };

    componentDidMount = () => {
        this.setPlayerDimensions();

        window.addEventListener('resize', debounce(this.setPlayerDimensions, 16));
        window.addEventListener('mousemove', debounce(this.setMousePosition, 10));
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', () => this.setPlayerDimensions());
        window.removeEventListener('mousemove', () => this.setMousePosition());
    };

    setPlayerDimensions = () => {
        const { fullScreen, isFullScreen, fullBleed } = this.props;

        if (isFullScreen !== isWrapperFullScreen(this.fullScreenWrapper)) {
            fullScreen(isWrapperFullScreen(this.fullScreenWrapper));
        }

        const { aspectRatio, setDimension } = this.props;
        const videoRatio = aspectRatio ? 1 / aspectRatio : 9 / 16;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        const videoWidth = windowHeight / videoRatio;
        const videoHeight = windowWidth * videoRatio;

        let dimension = {};

        if (fullBleed) {
            if (videoWidth < windowWidth) {
                dimension = {
                    height: videoHeight,
                    width: windowWidth,
                };
            } else {
                dimension = {
                    height: windowHeight,
                    width: videoWidth,
                };
            }
        } else if (windowWidth * videoRatio < windowHeight) {
            dimension = {
                height: windowWidth * videoRatio,
                width: windowWidth,
            };
        } else {
            dimension = {
                height: windowHeight,
                width: windowHeight / videoRatio,
            };
        }

        this.setFontSize(dimension);
        setDimension(dimension);
    };

    setMousePosition = (e: any) => {
        // FIXME: react version or flow version didn't have SyntheticEvent annotation
        const { setMousePosition, playerWidth, playerHeight } = this.props;
        const { clientX, clientY } = e;
        const x = ((e.clientX - (window.innerWidth - playerWidth) / 2) / playerWidth).toFixed(3);
        const y = ((e.clientY - (window.innerHeight - playerHeight) / 2) / playerHeight).toFixed(3);

        // eslint-disable-next-line object-curly-newline
        setMousePosition({ clientX, clientY, x, y });
    };

    setFontSize = (dimension: Object) => {
        const { aspectRatio } = this.props;

        // aspectRatio = width / height
        // > 1 : landscape video
        // < 1 : vertical video
        const baseWidth = aspectRatio > 1 ? BASE_SCREEN_WIDTH : BASE_SCREEN_WIDTH_VERTICAL;
        const fontSize = BASE_FONT_SIZE * (dimension.width / baseWidth);

        this.setState({ fontSize });
    };

    fullScreenWrapper: Node;

    innerWrapper: Node;

    render() {
        const { children, fullBleed, playerWidth, playerHeight } = this.props;

        const { fontSize } = this.state;

        return (
            <FullScreenWrapper
                ref={el => {
                    this.fullScreenWrapper = el;
                }}
                fullBleed={fullBleed}
            >
                <Wrapper
                    fullBleed={fullBleed}
                    playerHeight={playerHeight}
                    playerWidth={playerWidth}
                    ref={el => {
                        this.innerWrapper = el;
                    }}
                    tabIndex={-1}
                    wrapperFontSize={fontSize}
                >
                    {children}
                </Wrapper>
            </FullScreenWrapper>
        );
    }
}

export default ScreenWrapper;
