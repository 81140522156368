// @flow
import { DependencyList, useEffect } from 'react';
import useTimeout from './useTimeout';

/**
 * Returns a stateful value, a function to cancel debounce
 */

const useDebounce = (
    callback: Function,
    ms: number = 0,
    deps: DependencyList = []
): [boolean | null, Function] => {
    const [isReady, cancel, reset] = useTimeout(callback, ms);

    // reset timer onUpdate
    useEffect(reset, deps);

    return [isReady, cancel];
};

export default useDebounce;
