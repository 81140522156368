import React from "react";
import colors from "../styles/colors";
import FocusCircle from "./focus-circle";

const IconMuted = () => (
  <svg
    focusable={false}
    xmlns="http://www.w3.org/2000/svg"
    width="4em"
    height="4em"
    viewBox="0 0 40 40"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <g fill={colors.white} fillRule="nonzero">
        <path d="M17.542 9.349l-6.129 4.794h-3C7.63 14.143 7 14.784 7 15.58v6.77c0 .794.63 1.435 1.412 1.435h3.001l6.154 4.795c1.084.846 2.623.077 2.623-1.333v-16.59c0-1.359-1.564-2.154-2.648-1.307zM23.467 20.136l2.044-2.045-2.056-2.056 1.568-1.568 2.056 2.056 2.045-2.044 1.613 1.613-2.045 2.044 2.056 2.056-1.568 1.568-2.056-2.056-2.044 2.045z" />
      </g>
      <FocusCircle />
    </g>
  </svg>
);

export default IconMuted;
