import FONTS from '../constants/fonts';

const returnGoogleFontImport = fontSlug => {
    const googleFontApi = 'https://fonts.googleapis.com/css?family=';
    const customFontTypes = '400,400i,700,700i';

    return `@import url(${googleFontApi}${fontSlug}:${customFontTypes});`;
};

const returnFontFace = (fontName, slug, fontFaceStyles = false) => {
    const fontFaceStylesArray = fontFaceStyles
        ? fontFaceStyles
              .map(fontFaceStyle => `${[fontFaceStyle.name]}: ${fontFaceStyle.value};`)
              .join('')
        : '';

    if (!slug) return '';

    return `
        @font-face {
            font-display: block;
            font-family: ${fontName};
            src: local(${fontName}),
                url(https://edge-assets.wirewax.com/creativeData/fonts/${slug}.woff?d=${window.location.hostname}) format("woff"),
                url(https://edge-assets.wirewax.com/creativeData/fonts/${slug}.otf?d=${window.location.hostname}) format("opentype"),
                url(https://edge-assets.wirewax.com/creativeData/fonts/${slug}.ttf?d=${window.location.hostname}) format("truetype"),
                url(https://edge-assets.wirewax.com/creativeData/fonts/${slug}.eot?d=${window.location.hostname}) format("embedded-opentype");
            ${fontFaceStylesArray}
        }
    `;
};

const fontFamilies = FONTS.map(font => {
    if (font.googleFontSlug) {
        return returnGoogleFontImport(font.googleFontSlug);
    }

    if (font.fontFaces) {
        return font.fontFaces.map(fontFace =>
            returnFontFace(font.label, fontFace.slug, fontFace.fontStyles)
        );
    }

    return '';
});

export default fontFamilies.join('');
