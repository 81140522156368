import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import type { Props } from './meta.types';

const Meta = memo(({ title, image, description, id }: Props) => (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={`https://new-embedder.wirewax.com/${id}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`https:${image}`} />
    </Helmet>
));

Meta.displayName = 'Meta';
export default Meta;
