import styled from 'styled-components';

// height: 100vh is Viewport height
// height: 100% is ICB height
// Ref: https://developers.google.com/web/updates/2016/12/url-bar-resizing
export const Wrapper = styled.div`
    background: ${({ transparent }) => (transparent ? 'transparent' : 'black')};
    /* height: 100vh;  */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
`;

export const Button = styled.button`
    background: transparent;
    border: 0;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
`;

export const ControlsWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    ${props => props.fullBleed && `
        position: fixed;
    `}
`;

export const ControlText = styled.span`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`;

export const AppWrapper = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: ${({ disable }) => (disable ? 'none' : '')};
`;

export const ImageWrapper = styled.img`
    margin: 0 auto;
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
`;
