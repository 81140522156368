// @flow
import { useCallback, useEffect, useRef } from 'react';

/**
 * Returns a stateful value, cancel function to clear time out, reset function to reset time out.
 */

const useTimeout = (callback: Function, ms: number = 0): [boolean | null, Function, Function] => {
    const ready = useRef(false);
    const timer = useRef();
    const callbackFn = useRef(callback);

    const isReady = useCallback(() => ready.current, []);

    const set = useCallback(
        () => {
            ready.current = false;
            if (timer.current) {
                clearTimeout(timer.current); // clear timer
            }

            timer.current = setTimeout(() => {
                ready.current = true;
                callbackFn.current();
            }, ms);
        },
        [ms]
    );

    const clear = useCallback(() => {
        ready.current = null;
        if (timer.current) {
            clearTimeout(timer.current);
        } // clear timer
    }, []);

    // reset callback onUpdate
    useEffect(
        () => {
            callbackFn.current = callback;
        },
        [callback]
    );

    // set onMount
    useEffect(
        () => {
            set();

            return clear; // clear onUnmount
        },
        [ms]
    );

    return [isReady, clear, set];
};

export default useTimeout;
