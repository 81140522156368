import React from 'react';
import { ItemDiv, Section } from '../debugger.styles';
import copyToClipboard from '../../../utils/copyToClipboard';

const InfoSection = ({ label, info }: { label: string, info: Object }) => (
    <Section>
        <h3>{label}</h3>
        <ul>
            {Object.keys(info).map(key => (
                <li key={key}>
                    <ItemDiv>{key}</ItemDiv>
                    <ItemDiv value onClick={copyToClipboard}>
                        {info[key] ? info[key] : '-'}
                    </ItemDiv>
                </li>
            ))}
        </ul>
    </Section>
);

export default InfoSection;
