import { HIDE_SHARE_CONTROLS, HIDE_HOTSPOT_DOTS } from '../constants/configurationConstants';

const componentOptionChecker = (options: Object) => {
    const { noShare, noMarkers } = options;

    if (noShare === null) options.noShare = options[HIDE_SHARE_CONTROLS]; // FIXME

    if (noMarkers === null) options.noMarkers = options[HIDE_HOTSPOT_DOTS]; // FIXME

    return options;
};

export default componentOptionChecker;
