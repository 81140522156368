import styled from 'styled-components';
import breakpoints from '../../../styles/breakpoints';
import colors from '../../../styles/colors';

export const ButtonContainer = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    letter-spacing: 0.15em;
    justify-content: center;
    border: ${({ border }) => border || ''};
    width: ${({ width }) => width || '250px'};
    height: ${({ height }) => height || '80%'};
    color: ${({ color }) => color || colors.white};
    visibility: ${({ visibility }) => visibility || 'visible'};
    background: ${({ background }) => background || colors.heavyMetal};
    transition: color, background 200ms linear;

    &:hover {
        color: ${({ hoverColor }) => hoverColor || colors.heavyMetal};
        background: ${({ hoverBackground }) => hoverBackground || colors.white};
    }

    ${breakpoints.maxLg} {
        width: ${({ mediaWidthLg }) => mediaWidthLg || ''};
    }
    ${breakpoints.maxMd} {
        width: ${({ mediaWidthMd }) => mediaWidthMd || ''};
    }
    ${breakpoints.maxSm} {
        width: ${({ mobileWidth }) => mobileWidth || ''};
    }
`;

export const ButtonText = styled.p`
    font-family: Oswald;
    text-align: center;
    font-size: ${({ fontSize }) => fontSize || '16px'};

    ${breakpoints.maxLg} {
        font-size: ${({ largeFont }) => largeFont || '17px'};
    }
    ${breakpoints.maxMd} {
        font-size: ${({ mediumFont }) => mediumFont || '14px'};
    }
    ${breakpoints.maxSm} {
        font-size: ${({ mobileFont }) => mobileFont || '9px'};
    }
`;
