import { isOfflinePackage } from '../appConfig';

const offlinePackageUrl = '/wirewax-offline';

const getHost = (id: number) =>
    isOfflinePackage
        ? `${offlinePackageUrl}/videosData/${id}`
        : `https://edge-player5.wirewax.com/vidData/${id}`;

const video = (id: number) => `${getHost(id)}/vidData.json`;

const security = (id: number) => `${getHost(id)}/securityData.json`;

const metrics = 'https://metrics.wirewax.app/events';

const password = (id: number) => `https://hobnob.wirewax.com/video/${id}/passwordCheck`;

export default {
    offlinePackageUrl,
    video,
    metrics,
    password,
    security,
};
