import { connect } from 'react-redux';
import EndFrame from './endFrame.ui';
import videoActions from '../../../../state/video/video.actions';
import options from '../../../../state/options/options.actions';

const mapStateToProps = (state: Object) => ({
    isFullScreen: state.options.isFullScreen,
    width: state.video.width,
    height: state.video.height,
});

const mapDispatchToProps = (dispatch: any): Object => ({
    endFrameAnimationPlaying(animationPlaying: boolean) {
        dispatch(videoActions.endFrameAnimationPlaying(animationPlaying));
    },

    endFrameAnimationEnded(animationEnded: boolean) {
        dispatch(videoActions.endFrameAnimationEnded(animationEnded));
    },

    fullScreen(isFullScreen: boolean) {
        dispatch(options.fullScreen(isFullScreen));
    },
});

const connectApp = connect(
    mapStateToProps,
    mapDispatchToProps
)(EndFrame);

export default connectApp;
