import initialState from './video.state';
import {
    PLAY,
    PAUSE,
    SET_CURRENT_FRAME,
    TOGGLE_MUTE,
    SET_VOLUME,
    SELECT_SUBTITLE,
    PLAYER_READY,
    SET_DIMENSION,
    END_FRAME_ANIMATION_PLAYING,
    END_FRAME_ANIMATION_ENDED,
    SET_MOUSE_POSITION,
    SEEK,
} from './video.constant';
import { FULFILLED } from '../api/api.constant';
import { VIDEO, END_FRAME_ENDED } from '../options/options.constant';

type State = any;

type Action = Object;

const video = (state: State = initialState, action: Action): Object => {
    switch (action.type) {
        case `${VIDEO}_${FULFILLED}`: {
            const { label = 'Captions off' } =
                action.payload.subtitles.find(item => item.default) || {};

            return {
                ...state,
                subtitle: label,
            };
        }
        case PLAYER_READY:
            return {
                ...state,
                playerReady: true,
            };
        case PAUSE:
            return {
                ...state,
                playing: false,
            };
        case PLAY:
            return {
                ...state,
                playing: true,
                hasPlayed: true,
                hasEnded: false,
            };
        case TOGGLE_MUTE:
            return {
                ...state,
                mute: action.isMute,
            };
        case SET_VOLUME:
            return {
                ...state,
                volume: action.volume,
            };
        case SELECT_SUBTITLE:
            return {
                ...state,
                subtitle: action.lang,
            };
        case SET_CURRENT_FRAME:
            return {
                ...state,
                currentFrame: action.frame,
                currentTime: action.time,
                prevFrame: state.currentFrame || false,
            };
        case SET_DIMENSION:
            return {
                ...state,
                width: action.dimension.width,
                height: action.dimension.height,
            };
        case END_FRAME_ENDED:
            return {
                ...state,
                playing: false,
                hasEnded: true,
            };
        case END_FRAME_ANIMATION_PLAYING: {
            const { animationPlaying } = action;

            return {
                ...state,
                animationPlaying,
            };
        }
        case END_FRAME_ANIMATION_ENDED: {
            const { animationEnded } = action;

            return {
                ...state,
                animationEnded,
            };
        }
        case SET_MOUSE_POSITION: {
            const { position } = action;

            return {
                ...state,
                mousePosition: position,
            };
        }
        case SEEK:
            return {
                ...state,
                currentFrame: action.frame,
                prevFrame: false,
                currentTime: action.time,
            };
        default:
            return state;
    }
};

export default video;
