export const VIDEO = 'VIDEO';
export const END_FRAME = 'END_FRAME';
export const TOGGLE_OVERLAY = 'TOGGLE_OVERLAY';
export const TOGGLE_EMBED_OVERLAY = 'TOGGLE_EMBED_OVERLAY';
export const OVERLAY_EVENT = 'OVERLAY_EVENT';
export const SWITCH_VIDEO = 'SWITCH_VIDEO';
export const END_FRAME_ENDED = 'END_FRAME_ENDED';
export const END_FRAME_VIDEO = 8136146;
export const SET_VIDEO_QUALITY = 'SET_VIDEO_QUALITY';
export const SET_VIDEO_ID = 'SET_VIDEO_ID';
export const HOTSPOT_IS_CLICKED = 'HOTSPOT_IS_CLICKED';
export const HOTSPOT_CLICKOUT = 'HOTSPOT_CLICKOUT';
export const FULL_SCREEN = 'FULL_SCREEN';
export const CLOSE_OVERLAY_BOOLEAN = 'CLOSE_OVERLAY_BOOLEAN';
export const FORCE_SPRITE_ID_SHOWN = 'FORCE_SPRITE_ID_SHOWN';
export const TOGGLE_CONTROLS_VISIBILITY = 'TOGGLE_CONTROLS_VISIBILITY';
export const SET_USER_AGENT = 'SET_USER_USERAGENT';
export const SET_VIDEO_RENDITIONS = 'SET_VIDEO_RENDITIONS';
export const SET_OVERLAY_CONTENT = 'SET_OVERLAY_CONTENT';
export const SET_ACTION_LINK = 'SET_ACTION_LINK';
export const OVERRIDE_EMBED_LOC = 'OVERRIDE_EMBED_LOC';
export const SET_PLAYER_PARAMS = 'SET_PLAYER_PARAMS';
