import initialState from './api.state';
import { PENDING, FULFILLED, REJECTED } from './api.constant';

type State = any;

type Action = Object;

const status = (state: State = initialState, action: Action): Object => {
    if (action.type.includes(`_${PENDING}`)) {
        return {
            ...state,
            fetching: true,
        };
    }

    if (action.type.includes(`_${REJECTED}`)) {
        return {
            ...state,
            fetching: false,
            error: action.err,
            url: action.url,
        };
    }

    if (action.type.includes(`_${FULFILLED}`)) {
        return {
            ...state,
            fetching: false,
        };
    }

    return state;
};

export default status;
