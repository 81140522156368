import React, { Suspense, lazy, memo } from 'react';
import type { Props } from './app.types';
import MediaClickArea from '../component/mediaClickArea';
import Overlay from '../component/overlay';

const Hotspots = lazy(() => import('../features/hotspots'));
const TimeTriggers = lazy(() => import('../component/timeTriggers'));

const App = memo(
    ({
        activeOverlay,
        togglePlay,
        play,
        pause,
        skip,
        toggleOverlay,
        hotspotIsClicked,
        hotspotClickout,
        overlayEvent,
        closeOverlayAndAnimateOut,
        isImage,
        hideMainPlayButton,
        assetWidth,
        assetHeight,
        customParams,
        onMediaAreaClick,
    }: Props) => (
        <>
            <Suspense fallback="">
                <TimeTriggers closeOverlayAndAnimateOut={closeOverlayAndAnimateOut} />
            </Suspense>

            {/* FIXME: if use isOverlayClosed here, overlay close animation won't show, will fix this after merging overlay repo in new-embedder */}
            {Object.entries(activeOverlay).length > 0 ? (
                <Overlay
                    overlayEvent={overlayEvent}
                    play={play}
                    skip={skip}
                    toggleOverlay={toggleOverlay}
                    closeOverlayAndAnimateOut={closeOverlayAndAnimateOut}
                    assetWidth={assetWidth}
                    assetHeight={assetHeight}
                    customParams={customParams}
                />
            ) : (
                <>
                    <MediaClickArea onClick={onMediaAreaClick} />

                    {(hideMainPlayButton || isImage) && (
                        <Suspense fallback="">
                            <Hotspots
                                play={play}
                                pause={pause}
                                skip={skip}
                                hotspotIsClicked={hotspotIsClicked}
                                hotspotClickout={hotspotClickout}
                                toggleOverlay={toggleOverlay}
                                closeOverlayAndAnimateOut={closeOverlayAndAnimateOut}
                            />
                        </Suspense>
                    )}
                </>
            )}
        </>
    )
);

App.displayName = 'App';
export default App;
