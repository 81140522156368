import React from 'react'

const OpenOverlay = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 15">
    <g fill="none" fillRule="evenodd" stroke="#1D1D1B" strokeLinecap="round" strokeLinejoin="round" opacity=".5" transform="translate(1 1)">
      <rect width="15" height="13" rx="1" />
      <path d="M0 4h15" />
    </g>
  </svg>
)

export default OpenOverlay;
