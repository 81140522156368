import React from 'react';
import colors from '../styles/colors';

const IconFullscreen = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill={colors.codGray} fillRule="nonzero" d="M8.82 8.161V7.01c0-.419.065-.812.207-1.178.142-.366.336-.68.581-.955.246-.262.53-.484.865-.641A2.456 2.456 0 0 1 11.533 4h1.82v2.25h-1.82c-.104 0-.207.066-.298.21a.805.805 0 0 0-.142.497v1.178h2.26v2.29h-2.26V16H8.821v-5.549H7v-2.29h1.82z"/>
  </svg>
);

export default IconFullscreen;
