import { connect } from 'react-redux';
import VideoLoader from './videoLoader.ui';
import options from '../../state/options/options.actions';
import videoActions from '../../state/video/video.actions';
import api from '../../state/api/api.actions';

const mapStateToProps = (state: Object) => ({
    mainVideo: state.options.mainVideo,
    customPlayer: state.options.tpPlayer,
    tpVidId: state.options.tpVidId,
    video: state.options.activeVideo.video,
    videoType: state.options.videoType,
    renditionsArray: state.options.activeVideo.video.renditionsArray,
    userAgent: state.options.userAgent,
    fps: state.options.activeVideo.video.fps,
    subtitles: state.options.activeVideo.subtitles,
    subtitle: state.video.subtitle,
    volume: state.video.volume,
    frame: state.video.currentFrame,
    endFrameVideo: state.options.endFrameVideo,
    endFrameEnded: state.options.endFrameEnded,
    animationPlaying: state.video.animationPlaying,
    animationEnded: state.video.animationEnded,
    isOverlayOpen: !state.options.isOverlayClosed,
    hasHls: state.options.hasHls,
    forceRendition: state.options.playerParams.forceRendition,
});

const mapDispatchToProps = (dispatch: any): Object => ({
    toggleOverlay: overlay => dispatch(options.toggleOverlay(overlay)),
    hotspotIsClicked: (nameLink, spriteId) =>
        dispatch(options.hotspotIsClicked(nameLink, spriteId)),
    playerReady: () => dispatch(videoActions.playerReady()),
    setVolume: volume => dispatch(videoActions.setVolume(volume)),
    currentFrame: (frame: number, time: number) =>
        dispatch(videoActions.setCurrentFrame(frame, time)),
    switchVideo: (video, videoType) => dispatch(options.switchVideo(video, videoType)),
    endEndFrame: () => dispatch(options.endEndFrame()),
    mute: mute => dispatch(videoActions.mute(mute)),
    selectSubtitle: subtitle => dispatch(videoActions.selectSubtitle(subtitle)),
    setVideoQuality: (quality: number) => dispatch(options.setVideoQuality(quality)),
    apiRejected: () => dispatch(api.rejected('SOURCE_LOADING_REJECTED')),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(VideoLoader);
