import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import hotspots from '../features/hotspots/hotspotsSlice';
import video from './video/video.reducer';
import options from './options/options.reducer';
import checks from './checks/checks.reducer';
import notification from './notification/notification.reducer';
import api from './api/api.reducer';
import dynamic from './dynamic/dynamic.reducer';
import playback from './playback/playback.reducer';
import business from '../features/businessSuite/businessSuiteSlice';

const rootReducer = (history: Object) =>
    combineReducers({
        video,
        options,
        checks,
        notification,
        api,
        dynamic,
        playback,
        business,
        hotspots,
        toastr: toastrReducer,
        router: connectRouter(history),
    });

export default rootReducer;
