import { toastr } from 'react-redux-toastr';

const toastrOptions = {
    showCloseButton: false, // false by default
    closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
};

const copyToClipboard = event => {
    const range = document.createRange();

    range.selectNodeContents(document.body);
    document.getSelection().addRange(range);

    const str = event.target.textContent;

    if (str === '-') {
        return null;
    }

    return new Promise((resolve, reject) => {
        let success = false;
        const listener = e => {
            e.clipboardData.setData('text/plain', str);
            e.preventDefault();
            success = true;
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);

        success ? resolve(str) : reject(toastr.error('copy to clipboard', 'failed', toastrOptions));
    }).then(val => {
        toastr.success('copy to clipboard', val, toastrOptions);
        document.getSelection().removeAllRanges();
    });
};

export default copyToClipboard;
