import styled from 'styled-components';

const Button = styled.button`
  height: 2em;
  padding: 5px 0;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  white-space: nowrap;
  color: #1d1d1b;
  transition: opacity, 200ms;
  cursor: pointer;
  opacity: 0.6;

  ${props => props.label === 'jump' && 'font-size: 1em; width: 3em;'}

  ${props => props.label === 'exit' && 'padding-bottom: 0; margin-left: auto'}

  &:hover {
    opacity: 1
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3
  }
}
`;

export default Button;
