/* eslint-disable no-console */
import debug from 'debug';
import moment from 'moment';
import VConsole from 'vconsole';
import utils from './index';

function formatArgs(args) {
    const name = this.namespace;
    const colorCode = this.color;
    const dateTime = `[${moment().format('HH:mm:ss.SSS')}]`;

    args[0] = `${dateTime} %c${name} %c${args[0]} %c+${debug.humanize(this.diff)}`;
    args.splice(1, 0, `color: ${colorCode}`);
    args.splice(2, 0, 'color: inherit');
    args.splice(3, 0, `color: ${colorCode}`);
}

function initDebugger() {
    global.localStorage.debug = 'new-embedder:*';
    debug.formatArgs = formatArgs;
    debug.disable();
}

if (utils.canAccessLocalStorage()) {
    initDebugger();
} else {
    console.warn('Disabling debugger, since local storage is not readable');
}

const Logger = {
    log: debug('new-embedder:log'),
    debug: debug('new-embedder:debug'),
    error: debug('new-embedder:error'),
    info: debug('new-embedder:info'),
    warning: debug('new-embedder:warning'),
    enable: () => {
        // Enable mobile console plugin - vconsole
        // eslint-disable-next-line no-unused-vars
        try {
            const touchDevice = 'ontouchstart' in document.documentElement;

            if (touchDevice) {
                // eslint-disable-next-line no-unused-vars
                const vConsole = new VConsole();
            }

            // Enable debug level
            debug.enable('new-embedder:*');

            // Bind output stream
            Logger.log.log = console.log.bind(console);
            Logger.info.log = console.info.bind(console);
            Logger.error.log = console.error.bind(console);
            Logger.debug.log = console.debug.bind(console);
            Logger.warning.log = console.warn.bind(console);
            Logger.log('Logger', 'enabled');
        } catch (error) {
            console.error(error);
        }
    },
};

export default Logger;
