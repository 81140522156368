import styled from 'styled-components';

export const FullScreenWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    /* The following style only apply to IE 11 */
    ${props =>
        props.fullBleed &&
        `
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            flex-direction: column;
        }
    `};
`;

export const Wrapper = styled.div.attrs(({ playerWidth, playerHeight, wrapperFontSize }) => ({
    style: {
        height: `${playerHeight}px`,
        width: `${playerWidth}px`,
        fontSize: `${wrapperFontSize}px`,
    },
}))`
    position: relative;

    &:focus {
        outline: none;
    }

    /* The following style only apply to IE 11 */
    ${props =>
        props.fullBleed &&
        `
        position: absolute;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    `}
`;
