// @flow

import React from 'react';
import StyledPanel from './TabPanel.styles';
import type { Props } from './TabPanel.types';

const TabPanel = (props: Props) => {
    const { toggle, table, children } = props;

    return toggle && <StyledPanel table={table}>{children}</StyledPanel>;
};

export default TabPanel;
