/* eslint-disable no-console */
import AWS from 'aws-sdk';
import { KEYS } from './quizResults.constant';
import { encodeForSqs } from './quizResults.utils';
import Logger from '../../utils/Logger';

AWS.config.update({
    sqs: '2012-11-05',
    accessKeyId: KEYS.AWS_ACCESS_KEY,
    secretAccessKey: KEYS.AWS_SECRET_KEY,
    region: 'eu-west-1',
    sslEnabled: true,
    httpOptions: {
        timeout: 5000,
    },
});

const sqs = new AWS.SQS();

const send = (message: Object, QueueUrl: string) => {
    Logger.info('Send Quiz', { message });

    sqs.sendMessage({
        QueueUrl,
        MessageBody: encodeForSqs(message),
    })
        .promise()
        .catch(err => {
            console.warn(err);
        });
};

export { send };
