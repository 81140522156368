const colors = {
    black: '#000000',
    heavyMetal: '#1D1D1B',
    codGray: '#151515',
    mineShaft: '#292929',
    transparent: 'transparent',
    white: '#ffffff',
    transparentGray: 'rgba(255, 255, 255, 0.85)',
    nobel: '#b7b7b7',
    primary: '#eba7c6',
};

export default colors;
