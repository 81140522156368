import { NOTIFY, CLEAR_NOTIFICATION } from './notification.constant';

export const notify = (type: string, message: string): Object => ({
    type: NOTIFY,
    payload: { type, message },
});

export const clear = (): Object => ({
    type: CLEAR_NOTIFICATION,
});

export default {
    notify,
    clear,
};
