import { connect } from 'react-redux';
import options from '../../state/options/options.actions';
import videoActions from '../../state/video/video.actions';
import ScreenWrapper from './screenWrapper.ui';

const mapStateToProps = (state: Object) => ({
    isFullScreen: state.options.isFullScreen,
    fullBleed: state.options.playerParams.fullBleed,
    animationPlaying: state.video.animationPlaying,
    animationEnded: state.video.animationEnded,
});

const mapDispatchToProps = (dispatch: any): Object => ({
    fullScreen(isFullScreen) {
        dispatch(options.fullScreen(isFullScreen));
    },

    setMousePosition(position: Object) {
        dispatch(videoActions.setMousePosition(position));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ScreenWrapper);
