import React from 'react';
import type { BannerProps } from './whitelabelBanner.types';
import LogoBlack from '../../../../assets/logo-black';
import { LogoWrapper, BannerWrapper, TextWrapper, Button } from './whitelabelBanner.styles';

const WhitelabelBanner = ({ onClick, subText, buttonText }: BannerProps) => (
    <BannerWrapper>
        <LogoWrapper onClick={() => window.open('https://www.wirewax.com/?ref=embedder')}>
            <LogoBlack />
        </LogoWrapper>
        <TextWrapper>
            <p>{subText}</p>
        </TextWrapper>
        <Button onClick={onClick}>{buttonText}</Button>
    </BannerWrapper>
);

export default WhitelabelBanner;
