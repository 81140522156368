import React from 'react';
import { SecurityStyled, SecurityMessage } from '../common/security';
import type { Props } from './lockScreen.types';

const LockScreen = ({ Icon, message }: Props) => (
    <SecurityStyled>
        {Icon}
        <SecurityMessage style={{ marginTop: '2em', fontSize: '24px' }}>{message}</SecurityMessage>
    </SecurityStyled>
);

export default LockScreen;
