const colors = {
    candlelight: '#FCDB28',
    codGray: '#151515',
    ebonyClay: '#20232E',
    ebonyClayLight: '#292C3B',
    punch: '#DD4B39',
    shark: '#1C1D26',
    transparent: 'transparent',
    white: '#FFFFFF',
    inputBackground: '#3f3f3f',
    inputBlue: '#5579ff',
    primary: '#eba7c6',
    textBlack: '#1d1d1d',
};

export default colors;
