// Reference: https://reactjs.org/docs/hooks-faq.html, "Should I use one or many state variables?"
import { useRef, useEffect } from 'react';

/**
 * React Hook to add eventListener
 * @param {*} eventName Name of the event, required
 * @param {*} handler Callback function, required
 * @param {*} element DOM element to attach the eventListener, required
 */
const useEventListener = (eventName: string, handler: Function, element: Element) => {
    // Init handler
    const savedHandler = useRef();

    useEffect(
        () => {
            savedHandler.current = handler;
        },
        [handler]
    );

    // Add event listener
    useEffect(
        () => {
            const isSupported = element && element.addEventListener;

            if (!isSupported) {
                // eslint-disable-next-line no-console
                console.error("Element doesn't support addEventListener");
            }

            const eventHandler = event => savedHandler.current(event);

            element.addEventListener(eventName, eventHandler);

            return () => {
                // Clean up
                element.removeEventListener(eventName, eventHandler);
            };
        },
        [eventName, element]
    );
};

export default useEventListener;
