import React from 'react';

type WirewaxTaglineLogoProps = {
  height?: string,
  width?: string,
  viewBox?: string,
  fill?: string,
};

const defaults = {
  height: '100',
  width: '170',
  viewBox: '60 50 500 309.6',
  fill: '',
};

const WirewaxTaglineLogo = ({ height, width, viewBox, fill }: WirewaxTaglineLogoProps) => (
  <svg
    className="wirewax-tagline-logo"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox={viewBox}
    height={height}
    width={width}
    fill={fill}
  >
    <title>wirewax logo</title>
    <g>
      <g>
        <g>
          <path
            d="M203.6,221.7c0-22.9,0-46.7-0.2-70l0-1l-1.1,0.2c-5.5,0.9-11,1.7-16.3,2.5l-0.8,0.1l0,0.8
    	c0.3,23.3,0.4,47.1,0.5,70.1c0.1,21.2,0.2,43.1,0.4,64.6l0,1l1.1-0.2c5.1-0.8,10.5-1.7,15.8-2.6l0.8-0.1l0-0.7
    	C203.5,264.7,203.6,242.8,203.6,221.7z"
          />
          <path
            d="M253,146.6c-3.5-2.7-8.5-3.5-14-2.4c-9.5,1.8-18.8,3.5-27.7,5.1l-0.8,0.1l0,0.8
		c0.2,23.3,0.1,47,0.1,69.9c-0.1,21.2-0.2,43,0,64.6l0,1.1l1.1-0.2c5.1-0.9,10.5-2,15.9-3.1l0.8-0.2l0-0.7
		c-0.1-21.2,0-42.4,0.2-62.2c2.3-0.4,3.7-0.7,6.2-1.2c2.2-0.4,3.3-0.4,3.9,0c0.7,0.6,0.8,2.4,0.8,4.4c-0.2,16.9-0.3,36.8-0.2,56.5
		l0,1.1l1.1-0.2c5.2-1.1,10.6-2.3,16.3-3.5l0.7-0.2l0-0.7c0-19.7,0.1-39.7,0.3-56.5c0.6-6-1.4-10-6.2-12c5.8-4,6.5-10.3,6.4-15.5
		c0.1-12.2,0.2-22.9,0.2-32.8C258.2,153.5,256.4,149.2,253,146.6z M239.7,196.8c0,4.2-0.3,4.5-3.8,5.2c-2.9,0.6-4.5,0.9-7.1,1.4
		c0.1-16.4,0.2-29.1,0.1-41.2c1-0.2,1.9-0.4,2.7-0.5c1.5-0.3,2.8-0.6,4.8-0.9c1-0.2,1.8,0,2.5,0.5c0.6,0.5,1,1.3,1,2.3
		C239.8,174.4,239.8,185,239.7,196.8z"
          />
          <path
            d="M266,138.8l-0.7,0.2l0,0.7c0,23.2-0.2,46.7-0.3,69.5c-0.2,21-0.3,42.8-0.3,64.2v1.1l1.2-0.3
		c13.1-3,26.1-6.1,39.3-9.3l0.7-0.2l0-15.9l-1.2,0.3c-7.2,1.7-14.4,3.4-21.6,5.1c0-14.8,0.1-29.3,0.2-46.5
		c6.2-1.4,12.4-2.8,18.6-4.2l0.7-0.2l0-15.8l-1.2,0.3c-6,1.4-12.1,2.7-18.1,4.1c0.1-16.2,0.1-28.9,0.2-40.8
		c7.2-1.6,14.5-3.1,21.7-4.7l0.7-0.2l0-15.9l-1.2,0.2C291.8,133.3,279.1,136,266,138.8z"
          />
          <path
            d="M372,249.8l0.1-0.6c2.4-19.4,4.7-39.1,7-58.2c2.9-25,6-50.8,9.2-76.1l0.2-1.2l-1.3,0.2
		c-5.4,1-11.1,2-16.8,3.1l-0.7,0.1l-0.1,0.6c-2,17.5-3.9,34.9-5.7,51.5c-2.6-19.1-4.6-34.4-6.4-48.8l-0.1-1l-1,0.2
		c-5.1,1-10.2,2.1-15.3,3.1l-0.7,0.1l-0.1,0.6c-1.9,17.2-3.8,34.2-5.6,50.9c-2.3-17.1-4.3-32.8-6.2-48l-0.1-1l-1,0.2
		c-5.7,1.2-11.3,2.4-17,3.7l-0.8,0.2l0.1,0.8c3.3,26,6.7,52.4,10,77.9c2.2,16.9,4.4,33.8,6.5,50.7l0.1,1l1-0.2
		c5-1.2,10-2.3,15-3.5l0.6-0.1l0.1-0.6c2.1-16.2,4.4-33.7,7.1-54.8c1.9,18,3.7,34.7,5.4,51.6l0.1,1l1.1-0.2
		c4.9-1.1,9.9-2.1,14.6-3.1L372,249.8z"
          />
          <path
            d="M419.8,241.7l1-0.1c5.6-0.8,11.3-1.4,16.9-2.1l0.9-0.1l-0.1-0.9c-2.6-19.8-5.3-40-8-59.5
		c-3.1-22.8-6.4-46.5-9.3-69.7l-0.1-0.9l-1,0.1c-6.6,0.9-13.6,2-21.4,3.3l-0.7,0.1l-0.1,0.7c-3.1,24.3-6.1,49.1-9,73.1
		c-2.4,20-4.9,40.7-7.4,61l-0.2,1.2l1.3-0.2c5.2-1,10.4-1.9,15.6-2.8l0.7-0.1l0.1-0.7c1.3-10.1,2.7-20.9,4.2-33.1
		c4.2-0.7,8.4-1.3,12.5-1.9c1.4,11.2,2.7,21.6,4,31.6L419.8,241.7z M405.2,195.1l1.3-10.1c0.9-7.6,1.9-15.3,2.8-22.9
		c0.7,5.3,1.4,10.5,2.1,15.8l2.1,16C410.3,194.3,408.3,194.6,405.2,195.1z"
          />
          <path
            d="M497.4,235.6l-0.3-1.1c-5.3-21.6-10.8-43.8-17-67.9c5.8-22.7,11.1-43.3,16.2-63.1l0.3-1.1l-1.2,0
		c-5.7,0.1-11.5,0.2-17.3,0.5l-0.7,0l-0.2,0.7c-2.5,10.3-5,20.6-7.4,30.9c-3-9.9-5.9-19.9-8.8-29.8l-0.2-0.7l-0.8,0.1
		c-5.5,0.4-11.2,1-16.8,1.6l-1.1,0.1l0.3,1c4.7,15.9,9.6,31.9,14.4,47.5l4.2,13.8l-5.2,18.5c-4.7,16.8-9.6,34.2-14.4,51.3
		l-0.4,1.3l1.4-0.1c5.8-0.6,11.7-1.1,17.5-1.6l0.7-0.1l0.2-0.6c3-11.7,6-23.4,9.1-35c2.7,11.4,5.3,22.8,7.9,33.8l0.2,0.7l0.8,0
		c5.9-0.3,11.7-0.5,17.5-0.6L497.4,235.6z"
          />
          <path
            d="M163.3,156.1l-0.8,0.1l-0.1,0.7c-1.6,17.4-3.2,35-4.8,52.1c-2.5-18.4-4.9-35.1-7.2-50.8l-0.1-0.8
		l-0.9,0.1c-5.1,0.4-10.2,0.8-15.2,1.1l-0.8,0.1l-0.1,0.8c-1.1,12.9-2.3,25.8-3.4,38.8l-1.1,12.1c-2.5-17.1-5-33.9-7.4-50.3
		l-0.1-0.8l-0.8,0c-5.7,0.2-11.4,0.3-17,0.3l-1.1,0l0.2,1c6.8,46,13.5,92.1,19.7,135.1l0.1,0.8l0.9,0c4.8-0.2,9.6-0.5,14.4-0.9
		l0.8-0.1l0.1-0.7c1.8-17.1,3.7-35.1,5.9-54.8l1,8.7c1.7,14.9,3.4,29.9,5.1,44.8l0.1,0.9l0.9-0.1c4.7-0.5,9.5-1,14.3-1.6l0.8-0.1
		l0.1-0.7c2.7-27.5,5.8-55.5,8.7-82.6c1.9-17.8,4-36.2,5.8-54.3l0.1-1.1l-1.2,0.2C174.5,154.8,168.8,155.5,163.3,156.1z"
          />
        </g>
        <path
          d="M328.8,277.6c1.5,11.8,2.3,17.7,3.9,29.6c1.4-0.3,2.1-0.5,3.5-0.8c-1.6-11.8-2.4-17.7-3.9-29.6
	C330.9,277.2,330.2,277.3,328.8,277.6z"
        />
        <path
          d="M341.5,274.8c0.7,5.8,1.1,8.7,1.8,14.6c-1.9-5.6-2.8-8.4-4.7-14c-1.5,0.3-2.2,0.5-3.7,0.8
	c1.4,11.9,2.2,17.8,3.6,29.6c1.3-0.3,1.9-0.4,3.2-0.7c-0.8-6.3-1.2-9.4-2-15.7c2,6,3,9,5.1,15c1.4-0.3,2.1-0.4,3.5-0.7
	c-1.5-11.9-2.3-17.8-3.7-29.6C343.4,274.4,342.8,274.5,341.5,274.8z"
        />
        <path
          d="M356.2,275c-0.1-1.3-0.2-1.9-0.4-3.1c-3.8,0.8-5.7,1.2-9.6,2c0.1,1.3,0.2,1.9,0.4,3.1
	c1.2-0.3,1.9-0.4,3.1-0.6c1.2,10.6,1.8,15.9,3.1,26.6c1.4-0.3,2.1-0.4,3.5-0.7c-1.3-10.6-1.9-15.9-3.1-26.6
	C354.4,275.4,355,275.2,356.2,275z"
        />
        <path
          d="M363.7,297.5c-0.5-4.3-0.7-6.5-1.2-10.8c1.6-0.3,2.5-0.5,4.1-0.8c-0.1-1.3-0.2-1.9-0.3-3.1
	c-1.6,0.3-2.5,0.5-4.1,0.8c-0.4-3.8-0.6-5.7-1-9.5c1.9-0.4,2.9-0.6,4.8-0.9c-0.1-1.3-0.2-1.9-0.3-3.1c-3.3,0.6-4.9,1-8.2,1.6
	c1.3,11.9,1.9,17.9,3.2,29.8c3.3-0.6,5-1,8.3-1.6c-0.1-1.3-0.2-1.9-0.4-3.1C366.6,296.9,365.7,297.1,363.7,297.5z"
        />
        <path
          d="M377.3,282.3c1-0.7,1.5-1.2,1.3-3.4c-0.3-2.9-0.5-4.4-0.8-7.3c-0.2-2.4-1.7-3.5-4.2-3.1
	c-2.3,0.4-3.5,0.6-5.9,1.1c1.2,11.9,1.8,17.9,3,29.8c1.4-0.3,2.1-0.4,3.5-0.6c-0.6-5.6-0.9-8.4-1.4-14c0.6-0.1,0.9-0.2,1.5-0.3
	c1.1-0.2,1.3,0,1.4,1.2c0.5,5,0.8,7.5,1.3,12.6c1.4-0.3,2.1-0.4,3.5-0.6c-0.5-5-0.8-7.6-1.3-12.6
	C379,283.1,378.6,282.8,377.3,282.3z M374.3,281.2c-0.7,0.1-1.1,0.2-1.8,0.3c-0.4-3.8-0.6-5.7-1-9.5c0.7-0.1,1.1-0.2,1.8-0.3
	c0.8-0.2,1,0.1,1.1,0.8c0.3,3,0.4,4.4,0.8,7.4C375.2,280.9,375.2,281.1,374.3,281.2z"
        />
        <path
          d="M382.5,266.8c-0.3,12.2-0.4,18.3-0.7,30.5c1.3-0.2,2-0.3,3.4-0.6c0.1-3.1,0.1-4.6,0.2-7.7
	c1.2-0.2,1.8-0.3,3-0.5c0.6,2.9,1,4.4,1.6,7.4c1.4-0.2,2.2-0.4,3.6-0.6c-2.6-11.7-3.9-17.6-6.5-29.3
	C385.2,266.4,384.3,266.5,382.5,266.8z M385.4,285.9c0.1-3.8,0.1-5.7,0.2-9.5c0.8,3.6,1.2,5.5,2,9.1
	C386.7,285.6,386.3,285.7,385.4,285.9z"
        />
        <path
          d="M404,281.3c-1.4,0.2-2.1,0.3-3.5,0.6c0.3,3.5,0.4,5.2,0.7,8.7c0,0.5-0.5,0.9-1.3,1
	c-0.8,0.1-1.4-0.1-1.4-0.6c-0.8-9.1-1.2-13.7-1.9-22.8c0-0.5,0.5-0.9,1.3-1c0.8-0.1,1.4,0.1,1.5,0.6c0.2,1.9,0.2,2.8,0.4,4.7
	c1.4-0.2,2.1-0.3,3.5-0.6c-0.2-1.9-0.2-2.8-0.4-4.7c-0.2-2.7-2.4-3.5-5.1-3.1c-3.2,0.5-4.7,2.1-4.5,4.6c0.7,9.1,1.1,13.7,1.9,22.8
	c0.2,2.5,2.1,3.6,5.1,3.1c3.4-0.5,4.8-2.1,4.6-5C404.4,286.3,404.2,284.7,404,281.3z"
        />
        <path
          d="M413.1,262c-3.8,0.6-5.7,0.8-9.6,1.4c0.1,1.3,0.1,1.9,0.2,3.2c1.2-0.2,1.9-0.3,3.1-0.5
	c0.8,10.7,1.2,16.1,2,26.8c1.4-0.2,2.1-0.3,3.5-0.5c-0.8-10.7-1.3-16.1-2.1-26.8c1.2-0.2,1.8-0.3,3-0.4
	C413.2,263.9,413.2,263.2,413.1,262z"
        />
        <path
          d="M414.5,261.8c0.8,12,1.2,18,2.1,30c1.4-0.2,2.1-0.3,3.5-0.5c-0.9-12-1.3-18-2.1-30
	C416.6,261.5,415.9,261.6,414.5,261.8z"
        />
        <path
          d="M426.3,278.5c-1.4-7.2-2.1-10.7-3.4-17.9c-1.4,0.2-2.1,0.3-3.5,0.5c2.2,11.8,3.4,17.8,5.7,29.6
	c1.7-0.2,2.6-0.3,4.3-0.6c0.4-12.2,0.7-18.3,1.1-30.5c-1.4,0.2-2.1,0.3-3.5,0.5C426.6,267.5,426.5,271.2,426.3,278.5z"
        />
        <path
          d="M437,286c-0.2-4.4-0.4-6.6-0.6-10.9c1.6-0.2,2.4-0.3,4.1-0.5c-0.1-1.3-0.1-1.9-0.2-3.2
	c-1.6,0.2-2.4,0.3-4.1,0.5c-0.2-3.9-0.3-5.8-0.5-9.6c1.9-0.2,2.9-0.3,4.8-0.6c-0.1-1.3-0.1-1.9-0.2-3.2c-3.3,0.4-4.9,0.6-8.2,1
	c0.6,12.1,1,18.1,1.6,30.1c3.3-0.4,5-0.6,8.3-1c-0.1-1.3-0.1-1.9-0.2-3.2C439.8,285.7,438.9,285.8,437,286z"
        />
        <path
          d="M452.5,275.6c-1.2-7.2-1.8-10.8-3-18c-1.4,0.1-2.1,0.2-3.5,0.3c2,11.9,3,17.9,5,29.8
	c1.7-0.2,2.6-0.3,4.3-0.4c0.7-12.2,1.1-18.3,1.8-30.5c-1.4,0.1-2.1,0.2-3.5,0.3C453.1,264.5,452.9,268.2,452.5,275.6z"
        />
        <path
          d="M458.6,256.6c0.4,12.1,0.6,18.1,1,30.2c1.4-0.1,2.1-0.2,3.5-0.3c-0.4-12.1-0.6-18.1-1-30.2
	C460.6,256.5,460,256.5,458.6,256.6z"
        />
        <path
          d="M470.6,255.7c-2.3,0.2-3.5,0.3-5.9,0.4c0.3,12.1,0.4,18.2,0.7,30.3c2.4-0.2,3.6-0.3,5.9-0.4
	c2.5-0.2,3.8-1.7,3.7-4.1c-0.2-9-0.4-13.5-0.6-22.6C474.4,256.8,473.1,255.5,470.6,255.7z M470.6,282.8c-0.7,0.1-1.1,0.1-1.8,0.1
	c-0.2-9.6-0.3-14.3-0.6-23.9c0.7-0.1,1.1-0.1,1.8-0.1c0.8-0.1,1,0.2,1,0.9c0.2,8.7,0.3,13.1,0.5,21.8
	C471.6,282.5,471.5,282.7,470.6,282.8z"
        />
        <path
          d="M481,282.1c0-4.4-0.1-6.6-0.1-11c1.6-0.1,2.4-0.1,4.1-0.2c0-1.3,0-1.9,0-3.2
	c-1.6,0.1-2.4,0.1-4.1,0.2c0-3.9-0.1-5.8-0.1-9.7c1.9-0.1,2.9-0.2,4.8-0.3c0-1.3,0-1.9,0-3.2c-3.3,0.2-4.9,0.3-8.2,0.4
	c0.1,12.1,0.1,18.2,0.2,30.3c3.3-0.2,5-0.3,8.3-0.4c0-1.3,0-1.9,0-3.2C483.8,282,482.9,282,481,282.1z"
        />
        <path
          d="M492.8,254.3c-3.2,0.1-4.9,1.4-4.9,4c-0.1,9.2-0.2,13.9-0.3,23.1c0,2.6,1.7,3.9,4.7,3.8
	c3.4-0.1,4.9-1.4,5-4.4c0.1-9.1,0.1-13.7,0.2-22.8C497.6,255.4,495.6,254.2,492.8,254.3z M493.9,281.2c0,0.5-0.6,0.8-1.4,0.8
	c-0.8,0-1.3-0.3-1.3-0.8c0.1-9.2,0.2-13.8,0.3-23c0-0.5,0.6-0.9,1.3-0.9c0.8,0,1.4,0.3,1.4,0.8C494,267.4,494,272,493.9,281.2z"
        />
      </g>
      <g>
        <path
          d="M520.4,104.1c-1.3-1.4-3.1-2.1-5.3-2.1c-2.2,0-3.9,0.7-5.3,2.1c-1.3,1.4-2,3.2-2,5.3
	c0,2.2,0.7,3.9,2,5.3c1.3,1.4,3.1,2.1,5.3,2.1c2.1,0,3.9-0.7,5.3-2.1c1.3-1.4,2-3.2,2-5.3C522.4,107.2,521.7,105.4,520.4,104.1z
	 M521.1,109.4c0,1.9-0.5,3.4-1.6,4.5h0c-1.1,1.1-2.5,1.7-4.4,1.7c-1.8,0-3.3-0.6-4.4-1.7c-1.1-1.1-1.6-2.6-1.6-4.5
	c0-1.9,0.5-3.4,1.6-4.5c1.1-1.1,2.5-1.7,4.4-1.7c1.8,0,3.3,0.6,4.4,1.7C520.6,106,521.1,107.5,521.1,109.4z"
        />
        <path
          d="M517.6,109.2c0.3-0.4,0.5-1,0.5-1.6c0-0.6-0.2-1.2-0.7-1.6c-0.5-0.4-1.2-0.7-2.1-0.7h-2.6v7.8h1.4
	V110h0.7l1.9,3.1h1.7l-2.1-3.2C516.9,109.8,517.3,109.5,517.6,109.2z M514.1,106.4h1.2c1,0,1.4,0.4,1.4,1.2c0,0.8-0.4,1.2-1.4,1.2
	h-1.2V106.4z"
        />
      </g>
    </g>
  </svg>
);

WirewaxTaglineLogo.defaultProps = defaults;
export default WirewaxTaglineLogo;
