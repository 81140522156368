/* eslint-disable no-underscore-dangle */
import React, { lazy, PureComponent, Suspense } from 'react';

import type { Node } from 'react';
import FontPreloader from 'component/fontPreloader';
import type { Props, State } from './loadOptions.types';
import { getPlayerConfiguration, excludeEmbed } from './loadOptions.helpers';
import { ControlsWrapper, Wrapper, AppWrapper } from './loadOptions.styles';

import VideoLoader from '../videoLoader';
import ImageLoader from '../imageLoader';
import Meta from '../meta';
import { toggleFullScreen, getBrowserVisibilityValues } from '../../utils/domEvents';
import App from '../../app';
import ScreenWrapper from '../screenWrapper';
import EventManager from '../eventManager';
import Debugger from '../../features/debugger';
import componentOptionChecker from '../../utils/componentOptionChecker';
import EmbedOverlay from '../embedOverlay';
import Fade from '../../utils/fade';
import MediaClickArea from '../mediaClickArea';
import BusinessSuite from '../../features/businessSuite';
import { socialSharingList, handleShareClick } from '../../features/social';

const Controls = lazy(() => import('../../features/controlBar'));

class LoadOptions extends PureComponent<Props, State> {
    constructor(props: Object) {
        super(props);

        const {
            playerParams,
            playerParams: { customPlayer },
            userAgent: { isIOS },
        } = this.props;

        this.playerParams = playerParams;
        this.browserVisibility = getBrowserVisibilityValues();

        this.state = {
            hideMainPlayButton: this.playerParams.autoplay,
            showMuteButton: this.playerParams.autoplay && !this.playerParams.hideAudioIcon,
            isPlayingOnFullScreenToggle: null,
        };

        this.childRef = React.createRef();
        this.isIosDefaultPlayer = isIOS && !customPlayer;
    }

    componentDidMount() {
        // Fetch vidData.json
        const { id, getOptions, version } = this.props;

        getOptions(id, version);

        document.addEventListener(
            this.browserVisibility.visibilityChange,
            this.handleVisibilityChange,
            false
        );
    }

    componentDidUpdate(prevProps: Object) {
        const { mainVideo, playback, volume } = this.props;

        if (mainVideo !== prevProps.mainVideo) {
            // check to update this.playerParams with playerConfigurations
            const {
                video: { playerConfiguration = {} },
            } = mainVideo;

            this.playerParams = playerConfiguration
                ? componentOptionChecker({
                      ...this.playerParams,
                      ...getPlayerConfiguration(playerConfiguration || {}),
                  })
                : this.playerParams;
        }

        // TODO: This is a walk around for redux stage management
        // Currently our playback is not managed based on state
        // Which makes it very messy that play, pause, skip functions are passed
        // It has both update state and control player ref to work
        // Ideally, loadOption.play should only change playback states
        // And in this checking to call player ref to change playback
        const { isPlaying, isSeeking, seekTo } = prevProps.playback;

        if (isPlaying && !playback.isPlaying) {
            this.pause();
        }

        if (!isPlaying && playback.isPlaying) {
            this.play();
        }

        if (isSeeking) this.skip(seekTo);

        const {
            autoplay,
            overlayedVideo,
            mainVideoMuted,
        } = this.playerParams || {};

        if (overlayedVideo && !mainVideoMuted && autoplay && volume === 0 && this.mute) {
            this.mute();
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            this.browserVisibility.visibilityChange,
            this.handleVisibilityChange
        );
    }

    // Passed to video player, used in play and toggle play functions
    hidePlayButton = () => {
        const { hideMainPlayButton } = this.state;

        if (!hideMainPlayButton) {
            this.setState({ hideMainPlayButton: true });
        }
    };

    hideMuteIcon = () => {
        const { showMuteButton } = this.state;

        if (showMuteButton) {
            this.setState({ showMuteButton: false });
        }
    };

    // Passed to player to reload video and resume to the current time
    reload = () => {
        this.childRef.current._reload();
    };

    // Passed to App, onClick of play icon, fired on fullscreencheck and close overlay to resume playback
    play = () => {
        const { hideMainPlayButton } = this.state;
        const { isImage, playing, activeOverlay } = this.props;

        if (playing) return;

        // Reset Overlay:
        // on IOS video will be stalled and crash player if we don't pause video after returning from other tabs or back button (normally a clickout action)
        // Solution here is we show main play button again to force user to interact with player again.
        // Overlay could be showing at this moment. If overlay is not showing, it need to play
        if (Object.keys(activeOverlay).length > 0 && !hideMainPlayButton) {
            this.hidePlayButton();

            return;
        }

        if (!hideMainPlayButton) {
            this.hidePlayButton();
        }

        if (isImage) return;

        this.childRef.current._play();
    };

    // Passed to App and controls
    togglePlay = () => {
        const { hideMainPlayButton } = this.state;

        if (!hideMainPlayButton) {
            this.hidePlayButton();
        }

        this.childRef.current._togglePlay();
    };

    // Passed to app and controls, fired in visibility check and open overlay
    pause = () => {
        if (this.childRef.current) {
            this.childRef.current._pause();
        }
    };

    // Passed to app and controls
    skip = (to: number, format: string = 'fps') => {
        this.childRef.current._skip(to, format);

        const { reduxSeeked } = this.props;

        reduxSeeked();
    };

    toggleUnmuteButton = (flag: boolean) => {
        this.setState({ showMuteButton: flag });
    };

    // Passed to controls, used in unMute function
    mute = () => {
        const { showMuteButton } = this.state;

        if (showMuteButton) {
            this.hideMuteIcon();
        }

        this.childRef.current._mute();
    };

    // Passed to controls
    setVolume = (vol: number) => {
        // note: move showMuteButton to redux state and access from controls?
        const { showMuteButton } = this.state;

        if (showMuteButton) {
            this.hideMuteIcon();
        }

        this.childRef.current._setVolume(vol);
    };

    // Passed to controls
    selectSubtitle = (subtitle: string) => {
        // FIXME: For some reason when change from other lang to Captions off, vjs doesn't fire events;
        if (subtitle === 'Captions off') {
            const { selectSubtitle } = this.props;

            selectSubtitle('Captions off'); // Update redux store
        }

        this.childRef.current._selectSubtitle(subtitle);
    };

    // Passed to controls
    setVideoQuality = (quality: string) => {
        this.childRef.current._setStreamingQuality(quality);
    };

    // Passed to controls
    remainingTime = () => this.childRef.current._remainingTime();

    // Passed to controls
    bufferedPercent = () => this.childRef.current._bufferedPercent();

    videoDuration = () => this.childRef.current && this.childRef.current._duration();

    // On click of end frame replay
    restart = () => {
        this.childRef.current._restart();
    };

    // Not used - props.switchVideo is passed to player instead
    switchVideo = (video: Object, frame: number) => {
        this.childRef.current._switchVideo(video, frame);
    };

    // Passed to player and controls
    isPlayingOnFullScreenToggle = () => {
        const { playing } = this.props;

        this.setState({ isPlayingOnFullScreenToggle: playing });

        toggleFullScreen(this.videoWrapper);
    };

    unMute = () => {
        this.mute();
    };

    // Fired in event listener for browser visibility change
    handleVisibilityChange = () => {
        const { hasEnded, isFullScreen, toggleControlsVisibility } = this.props;
        const { isPlayingOnFullScreenToggle } = this.state;
        const { forceVisible } = this.playerParams;
        const hidden = forceVisible ? false : document[this.browserVisibility.hidden];

        if (hidden && !hasEnded && !isFullScreen && !isPlayingOnFullScreenToggle) {
            // IOS HTML5 video has stalled issue, set up to reload the video to bypass the issue
            if (this.isIosDefaultPlayer) {
                this.reload();
                toggleControlsVisibility(false);
                this.setState({ hideMainPlayButton: false });
            } else {
                this.pause();
            }
        }

        if (isPlayingOnFullScreenToggle) {
            this.setState({ isPlayingOnFullScreenToggle: false });
        }
    };

    // Passed to app and player
    closeOverlayAndAnimateOut = () => {
        // This function is passed to the repos that need to fire a close overlay event.
        // The Overlay component animates out the overlay based on the isOverlayClosed value
        // If the activeOverlay is set to {} and isOverlayClosed is set to true at once
        // The overlay will not animate out
        // This is why we set isOverlayClosed to true and wait for 200ms to set activeOverlay to {}
        const { toggleOverlay, closeOverlayBool } = this.props;

        closeOverlayBool();

        this.close = setTimeout(() => {
            toggleOverlay({});
            clearTimeout(this.close);
        }, 400);
    };

    // Passed to controls
    openEmbedOverlay = () => {
        const { toggleEmbedOverlay } = this.props;

        this.pause();
        toggleEmbedOverlay(true);
    };

    // Passed to app and controls
    closeEmbedOverlay = () => {
        const { toggleEmbedOverlay } = this.props;

        // use setTimeout when applying animation
        toggleEmbedOverlay(false);
        this.play();
    };

    /**
     * handleMediaAreaClick
     * - On mobile media area click control show/hide control bar
     * - On desktop, media area click play / pause video playback. if "preventPauseOnClick" is set to true, no playback changes
    */
    handleMediaAreaClick = () => {
        const {
            userAgent: { isMobile },
            toggleControlsVisibility,
            showControls = false
        } = this.props;

        const { preventPauseOnClick } = this.playerParams;

        if (isMobile) {
            toggleControlsVisibility(!showControls);

            return;
        }

        if (preventPauseOnClick) return;

        this.togglePlay();
    };


    videoWrapper: Node;

    childRef: any;

    controls: boolean;

    share: boolean;

    playerParams: Object;

    browserVisibility: Object;

    isIosDefaultPlayer: boolean;

    render = () => {
        const {
            activeVideo: {
                hotspots,
                subtitles,
                video: { duration, fps, aspectRatio },
            },
            hasHls,
            subtitle,
            location,
            volume,
            play,
            pause,
            currentTime,
            loaded,
            player,
            playing,
            videoType,
            setDimension,
            width,
            height,
            videoQuality,
            seek,
            isFullScreen,
            fullScreen,
            prevFrame,
            toggleControlsVisibility,
            showControls,
            videoRenditions,
            userAgent: { isFrameNotAccurate, isIE11, isMobile, isIOS },
            id,
            isImage,
            isEndFrameShown,
            isOverlayClosed,
            tpPlayer,
            embedOverlay,
        } = this.props;

        const { hideMainPlayButton, showMuteButton } = this.state;

        const {
            noControls,
            transparent,
            fullBleed,
            disableMobileFullScreen,
            preventPauseOnClick,
            noCustomisations,
            preloadFonts,
        } = this.playerParams;

        const hideControls =
            !isOverlayClosed ||
            noControls ||
            (!isImage && (!player || !hideMainPlayButton)) ||
            isEndFrameShown ||
            tpPlayer;

        if (!loaded) return null;

        return (
            <Wrapper transparent={transparent}>
                <EventManager
                    toggleUnmuteButton={this.toggleUnmuteButton}
                    play={this.play}
                    pause={this.pause}
                    skip={this.skip}
                    mute={this.mute}
                    setVolume={this.setVolume}
                    setVideoQuality={this.setVideoQuality}
                    fullScreen={this.isPlayingOnFullScreenToggle}
                />
                <Meta />

                <ScreenWrapper
                    ref={el => {
                        this.videoWrapper = el;
                    }}
                    aspectRatio={aspectRatio}
                    playerWidth={width}
                    playerHeight={height}
                    setDimension={setDimension}
                    fullScreen={fullScreen}
                >
                    {/* Render Image Layer */}
                    {isImage && <ImageLoader />}

                    {/* Render Video Layer */}
                    {!isImage && (
                        <VideoLoader
                            options={this.playerParams}
                            ref={this.childRef}
                            fullScreen={this.isPlayingOnFullScreenToggle}
                            hidePlayButton={this.hidePlayButton}
                            closeOverlayAndAnimateOut={this.closeOverlayAndAnimateOut}
                            playIconAction={this.play}
                            muteIconAction={this.mute}
                            restart={this.restart}
                            showMuteButton={showMuteButton}
                            hideMainPlayButton={hideMainPlayButton}
                            hideMainPlayButtonIcon={this.playerParams.hideMainPlayButton}
                            location={location}
                            play={play}
                            pause={pause}
                            seek={seek}
                            hideMuteIcon={this.hideMuteIcon}
                            showControls={showControls}
                            addAdditionalFramesToSkip={isFrameNotAccurate}
                        />
                    )}

                    {/* Render Interactivity Layer */}
                    {!videoType &&
                        !noCustomisations && (
                            <AppWrapper disable={tpPlayer}>
                                <App
                                    className="app"
                                    play={this.play}
                                    pause={this.pause}
                                    togglePlay={preventPauseOnClick ? () => {} : this.togglePlay}
                                    skip={this.skip}
                                    closeOverlayAndAnimateOut={this.closeOverlayAndAnimateOut}
                                    closeEmbedOverlay={this.closeEmbedOverlay}
                                    hideMainPlayButton={hideMainPlayButton}
                                    onMediaAreaClick={this.handleMediaAreaClick}
                                />
                                <Debugger play={this.play} skip={this.skip} pause={this.pause} />
                            </AppWrapper>
                        )}

                    {/* Render Controls */}
                    {noCustomisations && <MediaClickArea onClick={this.handleMediaAreaClick} />}

                    {!hideControls && (
                        <>
                            <Suspense fallback="">
                                <ControlsWrapper fullBleed={fullBleed}>
                                    <Controls
                                        className="controls"
                                        pause={this.pause}
                                        togglePlay={this.togglePlay}
                                        mute={this.mute}
                                        fullScreen={this.isPlayingOnFullScreenToggle}
                                        setVolume={this.setVolume}
                                        skip={this.skip}
                                        selectSubtitle={this.selectSubtitle}
                                        remainingTime={this.remainingTime}
                                        options={this.playerParams}
                                        currentTime={currentTime}
                                        duration={duration}
                                        videoDuration={this.videoDuration}
                                        id={id}
                                        hotspots={hotspots}
                                        handleShareClick={handleShareClick}
                                        socialSharingList={excludeEmbed(socialSharingList, isMobile)}
                                        playing={playing}
                                        prevFrame={prevFrame}
                                        volume={volume}
                                        buffered={this.bufferedPercent}
                                        selectedSubtitle={subtitle}
                                        subtitles={subtitles
                                            .map(track => track.label)
                                            .filter(sub => sub)}
                                        setVideoQuality={this.setVideoQuality}
                                        renditions={videoRenditions}
                                        selectedQuality={videoQuality}
                                        hasHls={hasHls}
                                        fps={fps}
                                        isFullScreen={isFullScreen}
                                        isOverlayOpen={!isOverlayClosed}
                                        openEmbedOverlay={this.openEmbedOverlay}
                                        disableMobileFullScreen={disableMobileFullScreen}
                                        isImage={isImage}
                                        isMobileDevice={isMobile}
                                        isiPhoneiPod={isIOS} // NOTE: used for noFullScreen button config
                                        isIE11={isIE11}
                                        toggleControlsVisibility={toggleControlsVisibility}
                                        showControls={showControls}
                                    />
                                </ControlsWrapper>
                            </Suspense>
                            <Fade show={embedOverlay}>
                                <EmbedOverlay closeEmbedOverlay={this.closeEmbedOverlay} />
                            </Fade>
                        </>
                    )}

                    {/* White Labels, Branding EndFrame, Notification banner */}
                    <BusinessSuite
                        onPlay={this.play}
                        onRestart={this.restart}
                        isPlayingOnFullScreenToggle={this.isPlayingOnFullScreenToggle}
                    />
                </ScreenWrapper>

                {preloadFonts && <FontPreloader />}
            </Wrapper>
        );
    };
}

export default LoadOptions;
