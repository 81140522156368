import { PENDING, FULFILLED, REJECTED } from './api.constant';

const pending = (request: string): Object => ({
    type: `${request}_${PENDING}`,
});

const rejected = (request: string, err: Object, url: string): Object => ({
    type: `${request}_${REJECTED}`,
    err,
    url,
});

const fetched = (request: string, payload: Object = {}): Object => ({
    type: `${request}_${FULFILLED}`,
    payload,
});

export default {
    pending,
    rejected,
    fetched,
};
