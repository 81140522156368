import styled from 'styled-components';
import colors from '../../styles/colors';
import breakpoints from '../../styles/breakpoints';

const ICON_SIZE_DESKTOP = 5;
const ICON_SIZE_MOBILE = 8;

export const Text = styled.p`
    color: ${colors.white};
    font-size: 1.5em;
    letter-spacing: 4px;
    margin: 0em;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-width 100ms ease-in, opacity 150ms ease-in;
    margin 100ms ease-in;
    white-space: nowrap;
`;

export const UnMuteStyled = styled.div`
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    border: solid 1px #ffffff;
    border-radius: ${ICON_SIZE_MOBILE + 1}em;
    bottom: 18%;
    cursor: pointer;
    display: flex;
    right: 4%;
    position: absolute;
    padding: 0.5em;
    transition: width 100ms ease-in;
    /* z-index >= 2 to place above the hotspot layer */
    z-index: 10;
    svg {
        width: ${ICON_SIZE_MOBILE}em;
        height: ${ICON_SIZE_MOBILE}em;
    }
    &:hover {
        border-radius: ${ICON_SIZE_MOBILE + 1}em;
        ${Text} {
            margin: 0 0.5em 0 1.5em;
            max-width: 15em;
            opacity: 1;
        }
        ${breakpoints.lg} {
            border-radius: ${ICON_SIZE_DESKTOP + 1}em;
        }
    }
    ${breakpoints.sm} {
        bottom: 20%;
        border-radius: 5em;
        svg {
            width: 8em;
            height: 8em;
        }
    }
    ${breakpoints.lg} {
        border-radius: 3em;
        svg {
            width: ${ICON_SIZE_DESKTOP}em;
            height: ${ICON_SIZE_DESKTOP}em;
        }
    }
`;
