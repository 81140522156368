import React from 'react';
import styled from 'styled-components';
import type { Props } from './errorScreen.types';

import { SecurityStyled, SecurityMessage } from '../common/security';
import breakpoints from '../../styles/breakpoints';
import WirewaxTaglineLogo from '../../assets/wirewax-tagline-logo';

const Icon = styled.img`
    height: 50%;
    ${breakpoints.xl} {
        height: 40%;
    }
    ${breakpoints.xxl} {
        height: 30%;
    }
`;

const errorAnimation =
    'https://ww5player.s3-eu-west-1.amazonaws.com/playerAssets/video-not-_ere.gif';

const ErrorScreen = ({ noId }: Props) => (
    <SecurityStyled>
        {noId && (
            <SecurityMessage noId={noId}>Video URL does not contain a valid ID.</SecurityMessage>
        )}
        <Icon alt="error animation gif" src={errorAnimation} />
        {noId ? (
            <SecurityMessage noId={noId}>
                Please check the URL and refresh your browser.
            </SecurityMessage>
        ) : (
            <SecurityMessage noId={noId}>
                Sorry this video is not available right now
            </SecurityMessage>
        )}
        {noId && (
            <a href="https://www.wirewax.com" alt="Wirewax homepage">
                <WirewaxTaglineLogo fill="#fff" />
            </a>
        )}
    </SecurityStyled>
);

export default ErrorScreen;
