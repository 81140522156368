import axios from 'axios';
import _ from 'lodash';
import api from '../api/api.actions';
import passwordActions from '../password/password.actions';
import {
    CHECKS,
    DOMAIN_LOCKED,
    COUNTRY_LOCKED,
    INCOMPATIBLE_VIDEO,
    VIDEO_VERSION,
} from './checks.constant';
import urls from '../../constants/endpoints';
import Logger from '../../utils/Logger';

// None of these functions were in use and were reverted by this commit from @pradeep https://github.com/wireWAX/new-embedder/commit/275ceca2ee17e55f5e9834f56010e8d24d8df081

// const escapeRegex = str => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');

// const parseUrl = url => url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('.')[0];

// const matchWildcard = (str, rule) => new RegExp(`^${rule.split('*').map(escapeRegex).join('.*')}$`).test(str);

// const isMatch = (domains, currentDomain) => domains.some(({ name }) => {
//   const parsedUrl = parseUrl(currentDomain);
//   return name.includes('*') ? matchWildcard(parsedUrl, name) : name.includes(window.location.host)
// });

// const shouldLock = (domains) => {
//   const { host, href } = window.location;
//   if (!domains.length) {
//     return false;
//   }
//   return isMatch(domains, href) ? false : !isMatch(domains, host)
// };

const checkDomainLock = (domains: Array<{ name: String }> = []) => {
    // check if video is in an iFrame - avoiding cors issues
    const iFramed = window.parent.location && window.location !== window.parent.location;

    // strip down the url to get hostname
    const referrer = _.isEmpty(document.referrer) ? '' : new URL(document.referrer).hostname;

    const url = iFramed ? referrer : window.location.host;

    const lock = domains.length !== 0 ? domains.every(({ name }) => !name.includes(url)) : false;

    return lock;
};

const domainLock = (domains: Array<{ name: String }> = []) => ({
    type: DOMAIN_LOCKED,
    lock: checkDomainLock(domains),
});

const countryLock = (countries: Array<{ name: String }> = []) => ({
    type: COUNTRY_LOCKED,
    lock:
        countries.length !== 0
            ? countries.every(({ name }) => !window.navigator.language.includes(name))
            : false,
});

const videoIncompatible = () => ({
    type: INCOMPATIBLE_VIDEO,
});

const videoVersion = (version: number) => ({
    type: VIDEO_VERSION,
    version,
});

const get = (id: number, password: string): Object => async (dispatch: Function) => {
    dispatch(api.pending(CHECKS));

    const url = urls.security(id);

    const cachebust = Math.round(Date.now());

    Logger.info('checks.action', '/GET security data');

    await axios
        .get(url, { params: { cachebust } })
        .then(({ data }) => {
            const { locking, passwordProtected, p5Compatible, version } = data;

            Logger.info('checks.action', 'Get Security Data Callback', { p5Compatible });

            if (!p5Compatible) {
                dispatch(videoIncompatible());
            } else {
                dispatch(videoVersion(version));
                dispatch(domainLock(locking.domain));
                dispatch(countryLock(locking.countries));

                if (passwordProtected && password) {
                    dispatch(passwordActions.passwordCheck(id, password));
                } else {
                    dispatch(passwordActions.passwordUpdate(passwordProtected));
                }
            }
        })
        .catch(error => {
            /*
            Older videos which were not edited will not have securityData.json
            These videos have to be redirected to embed.wirewax.com
            */
            console.log('INCOMPATIBLE_VIDEO videoIncompatible', error); // FIXME: this logging do nothing since the page will redirect
            dispatch(videoIncompatible());
        });
};

export default {
    get,
    domainLock,
    countryLock,
    videoIncompatible,
    videoVersion,
};
