import styled from 'styled-components';
import colors from '../../constants/colors';
import { SecurityStyled, SecurityMessage } from '../common/security';
import breakpoints from '../../styles/breakpoints';

const INPUT_HEIGHT = 40;
const INPUT_WIDTH = 415;
const INPUT_FONT_SIZE = 12;

const TRANSITION = 'all 200ms ease-in-out';

const inputStyles = `
    font-size: ${INPUT_FONT_SIZE}px;
    line-height: ${INPUT_HEIGHT}px;
    height: ${INPUT_HEIGHT}px;
    width: 100%;
`;

export const PasswordProtectedStyled = styled(SecurityStyled)``;

export const PasswordMessage = styled(SecurityMessage)`
    margin-top: 2rem;
    @media (orientation: landscape) {
        margin-top: 1rem;
        font-size: 1.5rem;
    }
    ${breakpoints.md} {
        margin-top: 3rem;
        font-size: 24px;
    }
    ${breakpoints.lg} {
        margin-top: 4rem;
    }
`;

export const InputContainer = styled.div`
    margin-top: 3rem;
    width: 100%;
    max-width: ${INPUT_WIDTH}px;
    @media (orientation: landscape) {
        margin-top: 2rem;
    }
    ${breakpoints.md} {
        margin-top: 4rem;
    }
    position: relative;
`;

export const Label = styled.label`
    font-size: ${INPUT_FONT_SIZE}px;
    left: ${({ reposition }) => (reposition ? '100%' : '30px')};
    line-height: ${INPUT_HEIGHT}px;
    opacity: ${({ reposition }) => (reposition ? 0.5 : 1)};
    position: absolute;
    top: 0;
    transform: ${({ reposition }) => (reposition ? 'translateX(-135px)' : 'translateX(0)')};
    transition: ${TRANSITION};
    white-space: nowrap;
    cursor: text;
`;

export const Input = styled.input`
    ${inputStyles}
    max-width: 100%;
    background-color: ${colors.inputBackground};
    border: 1px solid ${colors.transparent};
    color: ${colors.white};
    padding: 0 160px 0 30px;
    transition: ${TRANSITION};

    &:focus {
        border-bottom-color: ${colors.inputBlue};
        outline: none;
    }

    // Chrome autofill style fix
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid green;
        -webkit-text-fill-color: inherit;
        -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        transition: background-color 50000000s ease-in-out 0s;
    }

    // IE icon fix
    &::-ms-clear,
    &::-ms-reveal {
        display: none;
    }
`;

export const ShowHideButton = styled.span`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
`;

export const SubmitButton = styled.button`
    ${inputStyles}
    max-width: ${INPUT_WIDTH}px;
    align-items: center;
    background: ${({ disabled, warning }) => {
        let bgColor;

        if (disabled) {
            bgColor = colors.primary;
        } else if (warning) {
            bgColor = colors.punch;
        } else {
            bgColor = colors.primary;
        }

        return bgColor;
    }}
    color: ${({ warning }) => (warning ? colors.white : colors.textBlack)};
    cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 0 30px;
    position: relative;
    transition: ${TRANSITION};
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

export const ButtonText = styled.span`
    color: ${colors.white};
    margin-left: 1em;
`;
