import { connect } from 'react-redux';

import Meta from './meta';

const mapStateToProps = (state: Object) => ({
    description: state.options.activeVideo.video.description,
    id: state.options.id,
    image:
        state.options.activeVideo.video.posterFrame &&
        state.options.activeVideo.video.posterFrame.medium,
    title: state.options.activeVideo.video.vidName,
});

export default connect(mapStateToProps)(Meta);
