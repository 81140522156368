import React from 'react';
import colors from '../constants/colors';

const RegionLock = () => (
  <svg focusable={false} xmlns="http://www.w3.org/2000/svg" width="66" height="65" viewBox="0 0 66 65">
    <path fill={colors.white} fillRule="nonzero" d="M65.434 31.21C64.764 13.784 50.328 0 32.75 0 15.17 0 .736 13.784.065 31.21H0v2.6h.067C.757 51.2 15.15 64.955 32.693 64.991c.02 0 .038.008.058.008.02 0 .039-.008.058-.008C50.352 64.956 64.744 51.2 65.434 33.81h.066V31.21h-.066zM59.16 18.214H43.314A35.95 35.95 0 0 0 37.7 3.053c9.121 1.515 17.034 7.106 21.46 15.161zM23.606 31.21a80.38 80.38 0 0 1 .81-10.397h16.67c.495 3.446.766 6.92.811 10.4l-18.29-.003zm18.292 2.6a80.344 80.344 0 0 1-.81 10.396H24.416a80.314 80.314 0 0 1-.81-10.397h18.292zM32.75 2.622c2.632 0 6.086 5.803 7.906 15.592H24.845c1.82-9.789 5.273-15.592 7.905-15.592zm-4.95.431a35.954 35.954 0 0 0-5.613 15.16H6.34C10.767 10.16 18.68 4.57 27.802 3.054H27.8zM5.24 20.43v.383h16.558a83.522 83.522 0 0 0-.802 10.397H2.68a29.489 29.489 0 0 1 2.56-10.78zm0 24.156a29.489 29.489 0 0 1-2.56-10.777h18.314a83.76 83.76 0 0 0 .804 10.397H5.24v.38zm1.104 2.22h15.842a36.005 36.005 0 0 0 5.607 15.157c-9.116-1.518-17.024-7.106-21.45-15.158zM32.75 62.402c-2.633 0-6.089-5.805-7.908-15.599h15.815c-1.818 9.794-5.274 15.6-7.907 15.6zm4.957-.441a35.992 35.992 0 0 0 5.609-15.157h15.841c-4.425 8.052-12.333 13.64-21.45 15.157zM60.26 44.586v-.38H43.702a83.76 83.76 0 0 0 .804-10.397h18.313a29.524 29.524 0 0 1-2.559 10.777zM44.506 31.21a83.552 83.552 0 0 0-.804-10.397H60.26v-.382a29.512 29.512 0 0 1 2.561 10.78H44.506z" />
  </svg>
);

export default RegionLock;
