import styled, { css } from 'styled-components';
import colors from '../../../constants/colors';

export const SecurityStyled = styled.div`
    align-items: center;
    background: ${colors.codGray};
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    padding: 10vh;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
`;

export const SecurityMessage = styled.p`
    font-size: 17px;
    text-align: center;
    line-height: 1.3;
    ${({ noId }) =>
        noId &&
        css`
            margin-bottom: auto;
        `};
`;
