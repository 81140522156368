import React, { useEffect } from 'react';
import VideoInVideo from '../../../../component/videoInVideo';
import { EndFrameWrapper } from './endFrame.styles';
import EndFrameAnimation from './endFrameAnimation';
import type { Props } from './endFrame.types';

const EndFrame = ({
    restart,
    isFullScreen,
    isPlayingOnFullScreenToggle,
    fullScreen,
    width,
    height,
    endFrameAnimationEnded,
    endFrameAnimationPlaying,
}: Props) => {
    const videoSrc =
        'https://ww5player.s3-eu-west-1.amazonaws.com/endframe/end-frame-animation.mp4';

    if (isFullScreen) {
        isPlayingOnFullScreenToggle();
        fullScreen(false);
    }

    useEffect(() => {
        endFrameAnimationEnded(false);
        endFrameAnimationPlaying(true);

        return () => {};
    }, []);

    return (
        <EndFrameWrapper>
            <VideoInVideo
                autoplay
                endComponent={
                    <EndFrameAnimation
                        endFrameAnimationEnded={endFrameAnimationEnded}
                        endFrameAnimationPlaying={endFrameAnimationPlaying}
                        height={height}
                        width={width}
                        restart={restart}
                    />
                }
                videoSrc={videoSrc}
            />
        </EndFrameWrapper>
    );
};

export default EndFrame;
