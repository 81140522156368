import styled from 'styled-components';
import Button from '../../../../component/common/button';

const StyledTab = styled(Button)`
    border-bottom: 2px solid transparent;
    transition: border-bottom, 200ms;

    ${props =>
        props.isFocused &&
        `
    border-bottom: 2px solid #1d1d1b;
    opacity: 1;
  `};
`;

export default StyledTab;
