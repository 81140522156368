/* eslint-disable prettier/prettier */
// Wrap an element with <Fade></Fade> passing a show/hide variable as show
// component will fade on mount / unmount
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import styled, { css, keyframes } from 'styled-components';

type FadeType = {
    show: boolean,
    children: Node,
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const animateIn = css`
    ${fadeIn}
`;

const animateOut = css`
    ${fadeOut}
`;

const AnimateWrapper = styled.div`
    animation: ${({ show }) => (show ? animateIn : animateOut)} 0.3s;
`;

const Fade = ({ show, children }: FadeType) => {
    const [shouldRender, setRender] = useState(show);

    useEffect(
        () => {
            if (show) setRender(true);
        },
        [show]
    );

    const onAnimationEnd = () => {
        if (!show) setRender(false);
    };

    return shouldRender ? (
        <AnimateWrapper show={show} onAnimationEnd={onAnimationEnd}>
            {children}
        </AnimateWrapper>
    ) : null;
};

export default Fade;
